import './aflac-inputs.scss';

type AflacInputFieldProps = {
  label: string;
  value: string;
  placeholder: string;
  name: string;
  type: string;
  onChange: (e: any) => void;
  onClick: Function;
  onBlur: (e: any) => void;
  minLength?: number;
  maxLength?: number;
  inputMode?: 'text' | 'numeric' | 'email' | 'search' | 'tel';
  pattern: string;
  error?: boolean;
  variant?: string;
};

const AflacInputField = ({
  label,
  name,
  type,
  value,
  placeholder,
  onChange,
  onBlur,
  onClick,
  minLength,
  maxLength,
  inputMode,
  pattern,
  error,
  variant,
}: AflacInputFieldProps) => {
  return (
    <div className={`aflac-input-field-container ${error ? 'input-error' : ''}`}>
      <div className="input-field-label">
        <h3>{label}</h3>
        {variant !== '1' && error && <span className="age-error">*Must be older than 18</span>}
      </div>
      <input
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        inputMode={inputMode}
        minLength={minLength}
        maxLength={maxLength}
        pattern={pattern}
        onChange={onChange}
        onClick={() => onClick(label)}
        onBlur={onBlur}
      />
      {variant === '1' && error && <span className="age-error-variant-1">*Must be older than 18</span>}
    </div>
  );
};

export default AflacInputField;
