import './select-quote-card.scss';
import { useState } from 'react';
import QuoteCard from '../quote-card/QuoteCard';
import SelectField from '../../select-field/SelectField';

type SelectQuoteProps = {
  selectedPlan: { [name: string]: number };
  setSelectedCard: Function;
  setSelectedPlan: Function;
  selectedRatesCard: number;
  setSelectedRatesCard: Function;
  isSelected: boolean;
  ratesOptions: any;
};

const SelectQuoteCard = ({
  selectedPlan,
  selectedRatesCard,
  setSelectedRatesCard,
  setSelectedCard,
  setSelectedPlan,
  isSelected,
  ratesOptions,
}: SelectQuoteProps) => {

  return (
    <div className="select-quote-container" onClick={() => setSelectedCard(-2)}>
      <div className="select-quote-content">
        <div className="select-quote-checkbox">
          <div className={isSelected ? 'checked' : ''}></div>
        </div>
        <h4 className="select-quote-description">Select your own amount</h4>{' '}
      </div>
      {isSelected && (
        <div className="select-rate-dropdown-container">
          <SelectField
            options={ratesOptions}
            handleChange={(e) => {
              setSelectedRatesCard &&
                setSelectedRatesCard(e.target.selectedIndex);
              setSelectedPlan &&
                setSelectedPlan({
                  ...ratesOptions[e.target.selectedIndex - 1],
                });
            }}
            handleBlur={() => {}}
            name="quote-rate"
            placeholder="Rates"
            value={'this'}
          />
        </div>
      )}
      {selectedRatesCard !== -1 && selectedRatesCard !== 1 && (
        <QuoteCard
          isInput={false}
          monthlyCost={selectedPlan.monthlyCost}
          coverage={selectedPlan.coverage}
          interval={selectedPlan.interval}
          isSelected={false}
        />
      )}
    </div>
  );
};

export default SelectQuoteCard;
