import { ReactNode } from 'react';
import "./detail-card.scss"
type DetailCardType = {
  title: string;
  content: ReactNode;
};

const DetailCard = ({ title, content }: DetailCardType) => {
  return (
    <div className="detail-card">
      <div className="detail-card-title">{title}</div>
      <div className="detail-card-content">{content}</div>
    </div>
  );
};

export default DetailCard;
