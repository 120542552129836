import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../../components/common/buttons/PrimaryButton';
import { AnswerFinancialContext } from '../../../../context/AnswerFinancialContext';
import { UserContext } from '../../../../context/UserContext';
import './canopy-failure.scss';
import BackButton from '../../../../components/common/buttons/BackButton';

const CanopyFailure = () => {
  const { device, partnerName } = useContext(UserContext);
  const { setIsCanopy } = useContext(AnswerFinancialContext);
  const navigate = useNavigate();
  const image = require(`../../../../assets/images/partners/${partnerName}/afi/${device}/canopy-failure.png`);

  return (
    <div className="failure-container">
      <h1 className="failure-title">Connection failed</h1>
      <p className="failure-description">
        We weren’t able to get information on your existing coverage. Don’t worry though, we’ll guide you
        through the application process
      </p>
      <div className="failure-image-container">
        <img src={image} />
      </div>

      <div className="failure-buttons-container">
        <PrimaryButton
          text="Try Again"
          onClick={() => {
            sessionStorage.removeItem('afi_import_steps');
            navigate('/full-quote-auto/import/login');
          }}
        />
        <BackButton />
        <div className="failure-skip-button" onClick={() => setIsCanopy(false)}>
          Skip
        </div>
      </div>
    </div>
  );
};

export default CanopyFailure;
