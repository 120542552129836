import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import StepComponent from '../../../step-component/StepComponent';
import { AutoInsuranceContext } from '../../../../../context/AutoInsuranceContext';
import { UserContext } from '../../../../../context/UserContext';
import RadioButtonContainer from '../../../radio-button-container/RadioButtonContainer';
import { updateDriver } from '../../../../../services/helpers/AutoHelpers';

// Images
import { creditScoreImages } from '../../../../../constants/AutoConstants';

export const DriverCreditScore = ({ driverIndex }: {driverIndex: number}) => {
  const { driverInfo, setDriverInfo, updateAutoInsuranceStep } = useContext(AutoInsuranceContext);
  const { partnerName, device } = useContext(UserContext);
  const [creditScore, setCreditScore] = useState(driverInfo[driverIndex]?.creditScore || "750+");
  const navigate = useNavigate();

  const formContent = {
    header: 'Select credit score',
    ImagePaddingTop: '21px',
    ImagePaddingBottom: '0px',
    cardImg: creditScoreImages( partnerName, device ),
    buttonTxt: 'Next',
    onSubmit: () => {
      updateDriver({ creditScore: creditScore }, setDriverInfo, driverIndex);
      if (driverIndex === 0) {
        updateAutoInsuranceStep('driver-age');
        navigate('/auto/driver-age');
        return;
      }

      navigate(`/auto/driver-age-${driverIndex + 1}`);
    },
    fields: [
      <RadioButtonContainer
        options={['750+', '700-749', '650-699', '<649']}
        size="large"
        value={creditScore}
        handleChange={(val) => setCreditScore(val)}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.cardImg}
      />
    </>
  );
};
