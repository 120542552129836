import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import './autocomplete.scss';
/**
 * Autocomplete field
 * @param options Array of objects of any type
 * @param displayValueKey Defining key in each object from the options array of the desired element to search for
 * @example <Autocomplete value={value} setValue={setValue} options={states} displayValueKey={'id'} placeholder="State" />
 */

const autocompleteStyling = {
  borderRadius: '8px',
  border: '1px solid var(--form-border-color)',
  '& svg': {
    margin: '0px',
  },
  '& .MuiInputBase-input': {
    height: '17px',
  },
  '@media (min-width: 576px)': {
    '& .MuiButtonBase-root': {
      height: '30px',
    },
  },
};

const filterOptions = createFilterOptions({
  matchFrom: 'start',
});

const AutocompleteWrapper = ({
  value,
  setValue,
  options,
  displayValueKey,
  placeholder,
  disabled,
}: {
  value: any;
  setValue: Dispatch<SetStateAction<any>>;
  options: any[];
  displayValueKey: string;
  placeholder?: string;
  disabled?: boolean;
}) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [fieldOptions, setFieldOptions] = useState<any[]>(options.map((elem) => elem[displayValueKey]));
 
  useEffect(() => {
    setFieldOptions(options.map((elem) => elem[displayValueKey]));
  }, [options, displayValueKey]);

  const onInputChange = (e: React.SyntheticEvent, val: any) => {
    e !== null && setInputValue(val || '');
    e !== null && setValue(options.find((elem) => elem[displayValueKey] === val) || '');
  };

  return (
    <div className="autocomplete-container">
      <Autocomplete
        sx={autocompleteStyling}
        disablePortal
        inputValue={inputValue}
        disabled={disabled}
        options={fieldOptions}
        fullWidth={true}
        filterOptions={filterOptions}
        onInputChange={onInputChange}
        onChange={onInputChange}
        defaultValue={inputValue}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
      />
    </div>
  );
};

export default AutocompleteWrapper;
