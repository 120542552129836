// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.member-form-container {
  width: 100%;
  border: 1px solid var(--card-border-color);
  border-radius: 8px;
  padding: 12px 16px;
}
.member-form-container .title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 36px;
  font-weight: 700;
  color: var(--secondary-text-color);
}
.member-form-container .title-container svg {
  cursor: pointer;
  flex-shrink: 0;
}

.member-form-container.show-error-border {
  border: 1px solid var(--error-color);
}`, "",{"version":3,"sources":["webpack://./src/components/common/forms/MemberForm/member-form.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,0CAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AACI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kCAAA;AACN;AACM;EACE,eAAA;EACA,cAAA;AACR;;AAIE;EACE,oCAAA;AADJ","sourcesContent":[".member-form-container {\n    width: 100%;\n    border: 1px solid var(--card-border-color);\n    border-radius: 8px;\n    padding: 12px 16px;\n  \n    .title-container {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      margin-bottom: 24px;\n      font-size: 18px;\n      line-height: 36px;\n      font-weight: 700;\n      color: var(--secondary-text-color);\n  \n      svg {\n        cursor: pointer;\n        flex-shrink: 0;\n      }\n    }\n  }\n\n  .member-form-container.show-error-border {\n    border: 1px solid var(--error-color);\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
