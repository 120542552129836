import './app.scss';
import './styles/global.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AddInsurance from './routes/AddInsuranceRoutes';
import { UserProvider } from './context/UserContext';
import LifeInsurance from './routes/LifeInsuranceRoutes';
import AflacInsurance from './routes/AflacRoutes';
import VoluntaryAddInsuranceRoutes from './routes/VoluntaryRoutes';
import AddReport from './pages/reports/AddReport';
import AutoInsurance from './routes/autoInsuranceRoutes';
import AccidentInsurance from './routes/AccidentRoutes';
import { MembersProvider } from './context/MembersContext';
import { Helmet } from 'react-helmet';
import AnswerFinancialRoutes from './routes/AnswerFinancial';
import { AutoInsuranceProvider } from './context/AutoInsuranceContext';
import AnswerFinancialContextProvider from './context/AnswerFinancialContext';

const App = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  return (
    <div className="app" id="app">
      {process.env.REACT_APP_MODE !== 'production' && (
        <Router basename="/reports">
          <Routes>
            <Route path="/accidental-death" element={<AddReport />} />
          </Routes>
        </Router>
      )}

      {/* Testing Route */}
      {process.env.REACT_APP_MODE === 'development' && (
        <Router basename="/testing">
          <Routes>
            <Route path="/" element={<></>} />
          </Routes>
        </Router>
      )}

      <Router basename="/app">
        <UserProvider>
          <MembersProvider>
            <Helmet>
              <link rel="icon" type="image/svg" href="/assets/favicon.svg" sizes="16x16" />
            </Helmet>
            <AutoInsuranceProvider>
              <AnswerFinancialContextProvider>
                <AutoInsurance path="/auto/*" />
                <AnswerFinancialRoutes />
              </AnswerFinancialContextProvider>
            </AutoInsuranceProvider>
            <AddInsurance />
            <VoluntaryAddInsuranceRoutes />
            <LifeInsurance />
            <AflacInsurance />
          </MembersProvider>
        </UserProvider>
      </Router>
    </div>
  );
};

export default App;
