import React from 'react';
import './modal-card.scss';
import CloseIcon from '../../../assets/common/close-icon.svg';
const ModalCardContainer = (props: {
  content: React.ReactNode;
  title: string;
  showModal: boolean;
  handleToggle: () => void;
}) => {
  return (
    <div className={`modal-card-container ${props.showModal ? 'show-modal' : ''}`}>
      <div className="modal-card-wrapper">
        <div className="modal-card">
          <div className="modal-card-title-container">
            <CloseIcon onClick={() => props.handleToggle()} />
            <h1 className="modal-card-title">{props.title}</h1>
          </div>
          <div className="modal-card-content">{props.content}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalCardContainer;
