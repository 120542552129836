export const dependentsImages = (partner: string, device: string) => {
  const DependentsImages =
    require(`../assets/images/partners/${partner}/upsell/${device}/dependents.svg`).default;

  return <DependentsImages />;
};

export const debtImages = (partner: string, device: string) => {
  const DebtImages =
    require(`../assets/images/partners/${partner}/upsell/${device}/debt.svg`).default;

  return <DebtImages />;
};

export const healthImages = (partner: string, device: string) => {
  const HealthImages =
    require(`../assets/images/partners/${partner}/upsell/${device}/health.svg`).default;

  return <HealthImages />;
};

export const smokeImages = (partner: string, device: string) => {
  const SmokeImages =
    require(`../assets/images/partners/${partner}/upsell/${device}/smoke.svg`).default;

  return <SmokeImages />;
};

export const BLOCKED_UPSELL_PARTNERS = ['carshield'];
