import { useContext } from 'react';
import { AddInsuranceContext } from '../../../../context/AddInsuranceContext';
import { MembersContext } from '../../../../context/MembersContext';
import Beneficiary from '../common-steps/beneficiary/Beneficiary';

const AddBeneficiary = () => {
  const {
    beneficiariesInfo,
    setBeneficiariesInfo,
    showBeneficiaryModal,
    selectedBeneficiary,
    setSelectedBeneficiary,
    setShowBeneficiaryModal,
  } = useContext(MembersContext);
  const { setProgressStep } = useContext(AddInsuranceContext);
  return (
    <Beneficiary
      beneficiariesInfo={beneficiariesInfo}
      setBeneficiariesInfo={setBeneficiariesInfo}
      nextProgressStep={6}
      setProgressStep={setProgressStep}
      showBeneficiaryModal={showBeneficiaryModal}
      setShowBeneficiaryModal={setShowBeneficiaryModal}
      selectedBeneficiary={selectedBeneficiary}
      setSelectedBeneficiary={setSelectedBeneficiary}
      primaryLink={'/accidental-death/amounts'}
      secondaryLink={`/accidental-death/terms`}
    />
  );
};

export default AddBeneficiary;
