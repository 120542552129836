// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canopy-coverages-container {
  font-family: var(--primary-font);
  padding: 7px;
}
.canopy-coverages-container section.coverages-content {
  width: 100%;
}
.canopy-coverages-container div.header-container {
  margin-bottom: 0px;
}
.canopy-coverages-container h1.coverages-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
  padding: 0px 8px;
  padding-bottom: 30px;
}
.canopy-coverages-container .canopy-quotes-error {
  padding: 0px 0px 30px;
  font-size: 16px;
}
.canopy-coverages-container .coverages-button-container {
  padding-top: 16px;
  display: grid;
  gap: 8px;
}
.canopy-coverages-container .coverages-button-container button.secondary-button {
  color: var(--secondary-text-color);
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
}
.canopy-coverages-container .coverages-button-container a {
  text-decoration: none;
}
.canopy-coverages-container .form-question-container .question-field-container {
  margin-bottom: 10px;
}
.canopy-coverages-container .form-question-container .input-field-container div.error {
  position: static;
}
@media (min-width: 840px) {
  .canopy-coverages-container h1.coverages-title {
    font-size: 28px;
    line-height: 42px;
  }
  .canopy-coverages-container .canopy-quotes-error {
    font-size: 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/answer-financial-insurance/canopy-connect/CanopyCoverages/canopy-coverages.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,YAAA;AACF;AAAE;EACE,WAAA;AAEJ;AACE;EACE,kBAAA;AACJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,oBAAA;AAAJ;AAGE;EACE,qBAAA;EACA,eAAA;AADJ;AAIE;EACE,iBAAA;EACA,aAAA;EACA,QAAA;AAFJ;AAII;EACE,kCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAFN;AAKI;EACE,qBAAA;AAHN;AAQI;EACE,mBAAA;AANN;AASM;EACE,gBAAA;AAPR;AAaE;EACE;IACE,eAAA;IACA,iBAAA;EAXJ;EAcE;IACE,eAAA;EAZJ;AACF","sourcesContent":[".canopy-coverages-container {\n  font-family: var(--primary-font);\n  padding: 7px;\n  section.coverages-content {\n    width: 100%;\n  }\n\n  div.header-container {\n    margin-bottom: 0px;\n  }\n\n  h1.coverages-title {\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 34px;\n    padding: 0px 8px;\n    padding-bottom: 30px;\n  }\n\n  .canopy-quotes-error {\n    padding: 0px 0px 30px;\n    font-size: 16px;\n  }\n\n  .coverages-button-container {\n    padding-top: 16px;\n    display: grid;\n    gap: 8px;\n\n    button.secondary-button {\n      color: var(--secondary-text-color);\n      font-weight: 700;\n      font-size: 16px;\n      line-height: 30px;\n    }\n\n    a {\n      text-decoration: none;\n    }\n  }\n\n  .form-question-container {\n    .question-field-container {\n      margin-bottom: 10px;\n    }\n    .input-field-container {\n      div.error {\n        position: static;\n      }\n    }\n  }\n\n  //desktop\n  @media (min-width: 840px) {\n    h1.coverages-title {\n      font-size: 28px;\n      line-height: 42px;\n    }\n\n    .canopy-quotes-error {\n      font-size: 18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
