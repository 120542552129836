import React from 'react';
import PageTitle from '../../common/page-title/PageTitle';
import Header from '../../common/Header/Header';
import './vertical.scss';
import HeroCard from '../../common/hero-card/HeroCard';

type Props = {
  title: string;
  subtitle?: string;
  img: React.ReactNode;
  description?: string | React.ReactNode;
  content: React.ReactNode;
  headerContent: { logo: React.ReactNode | undefined; title: string };
  showInfoIcon: boolean;
  onIconClick?: Function;
  sameRow?: boolean;
};

const VerticalPageLayout = ({
  title,
  subtitle,
  img,
  description,
  content,
  headerContent,
  showInfoIcon,
  onIconClick,
  sameRow,
}: Props) => {
  return (
    <div className="vertical-layout-container">
      <Header headerContent={headerContent} showIcon={showInfoIcon} onIconClick={onIconClick} />
      {sameRow ? (
        <HeroCard title={title} cardImg={img} />
      ) : (
        <>
          <div className="vertical-layout-title">
            <PageTitle text={title} />
          </div>
          <div className="vertical-layout-image">{img}</div>
        </>
      )}

      <div
        className={`vertical-layout-content-wrapper ${
          description ? 'with-description' : 'without-description'
        }`}
      >
        {subtitle && <div className="vertical-layout-subtitle">{subtitle}</div>}
        {description &&
          (typeof description === 'string' ? (
            <p
              className="vertical-layout-description"
              dangerouslySetInnerHTML={{ __html: description || '' }}
            ></p>
          ) : (
            description
          ))}
        <div className="vertical-layout-content">{content}</div>
      </div>
    </div>
  );
};

export default VerticalPageLayout;
