// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-container {
  display: grid;
  align-items: center;
  gap: 12px;
  font-family: var(--primary-font);
  font-style: normal;
  color: var(--secondary-text-color);
}
.success-container .success-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
.success-container .success-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
}
.success-container .success-svg-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.success-container .success-svg-container svg {
  margin-bottom: 10px;
}
.success-container .success-buttons-container {
  display: grid;
  gap: 14px;
}
.success-container button {
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
}
@media (min-width: 580px) {
  .success-container {
    gap: 24px;
  }
  .success-container .success-title {
    font-size: 28px;
  }
  .success-container .success-description {
    font-size: 16px;
  }
  .success-container button {
    font-size: 18px;
    height: 64px;
  }
}
@media (min-width: 840px) {
  .success-container .success-description {
    font-size: 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/answer-financial-insurance/canopy-connect/CanopySuccess/canopy-success.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,gCAAA;EACA,kBAAA;EACA,kCAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AADJ;AAEI;EACE,mBAAA;AAAN;AAGE;EACE,aAAA;EACA,SAAA;AADJ;AAIE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAFJ;AAME;EA1CF;IA2CI,SAAA;EAHF;EAKE;IACE,eAAA;EAHJ;EAKE;IACE,eAAA;EAHJ;EAME;IACE,eAAA;IACA,YAAA;EAJJ;AACF;AAQE;EACE;IACE,eAAA;EANJ;AACF","sourcesContent":[".success-container {\n  display: grid;\n  align-items: center;\n  gap: 12px;\n  font-family: var(--primary-font);\n  font-style: normal;\n  color: var(--secondary-text-color);\n\n  .success-title {\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 30px;\n    text-align: center;\n  }\n\n  .success-description {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 28px;\n    text-align: center;\n  }\n\n  .success-svg-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    svg {\n      margin-bottom: 10px;\n    }\n  }\n  .success-buttons-container {\n    display: grid;\n    gap: 14px;\n  }\n\n  button {\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 30px;\n  }\n\n  // Tablet\n  @media (min-width: 580px) {\n    gap: 24px;\n\n    .success-title {\n      font-size: 28px;\n    }\n    .success-description {\n      font-size: 16px;\n    }\n\n    button {\n      font-size: 18px;\n      height: 64px;\n    }\n  }\n\n  // Desktop\n  @media (min-width: 840px) {\n    .success-description {\n      font-size: 18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
