import { useEffect, useState, useContext } from 'react';
import './side-progress-bar.scss';
import CheckMark from '../../../../assets/common/white-check.svg';
import ChevronDown from '../../../../assets/common/chevron-down.svg';
import ChevronUp from '../../../../assets/common/chevron-up.svg';
import { AnswerFinancialContext } from '../../../../context/AnswerFinancialContext';
import _ from 'lodash';

type SideProgressBarProps = {
  navigationData: any;
  currentStep: string;
  currentSection: string;
};

type AfiNavigationStep = {
  name: string;
  steps: string[];
};

type AfiNavigationMapping = {
  sectionName: string;
  identifier: string;
  steps: Array<AfiNavigationStep>;
};

const defaultAfiReverseMapping: Array<AfiNavigationMapping> = [
  {
    sectionName: 'Basics',
    identifier: 'basics',
    steps: [
      {
        name: 'About yourself',
        steps: ['basicsname'],
      },
      {
        name: 'Connect your account',
        steps: ['basicsaddress'],
      },
    ],
  },
  {
    sectionName: 'Drivers',
    identifier: 'drivers',
    steps: [
      {
        name: 'Driver details',
        steps: [
          'driversdetails',
          'driversdiscounts',
          'drivershistory',
          'driversincidents',
          'driversincidents-summary',
        ],
      },
      { name: 'Additional drivers', steps: ['driversdrivers-summary'] },
    ],
  },
  {
    sectionName: 'Your cars',
    identifier: 'cars',
    steps: [],
  },
  {
    sectionName: 'Quotes',
    identifier: 'quotes',
    steps: [],
  },
];

const SideProgressBar = ({ navigationData, currentStep, currentSection }: SideProgressBarProps) => {
  const { isCanopy } = useContext(AnswerFinancialContext);
  const sections = Object.keys(navigationData);
  const currentSectionIndex = sections.indexOf(currentSection);
  const toggleInit: { [key: string]: boolean } = {};
  sections.forEach((elem) => (toggleInit[elem] = currentSection === elem));
  const [toggleStatus, setToggleStatus] = useState<{ [key: string]: boolean }>(toggleInit);
  const [afiReverseMapping, setAfiReverseMapping] = useState(defaultAfiReverseMapping);

  const getStepStatus = (sectionStatus: string, foundStep: boolean, fields: Array<string>) => {
    if (sectionStatus === 'complete') {
      return 'complete';
    }

    if (sectionStatus === 'started') {
      if (isCanopy && currentSection === 'basics') {
        return 'complete';
      }

      if (fields.indexOf(`${currentSection}${currentStep}`) != -1) {
        return 'started';
      }
      if (!foundStep) {
        return 'complete';
      }
      if (foundStep) {
        return 'empty';
      }
    }
    return 'empty';
  };

  useEffect(() => {
    let afiMappingCopy = [...defaultAfiReverseMapping];
    if (!isCanopy) {
      afiMappingCopy.splice(3, 0, {
        sectionName: 'Coverage',
        identifier: 'coverage',
        steps: [
          { name: 'Current policy', steps: ['coverageexisting'] },
          { name: 'Credit', steps: ['coveragecredit'] },
          { name: 'Choose your coverage', steps: ['coveragenew-coverage'] },
        ],
      });
    }
    setAfiReverseMapping(afiMappingCopy);
  }, [isCanopy]);

  useEffect(() => {
    const tmp: { [key: string]: boolean } = {};
    sections.forEach((elem) => (tmp[elem] = currentSection === elem));
    setToggleStatus(tmp);
  }, [currentSection]);

  const getSectionSteps = (steps: Array<AfiNavigationStep>, sectionStatus: string) => {
    let foundStep = false;
    return (
      <div className="section-steps-container">
        {steps.map((step) => {
          if (step.steps.indexOf(`${currentSection}${currentStep}`) != -1 && !foundStep) {
            foundStep = true;
          }
          return (
            <div key={step.name} className="section-steps-element">
              {progressCircle(getStepStatus(sectionStatus, foundStep, step.steps))}
              {step.name}
            </div>
          );
        })}
      </div>
    );
  };

  const getSectionStatus = (index: number, currentIndex: number) => {
    if (currentSection === 'quotes') {
      return 'complete';
    }
    return index < currentIndex ? 'complete' : index === currentIndex ? 'started' : 'empty';
  };

  const progressCircle = (status: string) => {
    return <div className={`progress-circle circle-${status}`}>{status === 'complete' && <CheckMark />}</div>;
  };

  const toggleSection = (section: string) => {
    const tmp = { ...toggleStatus };
    tmp[section] = !tmp[section];
    setToggleStatus(tmp);
  };

  return (
    <div className="side-progress-bar-wrapper">
      {afiReverseMapping.map((section, index) => {
        const sectionStatus = getSectionStatus(index, currentSectionIndex);
        return (
          <div key={section.identifier} className="side-progress-bar-section">
            <div className="side-progress-bar-header">
              {progressCircle(sectionStatus)}
              <div className="side-progress-section-content">
                {section.sectionName}
                {section.steps.length > 0 && (
                  <div className="side-progress-section-expand">
                    {toggleStatus[section.identifier] ? (
                      <ChevronUp onClick={() => toggleSection(section.identifier)} />
                    ) : (
                      <ChevronDown onClick={() => toggleSection(section.identifier)} />
                    )}
                  </div>
                )}
              </div>
            </div>
            {section.steps.length > 0 &&
              toggleStatus[section.identifier] &&
              getSectionSteps(section.steps, sectionStatus)}
          </div>
        );
      })}
    </div>
  );
};

export default SideProgressBar;
