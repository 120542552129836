import { states } from '../../constants';
import { aflacDevice } from '../../services/helpers/CommonHelpers';

export const AFLAC_DEVICES = {
  tablet: 768,
  desktop: 1024,
};

export const SpartanLogo = (deviceWidth: number) => {
  let device = aflacDevice(deviceWidth);
  const SpartanImg = require(`../../assets/common/aflac/${device}/spartan.svg`).default;
  return <SpartanImg />;
};

export const DetailImages = (device: string, name: string) => {
  const DetailImg = require(`../../assets/common/aflac/${device}/${name}.svg`).default;

  return <DetailImg />;
};

export const StartImages = (deviceWidth: number, variant?: string) => {
  let device = aflacDevice(deviceWidth);
  const StartIcon = require(`../../assets/common/${
    variant ? 'aflacVariant1' : 'aflac'
  }/${device}/start-arrow.svg`).default;

  return <StartIcon />;
};

export const AflacDuckImages = (deviceWidth: number) => {
  let device = aflacDevice(deviceWidth);
  const AflacDuckIcon = require(`../../assets/common/aflac/${device}/aflac-duck.svg`).default;

  return <AflacDuckIcon />;
};

export const spartanDetails = [
  {
    title: 'Accidents happen',
    text: 'Aflac pays you cash to help with costs related to a covered accident or injury. Detailed policy coverage is available after entering in your state of residence.',
    icon: 'accident',
  },
  {
    title: 'Great option for athletes',
    text: 'If you lead an active life and play sports, work out, or participate in other similar activities having extra coverage to cover a deductible can be a smart idea.',
    icon: 'sport',
  },
  {
    title: 'Quick cash payments',
    text: "Get paid quickly for covered claims through Aflac's claims process. Aflac pays claims as fast as possible to help cover unexpected expenses.",
    icon: 'cash',
  },
];

export const coverageOptions = [
  { id: 'ind', value: 'Me' },
  { id: 'ind_sps', value: ' Me & My Spouse' },
  { id: 'ind_chd', value: 'Me & My Children' },
  { id: 'ind_fml', value: 'Me, My Spouse & My Children' },
];

export const aflacStates = states.map((state) => ({ value: state.id, id: state.value }));
