import React, { createContext, FC, useState } from 'react';
import { AddBeneficiary } from '../components/interfaces/AddTypeInterfaces';
import {
  defaultCoveragePlan,
  VOL_ADD_FULL_STEPS,
  voluntaryAddStepIds,
} from '../constants/VoluntaryConstants';
import { coveragePlanType, SelectedCoverageInterface } from '../components/interfaces/VoluntaryInterfaces';
import { FamilyMemberInterface, UserInterface } from '../components/interfaces/CommonInterfaces';
import { getProgressStep } from '../services/helpers/CommonHelpers';
type VoluntaryAddContextType = {
  policyId: string;
  setPolicyId: Function;
  progressStep: number;
  setProgressStep: Function;
  totalSteps: number;
  selectedCoveragePlan: SelectedCoverageInterface;
  setSelectedCoveragePlan: React.Dispatch<React.SetStateAction<SelectedCoverageInterface>>;
  completedAmounts: boolean;
  setCompletedAmounts: React.Dispatch<React.SetStateAction<boolean>>;
  showInfoModal: boolean;
  setShowInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  coveredMembers: (FamilyMemberInterface | UserInterface)[];
  setCoveredMembers: React.Dispatch<React.SetStateAction<(FamilyMemberInterface | UserInterface)[]>>;
  voluntaryBeneficiaries: AddBeneficiary[];
  setVoluntaryBeneficiaries: React.Dispatch<React.SetStateAction<AddBeneficiary[]>>;
  lastStep: string;
  updateLastStep: (newStep: string) => void;
};

const VoluntaryAddContext = createContext<VoluntaryAddContextType>({
  policyId: '',
  setPolicyId: Function,
  progressStep: 0,
  setProgressStep: Function,
  totalSteps: VOL_ADD_FULL_STEPS,
  selectedCoveragePlan: defaultCoveragePlan,
  setSelectedCoveragePlan: (data: React.SetStateAction<coveragePlanType>) => {},
  completedAmounts: false,
  setCompletedAmounts: (data: React.SetStateAction<boolean>) => {},
  showInfoModal: false,
  setShowInfoModal: (data: React.SetStateAction<boolean>) => {},
  coveredMembers: [],
  setCoveredMembers: (data: React.SetStateAction<(FamilyMemberInterface | UserInterface)[]>) => {},
  voluntaryBeneficiaries: [],
  setVoluntaryBeneficiaries: (data: React.SetStateAction<AddBeneficiary[]>) => {},
  lastStep: '',
  updateLastStep: () => null,
});

interface Props {
  children: React.ReactNode;
}

const VoluntaryAddProvider: FC<Props> = ({ children }) => {
  const [policyId, setPolicyId] = useState('');
  const [progressStep, setProgressStep] = useState(0);
  const totalSteps = VOL_ADD_FULL_STEPS;
  const [selectedCoveragePlan, setSelectedCoveragePlan] = useState(defaultCoveragePlan);
  const [voluntaryBeneficiaries, setVoluntaryBeneficiaries] = useState<AddBeneficiary[]>([]);
  const [completedAmounts, setCompletedAmounts] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [coveredMembers, setCoveredMembers] = useState<(FamilyMemberInterface | UserInterface)[]>([]);

  const [lastStep, setLastStep] = useState('');

  const updateLastStep = (newStep: string) => {
    const oldValue = getProgressStep(voluntaryAddStepIds, lastStep);
    const newValue = getProgressStep(voluntaryAddStepIds, newStep);
    // We only want to overwrite the last step if it's further ahead on the list
    // Ex: If the user is at the 4th step and navigates backwards to the 2nd step
    // we still want the value for lastStep to be 4
    if (newValue > oldValue) {
      setLastStep(newStep);
    }
  };

  return (
    <VoluntaryAddContext.Provider
      value={{
        policyId,
        setPolicyId,
        progressStep,
        setProgressStep,
        totalSteps,
        selectedCoveragePlan,
        setSelectedCoveragePlan,
        voluntaryBeneficiaries,
        setVoluntaryBeneficiaries,
        completedAmounts,
        setCompletedAmounts,
        showInfoModal,
        setShowInfoModal,
        coveredMembers,
        setCoveredMembers,
        lastStep,
        updateLastStep,
      }}
    >
      {children}
    </VoluntaryAddContext.Provider>
  );
};

export { VoluntaryAddContext, VoluntaryAddProvider };
