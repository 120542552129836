import React, { useState, useEffect } from 'react';
import getAddReports from '../../api/reports';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import { Disclaimer } from '../../components/common/disclaimer/Disclaimer';
import DateField from '../../components/common/forms/DateField/DateField';
import InputField from '../../components/common/forms/InputField/InputField';
import './add-report.scss';

const AddReport = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [_, setErrors] = useState([]);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadFiles, setUploadFiles] = useState(false);

  const onSubmit = async () => {
    setError('');
    setMessage('');
    setIsLoading(true);
    const data = {
      email,
      password,
      uploadFiles,
      startDate,
      endDate,
    };
    try {
      const resp = await getAddReports(data);
      if (resp) {
        setMessage('Reports generated and sent via email');
      } else {
        setError('Invalid request. Please check data and try again');
      }
      setIsLoading(false);
    } catch (e) {
      setError('Invalid request. Please check data and try again');
      setIsLoading(false);
    }
  };

  return (
    <div className="add-report-container">
      <div className="add-report-wrapper">
        <h1>AD&D Reporting</h1>
        <InputField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          type="email"
          name="email"
          setErrors={setErrors}
        />
        <InputField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          type="password"
          name="password"
          setErrors={setErrors}
        />
        <DateField
          value={startDate}
          setValue={setStartDate}
          errors={{}}
          setErrors={setErrors}
          placeholder="Start Date"
        />
        <DateField
          value={endDate}
          setValue={setEndDate}
          errors={{}}
          setErrors={setErrors}
          placeholder="End Date (optional - if empty it's gonna be for a month)"
        />
        <Disclaimer
          checked={uploadFiles}
          setChecked={setUploadFiles}
          labelText="Upload files to the SFTP server"
          labelAction={() => {
            setUploadFiles((prev) => !prev);
          }}
        />
        <PrimaryButton text="Get Reports" onClick={onSubmit} loading={isLoading} disabled={isLoading} />
        {error && <div className="error">{error}</div>}
        {message && <div>{message}</div>}
      </div>
    </div>
  );
};

export default AddReport;
