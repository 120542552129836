import { formatPhoneNumber } from '../../../../services/helpers/inputFieldHelpers';
import { FormikProps, FormikValues } from 'formik';
import InputField from '../InputField/InputField';
import { useEffect, useState } from 'react';

type PhoneProps = {
  placeholder: string;
  name: string;
  formikConfig: FormikProps<FormikValues>;
};

const PhoneField = ({ name, placeholder, formikConfig }: PhoneProps) => {
  const { handleChange, touched, setFieldValue, errors, setErrors, setFieldTouched } = formikConfig;
  const [displayValue, setDisplayValue] = useState<string>(
    formatPhoneNumber(formikConfig.values?.[name] || '') as string,
  );

  useEffect(() => {
    setDisplayValue(formatPhoneNumber(formikConfig.values?.[name] || '') as string);
  }, [formikConfig.values[name]]);

  return (
    <div>
      <InputField
        type="tel"
        inputMode="tel"
        name={name}
        placeholder={placeholder}
        value={displayValue}
        onChange={(e) => {
          // Have two versions: one as "(###) - ### - ####"" for display and one as "##########"" for the API call
          const unFormatedNumber = Array.from(e.target.value)
            .filter((char) => /\d/.test(String(char)))
            .join('');
          const formatedNumber = formatPhoneNumber(e.target.value) || '';
          setDisplayValue(formatedNumber);
          handleChange(unFormatedNumber);
          setFieldValue(name, unFormatedNumber);
        }}
        minLength={10}
        maxLength={14}
        errors={touched[name] ? errors : {}}
        setErrors={setErrors}
        onBlur={() => setFieldTouched(name, true)}
      />
    </div>
  );
};

export default PhoneField;
