import { aflacDevice } from '../services/helpers/CommonHelpers';

export const CarshieldAccidentLogo = (deviceWidth: number) => {
  let device = aflacDevice(deviceWidth);
  const CarshieldImg = require(`../assets/common/aflac/${device}/carshield-cares.svg`).default;
  return <CarshieldImg />;
};

export const AflacLogo = (deviceWidth: number) => {
  let device = aflacDevice(deviceWidth);
  const AflacImg = require(`../assets/common/aflac/${device}/aflac-logo-black.svg`).default;
  return <AflacImg />;
};

export const carshieldAccidentDetails = [
  {
    title: 'Accidents happen',
    text: 'Aflac’s accident insurance helps protect your family by covering a wide span of events, from emergency situations to common accidents. Depending on your family size, location, and coverage goals, Aflac has a plan to meet your needs.',
    icon: 'accident',
  },
  {
    title: 'Health insurance supplement',
    text: 'These policies are a good fit for individuals and families with a high deductible primary health plan. Aflac offers cash payments that you could put towards the cost your major medical insurance doesn’t cover.',
    icon: 'sport',
  },
  {
    title: 'Quick claims processing',
    text: 'Submit your claims online 24/7 through Aflac’s easy-to-navigate web portal. Aflac pays your claims as quickly as possible to help cover unexpected expenses.',
    icon: 'cash',
  },
];

export const CarshieldAccidentMeta = {
  PAGE_TITLE: 'Accident Insurance for Carshield Customers',
  META_DESCRIPTION: 'Accident insurance from Aflac available for Carshield customers. Get insurance today.',
};

export const SpartanAccidentMeta = {
  PAGE_TITLE: 'Accident Insurance for Spartan Racers',
  META_DESCRIPTION: 'Accident insurance from Aflac available for Spartan racers. Get insurance today.',
};
