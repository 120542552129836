import { useContext } from 'react';
import StepComponent from '../../step-component/StepComponent';
import { LifeInsuranceContext } from '../../../../context/LifeInsuranceContext';
import { useNavigate } from 'react-router-dom';
import AmountField from '../../forms/AmountField/AmountField';
import { useFormik } from 'formik';
import { amountFieldSchema } from '../../../schemas/lifeSchemas';
import { UserContext } from '../../../../context/UserContext';
import { incomeImages } from '../../../../constants/LifeConstants';

const income = '';

const Income = () => {
  const { lifeResponses, setLifeResponses } = useContext(LifeInsuranceContext);
  const { partnerName, device } = useContext(UserContext);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      income: typeof lifeResponses.income === "number" ? `${lifeResponses.income}` : income,
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: amountFieldSchema,
    onSubmit: () => {},
  });

  const { values, setFieldValue } = formik;

  const handleSubmit = () => {
    setLifeResponses((prev) => ({
      ...prev,
      income: parseInt(values.income),
    }));
    navigate('/life/savings');
  };

  const formContent = {
    header: 'What is your annual income?',
    img: incomeImages(partnerName, device),
    buttonTxt: 'Continue',
    disableButton: values.income.length < 1,
    onSubmit: handleSubmit,
    fields: [
      <AmountField
        placeholder="Enter Income"
        name="income"
        onSubmit={handleSubmit}
        value={values.income}
        setFieldValue={setFieldValue}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.img}
        disabledButton={formContent.disableButton}
      />
    </>
  );
};

export default Income;
