import _ from 'lodash';
import { AfiNavigationType } from '../../components/interfaces/AFIInterfaces';
import {
  AfiNavigation,
  customCompCollStates,
  incidentsStep,
} from '../../constants/AFIConstants';
import { customStateOptions } from '../../constants/AfiStateQuestions';
import {
  bodilyInjuryLimits,
  collisionDeductible,
  comprehensiveDeductible,
  coveragesLoanLeaseGap,
  coveragesRental,
  coveragesTowing,
  insuranceCarrier,
  medPay,
  pip,
  primaryBodilyInjuryLimit,
  primaryPropertyDamage,
  underinsuredMotoristsBodilyInjury,
  uninsuredBodilyInjuryLimit,
  uninsuredPropertyDamage,
} from '../../utils/afi/questions/coverage';

import { principalOperator } from '../../utils/afi/questions/vehicle';
import { getAfiDriverNames } from './AFIHelpers';
import {
  customizeDefault,
  customizeQuestionOptions,
  customizeQuestionOptionsWithDefault,
} from './AFIQuestionBuilder';
import {
  driverAAAMember,
  driverDefensiveDiscount,
  driverGoodStudentDiscount,
  driverStudentLivingAway,
  driverTrainingDiscount,
  incidentDescription,
} from '../../utils/afi/questions/driver';
import { getAgeFromBirthday } from './CommonHelpers';
import Tooltip from '../../components/common/tooltip/Tooltip';

export const toggleDriverSteps = (navigationObject: AfiNavigationType, newValues: any) => {
  const newObj = _.cloneDeep(navigationObject);
  //reset the steps so they never get added randomly to the navigation object
  delete newObj.drivers.steps.incidents;

  if (newValues.driverIncidents === 'Y') {
    if (!newObj.drivers.steps.incidents) {
      let navigationKeyValues = Object.entries(newObj.drivers.steps);
      navigationKeyValues.push(['incidents', incidentsStep]);

      let newNavigationObject = Object.fromEntries(navigationKeyValues);
      _.set(newObj, 'drivers.steps', newNavigationObject);
    }
  }
  return newObj;
};

export const toggleDiscountsByAge = (navigationObject: AfiNavigationType, newValues: any) => {
  const newObj = _.cloneDeep(navigationObject);
  const age = getAgeFromBirthday(newValues?.driverDob);
  _.set(
    newObj,
    ['drivers', 'steps', 'discounts', 'formSections', 0, 'fields'],
    age > 25
      ? [driverAAAMember, driverTrainingDiscount, driverDefensiveDiscount]
      : [
          driverAAAMember,
          driverTrainingDiscount,
          driverDefensiveDiscount,
          driverGoodStudentDiscount,
          driverStudentLivingAway,
        ],
  );
  return newObj;
};

export const updateCoverageSteps = (navigationObject: AfiNavigationType, newValues: any) => {
  const newObj = _.cloneDeep(navigationObject);

  let state = newValues.state || 'default';

  if (!(state in customStateOptions)) {
    state = 'default';
  }

  const customFields = customStateOptions[state];
  const defaultFields = customStateOptions.default;

  let defaultValues = {};

  // If coverage doesn't exist in the navigation object, we don't want to create it
  // This can happen inside the Canopy flow, where we don't have any info about "coverage" navigation
  // Running this sequence will only modify the coverage options for the normal flow, or any other flow which contains the coverages step
  if (customFields && 'coverage' in navigationObject) {
    const customQuestions = {
      insuranceCarrier: customizeQuestionOptions(
        insuranceCarrier,
        customFields.insuranceCarrier || defaultFields.insuranceCarrier,
      ),
      priorInsuranceBodilyInjuryLimits: customizeQuestionOptionsWithDefault(
        defaultValues,
        bodilyInjuryLimits,
        customFields.priorInsuranceBodilyInjuryLimits || defaultFields.priorInsuranceBodilyInjuryLimits,
      ),
      currentBodilyInjuryLimits: customizeQuestionOptionsWithDefault(
        defaultValues,
        primaryBodilyInjuryLimit,
        customFields.bodilyInjuryLiability || defaultFields.bodilyInjuryLiability,
      ),
      propertyDamageLiability: customizeQuestionOptionsWithDefault(
        defaultValues,
        primaryPropertyDamage,
        customFields.propertyDamageLiability || defaultFields.propertyDamageLiability,
      ),
      medPay: customizeQuestionOptionsWithDefault(
        defaultValues,
        medPay,
        customFields.medPay || defaultFields.medPay,
      ),
      pip: customizeQuestionOptionsWithDefault(defaultValues, pip, customFields.pip || defaultFields.pip),
      uninsuredMotoristsBodilyInjury: customizeQuestionOptionsWithDefault(
        defaultValues,
        uninsuredBodilyInjuryLimit,
        customFields.uninsuredMotoristsBodilyInjury || defaultFields.uninsuredMotoristsBodilyInjury,
      ),
      uninsuredMotoristsPropertyDamage: customizeQuestionOptionsWithDefault(
        defaultValues,
        uninsuredPropertyDamage,
        customFields.uninsuredMotoristsPropertyDamage || defaultFields.uninsuredMotoristsPropertyDamage,
      ),
      underinsuredMotoristsBodilyInjury: customizeQuestionOptionsWithDefault(
        defaultValues,
        underinsuredMotoristsBodilyInjury,
        customFields.underinsuredMotoristsBodilyInjury || defaultFields.underinsuredMotoristsBodilyInjury,
      ),
    };

    _.set(newObj, 'coverage.steps[existing].formSections[0].fields[0]', customQuestions.insuranceCarrier);
    _.set(
      newObj,
      'coverage.steps[existing].formSections[2].fields[0]',
      customQuestions.priorInsuranceBodilyInjuryLimits,
    );
    _.set(
      newObj,
      'coverage.steps[new-coverage].formSections[0].fields[0]',
      customQuestions.currentBodilyInjuryLimits,
    );
    _.set(
      newObj,
      'coverage.steps[new-coverage].formSections[0].fields[1]',
      customQuestions.propertyDamageLiability,
    );
    _.set(newObj, 'coverage.steps[new-coverage].formSections[0].fields[2]', customQuestions.medPay);
    _.set(newObj, 'coverage.steps[new-coverage].formSections[0].fields[3]', customQuestions.pip);
    _.set(
      newObj,
      'coverage.steps[new-coverage].formSections[1].fields[0]',
      customQuestions.uninsuredMotoristsBodilyInjury,
    );
    _.set(
      newObj,
      'coverage.steps[new-coverage].formSections[1].fields[1]',
      customQuestions.uninsuredMotoristsPropertyDamage,
    );
    _.set(
      newObj,
      'coverage.steps[new-coverage].formSections[1].fields[2]',
      customQuestions.underinsuredMotoristsBodilyInjury,
    );
  }

  if ('incidents' in customFields) {
    _.assign(newObj.drivers.steps, { incidents: incidentsStep });
    _.set(
      newObj,
      ['drivers', 'steps', 'incidents', 'formSections', 1, 'fields', 0],
      customizeQuestionOptions(incidentDescription, customFields.incidents),
    );
  }

  return { newNavigation: newObj, newValues: defaultValues };
};

export const updateCarsSteps = (navigationObject: AfiNavigationType, newValues: any) => {
  const newObj = _.cloneDeep(navigationObject);
  const newDrivers = getAfiDriverNames(newValues.drivers);

  _.set(
    newObj,
    ['cars', 'steps', 'details', 'formSections', 3, 'fields', 0],
    customizeQuestionOptions(principalOperator, newDrivers),
  );
  return newObj;
};

export const addCanopyCarFieldsToCoverage = (navigationObject: AfiNavigationType, newValues: any) => {
  const newObj = _.cloneDeep(navigationObject);
  const cars = newValues.cars.map((item: { [key: string]: { [key: string]: string } }) => {
    if (item.details.vehicleMake && item.details.vehicleModel && item.details.vehicleYear) return item;
  });
  let state = newValues.basics.address.state || 'default';

  if (!(state in customStateOptions)) {
    state = 'default';
  }

  cars.forEach((item: any, index: number) => {
    if (item) {
      const carName = `${item.details.vehicleMake} ${item.details.vehicleModel} ${item.details.vehicleYear}`;

      if (carName) {
        const shouldRemoveNone =
          item.details.vehicleOwnership === 'LIEN' ||
          (customCompCollStates.includes(state) && item.details.vehicleOwnership === 'LEASD');
        const existingValues = newValues['coverage']['new-coverage'];

        const newItem = {
          [`V${index + 1}_comprehensiveDeductible`]:
            existingValues[`V1_comprehensiveDeductible`] ||
            Object.keys(customStateOptions[state].comprehensiveDeductible)[0],
          [`V${index + 1}_collisionDeductible`]:
            existingValues[`V1_collisionDeductible`] ||
            Object.keys(customStateOptions[state].collisionDeductible)[0],
          [`V${index + 1}_coveragesRental`]: existingValues[`V1_coveragesRental`] || 'None',
          [`V${index + 1}_coveragesTowing`]: existingValues[`V1_coveragesTowing`] || 'None',
          [`V${index + 1}_coveragesLoanLeaseGap`]: existingValues[`V1_coveragesLoanLeaseGap`] || 'None',
        };

        if (shouldRemoveNone && newItem[`V${index + 1}_comprehensiveDeductible`] === 'None') {
          newItem[`V${index + 1}_comprehensiveDeductible`] = Object.keys(
            customStateOptions[state].comprehensiveDeductible,
          )[1];
        }
        if (shouldRemoveNone && newItem[`V${index + 1}_collisionDeductible`] === 'None') {
          newItem[`V${index + 1}_collisionDeductible`] = Object.keys(
            customStateOptions[state].collisionDeductible,
          )[1];
        }
        _.assign(newValues['coverage']['new-coverage'], { ...newItem });
      }
    }
  });

  return { newNavigation: newObj, newValues: newValues };
};

export const addCarFieldsToCoverage = (navigationObject: AfiNavigationType, newValues: any) => {
  const newObj = _.cloneDeep(navigationObject);
  const cars = newValues.cars.map((item: { [key: string]: { [key: string]: string } }) => {
    if (item.details.vehicleMake && item.details.vehicleModel && item.details.vehicleYear) return item;
  });
  let state = newValues.basics.address.state || 'default';

  if (!(state in customStateOptions)) {
    state = 'default';
  }

  let defaultValues = {};

  const carsToAdd = cars.map((item: any, index: number) => {
    if (item) {
      const carName = `${item.details.vehicleMake} ${item.details.vehicleModel} ${item.details.vehicleYear}`;

      if (carName) {
        const existingValues = newValues['coverage']['new-coverage'];
        _.assign(newValues['coverage']['new-coverage'], {
          [`V${index + 1}_comprehensiveDeductible`]:
            existingValues[`V${index + 1}_comprehensiveDeductible`] || '',
          [`V${index + 1}_collisionDeductible`]: existingValues[`V${index + 1}_collisionDeductible`] || '',
          [`V${index + 1}_coveragesRental`]: existingValues[`V${index + 1}_coveragesRental`] || '',
          [`V${index + 1}_coveragesTowing`]: existingValues[`V${index + 1}_coveragesTowing`] || '',
          [`V${index + 1}_coveragesLoanLeaseGap`]:
            existingValues[`V${index + 1}_coveragesLoanLeaseGap`] || '',
        });

        const shouldRemoveNone =
          item.details.vehicleOwnership === 'LIEN' ||
          (customCompCollStates.includes(state) && item.details.vehicleOwnership === 'LEASD');

        return {
          title: carName,
          fields: [
            customizeQuestionOptionsWithDefault(
              defaultValues,
              comprehensiveDeductible,
              _.omit(customStateOptions[state].comprehensiveDeductible, shouldRemoveNone ? 'None' : '') ||
                customStateOptions['default'].comprehensiveDeductible,
              `V${index + 1}_comprehensiveDeductible`,
            ),
            customizeQuestionOptionsWithDefault(
              defaultValues,
              collisionDeductible,
              _.omit(customStateOptions[state].collisionDeductible, shouldRemoveNone ? 'None' : '') ||
                customStateOptions['default'].collisionDeductible,
              `V${index + 1}_collisionDeductible`,
            ),
            customizeDefault(defaultValues, coveragesRental, `V${index + 1}_coveragesRental`),
            customizeDefault(defaultValues, coveragesTowing, `V${index + 1}_coveragesTowing`),
            customizeDefault(defaultValues, coveragesLoanLeaseGap, `V${index + 1}_coveragesLoanLeaseGap`),
          ],
        };
      }
    }
  });

  if (newValues.drivers[0].history.driverSr22 === 'R' && (state === 'FL' || state === 'VA')) {
    _.set(
      newObj,
      ['coverage', 'steps', 'new-coverage', 'formSections', 0, 'fields', 0],
      customizeQuestionOptions(primaryBodilyInjuryLimit, customStateOptions[state].bodilyInjury_FR44),
    );
    _.set(
      newObj,
      ['coverage', 'steps', 'new-coverage', 'formSections', 0, 'fields', 1],
      customizeQuestionOptions(primaryPropertyDamage, customStateOptions[state].propertyDamage_FR44),
    );
  }
  // Replacing old cars with new ones,
  newObj.coverage.steps['new-coverage'].formSections.splice(
    2,
    newObj.coverage.steps['new-coverage'].formSections.length -
      AfiNavigation.coverage.steps['new-coverage'].formSections.length,
    ...carsToAdd,
  );

  return { newNavigation: newObj, newValues: defaultValues };
};

export const updateDriverDetails = (afiValues: any, setAfiValues: any, newValues: any) => {
  const changeDriverValues = afiValues;
  changeDriverValues.driverFirstName = newValues.firstName;
  changeDriverValues.driverLastName = newValues.lastName;
  changeDriverValues.driverRelationshipToInsured = 'IN';
  setAfiValues(changeDriverValues, ['drivers', 0, 'details']);
  return;
};

export const disableFirstDriverFields = (navigationObject: AfiNavigationType) => {
  const newObj = _.cloneDeep(navigationObject);
  newObj.drivers.steps.details.formSections[0].fields[0].disabled = true;
  newObj.drivers.steps.details.formSections[0].fields[1].disabled = true;
  newObj.drivers.steps.details.formSections[4].fields[0].disabled = true;
  return newObj;
};

/**
 * Depending on the value of isCanopy, this function removes or adds back the basics and coverages sections from AfiNavigation
 * @param isCanopy - Boolean
 * @param navigationObject - AfiNavigation

 * @returns the new navigation object
 */

export const toggleAfiStepsForCanopy = (isCanopy: boolean, navigationObject: AfiNavigationType) => {
  const newObj = _.cloneDeep(navigationObject);
  if (isCanopy) {
    delete newObj.coverage;
  }
  return newObj;
};
