import React, { useState, createContext, FC, useEffect, useMemo } from 'react';
import { defaultUpsellInfo } from '../constants';
import { isUser } from '../services/helpers/AddHelpers';
import { useSearchParams } from 'react-router-dom';
import { formatDate, getHeaderContent, getHeaderTitle } from '../services/helpers/CommonHelpers';
import { getDecryptedContent } from '../api/apiFunctions';
import { defaultUserInfo, defaultAddressInfo } from '../constants';
import { UserInterface, AddressInterface } from '../components/interfaces/CommonInterfaces';
import { isAmalgamatedState } from '../services/helpers/AddHelpers';
import { UpsellInfoInterface } from '../components/interfaces/AddTypeInterfaces';
import { useWindowDimensions } from '../services/hooks/useWindowDimensions';
import { usePartnerStyling } from '../services/hooks/usePartnerStyling';
import { FamilyMemberInterface } from '../components/interfaces/CommonInterfaces';

export type UserContextType = {
  partnerHeader: React.ReactNode | undefined;
  partnerName: string;
  stepAnimationType: string;
  setStepAnimationType: Function;
  smallDevice: boolean;
  setSmallDevice: Function;
  authToken: string;
  setAuthToken: Function;
  userInfo: UserInterface;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInterface>>;
  familyMembers: FamilyMemberInterface[];
  setFamilyMembers: React.Dispatch<React.SetStateAction<FamilyMemberInterface[]>>;
  addressInfo: AddressInterface;
  setAddressInfo: React.Dispatch<React.SetStateAction<AddressInterface>>;
  isAmalgamated: boolean;
  setIsAmalgamated: React.Dispatch<React.SetStateAction<boolean>>;
  showErrorBanner: boolean;
  setShowErrorBanner: React.Dispatch<React.SetStateAction<boolean>>;
  upsellInfo: UpsellInfoInterface;
  setUpsellInfo: React.Dispatch<React.SetStateAction<UpsellInfoInterface>>;
  device: string;
  isMobile: boolean;
  headerContent: { logo: React.ReactNode | undefined; title: string };
  showDisclaimer: boolean;
  setShowDisclaimer: React.Dispatch<React.SetStateAction<boolean>>;
  isAddCompleted: boolean;
  setIsAddCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  isUpsellCompleted: boolean;
  setIsUpsellCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  trackingId: string;
  setTrackingId: React.Dispatch<React.SetStateAction<string>>;
};

const UserContext = createContext<UserContextType>({
  partnerHeader: undefined,
  partnerName: 'Your',
  stepAnimationType: 'slide-in',
  setStepAnimationType: Function,
  smallDevice: false,
  setSmallDevice: () => {},
  authToken: '',
  setAuthToken: Function,
  userInfo: defaultUserInfo,
  setUserInfo: (data: React.SetStateAction<UserInterface>) => {},
  familyMembers: [],
  setFamilyMembers: (data: React.SetStateAction<FamilyMemberInterface[]>) => {},
  addressInfo: defaultAddressInfo,
  setAddressInfo: (data: React.SetStateAction<AddressInterface>) => {},
  isAmalgamated: false,
  setIsAmalgamated: (data: React.SetStateAction<boolean>) => {},
  showErrorBanner: false,
  setShowErrorBanner: (data: React.SetStateAction<boolean>) => {},
  upsellInfo: defaultUpsellInfo,
  setUpsellInfo: (data: React.SetStateAction<UpsellInfoInterface>) => {},
  device: '',
  isMobile: true,
  headerContent: { logo: undefined, title: '' },
  showDisclaimer: false,
  setShowDisclaimer: (data: React.SetStateAction<boolean>) => {},
  isAddCompleted: false,
  setIsAddCompleted: (data: React.SetStateAction<boolean>) => {},
  isUpsellCompleted: false,
  setIsUpsellCompleted: (data: React.SetStateAction<boolean>) => {},
  trackingId: '',
  setTrackingId: (data: React.SetStateAction<string>) => {},
});

interface Props {
  children: React.ReactNode;
}

const UserProvider: FC<Props> = ({ children }) => {
  const [trackingId, setTrackingId] = useState('');
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [stepAnimationType, setStepAnimationType] = useState('slide-in');
  const [smallDevice, setSmallDevice] = useState(false);
  const [authToken, setAuthToken] = useState(localStorage.getItem('token') || '');
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem('userInfo') as string) || defaultUserInfo,
  );
  const [familyMembers, setFamilyMembers] = useState<FamilyMemberInterface[]>([]);
  const [addressInfo, setAddressInfo] = useState(
    JSON.parse(sessionStorage.getItem('addressInfo') as string) || defaultAddressInfo,
  );
  const [isAmalgamated, setIsAmalgamated] = useState(isAmalgamatedState(addressInfo.state));
  const [isAddCompleted, setIsAddCompleted] = useState(!!sessionStorage.getItem('addCompleted'));
  const [isUpsellCompleted, setIsUpsellCompleted] = useState(!!sessionStorage.getItem('upsellCompleted'));

  let storedUpsellInfo = sessionStorage.getItem('upsellInfo');
  const [upsellInfo, setUpsellInfo] = useState(
    (storedUpsellInfo && JSON.parse(storedUpsellInfo)) || defaultUpsellInfo,
  );
  let [searchParams] = useSearchParams();
  const { device, isMobile } = useWindowDimensions();
  const partnerName = usePartnerStyling();
  const partnerHeader = useMemo(() => {
    return getHeaderContent(partnerName, device);
  }, [device, partnerName]);

  const headerContent = useMemo(() => {
    let currentFlow = location.pathname.split('/')[2];
    return {
      logo: getHeaderContent(partnerName, device),
      title: getHeaderTitle(partnerName, currentFlow),
    };
  }, [device, partnerName, location.pathname]);

  useEffect(() => {
    if (authToken) {
      localStorage.setItem('token', authToken);
    }
  }, [authToken]);

  useEffect(() => {
    setTrackingId(searchParams.get('id') || sessionStorage.getItem('trackingId') || '');
    sessionStorage.setItem(
      'trackingId',
      searchParams.get('id') || sessionStorage.getItem('trackingId') || '',
    );
    if (window.innerHeight < 650) {
      setSmallDevice(true);
    }
  }, []);

  useEffect(() => {
    userInfo && sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
    addressInfo && sessionStorage.setItem('addressInfo', JSON.stringify(addressInfo));
  }, [userInfo, addressInfo]);

  useEffect(() => {
    sessionStorage.setItem('upsellInfo', JSON.stringify(upsellInfo));
  }, [upsellInfo]);

  useEffect(() => {
    isAddCompleted && sessionStorage.setItem('addCompleted', 'true');
  }, [isAddCompleted]);

  useEffect(() => {
    isUpsellCompleted && sessionStorage.setItem('upsellCompleted', 'true');
  }, [isUpsellCompleted]);

  useEffect(() => {
    const getUserInfo = async () => {
      const content = searchParams.get('content');
      if (content) {
        try {
          const decryptResp = await getDecryptedContent(content);
          const userInfoObj = JSON.parse(decryptResp.data.content);

          if (isUser(userInfoObj)) {
            setUserInfo({
              ...userInfoObj,
              dob: formatDate(userInfoObj.dob),
              id: '',
            });
          }
        } catch (e) {}
      }
    };

    getUserInfo();
  }, []);

  return (
    <UserContext.Provider
      value={{
        partnerName,
        setStepAnimationType,
        stepAnimationType,
        smallDevice,
        setSmallDevice,
        authToken,
        setAuthToken,
        userInfo,
        setUserInfo,
        familyMembers,
        setFamilyMembers,
        addressInfo,
        setAddressInfo,
        isAmalgamated,
        setIsAmalgamated,
        showErrorBanner,
        setShowErrorBanner,
        upsellInfo,
        setUpsellInfo,
        device,
        isMobile,
        partnerHeader,
        headerContent,
        showDisclaimer,
        setShowDisclaimer,
        isAddCompleted,
        setIsAddCompleted,
        isUpsellCompleted,
        setIsUpsellCompleted,
        trackingId,
        setTrackingId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
