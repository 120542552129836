import { useContext, useEffect, useState } from 'react';
import { AnswerFinancialContext } from '../../../../context/AnswerFinancialContext';
import { calculateProgress } from '../../../../services/helpers/AFIHelpers';
import { AfiIndexes } from '../../../interfaces/AFIInterfaces';
import './afi-progress-bar.scss';

const AfiProgressBar = ({
  sections,
  sectionId,
  currentSection,
  currentStep,
  indexes,
  stepId,
  customSetProgress = null,
}: {
  sections: string[];
  sectionId: string;
  currentSection: any;
  currentStep: any;
  stepId: string;
  indexes: AfiIndexes;
  customSetProgress?: number | null;
}) => {
  const { isCanopy } = useContext(AnswerFinancialContext);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (customSetProgress) {
      setProgress(customSetProgress);
      return;
    }
    setProgress(
      calculateProgress(currentSection.name, currentStep.name, indexes[currentSection.name] + 1, isCanopy),
    );
  }, [currentSection, currentStep, sectionId, stepId]);

  return (
    <>
      <div className="afi-tabs-container">
        {sections.map((item) => {
          return (
            <div key={item} className={`afi-tabs-item${item === sectionId ? ' active' : ''}`}>
              {item}
            </div>
          );
        })}
      </div>
      <div className="afi-progress-bar-container">
        <div
          className={`afi-progress-bar${progress === 100 ? ' filled' : ''}`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </>
  );
};

export default AfiProgressBar;
