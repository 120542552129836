import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { VoluntaryAddContext } from '../../context/VoluntaryContext';
import { beneficiariesApiCall, isValidUser } from '../../api/voluntaryAdd';
import Loader from '../../components/common/loader/Loader';
import { checkUserEmail } from '../../api/apiFunctions';
import mixpanelWrapper from '../../utils/tools/mixpanel';

const LoadVoluntaryFlow = () => {
  const navigate = useNavigate();
  const { setProgressStep, setVoluntaryBeneficiaries } = useContext(VoluntaryAddContext);
  const { authToken, userInfo, isUpsellCompleted } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
    if (authToken) {
      const getVoluntaryBeneficiaries = async () => {
        try {
          const resp = await beneficiariesApiCall(authToken);
          const processedBeneficiaries = resp.data.map((elem: any) => ({
            ...elem,
            isSelected: true,
          }));
          setVoluntaryBeneficiaries(processedBeneficiaries);
        } catch {
          setVoluntaryBeneficiaries([]);
        }
      };

      const getValidUser = async () => {
        try {
          const resp = await isValidUser(authToken);
          const user = resp.data.isAvailable;
          user === false &&
            navigate('/accidental-death/start', {
              replace: true,
            });
          user === true &&
            !isUpsellCompleted &&
            navigate('/accidental-death/start', {
              replace: true,
            });
        } catch {
          navigate('/accidental-death/start', { replace: true });
        } finally {
          setIsLoading(false);
        }
      };

      // check if user already has a policy
      const userAlreadyHasPolicy = async () => {
        try {
          const res = await checkUserEmail({
            email: userInfo.email,
            policyName: 'voluntary_accidental_death',
          });

          if (res?.data?.hasInsurance) {
            navigate('/accidental-death-voluntary/success', {
              replace: true,
            });
          }
        } catch {}
      };

      userAlreadyHasPolicy();
      getVoluntaryBeneficiaries();
      getValidUser();
    } else {
      navigate('/accidental-death/start', { replace: true });
      return;
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setProgressStep(1);
      //use "replace" so that the user skips this page when clicking the back button
      navigate('/accidental-death-voluntary/family', { replace: true });
    }
  }, [isLoading]);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default LoadVoluntaryFlow;
