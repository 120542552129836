// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimer-container {
  display: flex;
  align-items: flex-start;
  min-width: none;
  margin: 0 auto;
}
.disclaimer-container input[type=checkbox] {
  width: 28px;
  height: 28px;
  margin-right: 10px;
  accent-color: var(--radio-button-color);
  background-color: var(--secondary-background-color);
  border-color: #dddddd;
}
.disclaimer-container input[type=checkbox]:focus {
  box-shadow: none;
}
.disclaimer-container .disclaimer-button:checked {
  background-color: var(--radio-button-color) !important;
  border-color: #fff;
}
.disclaimer-container .disclaimer-label {
  text-decoration: underline;
  cursor: pointer;
  color: var(--secondary-text-color);
}

@media (max-height: 620px) {
  .disclaimer-container input[type=checkbox] {
    width: 20px;
    height: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/disclaimer/disclaimer.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,eAAA;EACA,cAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,uCAAA;EACA,mDAAA;EACA,qBAAA;AAEJ;AAAI;EACE,gBAAA;AAEN;AAEE;EACE,sDAAA;EACA,kBAAA;AAAJ;AAGE;EACE,0BAAA;EACA,eAAA;EACA,kCAAA;AADJ;;AAKA;EAEI;IACE,WAAA;IACA,YAAA;EAHJ;AACF","sourcesContent":[".disclaimer-container {\n  display: flex;\n  align-items: flex-start;\n  min-width: none;\n  margin: 0 auto;\n  input[type='checkbox'] {\n    width: 28px;\n    height: 28px;\n    margin-right: 10px;\n    accent-color: var(--radio-button-color);\n    background-color: var(--secondary-background-color);\n    border-color: #dddddd;\n\n    &:focus {\n      box-shadow: none;\n    }\n  }\n\n  .disclaimer-button:checked {\n    background-color: var(--radio-button-color) !important;\n    border-color: #fff;\n  }\n\n  .disclaimer-label {\n    text-decoration: underline;\n    cursor: pointer;\n    color: var(--secondary-text-color);\n  }\n}\n\n@media (max-height: 620px) {\n  .disclaimer-container {\n    input[type='checkbox'] {\n      width: 20px;\n      height: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
