// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.afi-progress-bar-container {
  width: 100%;
  height: 6px;
  background-color: var(--header-background-color);
}

.afi-progress-bar {
  height: 6px;
  background: var(--progress-bar-color);
  border-radius: 0px 100px 100px 0px;
  transition: width 0.5s;
}
.afi-progress-bar.filled {
  border-radius: 0px;
}

.afi-tabs-container {
  background-color: var(--header-background-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 11px;
}

.afi-tabs-item {
  text-transform: capitalize;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  color: var(--primary-text-color);
  opacity: 0.5;
}
.afi-tabs-item.active {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/common/progress-bar/AfiProgressBar/afi-progress-bar.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,WAAA;EACA,gDAAA;AACJ;;AACE;EACE,WAAA;EACA,qCAAA;EACA,kCAAA;EACA,sBAAA;AAEJ;AADI;EACE,kBAAA;AAGN;;AACE;EACE,gDAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,6BAAA;EACA,oBAAA;AAEJ;;AAAE;EACE,0BAAA;EACA,gCAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,YAAA;AAGJ;AAFI;EACE,UAAA;AAIN","sourcesContent":[".afi-progress-bar-container {\n    width: 100%;\n    height: 6px;\n    background-color: var(--header-background-color);\n  }\n  .afi-progress-bar {\n    height: 6px;\n    background: var(--progress-bar-color);\n    border-radius: 0px 100px 100px 0px;\n    transition: width 0.5s;\n    &.filled {\n      border-radius: 0px;\n    }\n  }\n\n  .afi-tabs-container {\n    background-color: var(--header-background-color);\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-around;\n    padding-bottom: 11px;\n  }\n  .afi-tabs-item {\n    text-transform: capitalize;\n    font-family: var(--primary-font);\n    font-style: normal;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 26px;\n    color: var(--primary-text-color);\n    opacity: 0.5;\n    &.active {\n      opacity: 1;\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
