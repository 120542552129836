import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { chooseCanopyMFAOption } from '../../../../api/apiFunctions';
import SecondaryButton from '../../../../components/common/buttons/SecondaryButton';
import { CanopyInsurerType, CanopyUserType } from '../../../../components/interfaces/AFIInterfaces';
import './canopy-options.scss';

const CanopyOptions = ({
  user,
  currentInsurer,
  options,
  startNewTimeout,
}: {
  user: CanopyUserType;
  currentInsurer: CanopyInsurerType;
  options: { [key: string]: string };
  startNewTimeout: Function;
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedOption) {
      const send = async () => {
        try {
          const data = await chooseCanopyMFAOption(selectedOption, user.pullId, user.pullJWT, user.canopyJWT);
          startNewTimeout();
        } catch (error) {
          navigate('/full-quote-auto/import/failed');
        }
      };
      send();
    }
  }, [selectedOption]);

  return (
    <div className="options-container">
      <h1 className="options-title">How do you want to get your security code?</h1>
      <div
        className="canopy-insurer-name"
        style={{
          color: currentInsurer.color || 'var(--secondary-text-color)',
        }}
      >
        {currentInsurer.name}
      </div>
      <div className="options-buttons-container">
        {Object.keys(options).map((item) => {
          return (
            <SecondaryButton
              text={options[item]}
              onClick={() => setSelectedOption(item)}
              loading={!!(selectedOption && selectedOption === item)}
              disabled={!!selectedOption}
              key={item}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CanopyOptions;
