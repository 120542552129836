import './loader.scss';
import { Spinner } from 'react-bootstrap';

const Loader = () => {
  return (
    <div className="loader-container">
      <Spinner
        animation="border"
        role="status"
        aria-hidden="true"
        variant="primary"
      />
    </div>
  );
};

export default Loader;
