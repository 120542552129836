import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import Header from '../Header/Header';
import LoadingImg from './../../../assets/common/loading.svg';
import LoadingMessage from './LoadingMessage/LoadingMessage';
import './loading-screen.scss';

const LoadingScreen = ({ text }: { text: string }) => {
  const { headerContent } = useContext(UserContext);

  return (
    <div className="loading-screen-container">
      <Header headerContent={headerContent} showIcon={false} onIconClick={() => {}} />
      <div className="loading-screen-image">
        <LoadingImg />
      </div>

      <LoadingMessage text={text} />
    </div>
  );
};

export default LoadingScreen;
