import { useState, useContext } from 'react';
import StepComponent from '../../step-component/StepComponent';
import RadioButtonContainer from '../../radio-button-container/RadioButtonContainer';
import { LifeInsuranceContext } from '../../../../context/LifeInsuranceContext';
import { useNavigate } from 'react-router-dom';
import { userInfoImages } from '../../../../constants/AddConstants';
import { UserContext } from '../../../../context/UserContext';
import { policyImages } from '../../../../constants/LifeConstants';

const Policy = () => {
  const {
    hasLifeInsurance,
    setHasLifeInsurance,
    setProgressStep,
    setInsuranceProvider,
  } = useContext(LifeInsuranceContext);
  const { partnerName, device } = useContext(UserContext);
  const [hasPolicy, setHasPolicy] = useState(hasLifeInsurance ? 'Yes' : 'No');
  const navigate = useNavigate();

  const formContent = {
    header: 'Do you have a life insurance policy?',
    img: policyImages(partnerName, device),
    buttonTxt: 'Continue',
    onSubmit: () => {
      setHasLifeInsurance(hasPolicy === 'Yes');
      if (hasPolicy === 'No') {
        setInsuranceProvider('non-holder');
        setProgressStep(3);
        navigate('/life/income');
        return;
      }
      if (hasPolicy === 'Yes') {
        navigate('/life/source');
        return;
      }
    },
    fields: [
      <RadioButtonContainer
        options={['Yes', 'No']}
        size="large"
        value={hasPolicy}
        handleChange={(val) => setHasPolicy(val)}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.img}
      />
    </>
  );
};

export default Policy;
