import { useContext } from 'react';
import { VoluntaryAddContext } from '../../../../context/VoluntaryContext';
import { MembersContext } from '../../../../context/MembersContext';
import Amounts from '../common-steps/Amounts';
import { getMonthlyPayment } from '../../../../services/helpers/VoluntaryHelpers';
import { useNavigate } from 'react-router-dom';

const VoluntaryAmounts = () => {
  const { showBeneficiaryModal, selectedBeneficiary, setSelectedBeneficiary, setShowBeneficiaryModal } =
    useContext(MembersContext);
  const {
    completedAmounts,
    setCompletedAmounts,
    coveredMembers,
    voluntaryBeneficiaries,
    setVoluntaryBeneficiaries,
    setProgressStep,
    updateLastStep,
  } = useContext(VoluntaryAddContext);

  const navigate = useNavigate();
  if (voluntaryBeneficiaries.length <= 1) {
    navigate('/accidental-death-voluntary/beneficiaries');
  }

  const onSpecialSubmit = () => {
    updateLastStep('beneficiary-split');
  };

  return (
    <Amounts
      beneficiariesInfo={voluntaryBeneficiaries}
      setBeneficiariesInfo={setVoluntaryBeneficiaries}
      showBeneficiaryModal={showBeneficiaryModal}
      setShowBeneficiaryModal={setShowBeneficiaryModal}
      selectedBeneficiary={selectedBeneficiary}
      setSelectedBeneficiary={setSelectedBeneficiary}
      completedAmounts={completedAmounts}
      setCompletedAmounts={setCompletedAmounts}
      setProgressStep={setProgressStep}
      nextProgressStep={6}
      buttonTxt={`Continue at ${getMonthlyPayment(coveredMembers.length)} / mo`}
      link="/accidental-death-voluntary/payment"
      onSpecialSubmit={onSpecialSubmit}
    />
  );
};

export default VoluntaryAmounts;
