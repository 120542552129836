// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.autocomplete-container {
  display: block;
  margin: 0 auto;
  width: 100%;
  position: relative;
}
.autocomplete-container#small {
  max-width: 305px;
}
.autocomplete-container fieldset {
  border: none;
}
.autocomplete-container ::-webkit-input-placeholder {
  color: var(--placeholder-text-color);
}
.autocomplete-container ::placeholder {
  color: var(--placeholder-text-color);
}
.autocomplete-container input[type=text] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}`, "",{"version":3,"sources":["webpack://./src/components/common/autocomplete/autocomplete.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;AACF;AAAE;EACE,gBAAA;AAEJ;AACE;EACE,YAAA;AACJ;AAEE;EACE,oCAAA;AAAJ;AAGE;EACE,oCAAA;AADJ;AAIE;EACE,gBAAA;EACA,qBAAA;EACA,wBAAA;AAFJ","sourcesContent":[".autocomplete-container {\n  display: block;\n  margin: 0 auto;\n  width: 100%;\n  position: relative;\n  &#small {\n    max-width: 305px;\n  }\n\n  fieldset {\n    border: none;\n  }\n\n  ::-webkit-input-placeholder {\n    color: var(--placeholder-text-color);\n  }\n\n  ::placeholder {\n    color: var(--placeholder-text-color);\n  }\n\n  input[type='text'] {\n    appearance: none;\n    -moz-appearance: none;\n    -webkit-appearance: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
