import { useState, useContext, useEffect } from 'react';
import './auto.scss';
import { introContent, introListText, zipCodeLimit } from './constants';
import InputField from '../../components/common/forms/InputField/InputField';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import VerticalLayout from '../../components/layouts/vertical-layout/VerticalLayout';
import { getNumberInputs } from '../../services/helpers/inputFieldHelpers';
import { AutoInsuranceContext } from '../../context/AutoInsuranceContext';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { getStateByZip } from '../../services/helpers/CommonHelpers';
import { introImages } from '../../constants/AutoConstants';
import { stringsObject } from '../../components/interfaces/InputInterfaces';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import { getAutoDisclaimerText, setAutoAddress } from '../../services/helpers/AutoHelpers';
import { yupMessages } from '../../services/yupValidations';
import {
  afiStates,
  partnerAutoTrackingRestrictions,
  transparentlyBlockedPartners,
} from '../../constants/CommonConstants';
import BulletList from '../../components/common/bullet-list/BulletList';
import mixpanelWrapper from '../../utils/tools/mixpanel';
import { AnswerFinancialContext } from '../../context/AnswerFinancialContext';
import TimedErrorBanner from '../../components/common/error-banner/ErrorBanner';
import { AFI_ERROR_BANNER_DURATION } from '../../constants/AFIConstants';

const AutoIntro = () => {
  const { zip, setZip, updateAutoInsuranceStep, setStepAnimationType, setShortState, setCity } =
    useContext(AutoInsuranceContext);
  const { setLastStepCompleted, AfiUserState, shouldSkipCanopy } = useContext(AnswerFinancialContext);
  const {
    partnerName,
    device,
    headerContent,
    setShowDisclaimer,
    showDisclaimer,
    showErrorBanner,
    setShowErrorBanner,
  } = useContext(UserContext);
  const [errors, setErrors] = useState<stringsObject | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
  }, []);

  const handleInputChange = (e: any) => {
    setErrors(null);

    if (getNumberInputs(e.target.value) && e.target.value.length <= zipCodeLimit) {
      setZip(e.target.value);
    }
  };

  const handleClick = async () => {
    const getState = getStateByZip(zip);
    let errorCopy = yupMessages.zipCodeInvalid;
    if (getState.value === 'error') {
      setErrors({ zip: errorCopy });
      return;
    }
    if (
      afiStates.includes(getState.value) &&
      !partnerAutoTrackingRestrictions[partnerName]?.includes(getState.value)
    ) {
      setLastStepCompleted('start');
      navigate(shouldSkipCanopy ? '/full-quote-auto/basics/name' : '/full-quote-auto/import/current-insurer');
      return;
    } else if (transparentlyBlockedPartners.includes(partnerName)) {
      // Return error instead of redirecting to Transparently for blocked partners
      errorCopy = 'Your state is not supported at this time. We hope to add coverage for your state soon.';
    } else {
      const res = await setAutoAddress(zip, setErrors, setShortState, setCity, setZip, setStepAnimationType);
      if (res) {
        updateAutoInsuranceStep('model');
        navigate('/auto/model');
        return;
      }
    }

    setErrors({ zip: errorCopy });
    return;
  };

  return (
    <div className="auto-intro-page">
      <VerticalLayout
        {...introContent}
        headerContent={headerContent}
        showInfoIcon={true}
        img={introImages(partnerName, device)}
        onIconClick={() => setShowDisclaimer(true)}
        content={
          <div className="auto-intro-content">
            <BulletList items={introListText} />
            <div className="auto-intro-fields">
              <InputField
                placeholder="Enter Zip Code"
                type="tel"
                name="zip"
                value={zip}
                onChange={handleInputChange}
                onClick={handleClick}
                errors={errors}
                setErrors={setErrors}
                required={true}
                enableSubmit={zip.length === zipCodeLimit}
                className="with-banner"
              />
              <PrimaryButton
                text="Start Saving"
                onClick={handleClick}
                disabled={!(zip.length === zipCodeLimit) || AfiUserState !== 'success'}
                loading={AfiUserState === 'loading'}
              />
            </div>
          </div>
        }
      />
      {showErrorBanner && (
        <TimedErrorBanner
          closeFunction={setShowErrorBanner}
          delay={AFI_ERROR_BANNER_DURATION}
          message="Uh oh! Something unexpected occurred. We were not able to process your data."
        />
      )}
      {showDisclaimer && (
        <FlowDisclaimer
          headerContent={headerContent}
          content={getAutoDisclaimerText(partnerName)}
          subtitle={'Disclaimer'}
        />
      )}
    </div>
  );
};

export default AutoIntro;
