import axios from 'axios';
import { getAgeValue } from '../services/helpers/AutoHelpers';
import { driverTemplate } from '../constants/AFIConstants';
import _ from 'lodash';

const creditMapping: any = {
  '750+': 1,
  '700-749': 2,
  '650-699': 3,
  '<649': 4,
};

const getGeneralInfo = (payload: any) => {
  const info: any = {
    pubCampaignID: process.env.REACT_APP_AUTO_PUB_CAMPAIGN_ID,
    vertical: 2,
    homeowner: payload.isHomeowner ? 1 : 3,
    state: payload.shortState,
    zipcode: payload.zip,
    currentlyinsured: payload.isInsured === 'Yes' ? 1 : 2,
    multidriverhousehold: payload.driverInfo.length > 1 ? 1 : 2,
  };
  if (process.env.REACT_APP_MODE !== 'production') {
    info.no_tracking = true;
  }
  if (payload.isInsured === 'Yes') {
    info.currentinsuranceprovider = payload.currentInsuranceProvider;
  }
  if (payload.driverInfo?.[0]) {
    info.firstName = payload.driverInfo[0].firstName;
    info.lastName = payload.driverInfo[0].lastName;
    info.creditevaluation = creditMapping[payload.driverInfo[0].creditScore];
    info.email = payload.driverInfo[0].email;
    info.age = getAgeValue(payload.driverInfo[0].driverAge).toString();
  }
  if (payload.vehicles?.[0]) {
    info.vehicleyear = payload.vehicles[0].year;
    info.vehiclecount = payload.vehicles.length;
  }
  return info;
};

const getRespondent = (payload: any) => {
  return [
    {
      firstName: payload.driverInfo[0].firstName,
      lastName: payload.driverInfo[0].lastName,
      email: payload.driverInfo[0].email,
      zipCode: payload.zip,
      city: payload.city,
      state: payload.shortState,
      homeOwner: payload.isHomeowner ? 1 : 3,
      country: 'United States',
      multidriverHousehold: payload.driverInfo.length > 1 ? 1 : 2,
    },
  ];
};

const getVehicles = (payload: any) => {
  const vehicles: any = [];
  payload.vehicles?.forEach((elem: any) => {
    if (elem.model) {
      vehicles.push({
        vehicleYear: elem.year,
        make: elem.make,
        model: elem.model,
      });
    }
  });
  return vehicles;
};

const getIncidents = (payload: any) => {
  const incidents = [];
  if (payload.hasDui === 'Yes') {
    incidents.push({
      drivers: 1,
      incidentType: 4,
    });
  }
  if (payload.hasTickets === 'Yes') {
    incidents.push({
      drivers: 1,
      incidentType: 2,
    });
  }
  if (payload.hasAccident === 'Yes') {
    incidents.push({
      drivers: 1,
      incidentType: 3,
    });
  }
  return incidents;
};

const getDrivers = (payload: any) => {
  const drivers: any = [];
  payload?.driverInfo.forEach((elem: any, index: number) => {
    if (elem.firstName) {
      drivers.push({
        FullName: `${elem.firstName} ${elem.lastName}`,
        age: getAgeValue(elem?.driverAge).toString(),
        primaryVehicle: index === 0 ? 1 : '',
        licenseStatus: index === 0 ? (payload.hasLiscense === 'Yes' ? 1 : 2) : '',
        creditEvaluation: creditMapping[elem.creditScore],
      });
    }
  });
  return drivers;
};

export const getAutoProviders = async (payload: any) => {
  const link: string = process.env.REACT_APP_AUTO_API || '';
  const body: any = getGeneralInfo(payload);

  const data: any = {};
  data.respondent = getRespondent(payload);
  data.vehicles = getVehicles(payload);
  const incidents = getIncidents(payload);
  if (incidents.length > 0) {
    data.incidents = incidents;
  } else {
    data.incidents = _.cloneDeep(driverTemplate.incidents);
  }
  data.drivers = getDrivers(payload);

  const currentPolicies: any = {
    currentInsuranceProvider: (payload.currentInsuranceProvider || 0).toString(),
  };
  data.currentPolicy = currentPolicies;

  if (Object.keys(data).length > 0) {
    body.data = JSON.stringify(data);
  }
  const resp = await axios.post(link, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return resp;
};
