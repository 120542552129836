import { states } from '../../../constants';
import { questionBuilder } from '../../../services/helpers/AFIQuestionBuilder';
import { yupValidations } from '../../../services/yupValidations';

export const address = questionBuilder(
  'address',
  'Address',
  'Address',
  yupValidations.stringMinMaxLength(2, null),
  'address',
);

export const apt = questionBuilder(
  'address2',
  'Address 2',
  'Apt/Suite',
  yupValidations.stringNotRequired(2, 15),
  'input',
);

export const city = questionBuilder(
  'city',
  'City',
  'City',
  yupValidations.stringMinMaxLength(2, 50),
  'input',
);

export const state = questionBuilder(
  'state',
  'State',
  'State',
  yupValidations.optionRequired,
  'select',
  undefined,
  states,
);

export const zip = questionBuilder('zip', 'Zip Code', 'Zip Code', yupValidations.zipCode, 'zip');

export const county = questionBuilder(
  'county',
  'County',
  'County',
  yupValidations.stringMinMaxLength(2, 22),
  'input',
);

export const parkingAddress = questionBuilder(
  'parkingAddress',
  'Address',
  'Address',
  yupValidations.stringMinMaxLength(2, null),
  'address',
);
export const parkingApt = questionBuilder(
  'parkingAddress2',
  'Address 2',
  'Apt/Suite',
  yupValidations.stringNotRequired(2, 15),
  'input',
);

export const parkingCity = questionBuilder(
  'parkingCity',
  'City',
  'City',
  yupValidations.stringMinMaxLength(2, 50),
  'input',
);

export const parkikngState = questionBuilder(
  'parkingState',
  'State',
  'State',
  yupValidations.optionRequired,
  'select',
  undefined,
  states,
);

export const parkingZip = questionBuilder(
  'parkingZip',
  'Zip Code',
  'Zip Code',
  yupValidations.zipCode,
  'zip',
);
