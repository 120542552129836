import axios from "axios";
import { MemberToPolicyInterface } from "../components/interfaces/VoluntaryInterfaces";
import endpoints from "./endpoints";

export const isValidUser = async (token: string) => {
  const resp = await axios.get(endpoints.voluntaryAddUserCheck, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return resp;
};

export const beneficiariesApiCall = async (token: string) => {
  const resp = await axios.get(endpoints.beneficiaries, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return resp;
};

export const createPolicy = async (token: string, data: Object) => {
  const resp = await axios.post(endpoints.createPolicy, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return resp;
};

export const generateIframeUrl = async (
  token: string,
  policyId: string,
  dependents: Array<MemberToPolicyInterface>,
  beneficiaries: Array<MemberToPolicyInterface>
) => {
  const resp = await axios.post(
    endpoints.voluntaryGenerateIframe,
    { policyId, dependents, beneficiaries },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return resp;
};
