import { upsellRecommendations } from './constants';
import './add.scss';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AddInsuranceContext } from '../../context/AddInsuranceContext';
import { upsellRecommendationImages } from '../../constants/AddConstants';
import { UserContext } from '../../context/UserContext';
import VerticalLayout from '../../components/layouts/vertical-layout/VerticalLayout';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import mixpanelWrapper from '../../utils/tools/mixpanel';

type UpsellProps = {
  type: string;
};

const AddUpsellRecommendations = ({ type }: UpsellProps) => {
  const { progressStep } = useContext(AddInsuranceContext);
  const { device, partnerName, headerContent, setIsUpsellCompleted } = useContext(UserContext);
  const { url, title, description } = upsellRecommendations[type];

  const navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
    //route blocking
    if (progressStep === 0) {
      navigate('/accidental-death/start');
      return;
    }
    setIsUpsellCompleted(true);
  }, []);

  return (
    <div className="add-upsell-page">
      <VerticalLayout
        headerContent={headerContent}
        showInfoIcon={false}
        img={upsellRecommendationImages(partnerName, device, type)}
        title={title}
        description={description}
        content={
          <>
            <div className="add-button-container">
              <PrimaryButton
                text="Continue"
                onClick={() => {
                  navigate(`/${url}`);
                }}
              />
            </div>
          </>
        }
      />
    </div>
  );
};

export default AddUpsellRecommendations;
