import { useState, useEffect } from 'react';
import ErrorIcon from '../../../../assets/common/error-icon.svg';
import '../InputField/input-field.scss';
import { stringsObject } from '../../../interfaces/InputInterfaces';
import { formatDate } from '../../../../services/helpers/inputFieldHelpers';
import { errorMessages } from '../../../../services/validation';
import { FormikErrors } from 'formik';

type InputProps = {
  value: string;
  setValue: Function;
  setErrors: Function;
  disabled?: boolean;
  errors?: stringsObject | FormikErrors<stringsObject>;
  setFieldTouched?: Function;
  handleChange?: Function;
  formik?: boolean;
  placeholder: string;
  hasRequiredMessage?: boolean;
  className?: string;
  name?: string;
};

const DateField = ({
  value,
  setValue,
  errors,
  setErrors,
  setFieldTouched,
  className,
  placeholder,
  hasRequiredMessage = false,
  formik = false,
  disabled = false,
  name = 'dob',
}: InputProps) => {
  const [displayValue, setDisplayValue] = useState(value?.split('/').join('') || '');

  useEffect(() => {
    setDisplayValue(value?.split('/').join('') || '');
  }, [value]);

  const onChange = (e: any) => {
    let errorCopy: any = { ...errors };
    errorCopy[e.target.name] = '';

    const tmp = e.target.value.split('/').join('');
    if (tmp.length === displayValue.length) {
      setDisplayValue(tmp.slice(0, -1));
    } else {
      setDisplayValue(tmp);
    }

    //Adjust date field for Formik
    if (formik) {
      e.target.value.length === 10 && setValue(formatDate(tmp));

      // if input field is complete and the date is invalid, force the error message to show before onBlur is triggered
      e.target.value.length === 10 &&
        !(new Date(formatDate(tmp)).toString() === 'Invalid Date') &&
        (errorCopy.dob = errorMessages.date);

      setFieldTouched && setFieldTouched(name, true);
    }

    setErrors(errorCopy);
  };

  const onBlur = () => {
    setValue(formatDate(displayValue));
    const valueCopy = formatDate(displayValue);

    let errorsCopy: any = { ...errors };
    if (valueCopy.length === 0) {
      errorsCopy[name] = errorMessages.required;
    } else if (valueCopy.length !== 10) {
      errorsCopy[name] = errorMessages.size;
    } else if (new Date(valueCopy).toString() === 'Invalid Date') {
      errorsCopy[name] = errorMessages.date;
    }

    setFieldTouched && setFieldTouched(name, true);
    setErrors(errorsCopy);
  };
  return (
    <div className={`input-field-container ${name}`}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input
          className={`${errors?.[name] ? 'input-error' : ''} ${className ? className : ''}`}
          inputMode="numeric"
          name={name}
          type="text"
          value={formatDate(displayValue)}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={10}
          minLength={10}
        />
        {(errors?.[name] || className === 'input-error') && <ErrorIcon />}
      </form>
      {errors && <div className="error">{errors?.[name]}</div>}
      {hasRequiredMessage && <div className="error placeholder-error">*Required Field</div>}
    </div>
  );
};

export default DateField;
