import './disclaimer.scss';
import { ToggleButton } from 'react-bootstrap';
import { ReactNode } from 'react';

type DisclaimerProps = {
  checked: boolean;
  setChecked: Function;
  labelText: string | ReactNode;
  labelAction: () => void;
};

export const Disclaimer = ({ setChecked, checked, labelText, labelAction }: DisclaimerProps) => {
  return (
    <div className="disclaimer-container" onClick={labelAction}>
      <ToggleButton
        value={checked ? 1 : 0}
        type="checkbox"
        onChange={(e) => setChecked(e.currentTarget.checked)}
        checked={checked}
      ></ToggleButton>
      <label className="disclaimer-label">{labelText}</label>
    </div>
  );
};
