import { useContext, useEffect, useMemo, useState } from 'react';
import StepComponent from '../../../step-component/StepComponent';
import { AutoInsuranceContext } from '../../../../../context/AutoInsuranceContext';
import { UserContext } from '../../../../../context/UserContext';
import 'react-datepicker/dist/react-datepicker.css';

// Images
import { contactDetailsImages, extraContactDetailsImages } from '../../../../../constants/AutoConstants';

import { useNavigate } from 'react-router';
import InputField from '../../../forms/InputField/InputField';
import { errorMessages } from '../../../../../services/validation';
import { updateDriver } from '../../../../../services/helpers/AutoHelpers';
import { isValidEmail } from '../../../../../services/helpers/inputFieldHelpers';
import { Disclaimer } from '../../../disclaimer/Disclaimer';

export const ContactDetails = ({ driverIndex }: { driverIndex: number }) => {
  const { driverInfo, setDriverInfo, updateAutoInsuranceStep } = useContext(AutoInsuranceContext);
  const { partnerName, device, setShowDisclaimer } = useContext(UserContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [confirmedDisclaimer, setConfirmedDisclaimer] = useState(false);
  const navigate = useNavigate();

  const handleDisableButton = () => {
    //   only disable continue button on first driver
    if (driverIndex === 0) {
      return !confirmedDisclaimer;
    }
    return false;
  };

  useEffect(() => {
    driverInfo[driverIndex]?.firstName && setFirstName(driverInfo[driverIndex]['firstName'].toString());
    driverInfo[driverIndex]?.lastName && setLastName(driverInfo[driverIndex]['lastName'].toString());
    driverInfo[driverIndex]?.email && setEmail(driverInfo[driverIndex]['email'].toString());
  }, []);

  const handleInputChange = (e: any, setState: Function) => {
    let errorCopy: any = { ...errors };
    errorCopy[e.target.name] = '';

    setState(e.target.value);
    setErrors(errorCopy);
  };

  const validateEmail = () => {
    !isValidEmail(email) && setErrors((prev) => ({ ...prev, email: 'Invalid email' }));
  };

  const handleImage = useMemo(() => {
    if (driverIndex === 0) {
      return contactDetailsImages(partnerName, device);
    }
    return extraContactDetailsImages(partnerName, device);
  }, [device, driverIndex]);

  const formContent = {
    header: `${driverIndex === 0 ? 'Your contact' : 'Their contact'}`,
    cardImg: handleImage,
    disabledButton: handleDisableButton(),
    buttonTxt: 'Next',
    onClick: () => {
      let errorsCopy = { ...errors };

      firstName.length === 0 ? (errorsCopy.firstName = errorMessages.required) : (errorsCopy.firstName = '');
      lastName.length === 0 ? (errorsCopy.lastName = errorMessages.required) : (errorsCopy.lastName = '');
      email.length === 0 ? (errorsCopy.email = errorMessages.required) : (errorsCopy.email = '');

      email.length !== 0 && !isValidEmail(email) && (errorsCopy.email = errorMessages.email);

      if (driverIndex !== 0) errorsCopy.email = '';
      const errorValues = Object.values(errorsCopy);

      setErrors(errorsCopy);
      return errorValues.find((elem) => elem !== '') ? true : false;
    },
    onSubmit: () => {
      updateDriver({ firstName, lastName, email }, setDriverInfo, driverIndex);

      if (driverIndex === 0) {
        updateAutoInsuranceStep('second-driver');
        navigate('/auto/second-driver');
      } else if (driverIndex === 4) {
        navigate('/auto/results');
      } else {
        navigate(`/auto/second-driver-${driverIndex + 1}`);
      }
    },
    fields: [
      <InputField
        placeholder="First Name"
        type="text"
        name="firstName"
        value={firstName}
        onChange={(e) => handleInputChange(e, setFirstName)}
        errors={errors}
        setErrors={setErrors}
        required={true}
      />,
      <InputField
        placeholder="Last Name"
        type="text"
        name="lastName"
        value={lastName}
        onChange={(e) => handleInputChange(e, setLastName)}
        errors={errors}
        setErrors={setErrors}
        required={true}
      />,
      <>
        {driverIndex === 0 && (
          <InputField
            placeholder="Email"
            type="email"
            name="email"
            value={email}
            onChange={(e) => handleInputChange(e, setEmail)}
            onBlur={validateEmail}
            errors={errors}
            setErrors={setErrors}
            required={true}
            inputMode="email"
          />
        )}
      </>,
      driverIndex === 0 && (
        <>
          <Disclaimer
            checked={confirmedDisclaimer}
            setChecked={setConfirmedDisclaimer}
            labelText={
              <>
                I agree with{' '}
                <u
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDisclaimer(true);
                  }}
                >
                  terms and conditions
                </u>
                .
              </>
            }
            labelAction={() => {
              setConfirmedDisclaimer((prev) => !prev);
            }}
          />
        </>
      ),
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onClick={formContent.onClick}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.cardImg}
        className="contact"
        disabledButton={formContent.disabledButton}
      />
    </>
  );
};
