import { FC } from 'react';
import './user-form.scss';
import MultiFieldContainer from '../../field-container/MultiFieldContainer';
import InputField from '../InputField/InputField';
import DateField from '../DateField/DateField';
import SelectField from '../../select-field/SelectField';
import { genderOptions } from '../../../../constants';
import { formatPhoneNumber } from '../../../../services/helpers/inputFieldHelpers';
import { useFormik } from 'formik';
import { userFormSchema } from '../../../schemas/commonSchemas';
import { UserInterface } from '../../../interfaces/CommonInterfaces';
import SSNField from '../SSNField/SSNField';

type UserFormType = {
  userInfo: UserInterface;
  name: string;
  updateMember: Function;
};

const UserForm: FC<UserFormType> = ({ userInfo, updateMember }: UserFormType) => {
  const formik = useFormik({
    initialValues: userInfo,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: false,
    validationSchema: userFormSchema,
    onSubmit: () => {},
  });

  const { values, handleChange, handleBlur, errors, touched, setErrors, setFieldTouched, setFieldValue } =
    formik;

  return (
    <div className="user-form-container">
      <MultiFieldContainer>
        <InputField
          name="firstName"
          type="text"
          value={values.firstName}
          placeholder="First Name"
          onChange={(e) => {
            updateMember({
              ...values,
              firstName: e.target.value,
            });
            handleChange(e);
          }}
          onBlur={handleBlur}
          setErrors={setErrors}
          errors={touched['firstName'] ? errors : {}}
        />
        <InputField
          name="lastName"
          type="text"
          value={values.lastName}
          placeholder="Last Name"
          onChange={(e) => {
            updateMember({
              ...values,
              lastName: e.target.value,
            });
            handleChange(e);
          }}
          onBlur={handleBlur}
          setErrors={setErrors}
          errors={touched['lastName'] ? errors : {}}
        />
      </MultiFieldContainer>
      <InputField
        name="email"
        type="email"
        value={values.email}
        placeholder="Email"
        onChange={(e) => {
          updateMember({
            ...values,
            email: e.target.value,
          });
          handleChange(e);
        }}
        onBlur={handleBlur}
        setErrors={setErrors}
        errors={touched['email'] ? errors : {}}
      />
      <DateField
        value={values.dob}
        setValue={(e: any) => {
          setFieldValue('dob', e);
          updateMember({
            ...values,
            dob: values.dob,
          });
        }}
        errors={touched['dob'] ? errors : {}}
        setErrors={setErrors}
        setFieldTouched={setFieldTouched}
        formik={true}
        placeholder="Date of Birth"
      />
      <SelectField
        placeholder="Gender"
        value={values.gender}
        options={genderOptions}
        name="gender"
        handleChange={(e) => {
          setFieldValue('gender', e.target.value);
          updateMember({
            ...values,
            gender: e.target.value,
          });
          setFieldTouched('gender', true);
        }}
      />
      <MultiFieldContainer>
        <InputField
          value={formatPhoneNumber(values.phone!) || ''}
          onChange={(e) => {
            const cleanNumber = e.target.value.replace(/[^\d]/g, '');
            // update member obj with clean number for api call
            setFieldValue('phone', cleanNumber);
            updateMember({
              ...values,
              phone: cleanNumber,
            });
          }}
          onBlur={(e: any) => {
            handleBlur(e);
          }}
          placeholder="Phone Number"
          type="tel"
          name="phone"
          minLength={10}
          maxLength={14}
          setErrors={setErrors}
          errors={touched['phone'] ? errors : {}}
        />
        <SSNField
          name="ssn"
          setFunction={(updateValue: string) => {
            setFieldValue('ssn', updateValue);
            updateMember({
              ...values,
              ssn: updateValue,
            });
          }}
          handleBlur={() => setFieldTouched('ssn', true)}
          value={values.ssn || ''}
          errors={touched['ssn'] ? errors : {}}
        />
      </MultiFieldContainer>
    </div>
  );
};

export default UserForm;
