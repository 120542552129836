import { useContext, useState } from 'react';
import StepComponent from '../../step-component/StepComponent';
import { LifeInsuranceContext } from '../../../../context/LifeInsuranceContext';
import { useNavigate } from 'react-router-dom';
import AmountField from '../../forms/AmountField/AmountField';
import { useFormik } from 'formik';
import { amountFieldSchema } from '../../../schemas/lifeSchemas';
import { UserContext } from '../../../../context/UserContext';
import { debtImages } from '../../../../constants/LifeConstants';

const debt = '';

const Debt = () => {
  const {
    lifeResponses,
    setLifeResponses,
    totalSteps,
    handleLifeCalculations,
  } = useContext(LifeInsuranceContext);
  const { partnerName, device, authToken } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isShortFlow = totalSteps === 5;

  const formik = useFormik({
    initialValues: {
      debt:
        typeof lifeResponses.debt === 'number' ? `${lifeResponses.debt}` : debt,
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: amountFieldSchema,
    onSubmit: () => {},
  });

  const { values, setFieldValue } = formik;

  const handleFormSubmit = async (onSubmit: Function) => {
    let debtAmount = parseInt(values.debt);
    let updatedAmounts = {
      ...lifeResponses,
      dependents: 0,
      education: 0,
      debt: debtAmount,
    };
    if (isShortFlow) {
      handleLifeCalculations(
        updatedAmounts,
        authToken,
        onSubmit,
        setIsLoading,
        'summary'
      );
      return;
    }
    setLifeResponses((prev) => ({
      ...prev,
      debt: debtAmount,
    }));
    onSubmit('dependents');
  };

  const submitCallback = (link: string) => {
    navigate(`/life/${link}`);
  };

  const formContent = {
    header: 'Do you have any debt?',
    subheader:
      'This includes any outstanding mortgage, car loans, credit card debt, and any other debt you may have',
    img: debtImages(partnerName, device),
    buttonTxt: 'Continue',
    disableButton: values.debt.length < 1,
    onAPIClick: handleFormSubmit,
    onSubmit: submitCallback,
    fields: [
      <AmountField
        placeholder="Enter Debt Amount"
        name="debt"
        onSubmit={() => {}}
        value={values.debt}
        setFieldValue={setFieldValue}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        subHeader={formContent.subheader}
        buttonTxt={formContent.buttonTxt}
        hasApiCall={true}
        onAPIClick={formContent.onAPIClick}
        onSubmit={formContent.onSubmit}
        isLoading={isLoading}
        fields={formContent.fields}
        cardImage={formContent.img}
        disabledButton={formContent.disableButton}
      />
    </>
  );
};

export default Debt;
