import { useContext, useState } from 'react';
import { UserContext } from '../../../../context/UserContext';
import './quote-card.scss';
import ChevronRight from '../../../../assets/common/chevron-right.svg';
import QuoteInfo from '../QuoteInfo/QuoteInfo';
import SecondaryButton from '../../buttons/SecondaryButton';
import { AfiQuoteType } from '../../../interfaces/AFIInterfaces';

type QuoteCardProps = {
  logo: string;
  price: string;
  product: AfiQuoteType;
};
const AfiQuoteCard = ({ logo, price, product }: QuoteCardProps) => {
  const { isMobile, device } = useContext(UserContext);
  const [showQuoteInfoModal, setShowQuoteInfoModal] = useState(false);

  const openQuotesModal = () => {
    device !== 'desktop' && setShowQuoteInfoModal(true);
  };

  const closeQuotesModal = () => {
    setShowQuoteInfoModal(false);
  };

  return (
    <>
      <div className="afi-quote-card-container" onClick={openQuotesModal}>
        <div className="afi-quote-card-top">
          {device !== 'desktop' && (
            <div className="afi-quote-card-logo" style={{ backgroundImage: `url(${logo || ''})` }}>
              {!logo && product?.insurer?.name}
            </div>
          )}
          {isMobile && <ChevronRight onClick={openQuotesModal} />}
          {device === 'tablet' && <SecondaryButton text="View details" onClick={openQuotesModal} />}
        </div>
        {device !== 'desktop' && (
          <div className="afi-quote-price">
            <span className="price">{price}</span>
            <span className="description"> monthly</span>
          </div>
        )}
        {device === 'desktop' && <QuoteInfo closeModal={closeQuotesModal} product={product} logo={logo} />}
      </div>
      {showQuoteInfoModal && <QuoteInfo closeModal={closeQuotesModal} product={product} logo={logo} />}
    </>
  );
};

export default AfiQuoteCard;
