import { AFIQuestionInterface, OptionType } from '../../components/interfaces/AFIInterfaces';
import { SchemaOf, string, StringSchema } from 'yup';
import { SelectOptionInterface } from '../../components/interfaces/InputInterfaces';
import _, { camelCase, isArray } from 'lodash';
import { generateSelectOptionsArray, sortCoveragesValues } from './AFIHelpers';
import { tooltipTexts } from '../../constants/AFIConstants';

/**
 * Returns a question in the AFI questionnaire in its required format.
 * @param identifier - Unique identifier, string.
 * @param title - Question title, string.
 * @param placeholder - Question placeholder, string.
 * @param validation - Question validation using yup.
 * @param type - Question type, between 'input' | 'radio' | 'select' | 'date' | 'address'.
 * @param value - Default question value, string. Pass undefined if chosen type is "select" or "radio" and you need the following value.
 * @param options - Optional parameter, only if type is "select" or "radio", as Array<{name: string; value: string}>.
 * @returns object of type AFIQuestionInterface.
 */
export const questionBuilder = (
  identifier: string,
  title: string,
  placeholder: string,
  validation: SchemaOf<string | Date | number> | StringSchema,
  type:
    | 'input'
    | 'multi-field-input'
    | 'radio'
    | 'select'
    | 'multi-field-select'
    | 'date'
    | 'short-date'
    | 'address'
    | 'zip'
    | 'email'
    | 'phone'
    | 'checkbox'
    | 'year'
    | 'amount',
  value?: string | undefined,
  options?: Array<OptionType> | Array<SelectOptionInterface> | undefined,
  hasLabel?: 'top' | 'left',
  tooltip?: string[],
) => {
  let question = {
    identifier: identifier,
    name: camelCase(identifier),
    title: title,
    placeholder: placeholder,
    validation: validation,
    type: type,
    value: value || '',
    hasLabel: hasLabel,
  } as AFIQuestionInterface;
  if (type === 'multi-field-input') {
    question.className = 'multi-field';
    question.type = question.identifier === 'premium' ? 'amount' : 'input';
  }
  if (type === 'multi-field-select') {
    question.className = 'multi-field';
    question.type = 'select';
  }
  if (type === 'select' || type === 'multi-field-select') {
    question.placeholder = 'Select';
  }
  if (
    type === 'select' ||
    type === 'multi-field-select' ||
    type === 'radio' ||
    type === 'checkbox' ||
    type === 'year'
  ) {
    question.options = options;
  }
  if (tooltip) {
    question.tooltip = tooltip;
  }
  return question;
};

export const customizeQuestionOptions = (
  question: AFIQuestionInterface,
  newOptions: { [key: string]: string },
  newKey?: string,
) => {
  const newObj = _.cloneDeep(question);
  newObj.options = generateSelectOptionsArray(newOptions);
  newObj.validation = string().oneOf(Object.keys(newOptions)).required('This can’t be left blank');
  if (newKey) {
    newObj.name = newKey;
  }
  return newObj;
};

export const customizeQuestionOptionsWithDefault = (
  defaultValues: { [key: string]: string },
  question: AFIQuestionInterface,
  newOptions: { [key: string]: string },
  newKey?: string,
) => {
  const newObj = customizeQuestionOptions(question, newOptions, newKey);
  if (newObj.options && newObj?.options?.length > 0) {
    const sortedOptions = sortCoveragesValues(newObj.options);
    defaultValues[newObj?.name] = sortedOptions[Math.floor((sortedOptions.length - 1) / 2)].value;
    newObj.value = defaultValues[newObj.name];
  }
  return newObj;
};

export const customizeDefault = (
  defaultValues: { [key: string]: string },
  question: AFIQuestionInterface,
  key: string,
) => {
  const newObj = { ...question };
  newObj.name = key;
  if (newObj.options && newObj?.options?.length > 0) {
    const sortedOptions = sortCoveragesValues(newObj.options);
    defaultValues[newObj?.name] = sortedOptions[Math.floor((sortedOptions.length - 1) / 2)].value;
    newObj.value = defaultValues[newObj.name];
  }
  //Tooltip texts need to be added to towing and rental questions when this function is called
  //because these questions and their options get added to the flow here once the user passes the address step
  if (['coveragesRental', 'coveragesTowing', 'coveragesLoanLeaseGap'].includes(newObj.identifier)) {
    newObj.tooltip =
      tooltipTexts[`${newObj.identifier}` as 'coveragesRental' | 'coveragesTowing' | 'coveragesLoanLeaseGap'];
  }
  return newObj;
};

export const customizeQuestionKey = (question: AFIQuestionInterface, newKey: string) => {
  const newObj = _.cloneDeep(question);
  newObj.name = newKey;
  return newObj;
};

/**
 * Used for questions with Yes/No answers.
 * @returns object of type AFIQuestionInterface.
 */
export const closedQuestionBuilder = (
  title: string,
  placeholder: string,
  value: 'yes' | 'no',
  identifier: string,
) => {
  return questionBuilder(
    identifier,
    title,
    placeholder,
    string().oneOf(['yes', 'no']).required(),
    'radio',
    value,
    [
      { displayName: 'Yes', value: 'yes' },
      { displayName: 'No', value: 'no' },
    ],
  );
};
