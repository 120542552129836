import React from 'react';
import './member-form-container.scss';

const MemberFormContainer = (props: {
  children: React.ReactNode;
}) => {
  return (
    <div className={`form-list-wrapper`}>
      {props.children}
    </div>
  );
};

export default MemberFormContainer;
