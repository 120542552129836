import { useContext } from 'react';
import StepComponent from '../../../step-component/StepComponent';
import { AutoInsuranceContext } from '../../../../../context/AutoInsuranceContext';
import { UserContext } from '../../../../../context/UserContext';
import RadioButtonContainer from '../../../radio-button-container/RadioButtonContainer';
import { useNavigate } from 'react-router';

// Images
import { extraDriverImages } from '../../../../../constants/AutoConstants';
const driverTitle = ['second', 'third', 'fourth', 'fifth'];

export const DriverExtra = ({ driverIndex }: { driverIndex: number }) => {
  const { hasExtraDrivers, setHasExtraDrivers, driverInfo, setDriverInfo } =
    useContext(AutoInsuranceContext);
  const { partnerName, device } = useContext(UserContext);
  const navigate = useNavigate();
  let drivers = driverIndex + 1;

  const formContent = {
    header: `Add a ${driverTitle[driverIndex]} driver?`,
    cardImg: extraDriverImages(partnerName, device),
    buttonTxt: hasExtraDrivers === 'Yes' ? 'Next' : 'Get My Quotes',
    onSubmit: () => {
      if (hasExtraDrivers === 'Yes') {
        navigate(`/auto/credit-score-${driverIndex + 2}`);
      } else {
        if (drivers < driverInfo.length) {
          setDriverInfo(driverInfo.slice(0, drivers));
        }
        navigate('/auto/results');
      }
    },
    errors: {},
    fields: [
      <RadioButtonContainer
        options={['Yes', 'No']}
        size="large"
        value={hasExtraDrivers}
        handleChange={(val) => setHasExtraDrivers(val)}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.cardImg}
      />
    </>
  );
};
