import './small-modal.scss';
import CloseButton from '../../../../assets/common/close-icon.svg';
import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';

type ModalProps = {
  setShowModal: Function;
  title: string;
  promptText: string;
  onClick: (e: any) => void;
};

const SmallModal = ({ setShowModal, onClick, title, promptText }: ModalProps) => {
  return (
    <div className="small-modal-container">
      <div className="small-modal">
        <div className="close-button-container">
          <CloseButton
            className="close-button"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </div>
        <p className="small-modal-title">{title}</p>
        <p className="small-modal-text">{promptText}</p>
        <div className="small-modal-buttons-container">
          <SecondaryButton
            text="No"
            onClick={() => {
              setShowModal(false);
            }}
          />
          <PrimaryButton text="Yes" onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default SmallModal;
