import { useState } from 'react';
import './spartan-bar.scss';
import './spartan-bar-variant.scss';
import AflacDropdown from '../aflac-inputs/AflacDropdown';
import { aflacStates, coverageOptions, StartImages } from '../../../../pages/aflac/constants';
import AflacInputField from '../aflac-inputs/AflacInputField';
import { getNumberInputs } from '../../../../services/helpers/inputFieldHelpers';
import { useWindowDimensions } from '../../../../services/hooks/useWindowDimensions';

const DROPDOWN_LABELS = {
  state: 'State',
  coverage: 'Covering?',
};

const TABLET_WIDTH = 768;

const SpartanInputsBar = ({ baseUrl, variant }: { baseUrl: string; variant?: string }) => {
  const [selectedState, setSelectedState] = useState({ id: '', value: '' });
  const [age, setAge] = useState('');
  const [ageError, setAgeError] = useState(false);
  const [selectedCoverage, setSelectedCoverage] = useState({ id: '', value: '' });
  const [activeField, setActiveField] = useState('');

  const handleStateSelection = (
    elem: { id: string; value: string },
    setSelectedOption: Function,
    index: number,
  ) => {
    if (elem) {
      setSelectedOption(index);
      setSelectedState(elem);
    }
  };

  const handleCoverageSelection = (
    elem: { id: string; value: string },
    setSelectedOption: Function,
    index: number,
  ) => {
    if (elem) {
      setSelectedOption(index);
      setSelectedCoverage(elem);
    }
  };
  const handleInputOnClick = (label: string) => {
    setActiveField(label);
  };

  const disableButton = () => {
    return !(selectedState.value && age && selectedCoverage.value && !ageError);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    window.location.href = `${baseUrl}&state=${selectedState.id}&age=${age}&coverage=${selectedCoverage.id}`;
  };

  return (
    <div className={`spartan-bar-container ${variant ? 'spartan-bar-variant' : ' '}`}>
      <div className="spartan-bar">
        <div className="spartan-bar-content">
          <div className="spartan-bar-item first-input">
            <AflacDropdown
              label={DROPDOWN_LABELS.state}
              options={aflacStates}
              value={selectedState.value}
              placeholder={DROPDOWN_LABELS.state}
              onChange={handleStateSelection}
              onClick={handleInputOnClick}
              setActiveField={setActiveField}
              closeDropdown={activeField !== DROPDOWN_LABELS.state}
            />
          </div>
          <div className="spartan-bar-item second-input">
            <AflacInputField
              name="age"
              label={'Age'}
              type="text"
              inputMode="numeric"
              placeholder={'Age'}
              pattern={'^[0-9]*$'}
              onClick={handleInputOnClick}
              onChange={(e) => {
                setAgeError(false);
                setActiveField('Age');
                if (getNumberInputs(e.target.value)) setAge(e.target.value);
              }}
              onBlur={(e) => {
                if (age !== '') {
                  setAgeError(parseInt(age) < 18);
                }
              }}
              minLength={1}
              maxLength={2}
              value={age}
              error={ageError}
              variant={variant}
            />
          </div>

          <div className="spartan-bar-item third-input">
            <AflacDropdown
              label={DROPDOWN_LABELS.coverage}
              options={coverageOptions}
              value={selectedCoverage.value}
              placeholder={'Coverage'}
              onChange={handleCoverageSelection}
              onClick={handleInputOnClick}
              setActiveField={setActiveField}
              closeDropdown={activeField !== DROPDOWN_LABELS.coverage}
            />
          </div>
        </div>
        {variant ? (
          <button
            className={`cta ${disableButton() ? 'disabled' : ''}`}
            id="spartan-go"
            onClick={handleSubmit}
            disabled={disableButton()}
          >
            Go {StartImages(useWindowDimensions().width, '1')}
          </button>
        ) : (
          <button
            className={`spartan-bar cta ${disableButton() ? 'disabled' : ''}`}
            id="spartan-go"
            onClick={handleSubmit}
            disabled={disableButton()}
          >
            {useWindowDimensions().width < TABLET_WIDTH && 'Start'} {StartImages(useWindowDimensions().width)}
          </button>
        )}
      </div>
    </div>
  );
};

export default SpartanInputsBar;
