import axios from 'axios';
import endpoints from './endpoints';

const getUpsellRecommendation =  async (payload: any, token: string) => {
    const resp = await axios.post(endpoints.upsellRecommendations, payload, {
        headers: {
            Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return resp;
}

export default getUpsellRecommendation
