import { string } from 'yup';
import { states } from '../../../constants';
import { generateSelectOptionsArray } from '../../../services/helpers/AFIHelpers';
import { questionBuilder } from '../../../services/helpers/AFIQuestionBuilder';
import { yupValidations } from '../../../services/yupValidations';

export const driverFirstName = questionBuilder(
  'driver_first_name',
  'First Name',
  'First Name',
  yupValidations.stringMinMaxLength(2, 25, 'name validation'),
  'multi-field-input',
);

export const driverLastName = questionBuilder(
  'driver_last_name',
  'Last Name',
  'Last Name',
  yupValidations.stringMinMaxLength(2, 25, 'name validation'),
  'multi-field-input',
);

export const driverDOB = questionBuilder(
  'driver_dob',
  'Date of Birth',
  'MM/DD/YYYY',
  yupValidations.ageMinMax(16, 100),
  'date',
);

export const driverGenderOptions: { [key: string]: string } = {
  M: 'Male',
  F: 'Female',
};
export const driverGender = questionBuilder(
  'driver_gender',
  'Gender',
  'Gender',
  string().oneOf(Object.keys(driverGenderOptions)).required('This can’t be left blank'),
  'radio',
  '',
  generateSelectOptionsArray(driverGenderOptions),
);

export const maritalStatusOptions = {
  S: 'Single',
  M: 'Married',
  W: 'Widowed',
  D: 'Divorced',
  V: 'Registered Domestic Partner',
};

export const maritalStatus = questionBuilder(
  'driver_marital_status',
  'Marital Status',
  'Marital Status',
  string().oneOf(Object.keys(maritalStatusOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(maritalStatusOptions),
);

export const driverOccupationOptions = {
  FRM: 'Agriculture/Farm Worker',
  BO: 'Bussiness Owner',
  CL: 'Clergy',
  CLR: 'Clerical Worker',
  CFT: 'Craftsman/Tradesman',
  DIS: 'Disabled',
  DEN: 'Doctor/Dentist',
  SCT: 'Educator',
  ENG: 'Engineer/Architect/Designer',
  ENT: 'Entertainer',
  ACT: 'Finance/Accountant',
  HO: 'Homemaker',
  CMP: 'Information Systems/Programming',
  LAB: 'Laborer',
  LAW: 'Lawyer',
  MKT: 'Marketing/Advertising',
  MI: 'Military',
  OF: 'Office Administrator',
  PCD: 'Other Professional',
  PHY: 'Physician/Surgeon/Veterinarian',
  RET: 'Retired',
  SAL: 'Sales/Sales management',
  SE: 'Self-Employed',
  ST: 'Student (full-time)',
  HOT: 'Travel/Hospitality/Restaurant',
  UNEM: 'Unemployed',
  OT: 'Other',
};

export const driverOccupation = questionBuilder(
  'driver_occupation',
  'Occupation',
  'Occupation',
  string().oneOf(Object.keys(driverOccupationOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(driverOccupationOptions),
);

export const driverEducationOptions: { [key: string]: string } = {
  LessThanHighSchoolDiploma: 'Some High School',
  HighSchool: 'High School Diploma',
  AssociateDegree: '2-Year College Degree',
  BachelorsDegree: '4-Year College Degree',
  MastersDegree: 'Graduate Degree',
  Doctorate: 'Doctorate Degree',
};

export const driverEducation = questionBuilder(
  'driver_education',
  'Education',
  'Education',
  string().oneOf(Object.keys(driverEducationOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(driverEducationOptions),
);

export const driverResidenceOptions = {
  RENTD: 'Rent',
  DW: 'Own',
};

// only needed for driver1
export const driverResidence = questionBuilder(
  'driver_residence',
  'Residence',
  'Residence',
  string().oneOf(Object.keys(driverResidenceOptions)).required('This can’t be left blank'),
  'radio',
  'RENTD',
  generateSelectOptionsArray(driverResidenceOptions),
);

//take age licensed and calculate date licensed based on dob => YYYY-MM-DD = backend or frontend work?
export const driverAgeLicensed = questionBuilder(
  'driver_age_licensed',
  'Age licensed',
  'Age',
  yupValidations.ageNumberRequired(16),
  'input',
  undefined,
);

export const driverRelationshipToInsuredOptions: { [key: string]: string } = {
  IN: 'Self',
  SP: 'Spouse',
  CH: 'Child',
  RE: 'Relative',
  DP: 'Domestic Partner',
  OT: 'Other',
};
export const driverRelationshipToInsured = questionBuilder(
  'driver_relationship_to_insured',
  'Relationship to Insured',
  'Relationship to Insured',
  string().oneOf(Object.keys(driverRelationshipToInsuredOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(driverRelationshipToInsuredOptions),
);

export const driverLicenseStatusOptions = {
  Active: 'Active',
  Expired: 'Expired',
  FORGN: 'Foreign',
  PERMT: 'Permit',
  LILMT: 'Restricted',
  Suspended: 'Suspended',
  Temporary: 'Temporary',
};

export const driverLicenseStatus = questionBuilder(
  'driver_license_status',
  'License Status',
  'License Status',
  string().oneOf(Object.keys(driverLicenseStatusOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(driverLicenseStatusOptions),
);

export const driverLicenseState = questionBuilder(
  'driverLicenseState',
  'License State',
  'License State',
  string()
    .oneOf(
      states.map((item: { value: any }) => {
        return item.value;
      }),
      'Invalid state',
    )
    .required('This can’t be left blank'),
  'select',
  undefined,
  states,
);

export const driverLicenseSuspensionOptions = { Yes: 'Yes', No: 'No' };
export const driverLicenseSuspension = questionBuilder(
  'driver_license_suspension',
  'License Suspension in the past 5 years',
  'License Suspension',
  string().oneOf(Object.keys(driverLicenseSuspensionOptions)).required('This can’t be left blank'),
  'radio',
  'No',
  generateSelectOptionsArray(driverLicenseSuspensionOptions),
);

export const driverSR22Options = { R: 'Yes', N: 'No' };
export const driverSR22 = questionBuilder(
  'driver_sr22',
  'SR22',
  'SR22',
  string().oneOf(Object.keys(driverSR22Options)).required('This can’t be left blank'),
  'radio',
  'N',
  generateSelectOptionsArray(driverSR22Options),
);

export const driverSR22FilingState = questionBuilder(
  'driver_sr22_filing_state',
  'SR22 Filing State',
  'Which state do you need the SR22 for',
  string()
    .oneOf(
      states.map((item: { value: any }) => {
        return item.value;
      }),
    )
    .required('This can’t be left blank'),
  'select',
  undefined,
  states,
);

export const driverFR44Options = { R: 'Yes', N: 'No' };
export const driverFR44 = questionBuilder(
  'driver_fr44',
  'FR44',
  'FR44',
  string().oneOf(Object.keys(driverFR44Options)).required('This can’t be left blank'),
  'radio',
  'N',
  generateSelectOptionsArray(driverFR44Options),
);

export const driverFR44FilingState = questionBuilder(
  'driver_fr44_filing_state',
  'FR44 Filing State',
  'Which state do you need the FR44 for',
  string()
    .oneOf(
      states.map((item: { value: any }) => {
        return item.value;
      }),
    )
    .required('This can’t be left blank'),
  'select',
  undefined,
  states,
);

export const driverTrainingDiscount = questionBuilder(
  'driver_training_discount',
  'Driver training discount',
  'Driver training discount',
  yupValidations.optionRequired,
  'checkbox',
  'No',
  [
    { displayName: 'N', value: 'No' },
    { displayName: 'Y', value: 'Yes' },
  ],
);

export const driverGoodStudentDiscount = questionBuilder(
  'driver_good_student_discount',
  'Good student discount',
  'Good student discount',
  yupValidations.optionRequired,
  'checkbox',
  'N',
  [
    { displayName: 'N', value: 'No' },
    { displayName: 'Y', value: 'Yes' },
  ],
);

export const driverStudentLivingAway = questionBuilder(
  'driver_student_living_away',
  'Student away from home',
  'Student living away from home',
  yupValidations.optionRequired,
  'checkbox',
  'N',
  [
    { displayName: 'N', value: 'No' },
    { displayName: 'Y', value: 'Yes' },
  ],
);

export const driverDefensiveDiscount = questionBuilder(
  'driver_defensive_discount',
  'Defensive driver discount',
  'Defensive driver discount',
  yupValidations.optionRequired,
  'checkbox',
  'N',
  [
    { displayName: 'N', value: 'N' },
    { displayName: 'Y', value: 'Y' },
  ],
);

export const defensiveDriverCourseDate = questionBuilder(
  'defensiveDriverCourseDate',
  'Defensive driver course date',
  'MM/DD/YYYY',
  yupValidations.minDateRequired(3, 'The course must have been completed within the last 3 years'),
  'date',
);

//only for driver1
export const driverAAAMember = questionBuilder(
  'driver_aaa_member',
  'AAA member discount',
  'Are you a member of AAA?',
  yupValidations.optionRequired,
  'checkbox',
  'None',
  [
    { displayName: 'No', value: 'None' },
    { displayName: 'Yes', value: 'AAA' },
  ],
);

export const incidentDescriptionOptions: { [key: string]: string } = {
  AFABDY: 'Bodily injury and damage to other vehicle',
  AFAPDL: 'Damage to other vehicle, under $1000',
  AFAPDH: 'Damage to other vehicle, over $1000',
  AFAPPDL: 'Only my vehicle damaged, under $1000',
  AFAPPDH: 'Only my vehicle damaged, over $1000 ',
  NOTATFAULTACCD: 'Accident was ruled "not my fault"',
  SPEEDING1: 'Speeding less than 10-MPH over limit',
  SPEEDING2: 'Speeding 10-to-20-MPH over limit',
  SPEEDING3: 'Speeding more than 20-MPH over limit',
  SIGNAL: 'Traffic sign violation (run a red light)',
  IMPROPTURN: 'Improper lane change / turn',
  DEFECTIVEEQUIP: 'Equipment failure (burned out light)',
  SEATBELT: 'No seatbelt',
  MINOR: 'Other minor violation (carpool, tail-gating)',
  NOINSURANCE: 'Driving without insurance',
  SUSPENREVOKE: 'Driving with suspended or revoked license',
  INTOXIC: 'Driving under the influence (DUI/DWI)',
  MAJOR: 'Other major violation (reckless driving)',
  ANIMAL: 'Collided with animal',
  NFAPARKED: 'Vehicle hit while parked',
  THEFT: 'Theft of vehicle',
  VAND: 'Vandalism',
  FIRE: 'Vehicle fire',
  GLASS: 'Cracked windshield (not damaged in accident)',
  WEATHER: 'Weather-related damage',
  COMPREHENSIVE: 'Other non-accident claim or loss',
};

export const incidentDescription = questionBuilder(
  'incidentDescription',
  'Incident Description',
  'Incident Description',
  string().oneOf(Object.keys(incidentDescriptionOptions)).required('This can’t be left blank'),
  'select',
  undefined,
  generateSelectOptionsArray(incidentDescriptionOptions),
);

export const incidentDate = questionBuilder(
  'incidentDate',
  'Incident Date',
  'MM/YYYY',
  yupValidations.maxYearDifference(5),
  'short-date',
);

export const driverIncidents = questionBuilder(
  'driverIncidents',
  'Driver Incidents',
  'Driver Incidents',
  yupValidations.optionRequired,
  'radio',
  'Y',
  [
    { displayName: 'Yes', value: 'Y' },
    { displayName: 'No', value: 'N' },
  ],
);
