// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar-wrapper {
  display: flex;
  justify-content: center;
  padding: 0px 70px 0 70px;
  margin-bottom: 16px;
}

.progress-bar-container {
  max-width: 600px;
  flex: 1 0 auto;
}
.progress-bar-container .progress-bar-title {
  font-family: var(--primary-font);
  font-size: 18px;
  line-height: 36px;
  font-weight: 500;
  padding-bottom: 8px;
  color: var(--primary-text-color);
}
.progress-bar-container .progress {
  height: 6px;
  border-radius: 20px;
  background-color: #fff;
}
.progress-bar-container .progress .progress-bar {
  height: 100%;
  border-radius: 20px;
  background-color: var(--accent-color);
}

@media (min-width: 580px) {
  .progress-bar-wrapper .progress-bar-container .segmented-bar-container {
    display: flex;
    justify-content: space-between;
    height: 6px;
  }
  .progress-bar-wrapper .progress-bar-container .segmented-bar-container .segmented-progress-bar {
    border: none;
    background-color: #fff;
    margin-right: 7px;
    border-radius: 29px;
    height: 100%;
  }
  .progress-bar-wrapper .progress-bar-container .segmented-bar-container .segmented-progress-bar.filled {
    background-color: var(--accent-color);
  }
}
@media (min-width: 840px) {
  .progress-bar-wrapper {
    padding: 0px 84px;
    margin-bottom: 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/progress-bar/ProgressBar/progress-bar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,wBAAA;EACA,mBAAA;AACF;;AACA;EACE,gBAAA;EACA,cAAA;AAEF;AAAE;EACE,gCAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gCAAA;AAEJ;AACE;EACE,WAAA;EACA,mBAAA;EACA,sBAAA;AACJ;AACI;EACE,YAAA;EACA,mBAAA;EACA,qCAAA;AACN;;AAKA;EAGM;IACE,aAAA;IACA,8BAAA;IACA,WAAA;EAJN;EAMM;IACE,YAAA;IACA,sBAAA;IACA,iBAAA;IACA,mBAAA;IACA,YAAA;EAJR;EAMM;IACE,qCAAA;EAJR;AACF;AAWA;EACE;IACE,iBAAA;IACA,mBAAA;EATF;AACF","sourcesContent":[".progress-bar-wrapper {\n  display: flex;\n  justify-content: center;\n  padding: 0px 70px 0 70px;\n  margin-bottom: 16px;\n}\n.progress-bar-container {\n  max-width: 600px;\n  flex: 1 0 auto;\n\n  .progress-bar-title {\n    font-family: var(--primary-font);\n    font-size: 18px;\n    line-height: 36px;\n    font-weight: 500;\n    padding-bottom: 8px;\n    color: var(--primary-text-color);\n  }\n\n  .progress {\n    height: 6px;\n    border-radius: 20px;\n    background-color: #fff;\n\n    .progress-bar {\n      height: 100%;\n      border-radius: 20px;\n      background-color: var(--accent-color);\n    }\n  }\n}\n\n//tablet\n@media (min-width: 580px) {\n  .progress-bar-wrapper {\n    .progress-bar-container {\n      .segmented-bar-container {\n        display: flex;\n        justify-content: space-between;\n        height: 6px;\n\n        .segmented-progress-bar {\n          border: none;\n          background-color: #fff;\n          margin-right: 7px;\n          border-radius: 29px;\n          height: 100%;\n        }\n        .segmented-progress-bar.filled {\n          background-color: var(--accent-color);\n        }\n      }\n    }\n  }\n}\n\n//desktop\n@media (min-width: 840px) {\n  .progress-bar-wrapper {\n    padding: 0px 84px;\n    margin-bottom: 32px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
