import SecondaryButton from '../../buttons/SecondaryButton';
import './summary.scss';
import SummaryCard from './SummaryCard';

type SummaryProps = {
  extraButtonText: string;
  content: any;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  extraOnClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const SummaryContainer = ({ extraButtonText, content, extraOnClick, onEdit, onDelete }: SummaryProps) => {
  return (
    <div className="afi-summary-cards-container">
      {content.map((item: any, index: number) => (
        <SummaryCard
          key={`item.cardTitle-${index}`}
          cardTitle={item.title}
          cardIndex={index}
          label={item.label}
          onEdit={onEdit}
          onDelete={onDelete}
          fields={item.fields}
          showError={content.length > 1}
          isComplete={item?.isComplete}
        />
      ))}
      <SecondaryButton text={extraButtonText} onClick={extraOnClick} />
    </div>
  );
};

export default SummaryContainer;
