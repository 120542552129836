// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-card-container {
  display: flex;
  justify-content: center;
}
.hero-card-container .hero-card-content-container {
  max-width: 573px;
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.hero-card-container .hero-card-content-container .hero-card-title {
  max-width: 233px;
}
.hero-card-container .hero-card-content-container .hero-card-title .page-title-container {
  text-align: left;
  font-size: 64px;
  padding: 0;
}
.hero-card-container .hero-card-content-container .hero-card-title .page-title-container .shrink {
  font-size: 32px;
  letter-spacing: 1px;
}
.hero-card-container .hero-card-content-container .hero-card-title .page-title-container .shrink::after {
  content: "";
  background-image: none;
}`, "",{"version":3,"sources":["webpack://./src/components/common/hero-card/hero-card.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;AACF;AACE;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,OAAA;AACJ;AACI;EACE,gBAAA;AACN;AAAM;EACE,gBAAA;EACA,eAAA;EACA,UAAA;AAER;AAAQ;EACE,eAAA;EACA,mBAAA;AAEV;AACQ;EACE,WAAA;EACA,sBAAA;AACV","sourcesContent":[".hero-card-container {\n  display: flex;\n  justify-content: center;\n\n  .hero-card-content-container {\n    max-width: 573px;\n    display: flex;\n    justify-content: space-between;\n    flex: 1;\n\n    .hero-card-title {\n      max-width: 233px;\n      .page-title-container {\n        text-align: left;\n        font-size: 64px;\n        padding: 0;\n\n        .shrink {\n          font-size: 32px;\n          letter-spacing: 1px;\n        }\n\n        .shrink::after {\n          content: '';\n          background-image: none;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
