import './buttons.scss';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  text: string;
  link?: string;
  buttonColor?: string;
  hoverButtonColor?: string;
  activeButtonColor?: string;
  fontColor?: string;
  disabled?: boolean;
  loading?: boolean;
  form?: string;
  type?: "button" | "submit" | "reset" | undefined
};

const PrimaryButton = ({
  text,
  onClick,
  buttonColor,
  hoverButtonColor,
  activeButtonColor,
  fontColor,
  loading = false,
  disabled = false,
  form,
  type,
}: ButtonProps) => {
  const [toggleHover, setToggleHover] = useState(false);
  const [toggleActive, setToggleActive] = useState(false);
  const [buttonStyle, setButtonStyle] = useState({
    backgroundColor: buttonColor,
    color: fontColor,
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    !disabled && onClick && onClick(e);
  };

  useEffect(() => {
    toggleHover && hoverButtonColor
      ? setButtonStyle({
          ...buttonStyle,
          backgroundColor: hoverButtonColor,
        })
      : setButtonStyle({
          ...buttonStyle,
          backgroundColor: buttonColor,
        });
  }, [toggleHover]);

  useEffect(() => {
    toggleActive && activeButtonColor
      ? setButtonStyle({
          ...buttonStyle,
          backgroundColor: activeButtonColor,
        })
      : setButtonStyle({
          ...buttonStyle,
          backgroundColor: toggleHover ? hoverButtonColor : buttonColor,
        });
  }, [toggleActive]);

  return (
    <>
      <button
        className={`primary-button ${disabled ? 'disabled' : ''}`}
        onClick={handleClick}
        onMouseOver={() => hoverButtonColor && setToggleHover(true)}
        onMouseLeave={() => hoverButtonColor && setToggleHover(false)}
        onMouseDown={() => activeButtonColor && setToggleActive(true)}
        onMouseUp={() => activeButtonColor && setToggleActive(false)}
        style={buttonStyle}
        form={form}
        type={type}
      >
        {loading ? (
          <Spinner
            animation="border"
            role="status"
            aria-hidden="true"
            variant="primary"
          />
        ) : (
          text
        )}
      </button>
    </>
  );
};

export default PrimaryButton;
