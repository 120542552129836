import { ReactNode, useContext } from 'react';
import './flow-disclaimer.scss';
import CloseIcon from '../../../assets/common/close-icon.svg';
import { UserContext } from '../../../context/UserContext';
import Header from '../Header/Header';

type FlowDisclaimerProps = {
  content: string | ReactNode;
  subtitle?: string;
  className?: string;
  headerContent: { logo: React.ReactNode | undefined; title: string };
};

export const FlowDisclaimer = ({ content, subtitle, className, headerContent }: FlowDisclaimerProps) => {
  const { setShowDisclaimer } = useContext(UserContext);
  return (
    <div className="modal">
      <Header showIcon={true} headerContent={headerContent} onIconClick={() => setShowDisclaimer(false)} />

      <header className="modal-header">
        <div className="modal-header-content">
          <CloseIcon className="close-button" onClick={() => setShowDisclaimer(false)} />
          {subtitle}
        </div>
      </header>
      <div className={`modal-body ${className ? className : ''}`}>
        <div className="modal-content">{content}</div>
      </div>
    </div>
  );
};
