import { useContext, useEffect, useMemo, useState } from 'react';
import { LifeInsuranceContext } from '../../context/LifeInsuranceContext';
import { UserContext } from '../../context/UserContext';
import { LifeDisclaimer } from '../../constants/LifeConstants';
import PageTitle from '../../components/common/page-title/PageTitle';
import LifeRecommendationCard from '../../components/common/cards/recommendation-card/life-recommendation/LifeRecommendationCard';
import Calculator from '../../components/common/calculator/Calculator';
import { useFormik } from 'formik';
import './life.scss';
import { getAgeFromBirthday } from '../../services/helpers/CommonHelpers';
import { getHavenLink, getLifeQuote } from '../../api/apiFunctions';
import { getValidIntervals, getClosestIntervals } from '../../utils/life/life';
import { getRecommendedContent } from '../../services/helpers/LifeHelpers';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/common/Header/Header';
import mixpanelWrapper from '../../utils/tools/mixpanel';

const LifeRecommendations = () => {
  const {
    policyAmounts,
    setPolicyAmounts,
    yearOptions,
    amountOptions,
    havenRedirectInfo,
    setHavenRedirectInfo,
    lifeProductType,
    setLifeProductType,
    insuranceProvider,
    progressStep,
  } = useContext(LifeInsuranceContext);
  const { userInfo, addressInfo, setShowErrorBanner, authToken, upsellInfo, headerContent } =
    useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
    if (progressStep === 0) {
      navigate('/life/start');
    }
  }, []);

  const recommendedContent = useMemo(() => {
    const { content } = getRecommendedContent(insuranceProvider);
    return content;
  }, [insuranceProvider]);

  const formik = useFormik({
    initialValues: {
      termLength: '10',
      amount: policyAmounts.policyAmount.toString(),
      monthlyCost: policyAmounts.monthlyCost.toString(),
    },
    enableReinitialize: false,
    onSubmit: () => {},
  });
  const { values, handleChange, setValues } = formik;

  const [availableYears, setAvailableYears] = useState([...yearOptions]);
  const [availableAmounts, setAvailableAmounts] = useState([...amountOptions]);

  useEffect(() => {
    setAvailableYears(
      getValidIntervals(
        addressInfo.state,
        getAgeFromBirthday(userInfo.dob),
        parseInt(values.amount),
        upsellInfo,
      ),
    );
  }, [addressInfo.state, userInfo.dob, values.amount]);

  useEffect(() => {
    if (values.termLength) {
      if (availableYears.find((elem) => elem.value === values.termLength.toString() && elem.isDisabled)) {
        setValues({
          ...values,
          termLength: getClosestIntervals(
            addressInfo.state,
            getAgeFromBirthday(userInfo.dob),
            parseInt(values.amount),
            parseInt(values.termLength),
            upsellInfo,
          ).toString(),
        });
      }
    }
  }, [availableYears]);

  const updateQuote = async () => {
    setIsLoading(true);
    const payload = {
      coverageAmount: parseInt(values.amount),
      health: upsellInfo.health,
      smoker: upsellInfo.smoker,
      termLength: parseInt(values.termLength),
    };

    try {
      const res = await getLifeQuote(payload, authToken);
      const info = res?.data?.quotes[0];
      setPolicyAmounts({
        termLength: info?.termLength,
        policyAmount: info?.coverageAmount,
        monthlyCost: info?.monthlyRate,
      });

      // Handle Haven Link
      const havenLinkPayload = {
        coverageAmount: info.coverageAmount,
        health: upsellInfo.health,
        smoker: upsellInfo.smoker,
        termLength: info.termLength,
        policyPrice: info.monthlyRate,
      };
      let resp = await getHavenLink(havenLinkPayload, authToken);
      setHavenRedirectInfo(resp.data);
      setLifeProductType(info?.productIdentifier);
    } catch {
      setShowErrorBanner(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="life-recommendation-page">
      <Header headerContent={headerContent} showIcon={false} />
      <div className="life-recommentation-content">
        <PageTitle text={'Your <span>Life Insurance</span> <br> Recommendation'} />
        <LifeRecommendationCard
          recommendedValues={policyAmounts}
          showExamDisclaimer={lifeProductType === 'HavenTermSIT'}
          havenRedirectInfo={havenRedirectInfo}
        />
        <Calculator
          handleYears={handleChange}
          handleAmounts={handleChange}
          values={values}
          calculateFunction={updateQuote}
          calculatorYearOptions={availableYears}
          calculatorAmountOptions={availableAmounts}
          isLoading={isLoading}
          title="Our policies are generally 10-15% lower than all of our competitors"
        />
        {recommendedContent}
        <LifeDisclaimer />
      </div>
    </div>
  );
};

export default LifeRecommendations;
