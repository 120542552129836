import { useContext, useEffect } from 'react';
import { VoluntaryAddContext } from '../../../../context/VoluntaryContext';
import { MembersContext } from '../../../../context/MembersContext';
import Beneficiary from '../common-steps/beneficiary/Beneficiary';
import { getMonthlyPayment } from '../../../../services/helpers/VoluntaryHelpers';
import { VOL_ADD_FULL_STEPS, VOL_ADD_SHORT_STEPS } from '../../../../constants/VoluntaryConstants';

const VoluntaryBeneficiary = () => {
  const { showBeneficiaryModal, selectedBeneficiary, setSelectedBeneficiary, setShowBeneficiaryModal } =
    useContext(MembersContext);
  const {
    setProgressStep,
    coveredMembers,
    voluntaryBeneficiaries,
    setVoluntaryBeneficiaries,
    updateLastStep,
  } = useContext(VoluntaryAddContext);

  const onSpecialSubmit = (hasAdditionalBeneficiaries: boolean) => {
    updateLastStep(hasAdditionalBeneficiaries ? 'beneficiary-split' : 'beneficiaries');
  };

  return (
    <Beneficiary
      beneficiariesInfo={voluntaryBeneficiaries}
      setBeneficiariesInfo={setVoluntaryBeneficiaries}
      nextProgressStep={voluntaryBeneficiaries.length > 1 ? VOL_ADD_SHORT_STEPS : VOL_ADD_FULL_STEPS} // ? 5 (if less than 2 beneficiaries) : 6 (if 2 or more beneficiaries)
      setProgressStep={setProgressStep}
      showBeneficiaryModal={showBeneficiaryModal}
      setShowBeneficiaryModal={setShowBeneficiaryModal}
      selectedBeneficiary={selectedBeneficiary}
      setSelectedBeneficiary={setSelectedBeneficiary}
      primaryLink={'/accidental-death-voluntary/beneficiary-split'}
      secondaryLink={'/accidental-death-voluntary/payment'}
      buttonText={`Continue at ${getMonthlyPayment(coveredMembers.length)} / mo`}
      onSpecialSubmit={onSpecialSubmit}
    />
  );
};
export default VoluntaryBeneficiary;
