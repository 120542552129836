import './brand-card.scss';
type BrandCardProps = {
  brandInfo: any;
  handleHover: (e: any, payload?: any) => void;
  handleClick: (e: any, payload?: any) => void;
  isSelected: boolean;
  isDisabled: boolean;
};

const BrandCard = ({
  brandInfo,
  handleHover,
  handleClick,
  isSelected,
  isDisabled,
}: BrandCardProps) => {
  return (
    <div
      className={`brand-card ${isSelected ? 'selected' : ''} ${
        isDisabled ? 'disabled' : ''
      }`}
      onMouseOver={(e) => handleHover(e, brandInfo)}
      onClick={(e) => handleClick(e, brandInfo)}
    >
      <div
        className="brand-card-image"
        style={{ backgroundImage: `url(${brandInfo.logoUrl})` }}
      ></div>
    </div>
  );
};

export default BrandCard;
