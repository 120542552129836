import { useEffect } from 'react';
import { genderOptions } from '../../../../constants';
import MultiFieldContainer from '../../field-container/MultiFieldContainer';
import SelectField from '../../select-field/SelectField';
import DateField from '../DateField/DateField';
import InputField from '../InputField/InputField';
import { relationOptions } from '../../../../constants/VoluntaryConstants';
import { FamilyMemberInterface } from '../../../interfaces/CommonInterfaces';
import { useFormik } from 'formik';
import RemoveIcon from '../../../../assets/common/trash-can.svg';
import { isMemberValid } from '../../../../services/helpers/VoluntaryHelpers';
import './member-form.scss';
import { memberFormSchema } from '../../../schemas/commonSchemas';
import { getAgeFromBirthday } from '../../../../services/helpers/CommonHelpers';

type MemberFormType = {
  memberInfo: FamilyMemberInterface;
  removeMember: Function;
  updateMember: Function;
};

const MemberForm = ({ memberInfo, removeMember, updateMember }: MemberFormType) => {
  const formik = useFormik({
    initialValues: memberInfo,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: false,
    validationSchema: memberFormSchema,
    // fix for showing error when page loads with prefilled fields. Unable to show error message without it
    initialTouched: {
      dob: memberInfo.dob.length > 0,
      kinship: memberInfo.kinship.length > 0,
    },
    onSubmit: () => {},
  });

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setErrors,
    setFieldTouched,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (values.dob) {
      let age = getAgeFromBirthday(values.dob);
      let { isValid, errorMessage } = isMemberValid(values.kinship, age);
      if (!isValid) {
        setErrors({
          ...errors,
          ['dob']: errorMessage,
        });
      }
    }
  }, [values.kinship, values.dob, errors]);

  return (
    <div className={`member-form-container ${memberInfo.hasError ? 'show-error-border' : ''}`}>
      <div className="title-container">
        <p>Additional Family Member</p>
        <RemoveIcon onClick={removeMember} />
      </div>
      <MultiFieldContainer>
        <InputField
          name="firstName"
          type="text"
          value={values.firstName}
          placeholder="First Name"
          onChange={handleChange}
          onBlur={(e) => {
            updateMember('firstName', e.target.value);
            handleBlur(e);
          }}
          setErrors={setErrors}
          errors={touched['firstName'] ? errors : {}}
        />
        <InputField
          name="lastName"
          type="text"
          value={values.lastName}
          placeholder="Last Name"
          onChange={handleChange}
          onBlur={(e) => {
            updateMember('lastName', e.target.value);
            handleBlur(e);
          }}
          setErrors={setErrors}
          errors={touched['lastName'] ? errors : {}}
        />
      </MultiFieldContainer>
      <DateField
        value={values.dob}
        setValue={(e: any) => {
          setFieldValue('dob', e);
          updateMember('dob', values.dob);
        }}
        errors={touched['dob'] ? errors : {}}
        setErrors={setErrors}
        setFieldTouched={setFieldTouched}
        formik={true}
        placeholder="Date of Birth"
      />
      <MultiFieldContainer>
        <SelectField
          placeholder="Gender"
          value={values.gender}
          options={genderOptions}
          name="gender"
          handleChange={(e) => {
            setFieldValue('gender', e.target.value);
            updateMember('gender', e.target.value);
            setFieldTouched('gender', true);
          }}
        />
        <SelectField
          placeholder="Relation"
          value={values.kinship}
          options={relationOptions}
          name="kinship"
          handleChange={(e) => {
            setFieldValue('kinship', e.target.value);
            updateMember('kinship', e.target.value);
            setFieldTouched('kinship', true);
          }}
        />
      </MultiFieldContainer>
    </div>
  );
};

export default MemberForm;
