import { useState } from 'react';
import { stringsObject } from '../../../interfaces/InputInterfaces';
import ErrorIcon from '../../../../assets/common/error-icon.svg';
import { FormikErrors } from 'formik';

const processFunc = (value: string, isActive: boolean, showSSN: boolean) => {
  let displayValue = '';
  for (let i = 0; i < value.length; i++) {
    displayValue += `${
      (i === value.length - 1 && isActive) || showSSN ? value[i] : '*'
    }${i === 2 || i === 4 ? '-' : ''}`;
  }
  return displayValue;
};

const unProcessFunc = (value: string, initialValue: string) => {
  const tmp = value.split('-').join('');
  if (tmp.length > initialValue.length) {
    const extra = tmp.substring(
      tmp.length - (tmp.length - initialValue.length)
    );
    return `${initialValue}${extra}`;
  }
  return initialValue.slice(0, -1);
};

type InputProps = {
  value: string;
  disabled?: boolean;
  errors?: stringsObject | FormikErrors<stringsObject>;
  handleBlur: Function;
  className?: string;
  name: string;
  setFunction: (updateValue: string) => void;
};

const SSNField = ({
  value,
  disabled,
  errors,
  className,
  name,
  handleBlur,
  setFunction,
}: InputProps) => {
  const [showSSN, setShowSSN] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const disableArrowNavigation = (e: any) => {
    if (e.keyCode === 37 || e.keyCode === 39) {
      e.preventDefault();
    }
  };

  const onInput = (e: any) => {
    const reg = /^\d+$/;

    const unProcessValue = unProcessFunc(e.target.value, value);
    if (unProcessValue.length < 10) {
      // handle on numbers
      if (reg.test(unProcessValue) || unProcessValue === "") {
        setFunction(unProcessValue);
        return;
      }
      // if they try to input other characters, set to previous value
      setFunction(value);
    }
    return;
  };

  const handleFieldBlur = () => {
    handleBlur();
    setIsActive(false);
  };

  const handleFieldFocus = (e: any) => {
    e.preventDefault();
    setIsActive(true);
    if (e.currentTarget) {
      e.currentTarget.selectionStart = e.currentTarget.value.length;
      e.currentTarget.selectionEnd = e.currentTarget.value.length;
      e.currentTarget.setSelectionRange(
        e.currentTarget.value.length,
        e.currentTarget.value.length
      );
    }
  };
  return (
    <div className="input-field-container">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input
          className={`${errors?.ssn ? 'input-error' : ''} ${
            className ? className : ''
          }`}
          inputMode="numeric"
          name={name}
          type="text"
          value={processFunc(value, isActive, showSSN)}
          placeholder="SSN"
          disabled={disabled}
          onChange={onInput}
          onBlur={handleFieldBlur}
          onKeyDown={disableArrowNavigation}
          onFocus={handleFieldFocus}
          autoComplete="off"
        />
        {(errors?.ssn || className === 'input-error') && <ErrorIcon />}
      </form>
      {errors && <div className="error">{errors.ssn}</div>}
    </div>
  );
};

export default SSNField;