import { useContext, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import OnboardingProgressBar from '../../components/common/progress-bar/ProgressBar/ProgressBar';
import { AddInsuranceContext } from '../../context/AddInsuranceContext';
import { UserContext } from '../../context/UserContext';
import { UserInfoStep } from '../../components/common/steps/add-insurance/UserInfo';
import { AddUserAddress } from '../../components/common/steps/add-insurance/AddUserAddress';
import { UserDob } from '../../components/common/steps/add-insurance/UserDob';
import { UserGender } from '../../components/common/steps/add-insurance/UserGender';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import AddBeneficiary from '../../components/common/steps/add-insurance/AddBeneficiary';
import AddAmounts from '../../components/common/steps/add-insurance/AddAmounts';
import {
  beneficiaryStep,
  getProgressStep,
  questionnaireStepIds,
  upsellStepIds,
  upsellStepNames,
  getUpsellProgressStep,
} from './constants';
import TimedErrorBanner from '../../components/common/error-banner/ErrorBanner';
import { createBrowserHistory } from 'history';
import { DisclaimerTable } from '../../components/common/flow-disclaimer/DisclaimerList';
import { getSafetyNetPolicyInfo } from './constants';
import { Dependents } from '../../components/common/steps/add-insurance/upsell/Dependents';
import { Debt } from '../../components/common/steps/add-insurance/upsell/Debt';
import { Health } from '../../components/common/steps/add-insurance/upsell/Health';
import { Smoke } from '../../components/common/steps/add-insurance/upsell/Smoke';
import Header from '../../components/common/Header/Header';
import { BLOCKED_UPSELL_PARTNERS } from '../../constants/UpsellConstants';
import mixpanelWrapper from '../../utils/tools/mixpanel';

const AddQuestionPage = () => {
  const {
    totalSteps,
    totalUpsellSteps,
    progressStep,
    setProgressStep,
    upsellProgressStep,
    setUpsellProgressStep,
    lastCompletedUpsellStep,
  } = useContext(AddInsuranceContext);
  const {
    showErrorBanner,
    setShowErrorBanner,
    setStepAnimationType,
    isAmalgamated,
    headerContent,
    showDisclaimer,
    setShowDisclaimer,
    partnerName,
    isAddCompleted,
  } = useContext(UserContext);
  let { stepId } = useParams();
  const navigate = useNavigate();
  let history = createBrowserHistory({ window });
  let isAdd = questionnaireStepIds.some((id) => id === stepId);
  let isUpsell = upsellStepIds.some((id) => stepId === id);

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
  }, [stepId]);

  useEffect(() => {
    // first flow
    if (isAdd) {
      let newProgress = progressStep;
      setProgressStep(getProgressStep(stepId || ''));
      newProgress = getProgressStep(stepId || '');
      if (newProgress - 1 > progressStep) {
        navigate('/accidental-death/start');
        return;
      }
    }

    // upsell steps
    if (isUpsell) {
      setUpsellProgressStep(getUpsellProgressStep(stepId || ''));
      // block route if add not completed
      if (!isAddCompleted || BLOCKED_UPSELL_PARTNERS.indexOf(partnerName) !== -1) {
        navigate('/accidental-death/start');
      }
      // check for if user manually put in the URL. Takes them back to their last completed step
      if (lastCompletedUpsellStep) {
        // get its position in the array of names.
        let lastCompletedPosition = getUpsellProgressStep(lastCompletedUpsellStep);
        let currentStepPosition = getUpsellProgressStep(stepId || '');
        currentStepPosition - 1 > lastCompletedPosition &&
          navigate(`/accidental-death/${upsellStepNames[lastCompletedPosition]}`);
      }
    }
  }, [stepId, partnerName]);

  useEffect(() => {
    if (isAdd && progressStep === 0) {
      navigate('/accidental-death/start');
      return;
    }
  }, []);

  useEffect(() => {
    history.listen(({ action }) => {
      action === 'POP' && setStepAnimationType('slide-in_navback');
    });
  }, [history]);

  const checkStep = useMemo(() => {
    switch (stepId) {
      case 'name':
        return <UserInfoStep />;
      case 'address':
        return <AddUserAddress />;
      case 'birthday':
        return <UserDob />;
      case 'gender':
        return <UserGender />;
      case 'beneficiary':
        return <AddBeneficiary />;
      case 'amounts':
        return <AddAmounts />;
      case 'dependents':
        return <Dependents />;
      case 'debt':
        return <Debt />;
      case 'health':
        return <Health />;
      case 'smoke':
        return <Smoke />;

      default:
        break;
    }
  }, [stepId]);

  useEffect(() => {
    if (stepId && beneficiaryStep.includes(stepId)) setProgressStep(5);
  }, [stepId]);

  return (
    <>
      <div className="auto-questionnaire-page add">
        <Header headerContent={headerContent} showIcon={true} onIconClick={() => setShowDisclaimer(true)} />
        {showErrorBanner && (
          <TimedErrorBanner
            closeFunction={setShowErrorBanner}
            delay={3000}
            message="Uh oh! Something unexpected occurred. We were not able to process your request."
          />
        )}
        <OnboardingProgressBar
          currentStep={isAdd ? progressStep : upsellProgressStep}
          totalSteps={isAdd ? totalSteps : totalUpsellSteps}
          title="Step"
        />
        {checkStep}
      </div>
      {showDisclaimer && (
        <FlowDisclaimer
          className="add-table"
          headerContent={headerContent}
          content={
            <>
              <DisclaimerTable info={getSafetyNetPolicyInfo(isAmalgamated)} />
              {!isAmalgamated && (
                <>
                  <p className="disclaimer-table-text">
                    FOR NY RESIDENTS: AD&D policy provides ACCIDENT insurance only. It does NOT provide basic
                    hospital, basic medical or major medical insurance as defined by the New York State
                    Department of Financial Services.
                  </p>
                  <p className="disclaimer-table-text">
                    IMPORTANT NOTICE: THIS POLICY DOES NOT PROVIDE COVERAGE FOR SICKNESS.
                    <br /> <br />
                    Accidental Death & Dismemberment insurance coverage is issued by The Prudential Insurance
                    Company of America, Newark, NJ. The Booklet-Certificate contains all details including any
                    policy exclusions, limitations and restrictions, which may apply. CA COA #1179, NAIC
                    #68241. Contract Series: 83500.
                    <br />
                    1061616-00001-00
                  </p>
                </>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default AddQuestionPage;
