import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import VerticalPageLayout from '../../components/layouts/vertical-layout/VerticalLayout';
import {
  volIntroImage,
  VoluntaryDisclaimerContent,
  VoluntaryIntroContent,
} from '../../constants/VoluntaryConstants';
import { UserContext } from '../../context/UserContext';
import { VoluntaryAddContext } from '../../context/VoluntaryContext';
import './voluntary.scss';
import mixpanelWrapper from '../../utils/tools/mixpanel';

const VoluntaryIntro = () => {
  const { headerContent, partnerName, device, showDisclaimer, setShowDisclaimer } = useContext(UserContext);
  const { setProgressStep } = useContext(VoluntaryAddContext);
  const navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
  }, []);

  const onClick = () => {
    setProgressStep(1);
    navigate('/accidental-death-voluntary/address');
  };

  return (
    <div className="voluntary-add-intro-page">
      <VerticalPageLayout
        {...VoluntaryIntroContent}
        headerContent={headerContent}
        showInfoIcon={true}
        onIconClick={() => {
          setShowDisclaimer(true);
        }}
        img={volIntroImage(partnerName, device)}
        content={<PrimaryButton text="Enroll now" onClick={onClick} />}
      />
      {showDisclaimer && (
        <FlowDisclaimer
          headerContent={headerContent}
          subtitle="Disclaimer"
          content={<VoluntaryDisclaimerContent />}
        />
      )}
    </div>
  );
};

export default VoluntaryIntro;
