// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-report-container {
  padding: 50px;
}
.add-report-container .add-report-wrapper {
  background-color: var(--secondary-background-color);
  height: calc(100vh - 100px);
  width: calc(100vw - 100px);
  border-radius: 10px;
  padding: 10px;
}
.add-report-container .add-report-wrapper h1 {
  text-align: center;
  padding-bottom: 20px;
}
.add-report-container .add-report-wrapper input {
  margin: 10px 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/reports/add-report.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EACI,mDAAA;EACA,2BAAA;EACA,0BAAA;EACA,mBAAA;EACA,aAAA;AAER;AAAQ;EACI,kBAAA;EACA,oBAAA;AAEZ;AACQ;EACI,gBAAA;AACZ","sourcesContent":[".add-report-container {\n    padding: 50px;\n    .add-report-wrapper {\n        background-color: var(--secondary-background-color);\n        height: calc(100vh - 100px);\n        width: calc(100vw - 100px);\n        border-radius: 10px;\n        padding: 10px;\n\n        h1 {\n            text-align: center;\n            padding-bottom: 20px;\n        }\n        \n        input {\n            margin: 10px 0px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
