// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
@keyframes tooltip-reveal {
  0% {
    opacity: 0.3;
  }
}
div#popover-basic {
  background-color: var(--tooltip-bg-color);
  padding: 13px;
  border-radius: 11px;
  color: var(--primary-text-color);
  font-family: var(--primary-font);
  max-width: 200px;
  width: max-content;
  font-weight: 500;
  font-size: 12px;
  max-height: 1000px;
  opacity: 0.97;
  animation-name: tooltip-reveal;
  animation-timing-function: ease-in-out;
  animation-duration: 0.2s;
}
@media (min-width: 840px) {
  div#popover-basic {
    font-size: 14px !important;
    max-width: 311px;
  }
}

.tooltip-text {
  font-weight: 500;
  padding-bottom: 5px;
}
.tooltip-text::before {
  content: "•  ";
}

.tooltip-container {
  padding-left: 4px;
}
.tooltip-container .tooltip-button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
@media (min-width: 840px) {
  .tooltip-container {
    padding-left: 11px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/tooltip/tool-tip.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE;IACE,YAAA;EAEF;AACF;AACA;EACE,yCAAA;EACA,aAAA;EACA,mBAAA;EACA,gCAAA;EACA,gCAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,sCAAA;EACA,wBAAA;AACF;AAEE;EAjBF;IAkBI,0BAAA;IACA,gBAAA;EACF;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF;AACE;EACE,cAAA;AACJ;;AAGA;EACE,iBAAA;AAAF;AAEE;EACE,YAAA;EACA,aAAA;EACA,6BAAA;EACA,eAAA;AAAJ;AAIE;EAXF;IAYI,kBAAA;EADF;AACF","sourcesContent":["@keyframes tooltip-reveal {\n  0% {\n    opacity: 0.3;\n  }\n}\n\ndiv#popover-basic {\n  background-color: var(--tooltip-bg-color);\n  padding: 13px;\n  border-radius: 11px;\n  color: var(--primary-text-color);\n  font-family: var(--primary-font);\n  max-width: 200px;\n  width: max-content;\n  font-weight: 500;\n  font-size: 12px;\n  max-height: 1000px;\n  opacity: 0.97;\n  animation-name: tooltip-reveal;\n  animation-timing-function: ease-in-out;\n  animation-duration: 0.2s;\n\n  //desktop\n  @media (min-width: 840px) {\n    font-size: 14px !important;\n    max-width: 311px;\n  }\n}\n\n.tooltip-text {\n  font-weight: 500;\n  padding-bottom: 5px;\n\n  &::before {\n    content: '•  ';\n  }\n}\n\n.tooltip-container {\n  padding-left: 4px;\n\n  .tooltip-button {\n    border: none;\n    outline: none;\n    background-color: transparent;\n    cursor: pointer;\n  }\n\n  //desktop\n  @media (min-width: 840px) {\n    padding-left: 11px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
