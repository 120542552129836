import "./disclaimer-list.scss";
import { DisclaimerObj } from "../../interfaces/AddTypeInterfaces";
type DisclaimerListProps = {
  info: DisclaimerObj[];
};

export const DisclaimerList = ({ info }: DisclaimerListProps) => {
  return (
    <>
      {info.map((item, idx) => {
        return (
          <div key={idx} className="disclaimer-item">
            <p className="disclaimer-item-title">{item.title}</p>
            <p className="disclaimer-item-description">{item.description}</p>
          </div>
        );
      })}
    </>
  );
};

export const DisclaimerTable = ({ info }: DisclaimerListProps) => {
  return (
    <>
      {info.map((item, idx) => {
        return (
          <div className="disclaimer-table-container" key={idx}>
            <div className="disclaimer-table-title" >{item.title}</div>
            <div className="disclaimer-table-description" dangerouslySetInnerHTML={{__html: item.description}}>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default DisclaimerList;
