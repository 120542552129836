import './loading-message.scss';
const LoadingMessage = ({ text }: { text: string }) => {
  return (
    <div className="loading-screen-text">
      {text}
      <span className="first-dot">.</span>
      <span className="second-dot">.</span>
      <span className="third-dot">.</span>
    </div>
  );
};

export default LoadingMessage;
