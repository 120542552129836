import axios from 'axios';
import endpoints from './endpoints';

const getAddReports =  async (payload: any) => {
    try {
        await axios.post(endpoints.addReport, payload);
        return true;
    } catch(e) {
        return false
    }
}

export default getAddReports
