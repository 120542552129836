import React from 'react';
import InfoIcon from '../../../assets/common/info-icon.svg';
import './header.scss';

type HeaderType = {
  onIconClick?: Function;
  showIcon: boolean;
  headerContent: { logo: React.ReactNode | undefined; title: string };
};

const Header = ({ onIconClick, headerContent, showIcon }: HeaderType) => {
  const { logo, title } = headerContent;
  return (
    <div className="header-container">
      <div className="header-image">{logo}</div>
      <div className="header-content">
        <p>{title}</p>
        {showIcon && (
          <div>
            <InfoIcon onClick={onIconClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
