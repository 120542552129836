import { UpsellInfoInterface } from './components/interfaces/AddTypeInterfaces';
import { VehicleMakersInterface } from './components/interfaces/VehicleInterfaces';
export const vehicleMakers: VehicleMakersInterface = {
  Acura: [
    'RLX',
    'TLX',
    'MDX',
    'RDX',
    'MDX Sport Hybrid',
    'RLX Sport Hybrid',
    'NSX',
    'ILX',
    'TSX',
    'TL',
    'ZDX',
    'RL',
    'RSX',
    'CL',
    'Integra',
    'SLX',
    'Legend',
    'Vigor',
  ],
  'Alfa Romeo': ['4C Spider', 'Stelvio', 'Giulia', '4C', '164', 'Spider'],
  'Aston Martin': [
    'Vanquish S',
    'DB11',
    'Rapide S',
    'Vanquish',
    'Vantage',
    'DB9 GT',
    'DB9',
    'Rapide',
    'DBS',
    'Virage',
  ],
  Audi: [
    'Q3',
    'SQ5',
    'R8',
    'Q5',
    'A7',
    'A6',
    'A4',
    'Q7',
    'Q8',
    'RS 5',
    'A8',
    'A3',
    'S5',
    'A4 allroad',
    'e-tron',
    'RS 3',
    'A5',
    'S3',
    'S4',
    'TT',
    'A3 Sportback e-tron',
    'S7',
    'S6',
    'S8',
    'RS 7',
    'A5 Sport',
    'allroad',
    'RS 4',
    'A4 (2005.5)',
    'S4 (2005.5)',
    'RS 6',
    'Cabriolet',
    '90',
    'Quattro',
    '100',
    '80',
    'A6 allroad',
  ],
  BMW: [
    '3 Series',
    'X3',
    '7 Series',
    'X7',
    'X5',
    '4 Series',
    '2 Series',
    '5 Series',
    'M5',
    'i8',
    '8 Series',
    'M6',
    'i3',
    '6 Series',
    'M4',
    'X6 M',
    'X2',
    'X1',
    'X6',
    'X4',
    'M2',
    'Z4',
    'M3',
    'X5 M',
    '1 Series',
    'Z4 M',
    'Alpina B7',
    'Z8',
    'Z3',
    'M',
  ],
  Bentley: ['Bentayga', 'Flying Spur', 'Continental', 'Mulsanne', 'Azure T', 'Brooklands', 'Arnage', 'Azure'],
  Buick: [
    'Envision',
    'Encore',
    'Enclave',
    'Regal Sportback',
    'Regal TourX',
    'Cascada',
    'LaCrosse',
    'Regal',
    'Verano',
    'Lucerne',
    'Rendezvous',
    'Terraza',
    'Rainier',
    'Century',
    'Park Avenue',
    'LeSabre',
    'Riviera',
    'Skylark',
    'Roadmaster',
    'Encore GX',
  ],
  Cadillac: [
    'Escalade ESV',
    'XT6',
    'XT5',
    'XT4',
    'CT6-V',
    'Escalade',
    'CT5',
    'CTS-V',
    'CT6',
    'CTS',
    'XTS',
    'ATS-V',
    'ATS',
    'ELR',
    'SRX',
    'Escalade EXT',
    'DTS',
    'STS',
    'XLR',
    'DeVille',
    'Seville',
    'Eldorado',
    'Catera',
    'Fleetwood',
    'Allante',
    'Sixty Special',
    'Brougham',
    'CT4',
  ],
  Chevrolet: [
    'Malibu',
    'Corvette',
    'Silverado 2500 HD Crew Cab',
    'Colorado Crew Cab',
    'Silverado 3500 HD Crew Cab',
    'Traverse',
    'Camaro',
    'Equinox',
    'Blazer',
    'Suburban',
    'Spark',
    'Silverado 1500 Crew Cab',
    'Bolt EV',
    'Tahoe',
    'Impala',
    'Trax',
    'Cruze',
    'Express 2500 Cargo',
    'Colorado Extended Cab',
    'Express 2500 Passenger',
    'Express 3500 Passenger',
    'Silverado 2500 HD Double Cab',
    'Silverado 1500 Regular Cab',
    'Express 3500 Cargo',
    'Silverado 1500 LD Double Cab',
    'Silverado 2500 HD Regular Cab',
    'Silverado 1500 Double Cab',
    'Sonic',
    'Volt',
    'Suburban 3500HD',
    'City Express',
    'Silverado 3500 HD Double Cab',
    'Silverado 3500 HD Regular Cab',
    'SS',
    'Cruze Limited',
    'Impala Limited',
    'Malibu Limited',
    'Spark EV',
    'Captiva Sport',
    'Express 1500 Cargo',
    'Express 1500 Passenger',
    'Suburban 1500',
    'Avalanche',
    'Silverado 2500 HD Extended Cab',
    'Silverado 3500 HD Extended Cab',
    'Silverado 1500 Extended Cab',
    'Suburban 2500',
    'Colorado Regular Cab',
    'Aveo',
    'HHR',
    'Cobalt',
    'TrailBlazer',
    'Malibu (Classic)',
    'Uplander Cargo',
    'Uplander Passenger',
    'Silverado (Classic) 3500 Extended Cab',
    'Silverado (Classic) 2500 HD Crew Cab',
    'Silverado (Classic) 1500 Crew Cab',
    'Silverado (Classic) 1500 Regular Cab',
    'Silverado (Classic) 1500 Extended Cab',
    'Silverado (Classic) 1500 HD Crew Cab',
    'Silverado (Classic) 3500 Crew Cab',
    'Monte Carlo',
    'Silverado (Classic) 3500 Regular Cab',
    'Silverado (Classic) 2500 HD Regular Cab',
    'Silverado (Classic) 2500 HD Extended Cab',
    'Avalanche 2500',
    'Avalanche 1500',
    'SSR',
    'Silverado 3500 Crew Cab',
    'Silverado 3500 Extended Cab',
    'Silverado 3500 Regular Cab',
    'Silverado 1500 HD Crew Cab',
    'Astro Cargo',
    'Classic',
    'Astro Passenger',
    'Cavalier',
    'Venture Passenger',
    'Venture Cargo',
    'Silverado 2500 Extended Cab',
    'S10 Crew Cab',
    'Silverado 2500 Crew Cab',
    'Silverado 2500 Regular Cab',
    'Tracker',
    'S10 Extended Cab',
    'S10 Regular Cab',
    'Prizm',
    'Metro',
    'Lumina',
    '2500 HD Regular Cab',
    '2500 Crew Cab',
    '2500 HD Extended Cab',
    '3500 Crew Cab',
    '3500 Extended Cab',
    '3500 Regular Cab',
    'Tahoe (New)',
    '1500 Extended Cab',
    '2500 Regular Cab',
    'G-Series 1500',
    '1500 Regular Cab',
    '2500 Extended Cab',
    'G-Series 3500',
    'G-Series 2500',
    'Beretta',
    'G-Series G30',
    'Lumina Passenger',
    'Sportvan G30',
    'Corsica',
    'Lumina Cargo',
    'Caprice Classic',
    '3500 HD Extended Cab',
    'G-Series G20',
    'G-Series G10',
    '3500 HD Regular Cab',
    'Sportvan G20',
    'S10 Blazer',
    'Lumina APV',
    'Sportvan G10',
    'APV Cargo',
    'Caprice',
    'Trailblazer',
  ],
  Chrysler: [
    'Pacifica',
    'Voyager',
    'Pacifica Hybrid',
    '300',
    '200',
    'Town & Country',
    'PT Cruiser',
    'Sebring',
    'Aspen',
    'Crossfire',
    '300M',
    'Concorde',
    'Prowler',
    'LHS',
    'Cirrus',
    'Grand Voyager',
    'New Yorker',
    'LeBaron',
    'Fifth Ave',
    'Imperial',
  ],
  Daewoo: ['Nubira', 'Leganza', 'Lanos'],
  Daihatsu: ['Rocky', 'Charade'],
  Dodge: [
    'Durango',
    'Charger',
    'Challenger',
    'Journey',
    'Grand Caravan Passenger',
    'Viper',
    'Dart',
    'Avenger',
    'Caliber',
    'Grand Caravan Cargo',
    'Nitro',
    'Ram 2500 Regular Cab',
    'Ram 1500 Quad Cab',
    'Dakota Extended Cab',
    'Ram 1500 Crew Cab',
    'Dakota Crew Cab',
    'Ram 2500 Mega Cab',
    'Ram 1500 Regular Cab',
    'Ram 3500 Crew Cab',
    'Ram 2500 Crew Cab',
    'Ram 3500 Mega Cab',
    'Ram 3500 Regular Cab',
    'Ram 3500 Quad Cab',
    'Ram 2500 Quad Cab',
    'Sprinter 2500 Cargo',
    'Sprinter 2500 Passenger',
    'Magnum',
    'Ram 1500 Mega Cab',
    'Sprinter 3500 Cargo',
    'Caravan Passenger',
    'Caravan Cargo',
    'Dakota Quad Cab',
    'Dakota Club Cab',
    'Stratus',
    'Neon',
    'Dakota Regular Cab',
    'Intrepid',
    'Ram Van 1500',
    'Ram Van 2500',
    'Ram Van 3500',
    'Ram Wagon 2500',
    'Ram Wagon 1500',
    'Ram Wagon 3500',
    'Ram 1500 Club Cab',
    'Ram 2500 Club Cab',
    'Ram 3500 Club Cab',
    'Stealth',
    'Spirit',
    'Ram Van B250',
    'Ram Wagon B350',
    'Colt',
    'Ram Van B150',
    'Ram Van B350',
    'Ram Wagon B150',
    'Shadow',
    'Ram Wagon B250',
    'D350 Club Cab',
    'D250 Club Cab',
    'D150 Club Cab',
    'D350 Regular Cab',
    'D150 Regular Cab',
    'D250 Regular Cab',
    'Dynasty',
    'Ram 50 Regular Cab',
    'Daytona',
    'Ramcharger',
    'Monaco',
  ],
  Eagle: ['Talon', 'Vision', 'Summit', 'Premier'],
  FIAT: ['500L', '500e', '500', '500c', '500 Abarth', '124 Spider', '500X', '500c Abarth'],
  Ferrari: [
    'GTC4Lusso',
    '488 GTB',
    '812 Superfast',
    'Portofino',
    '488 Spider',
    'F12berlinetta',
    'California',
    'FF',
    '458 Italia',
    '458 Speciale',
    '458 Spider',
    '599 GTB Fiorano',
    '599 GTO',
    '612 Scaglietti',
    '430 Scuderia',
    'F430',
  ],
  Fisker: ['Karma'],
  Ford: [
    'Fusion',
    'EcoSport',
    'Escape',
    'Mustang',
    'Edge',
    'F150 SuperCrew Cab',
    'Ranger SuperCab',
    'Expedition MAX',
    'Expedition',
    'Ranger SuperCrew',
    'Explorer',
    'F150 Super Cab',
    'F250 Super Duty Regular Cab',
    'F150 Regular Cab',
    'F450 Super Duty Crew Cab',
    'F350 Super Duty Crew Cab',
    'Fiesta',
    'F250 Super Duty Crew Cab',
    'F350 Super Duty Super Cab',
    'Flex',
    'Transit 150 Van',
    'Fusion Energi',
    'Transit Connect Cargo',
    'Transit 150 Wagon',
    'Transit 350 Van',
    'F250 Super Duty Super Cab',
    'Transit 250 Van',
    'Transit 350 Wagon',
    'Transit Connect Passenger',
    'Taurus',
    'C-MAX Hybrid',
    'F350 Super Duty Regular Cab',
    'Focus',
    'Transit 350 HD Van',
    'C-MAX Energi',
    'Expedition EL',
    'Focus ST',
    'E350 Super Duty Cargo',
    'E150 Passenger',
    'E150 Cargo',
    'E250 Cargo',
    'E350 Super Duty Passenger',
    'Crown Victoria',
    'Ranger Super Cab',
    'Ranger Regular Cab',
    'E150 Super Duty Passenger',
    'Explorer Sport Trac',
    'Taurus X',
    'E250 Super Duty Cargo',
    'E150 Super Duty Cargo',
    'Freestar Cargo',
    'Five Hundred',
    'Freestar Passenger',
    'Freestyle',
    'GT',
    'Excursion',
    'Thunderbird',
    'F150 (Heritage) Super Cab',
    'F150 (Heritage) Regular Cab',
    'Explorer Sport',
    'Windstar Cargo',
    'ZX2',
    'Windstar Passenger',
    'Econoline E250 Cargo',
    'Econoline E350 Super Duty Passenger',
    'Econoline E150 Cargo',
    'Econoline E150 Passenger',
    'Econoline E350 Super Duty Cargo',
    'Escort',
    'Contour',
    'Econoline E350 Cargo',
    'F250 Regular Cab',
    'F250 Super Cab',
    'Club Wagon',
    'Aerostar Passenger',
    'Aspire',
    'Aerostar Cargo',
    'Probe',
    'F350 Crew Cab',
    'F350 Regular Cab',
    'F250 Crew Cab',
    'F350 Super Cab',
    'Bronco',
    'Tempo',
    'Festiva',
    'Bronco Sport',
    'Transit Connect Cargo Van',
  ],
  Freightliner: [
    'Sprinter 2500 Cargo',
    'Sprinter 2500 Crew',
    'Sprinter 3500 Cargo',
    'Sprinter WORKER Cargo',
    'Sprinter 3500XD Cargo',
    'Sprinter 2500 Passenger',
    'Sprinter WORKER Passenger',
  ],
  GMC: [
    'Sierra 2500 HD Crew Cab',
    'Sierra 3500 HD Crew Cab',
    'Terrain',
    'Sierra 1500 Crew Cab',
    'Yukon XL',
    'Yukon',
    'Acadia',
    'Canyon Crew Cab',
    'Sierra 1500 Double Cab',
    'Sierra 1500 Limited Double Cab',
    'Sierra 2500 HD Double Cab',
    'Sierra 2500 HD Regular Cab',
    'Canyon Extended Cab',
    'Sierra 1500 Regular Cab',
    'Savana 2500 Passenger',
    'Savana 3500 Passenger',
    'Savana 3500 Cargo',
    'Savana 2500 Cargo',
    'Sierra 3500 HD Double Cab',
    'Sierra 3500 HD Regular Cab',
    'Acadia Limited',
    'Savana 1500 Cargo',
    'Savana 1500 Passenger',
    'Yukon XL 1500',
    'Sierra 3500 HD Extended Cab',
    'Sierra 1500 Extended Cab',
    'Yukon XL 2500',
    'Sierra 2500 HD Extended Cab',
    'Canyon Regular Cab',
    'Envoy',
    'Sierra (Classic) 1500 HD Crew Cab',
    'Sierra (Classic) 3500 Crew Cab',
    'Sierra (Classic) 1500 Crew Cab',
    'Sierra (Classic) 1500 Extended Cab',
    'Sierra (Classic) 2500 HD Crew Cab',
    'Sierra (Classic) 1500 Regular Cab',
    'Sierra (Classic) 3500 Extended Cab',
    'Sierra (Classic) 3500 Regular Cab',
    'Sierra (Classic) 2500 HD Extended Cab',
    'Sierra (Classic) 2500 HD Regular Cab',
    'Envoy XL',
    'Sierra 3500 Crew Cab',
    'Sierra 1500 HD Crew Cab',
    'Sierra 3500 Extended Cab',
    'Sierra 3500 Regular Cab',
    'Envoy XUV',
    'Safari Cargo',
    'Safari Passenger',
    'Sierra 2500 Regular Cab',
    'Sonoma Crew Cab',
    'Sierra 2500 Crew Cab',
    'Sierra 2500 Extended Cab',
    'Sonoma Extended Cab',
    'Sonoma Regular Cab',
    'Jimmy',
    'Yukon Denali',
    'Sierra (Classic) 2500 Crew Cab',
    '2500 Crew Cab',
    '3500 Regular Cab',
    '1500 Club Coupe',
    '3500 Extended Cab',
    '2500 HD Regular Cab',
    '2500 HD Extended Cab',
    '3500 Crew Cab',
    'Suburban 2500',
    'Suburban 1500',
    '2500 Club Coupe',
    '2500 Regular Cab',
    '1500 Regular Cab',
    '3500 Club Coupe',
    'Sonoma Club Coupe Cab',
    '2500 HD Club Coupe',
    'Rally Wagon G3500',
    'Vandura G3500',
    'Rally Wagon G2500',
    'Vandura G2500',
    'Vandura G1500',
    'Rally Wagon 2500',
    'Rally Wagon 3500',
    'Vandura 1500',
    'Vandura 3500',
    'Vandura 2500',
    'Rally Wagon 1500',
    'Sonoma Club Cab',
    'Hummer EV',
  ],
  Genesis: ['G70', 'G80', 'G90', 'GV80'],
  Geo: ['Metro', 'Prizm', 'Tracker', 'Storm'],
  HUMMER: ['H3T', 'H3', 'H2', 'H1'],
  Honda: [
    'Insight',
    'Civic',
    'Accord Hybrid',
    'Odyssey',
    'Ridgeline',
    'Passport',
    'Accord',
    'Pilot',
    'CR-V',
    'HR-V',
    'Clarity Electric',
    'Civic Type R',
    'Clarity Fuel Cell',
    'Clarity Plug-in Hybrid',
    'Fit',
    'CR-Z',
    'Crosstour',
    'Accord Crosstour',
    'Element',
    'S2000',
    'Prelude',
    'del Sol',
    'CR-V Hybrid',
  ],
  Hyundai: [
    'Santa Fe',
    'Sonata',
    'Kona Electric',
    'NEXO',
    'Veloster',
    'Elantra GT',
    'Kona',
    'Palisade',
    'Elantra',
    'Venue',
    'Ioniq Plug-in Hybrid',
    'Tucson',
    'Accent',
    'Ioniq Electric',
    'Sonata Hybrid',
    'Ioniq Hybrid',
    'Sonata Plug-in Hybrid',
    'Santa Fe XL',
    'Santa Fe Sport',
    'Azera',
    'Tucson Fuel Cell',
    'Genesis',
    'Genesis Coupe',
    'Equus',
    'Veracruz',
    'Entourage',
    'Tiburon',
    'XG350',
    'XG300',
    'Scoupe',
    'Excel',
  ],
  INFINITI: [
    'QX80',
    'QX50',
    'QX60',
    'Q60',
    'Q50',
    'Q70',
    'QX30',
    'QX70',
    'Q40',
    'EX',
    'QX',
    'G',
    'JX',
    'FX',
    'M',
    'Q',
    'I',
    'J',
  ],
  Isuzu: [
    'i-370 Extended Cab',
    'i-290 Extended Cab',
    'Ascender',
    'i-370 Crew Cab',
    'i-350 Crew Cab',
    'i-280 Extended Cab',
    'Axiom',
    'Rodeo',
    'Rodeo Sport',
    'Trooper',
    'VehiCROSS',
    'Amigo',
    'Hombre Spacecab',
    'Hombre Regular Cab',
    'Oasis',
    'Regular Cab',
    'Spacecab',
    'Stylus',
    'Impulse',
  ],
  Jaguar: ['E-PACE', 'I-PACE', 'F-PACE', 'F-TYPE', 'XE', 'XF', 'XJ', 'XK', 'S-Type', 'X-Type'],
  Jeep: [
    'Gladiator',
    'Compass',
    'Cherokee',
    'Grand Cherokee',
    'Renegade',
    'Wrangler',
    'Wrangler Unlimited',
    'Patriot',
    'Liberty',
    'Commander',
    'Comanche Regular Cab',
    'Grand Cherokee L',
    'Grand Wagoneer',
  ],
  Kia: [
    'Sorento',
    'Rio',
    'Sedona',
    'Optima',
    'Sportage',
    'Optima Hybrid',
    'Optima Plug-in Hybrid',
    'Telluride',
    'Forte',
    'Soul',
    'Stinger',
    'Niro',
    'Niro Plug-in Hybrid',
    'Cadenza',
    'K900',
    'Niro EV',
    'Soul EV',
    'Forte5',
    'Forte Koup',
    'Rondo',
    'Amanti',
    'Borrego',
    'Spectra',
    'Optima (2006.5)',
    'Sephia',
    'K5',
    'Seltos',
  ],
  Lamborghini: ['Huracan', 'Aventador', 'Gallardo', 'Murcielago', 'Murcielago LP640'],
  'Land Rover': [
    'Discovery Sport',
    'Range Rover Evoque',
    'Range Rover Sport',
    'Range Rover',
    'Discovery',
    'Range Rover Velar',
    'LR4',
    'LR2',
    'LR3',
    'Freelander',
    'Discovery Series II',
    'Defender 90',
    'Defender 110',
  ],
  Lexus: ['ES', 'LC', 'LX', 'RC', 'GX', 'IS', 'GS', 'LS', 'UX', 'NX', 'RX', 'CT', 'HS', 'LFA', 'IS F', 'SC'],
  Lincoln: [
    'MKZ',
    'Aviator',
    'Navigator',
    'Corsair',
    'Navigator L',
    'Nautilus',
    'Continental',
    'MKT',
    'MKC',
    'MKX',
    'MKS',
    'Town Car',
    'Mark LT',
    'LS',
    'Zephyr',
    'Blackwood',
    'Mark VIII',
    'Mark VII',
  ],
  Lotus: ['Evora 400', 'Evora', 'Elise', 'Exige', 'Exige S'],
  MAZDA: [
    'CX-30',
    'MAZDA3',
    'CX-5',
    'MAZDA6',
    'CX-9',
    'MX-5 Miata RF',
    'MX-5 Miata',
    'CX-3',
    'MAZDA5',
    'MAZDA2',
    'CX-7',
    'RX-8',
    'Tribute',
    'B-Series Regular Cab',
    'B-Series Extended Cab',
    'MPV',
    'B-Series Cab Plus',
    'Protege5',
    'Protege',
    '626',
    'Millenia',
    'MX-6',
    'MX-3',
    '929',
    'RX-7',
    '323',
    'Navajo',
  ],
  MINI: [
    'Countryman',
    'Convertible',
    'Hardtop 2 Door',
    'Hardtop 4 Door',
    'Clubman',
    'Paceman',
    'Coupe',
    'Roadster',
    'Hardtop',
    'Cooper',
  ],
  Maserati: ['Levante', 'Ghibli', 'Quattroporte', 'GranTurismo', 'GranSport', 'Coupe'],
  Maybach: ['62', '57'],
  McLaren: ['570GT', '720S', '570S', '650S', '675LT', 'MP4-12C'],
  'Mercedes-Benz': [
    'GLS',
    'CLA',
    'G-Class',
    'C-Class',
    'GLA',
    'E-Class',
    'GLE',
    'GLC',
    'A-Class',
    'Mercedes-AMG C-Class',
    'CLS',
    'Mercedes-AMG CLA',
    'GLC Coupe',
    'Mercedes-AMG CLS',
    'Mercedes-AMG E-Class',
    'Mercedes-AMG GLE',
    'Mercedes-AMG G-Class',
    'Mercedes-AMG GLA',
    'Mercedes-AMG S-Class',
    'SL',
    'Mercedes-AMG GT',
    'Metris Passenger',
    'Mercedes-AMG GLE Coupe',
    'Mercedes-Maybach S-Class',
    'Mercedes-AMG GLC Coupe',
    'Sprinter 2500 Cargo',
    'S-Class',
    'Metris Cargo',
    'SLC',
    'Mercedes-AMG SLC',
    'Mercedes-AMG GLC',
    'Mercedes-AMG SL',
    'Mercedes-AMG GLS',
    'Sprinter 2500 Crew',
    'Metris WORKER Cargo',
    'Metris WORKER Passenger',
    'Sprinter 3500 XD Cargo',
    'Sprinter 2500 Passenger',
    'Sprinter WORKER Cargo',
    'Sprinter 3500 Cargo',
    'B-Class',
    'Sprinter WORKER Passenger',
    'CLS-Class',
    'GL-Class',
    'GLE Coupe',
    'Mercedes-Maybach S 600',
    'SLK',
    'Mercedes-AMG SLK',
    'M-Class',
    'CLA-Class',
    'GLK-Class',
    'GLA-Class',
    'SLS-Class',
    'SL-Class',
    'SLK-Class',
    'CL-Class',
    'R-Class',
    'CLK-Class',
    'SLR McLaren',
    '300 E',
    '190 E',
    '300 D',
    '300 SD',
    '300 CE',
    '500 SEL',
    '400 SEL',
    '500 SL',
    '500 E',
    '300 SL',
    '600 SEC',
    '300 TE',
    '400 E',
    '300 SE',
    '600 SL',
    '600 SEL',
    '500 SEC',
    '400 SE',
    'GLB',
  ],
  Mercury: [
    'Milan',
    'Mariner',
    'Grand Marquis',
    'Mountaineer',
    'Sable',
    'Montego',
    'Monterey',
    'Marauder',
    'Villager',
    'Cougar',
    'Mystique',
    'Tracer',
    'Capri',
    'Topaz',
  ],
  Mitsubishi: [
    'Outlander Sport',
    'Eclipse Cross',
    'Mirage G4',
    'Mirage',
    'Outlander',
    'Outlander PHEV',
    'Lancer',
    'i-MiEV',
    'Lancer Evolution',
    'Eclipse',
    'Galant',
    'Endeavor',
    'Raider Double Cab',
    'Raider Extended Cab',
    'Montero',
    'Diamante',
    'Montero Sport',
    '3000GT',
    'Mighty Max Regular Cab',
    'Expo',
    'Mighty Max Macro Cab',
    'Precis',
  ],
  Nissan: [
    'Armada',
    'Kicks',
    'Murano',
    'NV3500 HD Passenger',
    'NV1500 Cargo',
    'Maxima',
    '370Z',
    'Altima',
    'GT-R',
    'NV3500 HD Cargo',
    'Pathfinder',
    'Rogue',
    'NV200',
    'Rogue Sport',
    'NV2500 HD Cargo',
    'Versa',
    'TITAN XD Crew Cab',
    'Titan Crew Cab',
    'Frontier King Cab',
    'Frontier Crew Cab',
    'LEAF',
    'Sentra',
    'TITAN Single Cab',
    'NV200 Taxi',
    'Titan King Cab',
    'TITAN XD Single Cab',
    'Versa Note',
    'TITAN XD King Cab',
    'Quest',
    'JUKE',
    'Rogue Select',
    'Xterra',
    'cube',
    '350Z',
    'Pathfinder Armada',
    'Frontier Regular Cab',
    '200SX',
    '240SX',
    'Regular Cab',
    'King Cab',
    '300ZX',
    'NX',
    'Stanza',
  ],
  Oldsmobile: [
    'Bravada',
    'Alero',
    'Silhouette',
    'Aurora',
    'Intrigue',
    'Cutlass',
    'LSS',
    '88',
    'Achieva',
    'Regency',
    'Cutlass Supreme',
    'Ciera',
    '98',
    'Cutlass Ciera',
    'Cutlass Cruiser',
    'Toronado',
    'Custom Cruiser',
  ],
  Panoz: ['Esperante'],
  Plymouth: [
    'Neon',
    'Grand Voyager',
    'Breeze',
    'Voyager',
    'Prowler',
    'Acclaim',
    'Laser',
    'Colt',
    'Colt Vista',
    'Sundance',
  ],
  Polestar: ['2'],
  Pontiac: [
    'Vibe',
    'G6',
    'G3',
    'G6 (2009.5)',
    'Solstice',
    'G8',
    'Torrent',
    'G5',
    'Grand Prix',
    'GTO',
    'Montana SV6',
    'Bonneville',
    'Aztek',
    'Grand Am',
    'Montana',
    'Sunfire',
    'Firebird',
    'Trans Sport',
    'Sunbird',
    'LeMans',
  ],
  Porsche: [
    'Panamera',
    'Taycan',
    '911',
    'Macan',
    'Cayenne Coupe',
    'Cayenne',
    '718 Boxster',
    '718 Cayman',
    'Cayman',
    'Boxster',
    'Carrera GT',
    '968',
    '928',
    '718 Spyder',
  ],
  Ram: [
    '3500 Crew Cab',
    '1500 Crew Cab',
    '2500 Crew Cab',
    '2500 Mega Cab',
    '1500 Classic Quad Cab',
    '1500 Classic Crew Cab',
    '1500 Classic Regular Cab',
    '1500 Quad Cab',
    '2500 Regular Cab',
    '3500 Mega Cab',
    'ProMaster Cargo Van',
    'ProMaster City',
    'ProMaster Window Van',
    '3500 Regular Cab',
    '1500 Regular Cab',
    'C/V Tradesman',
    'ProMaster 3500 Cargo',
    'ProMaster 2500 Cargo',
    'ProMaster 1500 Cargo',
    'C/V',
    'Dakota Crew Cab',
    'Dakota Extended Cab',
  ],
  Rivian: ['R1S', 'R1T'],
  'Rolls-Royce': ['Ghost', 'Wraith', 'Dawn', 'Phantom'],
  SRT: ['Viper'],
  Saab: ['9-4X', '3-Sep', '5-Sep', '9-7X', '9-2X', '900', '9000'],
  Saturn: ['VUE', 'Outlook', 'Aura', 'SKY', 'Astra', 'Relay', 'Ion', 'L-Series', 'S-Series'],
  Scion: ['iA', 'tC', 'iM', 'FR-S', 'iQ', 'xB', 'xD', 'xA'],
  Subaru: [
    'BRZ',
    'WRX',
    'Impreza',
    'Ascent',
    'Legacy',
    'Crosstrek',
    'Forester',
    'Outback',
    'XV Crosstrek',
    'Tribeca',
    'B9 Tribeca',
    'Baja',
    'SVX',
    'Loyale',
    'Justy',
  ],
  Suzuki: [
    'SX4',
    'Kizashi',
    'Grand Vitara',
    'Equator Extended Cab',
    'Equator Crew Cab',
    'XL7',
    'Reno',
    'Forenza',
    'Aerio',
    'XL-7',
    'Verona',
    'Vitara',
    'Esteem',
    'Swift',
    'Sidekick',
    'X-90',
    'Samurai',
  ],
  Tesla: ['Model X', 'Model 3', 'Model S'],
  Toyota: [
    'Avalon',
    'Camry',
    'Corolla Hatchback',
    'Corolla',
    '86',
    'Avalon Hybrid',
    '4Runner',
    'C-HR',
    'Camry Hybrid',
    'Corolla Hybrid',
    'GR Supra',
    'Highlander',
    'Prius',
    'Prius Prime',
    'RAV4',
    'Tacoma Access Cab',
    'Sienna',
    'Land Cruiser',
    'Yaris',
    'RAV4 Hybrid',
    'Highlander Hybrid',
    'Sequoia',
    'Tundra Double Cab',
    'Tacoma Double Cab',
    'Tundra CrewMax',
    'Yaris Hatchback',
    'Mirai',
    'Prius c',
    'Corolla iM',
    'Yaris iA',
    'Prius v',
    'Tundra Regular Cab',
    'Prius Plug-in Hybrid',
    'Venza',
    'FJ Cruiser',
    'Tacoma Regular Cab',
    'Matrix',
    'Solara',
    'Tundra Access Cab',
    'Celica',
    'Echo',
    'MR2',
    'Tacoma Xtracab',
    'Supra',
    'T100 Regular Cab',
    'T100 Xtracab',
    'Tercel',
    'Paseo',
    'Previa',
    'Regular Cab',
    'Xtra Cab',
    'Cressida',
    'RAV4 Prime',
  ],
  Volkswagen: [
    'Passat',
    'Tiguan',
    'Jetta',
    'Atlas',
    'Golf SportWagen',
    'Arteon',
    'e-Golf',
    'Golf',
    'Golf GTI',
    'Jetta GLI',
    'Beetle',
    'Golf Alltrack',
    'Golf R',
    'Tiguan Limited',
    'CC',
    'Touareg',
    'Eos',
    'GTI',
    'Routan',
    'Jetta SportWagen',
    'New Beetle',
    'Rabbit',
    'Touareg 2',
    'GLI',
    'R32',
    'Phaeton',
    'Jetta (New)',
    'Eurovan',
    'Cabrio',
    'Passat (New)',
    'Cabrio (New)',
    'Golf (New)',
    'GTI (New)',
    'Golf III',
    'Jetta III',
    'Corrado',
    'Cabriolet',
    'Fox',
    'Atlas Cross Sport',
  ],
  Volvo: [
    'XC40',
    'XC60',
    'XC90',
    'S60',
    'V60',
    'V90',
    'S90',
    'S80',
    'XC70',
    'C30',
    'C70',
    'V50',
    'S40',
    'V70',
    'S40 (New)',
    'V40',
    'S70',
    '850',
    '960',
    '940',
    '240',
    '740',
  ],
  smart: ['fortwo electric drive', 'fortwo electric drive cabrio', 'fortwo cabrio', 'fortwo'],
};

export const insuranceOptions = [
  { id: '1', value: 'Progressive' },
  { id: '2', value: 'Geico' },
  { id: '3', value: 'State Farm' },
  { id: '4', value: 'Allstate' },
  { id: '5', value: 'Nationwide' },
  { id: '6', value: 'Farmers Insurance' },
  { id: '7', value: 'Liberty Mutual' },
  { id: '17', value: 'American Family' },
  { id: '8', value: 'Elephant Insurance' },
  { id: '9', value: 'Mercury Insurance' },
  { id: '10', value: 'Safe Auto' },
  { id: '11', value: 'Metlife' },
  { id: '12', value: 'Infinity Insurance' },
  { id: '13', value: 'Dairyland Insurance' },
  { id: '14', value: 'The General' },
  { id: '15', value: '21st Century' },
  { id: '16', value: 'Direct General' },
  { id: '17', value: 'Other' },
  { id: '18', value: 'Ensurance' },
  { id: '19', value: 'Travelers' },
  { id: '20', value: 'USAA' },
];

export const states = [
  {
    id: 'Alabama',
    value: 'AL',
  },
  {
    id: 'Alaska',
    value: 'AK',
  },
  {
    id: 'Arizona',
    value: 'AZ',
  },
  {
    id: 'Arkansas',
    value: 'AR',
  },
  {
    id: 'California',
    value: 'CA',
  },
  {
    id: 'Colorado',
    value: 'CO',
  },
  {
    id: 'Connecticut',
    value: 'CT',
  },
  {
    id: 'Delaware',
    value: 'DE',
  },
  {
    id: 'Florida',
    value: 'FL',
  },
  {
    id: 'Georgia',
    value: 'GA',
  },
  {
    id: 'Hawaii',
    value: 'HI',
  },
  {
    id: 'Idaho',
    value: 'ID',
  },
  {
    id: 'Illinois',
    value: 'IL',
  },
  {
    id: 'Indiana',
    value: 'IN',
  },
  {
    id: 'Iowa',
    value: 'IA',
  },
  {
    id: 'Kansas',
    value: 'KS',
  },
  {
    id: 'Kentucky',
    value: 'KY',
  },
  {
    id: 'Louisiana',
    value: 'LA',
  },
  {
    id: 'Maine',
    value: 'ME',
  },
  {
    id: 'Maryland',
    value: 'MD',
  },
  {
    id: 'Massachusetts',
    value: 'MA',
  },
  {
    id: 'Michigan',
    value: 'MI',
  },
  {
    id: 'Minnesota',
    value: 'MN',
  },
  {
    id: 'Mississippi',
    value: 'MS',
  },
  {
    id: 'Missouri',
    value: 'MO',
  },
  {
    id: 'Montana',
    value: 'MT',
  },
  {
    id: 'Nebraska',
    value: 'NE',
  },
  {
    id: 'Nevada',
    value: 'NV',
  },
  {
    id: 'New Hampshire',
    value: 'NH',
  },
  {
    id: 'New Jersey',
    value: 'NJ',
  },
  {
    id: 'New Mexico',
    value: 'NM',
  },
  {
    id: 'New York',
    value: 'NY',
  },
  {
    id: 'North Carolina',
    value: 'NC',
  },
  {
    id: 'North Dakota',
    value: 'ND',
  },
  {
    id: 'Ohio',
    value: 'OH',
  },
  {
    id: 'Oklahoma',
    value: 'OK',
  },
  {
    id: 'Oregon',
    value: 'OR',
  },
  {
    id: 'Pennsylvania',
    value: 'PA',
  },
  {
    id: 'Rhode Island',
    value: 'RI',
  },
  {
    id: 'South Carolina',
    value: 'SC',
  },
  {
    id: 'South Dakota',
    value: 'SD',
  },
  {
    id: 'Tennessee',
    value: 'TN',
  },
  {
    id: 'Texas',
    value: 'TX',
  },
  {
    id: 'Utah',
    value: 'UT',
  },
  {
    id: 'Vermont',
    value: 'VT',
  },
  {
    id: 'Virginia',
    value: 'VA',
  },
  {
    id: 'Washington',
    value: 'WA',
  },
  {
    id: 'West Virginia',
    value: 'WV',
  },
  {
    id: 'Wisconsin',
    value: 'WI',
  },
  {
    id: 'Wyoming',
    value: 'WY',
  },
];

export const amalgamatedStates = [
  'AK',
  'GA',
  'KS',
  'LA',
  'ME',
  'MN',
  'MS',
  'MT',
  'NM',
  'OR',
  'UT',
  'WA',
  'WV',
];

export const defaultUserInfo = {
  id: '',
  firstName: '',
  lastName: '',
  dob: '',
  gender: '',
  email: '',
};

export const defaultAddressInfo = {
  address: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
};

export const harmonicPartners: { [name: string]: string } = {
  blue_acorn: 'Blue Acorn',
  blueacorn: 'Blue Acorn',
};

export const defaultUpsellInfo: UpsellInfoInterface = {
  hasDependents: true,
  debt: true,
  health: 'Excellent',
  smoker: false,
};

export const genderOptions = [
  { id: '0', value: 'male', displayName: 'Male' },
  { id: '1', value: 'female', displayName: 'Female' },
];
