// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-list-wrapper {
  width: 100%;
  padding: 16px 0px;
  border-bottom: 1px solid var(--stroke-color);
  border-top: 1px solid var(--stroke-color);
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/forms/MemberForm/member-form-container.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,4CAAA;EACA,yCAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;AACF","sourcesContent":[".form-list-wrapper {\n  width: 100%;\n  padding: 16px 0px;\n  border-bottom: 1px solid var(--stroke-color);\n  border-top: 1px solid var(--stroke-color);\n  display: flex;\n  flex-direction: column;\n  grid-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
