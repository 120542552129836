import { useContext, useState, useMemo, useRef, useEffect } from 'react';
import QuoteCard from '../../components/common/afi/QuoteCard/QuoteCard';
import Header from '../../components/common/Header/Header';
import { UserContext } from '../../context/UserContext';
import './afi.scss';
import ChevronLeft from '../../assets/common/chevron-left.svg';
import ChevronRight from '../../assets/common/chevron-right.svg';
import { formatAFIPriceString } from '../../services/helpers/AFIHelpers';
import { AnswerFinancialContext } from '../../context/AnswerFinancialContext';
import SideProgressBar from '../../components/common/progress-bar/SideProgressBar/SideProgressBar';
import { AFI_DEFAULT_IMAGE } from '../../constants/AFIConstants';
import CanopyCoverages from './canopy-connect/CanopyCoverages/CanopyCoverages';
import AfiQuotesError from '../../components/common/afi/AfiQuotesError/AfiQuotesError';
import mixpanelWrapper from '../../utils/tools/mixpanel';
import BackButton from '../../components/common/buttons/BackButton';

const generateLogoUrl = (code: string) => {
  if (code) {
    return `${AFI_DEFAULT_IMAGE}_${code}.png`;
  }
  return '';
};

const AfiQuotes = () => {
  const { headerContent, device } = useContext(UserContext);

  const { quotes, AfiNavigation, carriersError, isCanopy } = useContext(AnswerFinancialContext);

  // Ref for the cards container
  const cardsRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [shouldScroll, setShouldScroll] = useState({
    right: cardsRef?.current?.scrollWidth - cardsRef?.current?.clientWidth > cardsRef?.current?.scrollLeft,
    left: !!cardsRef?.current?.scrollLeft,
  });

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
  }, []);

  const handleScroll = (direction: 'left' | 'right') => {
    // This will scroll by 100 pixels in the chosen direction
    cardsRef?.current?.scrollBy({ left: 100 * (direction === 'right' ? 1 : -1), behavior: 'smooth' });
  };

  // Whenever the width of the cards container changes, we must update the shouldScroll variable
  useEffect(() => {
    setShouldScroll({
      right: cardsRef?.current?.scrollWidth - cardsRef?.current?.clientWidth > cardsRef?.current?.scrollLeft,
      left: !!cardsRef?.current?.scrollLeft,
    });
  }, [
    cardsRef,
    cardsRef?.current?.scrollWidth,
    cardsRef?.current?.clientWidth,
    cardsRef?.current?.scrollLeft,
  ]);

  const quotesContent = useMemo(
    () =>
      quotes.map((item, index) => (
        <QuoteCard
          key={index}
          logo={generateLogoUrl(item?.policyInfo?.naicCode || '')}
          price={formatAFIPriceString(item?.policyInfo?.fullAmount, item?.policyInfo?.duration)}
          product={item}
        />
      )),
    [quotes],
  );

  const displayCanopyContent = useMemo(() => {
    if (isCanopy) {
      if (quotes.length > 0 && !carriersError) {
        return <CanopyCoverages />;
      }
      return <AfiQuotesError />;
    }
  }, [quotes, carriersError, isCanopy]);

  const displayAfiPageTitle = useMemo(() => {
    return (
      !isCanopy &&
      !carriersError && (
        <div className="afi-quotes-heading">
          <h1 className="afi-quotes-title">Our top quotes</h1>

          <p className="afi-quotes-description">
            Check out these quotes and coverage levels to see which is right for you.{' '}
          </p>
        </div>
      )
    );
  }, [isCanopy, carriersError]);

  return (
    <div className="afi-quotes-page-container">
      <Header headerContent={headerContent} showIcon={false} onIconClick={() => {}} />
      <div className="afi-quotes-content">
        {device === 'desktop' && (
          <SideProgressBar currentSection="quotes" currentStep="quotes" navigationData={AfiNavigation} />
        )}
        <section className={`afi-quotes-content-wrapper ${isCanopy ? 'canopy' : ''}`}>
          {displayCanopyContent}
          {displayAfiPageTitle}
          {!carriersError
            ? !isCanopy &&
              (device === 'desktop' ? (
                <div className="afi-quotes-container">
                  {/* If shouldScroll is false for both sides, it means there's no need to scroll, thus, we can hide the chevrons */}
                  {(shouldScroll.left || shouldScroll.right) && (
                    // If shouldScroll left is false it means we can disable the left chevron
                    <ChevronLeft
                      className={`scroll ${!shouldScroll.left ? 'disabled' : ' '}`}
                      onClick={() => handleScroll('left')}
                    />
                  )}
                  <div className="afi-scrollable-quotes-container">
                    <div
                      className="afi-quotes"
                      ref={cardsRef}
                      onScroll={() =>
                        setShouldScroll({
                          right:
                            cardsRef?.current?.scrollWidth - cardsRef?.current?.clientWidth >
                            cardsRef?.current?.scrollLeft,
                          left: !!cardsRef?.current?.scrollLeft,
                        })
                      }
                    >
                      {quotesContent}
                    </div>
                  </div>
                  {(shouldScroll.left || shouldScroll.right) && (
                    <ChevronRight
                      className={`scroll ${!shouldScroll.right ? 'disabled' : ' '}`}
                      onClick={() => handleScroll('right')}
                    />
                  )}
                </div>
              ) : (
                <div className="afi-quotes-container">{quotesContent}</div>
              ))
            : !isCanopy && <AfiQuotesError />}
          <div className={`${isCanopy ? 'canopy' : ''} buttons-container`}>
            <BackButton />
          </div>
        </section>
      </div>
    </div>
  );
};

export default AfiQuotes;
