import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import "./address-field.scss";

type AddressFieldProps = {
  address: string;
  setAddress: Function;
  setCity?: Function;
  setLongState?: Function;
  setShortState?: Function;
  setZip?: Function;
  setFullCity?: Function;
  name?: string;
};

type AddressType = {
  long_name: string;
  short_name: string;
  types: string[];
};

type AddressSectionObject = {
  [key: string]: AddressType;
};

const AddressField: React.FC<AddressFieldProps> = ({
  address,
  setAddress,
  setCity,
  setLongState,
  setShortState,
  setZip,
  setFullCity,
}: AddressFieldProps) => {
  const handleChange = (newAddress: string) => {
    setAddress(newAddress);
  };

  const handleSelect = async (address: string) => {
    let addressResp = await geocodeByAddress(address);

    if (addressResp) {
      let addressItems = addressResp[0].address_components;

      const componentsByType = {} as AddressSectionObject;
      for (let i = 0; i < addressItems.length; i++) {
        var component = addressItems[i];
        componentsByType[component.types[0]] = component;
      }
      const getAddressValue = (type: string, length?: string) => {
        if (componentsByType[type]) {
          if (length === "short_name") {
            return (
              componentsByType[type].short_name ||
              componentsByType[type].long_name
            );
          }

          if (length === "long_name") {
            return (
              componentsByType[type].long_name ||
              componentsByType[type].short_name
            );
          }

          return (
            componentsByType[type].long_name ||
            componentsByType[type].short_name
          );
        }
        return "";
      };
      const street = `${getAddressValue("street_number") ? getAddressValue("street_number") : getAddressValue("premise")} ${getAddressValue(
        "route"
      )}`;
     
      //   checking both for certain new york areas
      const city = getAddressValue("locality") || getAddressValue("political");
      const fullCity = `${city}, ${getAddressValue(
        "administrative_area_level_1",
        "short_name"
      )}`;

      const shortStateName = getAddressValue(
        "administrative_area_level_1",
        "short_name"
      );
      const longStateName = getAddressValue("administrative_area_level_1");

      const zipCode = `${getAddressValue("postal_code")}`;
     
      setAddress(street);
      if (setCity) {
        setCity(city);
      }


      if (setLongState) {
        setLongState(longStateName)
      }
      if (setShortState) {
        setShortState(shortStateName)
      }

      if (setZip) {
        setZip(zipCode);
      }
    }
  };

  const searchOptions = {
    componentRestrictions: { country: ["us"] },
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="location-search-container">
          <input
            {...getInputProps({
              placeholder: "Address",
              className: "location-search-input",
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => (
              <div
                {...getSuggestionItemProps(suggestion, {
                  className: suggestion.active
                    ? "suggesstion-item-active"
                    : "suggesstion-item",
                })}
                key={index}
              >
                <span>{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AddressField;
