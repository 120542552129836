import React from 'react';
import PageTitle from '../page-title/PageTitle';
import './hero-card.scss';

type HeroCardProps = {
  title: string;
  cardImg: React.ReactNode;
};

const HeroCard = ({ title, cardImg }: HeroCardProps) => {
  return (
    <div className="hero-card-container">
      <div className="hero-card-content-container">
        <div className="hero-card-title">
          <PageTitle text={title} />
        </div>
        <div className="hero-card-image">{cardImg}</div>
      </div>
    </div>
  );
};

export default HeroCard;
