import React from 'react';
import "./summary-content-container.scss"

const SummaryContentContainer = (props: {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}) => {
  return (
    <div className="summary-content-container">
        <div className="left-content">{props.leftContent}</div>
        <div className="right-content">{props.rightContent}</div>
    </div>
  );
};

export default SummaryContentContainer;
