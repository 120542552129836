import {
  AfiNavigation,
  basicsTemplate,
  carTemplate,
  coverageTemplate,
  driverTemplate,
  incidentTypes,
  progressBarBreakpoints,
  stepsFlow,
} from '../../constants/AFIConstants';
import {
  AfiNavigationType,
  AfiStepFieldType,
  AfiStepType,
  PriorPolicyCoverageType,
  OptionType,
  SectionNameType,
} from '../../components/interfaces/AFIInterfaces';
import _ from 'lodash';
import { allFieldsValid } from '../validation';
import { formatDate } from './inputFieldHelpers';
import { customStateOptions } from '../../constants/AfiStateQuestions';
import { getAgeFromBirthday } from './CommonHelpers';

/**
 * Function that calculates the progress displayed on a progress bar (in %).
 * The progress bar can cover a number of sections.
 * Each section can have multiple subsections, the number of sections does not have to be consistent throughout all sections.
 * Certain sections can be progressed multiple times.
 * @param currentSection Current Section.
 * @param currentStepName Current Step (part of a section).
 * @param specialCaseIndex Certain sections can be progressed multiple times, this index shows how many times it's been progressed.
 * @example Static example: calculateProgress("cars", "user-info", 1)
 * @example Dynamic example: calculateProgress(currentSection, currentStepName, getIndexBySection())
 */
export const calculateProgress = (
  currentSection: SectionNameType,
  currentStepName: string,
  specialCaseIndex?: number | null,
  isCanopy: boolean = false,
) => {
  let steps = Object.keys(AfiNavigation);
  isCanopy && (steps = steps.filter((elem) => elem !== 'coverage'));

  // Calculate base progress for each section
  const sectionLength = 100 / steps.length;

  // Calculate progress until current section
  const currentProgress = steps.indexOf(currentSection) * sectionLength;

  // Calculate the ammount of substeps for the current section
  const substepAmmount = Object.keys(AfiNavigation[currentSection].steps).length;

  // Cover special cases (sections that can be progressed multiple times)
  const specialCaseMultiplier = (index?: number) => {
    if (index !== undefined) {
      return progressBarBreakpoints[index] + 0;
    } else {
      return 1;
    }
  };

  // Calculate the % value of each substep from the total
  const substepLength = () => {
    if (specialCaseIndex) {
      return (
        ((specialCaseMultiplier(specialCaseIndex as number) - specialCaseMultiplier(specialCaseIndex - 1)) *
          sectionLength) /
        substepAmmount
      );
    } else {
      return sectionLength / substepAmmount;
    }
  };

  // Calculate progress until current step
  const calculateCurrentProgress = () => {
    if (specialCaseIndex) {
      return currentProgress + progressBarBreakpoints[specialCaseIndex - 1] * sectionLength;
    } else {
      return currentProgress;
    }
  };

  // Calculate total progress
  const totalProgress =
    calculateCurrentProgress() +
    substepLength() * (Object.keys(AfiNavigation[currentSection].steps).indexOf(currentStepName) + 1);

  return totalProgress;
};

export const generateSelectOptionsArray = (obj: { [key: string]: string }) => {
  return Object.keys(obj).map((item: string) => {
    return { displayName: obj[item], value: item };
  });
};

export const getCarrierByDisplayName = (displayName: string, state: string) => {
  const newState = state in customStateOptions ? state : 'default';
  return Object.keys(customStateOptions[newState].insuranceCarrier).find(
    (key) => customStateOptions[newState].insuranceCarrier[key] === displayName,
  );
};

export const findCurrentStep = (stepId: string, section: any) => {
  if (!section) return undefined;
  if (stepId in section.steps) {
    return section.steps[stepId];
  }
  // If stepId is the section summary, set the current step to the last one in the section
  if (stepId === section.sectionSummary) {
    return section.steps[Object.keys(section.steps).pop() as string];
  }
  // If stepId is a step summary, set the current step to the step it belongs to
  const stepSummary = _.find(section.steps, (item) => item.stepSummary === stepId);
  if (stepSummary) {
    return stepSummary;
  }
};

// Will return the next step in line for navigation
// All conditions are ordered by importance
export const findNextStep = (
  stepId: string,
  sectionId: string,
  currentStep: AfiStepType[keyof AfiStepType],
  currentSection: AfiNavigationType[keyof AfiNavigationType],
  navigationObject: AfiNavigationType,
) => {
  const sections = Object.keys(navigationObject);
  const stepSummary = currentStep?.stepSummary;
  const sectionSummary = currentSection?.sectionSummary;
  const steps = Object.keys(currentSection.steps);
  const currentStepIndex = steps.indexOf(currentStep.name as string);
  const currentSectionIndex = sections.indexOf(currentSection.name as string);
  if (stepId === sectionSummary) {
    return {
      section: sections[currentSectionIndex + 1],
      step: Object.keys(navigationObject[sections[currentSectionIndex + 1]].steps)[0],
    };
  }

  if (stepSummary && stepSummary !== stepId) {
    return { section: sectionId, step: stepSummary };
  }

  if (currentStepIndex !== steps.length - 1) {
    return {
      section: sectionId,
      step: steps[currentStepIndex + 1],
    };
  } else {
    if (sectionSummary) {
      return {
        section: sectionId,
        step: sectionSummary,
      };
    } else {
      return {
        section: sections[currentSectionIndex + 1],
        step: Object.keys(navigationObject[sections[currentSectionIndex + 1]].steps)[0],
      };
    }
  }
};

export const getAfiDriverNames = (
  driverList: {
    [key: string]: { driverFirstName: string; driverLastName: string };
  }[],
) => {
  const newObj = {} as { [key: string]: string };
  driverList.map(
    (item, index) =>
      (newObj[`D${index + 1}`] = `${item.details.driverFirstName} ${item.details.driverLastName}`),
  );
  return newObj;
};

export const getDynamicFields = (formikConfig: any, stepId: string, section: { [key: string]: string }) => {
  const hideField = (
    step: string,
    dependencyKey: string,
    dependencyValue: string,
    sectionTitleToHide: string,
  ) => {
    return stepId === step &&
      dependencyKey in formikConfig.values &&
      formikConfig.values[dependencyKey] === dependencyValue &&
      section.title === sectionTitleToHide
      ? true
      : false;
  };
  if (
    hideField('address', 'sameParkingAddress', 'Yes', 'Parking address') ||
    hideField('history', 'driverSr22', 'N', 'Filing state') ||
    hideField('discounts', 'driverDefensiveDiscount', 'N', 'Defensive driver course date')
  ) {
    return true;
  }

  return false;
};

export const dynamicFieldsValidation = (formikConfig: any, stepId: string) => {
  if (!formikConfig.values) return true;

  if (stepId === 'new-coverage') {
    const vehicles = Object.keys(formikConfig.values) // Multiple vehicle options.. V1, V2
      .filter((item) => item.includes('comprehensiveDeductible'))
      .map((field) => field.split('_')[0] + '_');

    // E.g.: collisionDeductible (valueKey) is dependant on comprehensiveDeductible(item inside dependencies array) to not be equal to "None"
    const isValid = (valueKey: string, dependencies: string[], errorMessage: string, vehicle: string) => {
      let isValid = true;
      const value = formikConfig.values[`${vehicle}${valueKey}`];
      // If there is an error on the field already, we skip next checks
      if (formikConfig.errors[`${vehicle}${valueKey}`]) return;
      dependencies.forEach((dependency) => {
        const dependencyValue = formikConfig.values[`${vehicle}${dependency}`];
        if ((!dependencyValue && value !== 'None') || (value !== 'None' && dependencyValue === 'None')) {
          isValid = false;
          return;
        }
      });
      if (!isValid) {
        formikConfig.setFieldError(`${vehicle}${valueKey}`, errorMessage);
        formikConfig.setFieldTouched(`${vehicle}${valueKey}`);
      }
    };

    vehicles.map((vehicle) => {
      // If Collision Deductible is not "None", Comprehensive must be set and not "None"
      isValid(
        'collisionDeductible',
        ['comprehensiveDeductible'],
        'Collision coverage cannot be set without setting the Comprehensive coverage first',
        vehicle,
      );
      // If Towing is not "None", Comprehensive and Collision must both be set and not "None"
      isValid(
        'coveragesTowing',
        ['comprehensiveDeductible', 'collisionDeductible'],
        'Value cannot be set without setting the Comprehensive and Collision coverage first',
        vehicle,
      );
      // If Rental is not "None", Comprehensive and Collision must both be set and not "None"
      isValid(
        'coveragesRental',
        ['comprehensiveDeductible', 'collisionDeductible'],
        'Value cannot be set without setting the Comprehensive and Collision coverage first',
        vehicle,
      );
    });

    const validateOrder = (fields: { key: string; fieldName: string }[]) => {
      fields.map((field, index) => {
        if (index === 0 || !formikConfig.values[field.key]) {
          return;
        } else {
          const currentValue = formikConfig.values[field.key].replaceAll(',', '');
          const lastValue = formikConfig.values[fields[index - 1].key].replaceAll(',', '');
          if (
            index - 1 >= 0 &&
            currentValue &&
            lastValue &&
            !formikConfig.errors[field.key] &&
            (parseInt(currentValue.split('/')[0]) > parseInt(lastValue.replaceAll(',', '').split('/')[0]) ||
              (isNaN(parseInt(lastValue.split('/')[0])) && parseInt(currentValue.split('/')[0])))
          ) {
            formikConfig.setFieldError(
              field.key,
              'Value must be lower or equal to ' + fields[index - 1].fieldName,
            );
            formikConfig.setFieldTouched(field.key, true);
          }
        }
      });
    };
    if ('primaryBodilyInjuryLimit' in formikConfig.values)
      validateOrder([
        { key: 'primaryBodilyInjuryLimit', fieldName: 'Primary Bodily Injury' },
        { key: 'uninsuredBodilyInjuryLimit', fieldName: 'Uninsured Bodily Injury' },
        { key: 'underinsuredMotoristsBodilyInjury', fieldName: 'Underinsured Bodily Injury' },
      ]);

    if ('primaryPropertyDamage' in formikConfig.values)
      validateOrder([
        { key: 'primaryPropertyDamage', fieldName: 'Primary Property Damage' },
        { key: 'uninsuredPropertyDamage', fieldName: 'Uninsured Property Damage' },
      ]);
  }
  if (stepId === 'existing' && formikConfig?.values?.insuranceCarrier === 'None') {
    return allFieldsValid(
      _.omit(formikConfig.values, [
        'priorPolicyExpDate',
        'priorInsuranceBodilyInjuryLimits',
        'canceledBefore',
        'insuranceDuration',
        'paymentFrequency',
        'premium',
        'insuranceCarrierDisplayName',
      ]),
      _.omit(formikConfig.errors, [
        'priorPolicyExpDate',
        'priorInsuranceBodilyInjuryLimits',
        'canceledBefore',
        'insuranceDuration',
        'paymentFrequency',
        'premium',
        'insuranceCarrierDisplayName',
      ]),
    );
  }
  if (stepId === 'existing' && 'insuranceCarrierDisplayName' in formikConfig.values) {
    return allFieldsValid(formikConfig.values, formikConfig.errors, 'insuranceCarrierDisplayName');
  }
  if (stepId === 'address') {
    if ('sameParkingAddress' in formikConfig.values && formikConfig.values.sameParkingAddress === 'Yes') {
      return allFieldsValid(
        _.omit(formikConfig.values, [
          'parkingAddress',
          'parkingAddress2',
          'parkingCity',
          'parkingState',
          'parkingZip',
          'address2',
        ]),
        _.omit(formikConfig.errors, [
          'parkingAddress',
          'parkingAddress2',
          'parkingCity',
          'parkingState',
          'parkingZip',
          'address2',
        ]),
      );
    } else {
      return allFieldsValid(
        _.omit(formikConfig.values, ['parkingAddress2', 'address2']),
        _.omit(formikConfig.errors, ['parkingAddress2', 'address2']),
      );
    }
  }
  if (stepId === 'history' && 'driverSr22' in formikConfig.values && formikConfig.values.driverSr22 === 'N') {
    return allFieldsValid(
      _.omit(formikConfig.values, ['driverSr22FilingState']),
      _.omit(formikConfig.errors, ['driverSr22FilingState']),
    );
  }
  if (
    stepId === 'discounts' &&
    'driverDefensiveDiscount' in formikConfig.values &&
    formikConfig.values.driverDefensiveDiscount === 'N'
  ) {
    return allFieldsValid(
      _.omit(formikConfig.values, ['defensiveDriverCourseDate']),
      _.omit(formikConfig.errors, ['defensiveDriverCourseDate']),
    );
  }

  //Age licensed and DriverDob error handling
  if (
    stepId === 'details' &&
    'driverAgeLicensed' in formikConfig.values &&
    'driverDob' in formikConfig.values &&
    !formikConfig.errors.driverAgeLicensed
  ) {
    const age = getAgeFromBirthday(String(formikConfig?.values?.driverDob));
    if (parseInt(String(formikConfig.values.driverAgeLicensed)) > age) {
      formikConfig.setFieldTouched('driverAgeLicensed', true);
      formikConfig.setFieldError(
        'driverAgeLicensed',
        'Your age when licensed conflicts with your entered birth date',
      );
      return;
    }
  }

  //Vehicle Purchase Date error handling
  if (
    stepId === 'details' &&
    'vehicleYear' in formikConfig.values &&
    ('purchaseDate' as string) in formikConfig.values &&
    !formikConfig.errors.purchaseDate
  ) {
    const purchaseYear = parseInt(formikConfig.values.purchaseDate.slice(3));
    const carYear = parseInt(String(formikConfig.values.vehicleYear));
    if (carYear - 1 > purchaseYear) {
      formikConfig.setFieldTouched('purchaseDate', true);
      formikConfig.setFieldError('purchaseDate', 'Your purchase year cannot be before the car was made');
      return;
    }
  }

  return allFieldsValid(formikConfig.values, formikConfig.errors);
};
export const formatAFIPriceString = (str: string, type: string) => {
  const currencyOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  } as Intl.NumberFormatOptions;

  // Title Example: 3087.00 6 Months -> $514.5
  if (type.toLocaleLowerCase().includes('months')) {
    return `$${(+str / (parseInt(type.split(' ')[0]) || 6)).toFixed()}`;
  }

  if (!isNaN(+str)) {
    // Example: 5000 -> $5,000
    return Intl.NumberFormat('en-us', currencyOptions).format(+str);
  } else {
    // Example: $5000 -> $5,000
    str = str?.replaceAll('$', '');
    if (!isNaN(+str)) {
      return Intl.NumberFormat('en-us', currencyOptions).format(+str);
    }
    // Example: covered -> Covered
    if (str && str.toLocaleLowerCase() === 'covered') {
      return 'Covered';
    }
    // For multiple prices spread by a "/" we can use the short formatting, displaying a K instead of all digits of numbers over 1000
    // Example: 50,000/100,000 -> $50k/$100k
    if (str?.includes('/')) {
      const tmp = str.split('/');
      return `${Intl.NumberFormat('en-us', {
        ...currencyOptions,
        notation: 'compact',
        compactDisplay: 'short',
      }).format(+tmp[0].replace(/\,/g, ''))}/${Intl.NumberFormat('en-us', {
        ...currencyOptions,
        notation: 'compact',
        compactDisplay: 'short',
      }).format(+tmp[1].replace(/\,/g, ''))}`;
    }
    // Example: "50,000 with a 250 deductible" -> $50,000
    if (parseInt(str?.split(' ')[0])) {
      return Intl.NumberFormat('en-us', currencyOptions).format(+str?.replace(/\,/g, '').split(' ')[0]);
    } else {
      // Example: NaN -> Not covered
      return 'Not covered';
    }
  }
};

export const toggleDisabledDriverFields = (formSections: AfiStepFieldType[], index: number) => {
  _.assign(formSections[0].fields[0], { disabled: index ? false : true });
  _.assign(formSections[0].fields[1], { disabled: index ? false : true });
  _.assign(formSections[4].fields[0], { disabled: index ? false : true });
  if (formSections[4].fields[0].options) {
    if (index) {
      _.assign(formSections[4].fields[0], {
        options: formSections[4].fields[0].options.filter((item) => item.value !== 'IN'),
      });
    } else {
      if (!formSections[4].fields[0].options.find((item) => item.value === 'IN')) {
        formSections[4].fields[0].options.splice(0, 0, { displayName: 'Self', value: 'IN' });
      }
    }
  }
};
export const toggleDisabledEmailField = (formSections: AfiStepFieldType[]) => {
  _.assign(formSections[0].fields[2], { disabled: true });
};

export const getDriverFullName = (dataObject: any, index: number) => {
  const driverDetails = dataObject.drivers[index].details;
  return `${driverDetails.driverFirstName} ${driverDetails.driverLastName}`;
};

const formatAFIDate = (stringDate: string) => {
  if (
    stringDate &&
    new Date(stringDate.includes('T') ? stringDate : `${stringDate} 00:00:00`).toString() !== 'Invalid Date'
  ) {
    return new Date(stringDate.includes('T') ? stringDate : `${stringDate} 00:00:00`)
      .toLocaleDateString('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replaceAll('/', '-');
  }
  return '';
};

export const serializeAfiData = (data: any) => {
  const drivers = data.drivers.map((driver: { [key: string]: { [key: string]: string } }) => {
    return {
      general: {
        name: {
          firstName: driver.details.driverFirstName,
          lastName: driver.details.driverLastName,
        },
      },
      driver: {
        licensceState: driver.details.driverLicenseState,
        licensceStatus: driver.details.driverLicenseStatus,
        person: {
          dob: formatAFIDate(driver.details.driverDob),
          gender: driver.details.driverGender,
          maritalStatus: driver.details.driverMaritalStatus,
          education: driver.details.driverEducation,
          occupation: driver.details.driverOccupation,
          AAAMember: driver.discounts.driverAaaMember,
        },
        question: {
          licenseSuspension: 'No',
        },
      },
      itemInfo: {
        other: {
          ageLicensed: driver.details.driverAgeLicensed,
        },
      },
      personalInfo: {
        relationshipToInsured: driver.details.driverRelationshipToInsured,
        defensiveDriver: driver.discounts.driverDefensiveDiscount,
        defensiveDriverCourseDate:
          driver.discounts.driverDefensiveDiscount === 'Y'
            ? formatAFIDate(driver.discounts.defensiveDriverCourseDate)
            : '',
        studentAway: driver.discounts.driverStudentLivingAway,
        studentDiscount: driver.discounts.driverGoodStudentDiscount,
        trainingDiscount: driver.discounts.driverTrainingDiscount,
        financialResponsibility: {
          SR22orFR44: driver.history.driverSr22,
          SR22orFR44FilingState:
            driver.history.driverSr22 === 'R' ? driver.history.driverSr22FilingState : '',
        },
      },
    };
  });

  const vehicles = data.cars.map((car: { [key: string]: { [key: string]: string } }, index: number) => {
    const [month, year] = car?.details?.purchaseDate.split('/');
    return {
      purchaseDate: formatAFIDate(`${month}/01/${year}`),
      salvaged: car.details.salvagedTitle,
      damage: car.details.existingDamage,
      devices: [
        car.details.steeringLock,
        car.details.carAlarm,
        car.details.disablingDevice,
        car.details.recoveryDevice,
      ].filter((item) => item !== 'None'),
      annualMileage: +car.details.annualMileage.replaceAll(',', ''),
      model: car.details.vehicleModel,
      make: car.details.vehicleMake,
      year: car.details.vehicleYear,
      vin: '0000000000',
      usage: car.details.vehicleUsage,
      dailyCommute: '0',
      itemInfo: {
        other: {
          registeredTo: car.details.registeredTo,
          ownership: car.details.vehicleOwnership,
          rideshare: car.details.rideSharing,
        },
      },
      coverages: {
        loanGap: data.coverage['new-coverage'][`V${index + 1}_coveragesLoanLeaseGap`],
        comprehensiveDeductible: data.coverage['new-coverage'][`V${index + 1}_comprehensiveDeductible`],
        collisionDeductible: data.coverage['new-coverage'][`V${index + 1}_collisionDeductible`],
        bodilyInjuryLiability: data.coverage['new-coverage'].primaryBodilyInjuryLimit,
        uninsuredMotoristsBodilyInjury: data.coverage['new-coverage'].uninsuredBodilyInjuryLimit,
        rental: data.coverage['new-coverage'][`V${index + 1}_coveragesRental`],
        towing: data.coverage['new-coverage'][`V${index + 1}_coveragesTowing`],
        propertyDamageLiability: data.coverage['new-coverage'].primaryPropertyDamage,
        medPay: data.coverage['new-coverage'].medPay,
        uninsuredMotoristsPropertyDamage: data.coverage['new-coverage'].uninsuredPropertyDamage,
        underinsuredMotoristsPropertyDamage:
          data.coverage['new-coverage'].underinsuredMotoristsPropertyDamage,
        underinsuredMotoristsBodilyInjury: data.coverage['new-coverage'].underinsuredMotoristsBodilyInjury,
        pipCoverage: {
          amount:
            data.coverage['new-coverage'].pip === 'Not Available' ? '' : data.coverage['new-coverage'].pip,
        },
      },
    };
  });

  const vehicleOperators = data.cars.map(
    (car: { details: { principalOperator: string | number } }, index: number) => {
      return {
        vehicleId: `V${index + 1}`,
        driverId: car.details.principalOperator,
        description: 'Primary',
      };
    },
  );

  const driverIncidents = data.drivers.map((driver: any, index: number) => {
    const incidentsArray = [] as { [key: string]: string }[];
    if (driver.history.driverIncidents === 'Y') {
      driver.incidents.map((incident: { [key: string]: string }) => {
        if (incident.incidentDate && incident.incidentDescription) {
          const [month, year] = incident?.incidentDate.split('/');
          incidentsArray.push({
            driverId: `D${index + 1}`,
            description: incident.incidentDescription,
            date: `${year}-${month}-01`,
            type:
              incident.incidentDescription in incidentTypes
                ? incidentTypes[incident.incidentDescription]
                : '',
          });
        }
      });
    }
    return incidentsArray;
  });

  return {
    applicant: {
      general: {
        name: {
          firstName: data.basics.name.firstName,
          lastName: data.basics.name.lastName,
        },
        communications: {
          phone: {
            home: data.drivers[0].details.homePhone,
            business: data.drivers[0].details.homePhone,
          },
          email: data.basics.name.emailAddress,
        },
        address: {
          addr1: data.basics.address.address,
          addr2: data.basics.address.address2,
          city: data.basics.address.city,
          state: data.basics.address.state,
          zip: data.basics.address.zip,
        },
      },
      insured: {
        person: {
          dob: formatAFIDate(data.drivers[0].details.driverDob),
          gender: data.drivers[0].details.driverGender,
          maritalStatus: data.drivers[0].details.driverMaritalStatus,
        },
        principal: {
          creditScore: data.coverage.credit.creditScore,
        },
      },
    },
    policy: {
      personal: {
        persApplicationInfo: {
          residence: data.basics.address.driverResidence,
          address: {
            addr1: data.basics.address.address,
            addr2: data.basics.address.address2,
            city: data.basics.address.city,
            state: data.basics.address.state,
            zip: data.basics.address.zip,
          },
        },
        incidents: driverIncidents.flat(),
        questions: {
          declinedInsurance: data.coverage.existing.canceledBefore,
          allDriversListed: 'Yes',
          creditAuthorization: 'Yes',
        },
        operators: vehicleOperators,
        quoteInfo: {
          itemInfo: {
            otherIdentifier: {},
          },
        },
        startDate: formatAFIDate(data.coverage['new-coverage'].policyStartDate),
        other: {
          priorExpirationDate:
            data.coverage.existing.insuranceCarrier === 'None'
              ? ''
              : formatAFIDate(data.coverage.existing.priorPolicyExpDate),
          pastProvider:
            data.coverage.existing.insuranceCarrierDisplayName && data.basics.address.state
              ? getCarrierByDisplayName(
                  data.coverage.existing.insuranceCarrierDisplayName,
                  data.basics.address.state,
                )
              : data.coverage.existing.insuranceCarrier || '',
          pastProviderDisplayName: data.coverage.existing.insuranceCarrierDisplayName,
          amount: data.coverage.existing.premium,
          frequency: data.coverage.existing.paymentFrequency,
          priorInsuranceBodilyInjuryLimits:
            data.coverage.existing.insuranceCarrier === 'None'
              ? 'None'
              : data.coverage.existing.priorInsuranceBodilyInjuryLimits,
        },
      },
      personalAutoLineBusiness: {
        coverageDuration:
          data.coverage.existing.insuranceCarrier === 'None'
            ? 'None'
            : data.coverage.existing.insuranceDuration,
        drivers: drivers,
        vehicles: vehicles,
      },
      itemInfo: {
        other: {},
      },
      location: {
        garagingAddress:
          data.basics.address.sameParkingAddress === 'Yes'
            ? {
                addr1: data.basics.address.address,
                addr2: data.basics.address.address2,
                city: data.basics.address.city,
                state: data.basics.address.state,
                zip: data.basics.address.zip,
              }
            : {
                addr1: data.basics.address.parkingAddress,
                addr2: data.basics.address.parkingAddress2,
                city: data.basics.address.parkingCity,
                state: data.basics.address.parkingState,
                zip: data.basics.address.parkingZip,
              },
      },
    },
  };
};

const formatAPIDate = (stringDate: string) => {
  if (stringDate && new Date(stringDate.includes('T') ? stringDate : `${stringDate} 00:00:00`).toString() !== 'Invalid Date') {
    return formatDate(
      new Date(stringDate.includes('T') ? stringDate : `${stringDate} 00:00:00`).toLocaleDateString('en-us', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    );
  }
  return '';
};

export const deserializeAfiData = (data: any) => {
  const address = {
    address: data?.policy?.personal?.persApplicationInfo?.address?.addr1 || '',
    address2: data?.policy?.personal?.persApplicationInfo?.address?.addr2 || '',
    city: data?.policy?.personal?.persApplicationInfo?.address?.city || '',
    state: data?.policy?.personal?.persApplicationInfo?.address?.state || '',
    zip: data?.policy?.personal?.persApplicationInfo?.address?.zip || '',
  };
  const parkingAddress = {
    address: data?.policy?.location?.garagingAddress?.addr1 || '',
    address2: data?.policy?.location?.garagingAddress?.addr2 || '',
    city: data?.policy?.location?.garagingAddress?.city || '',
    state: data?.policy?.location?.garagingAddress?.state || '',
    zip: data?.policy?.location?.garagingAddress?.zip || '',
  };
  const sameParkingAddress = _?.isEqual(address, parkingAddress);

  const basics = {
    name: {
      firstName: data?.applicant?.general?.name?.firstName || '',
      lastName: data?.applicant?.general?.name?.lastName || '',
      emailAddress: data?.applicant?.general?.communications?.email || '',
    },
    address: {
      address: address?.address || basicsTemplate.address.address,
      address2: address?.address2 || basicsTemplate.address.address2,
      city: address?.city || basicsTemplate.address.city,
      state: address?.state || basicsTemplate.address.state,
      zip: address?.zip || basicsTemplate.address.zip,
      driverResidence:
        data?.policy?.personal?.persApplicationInfo?.residence || basicsTemplate.address.driverResidence,
      sameParkingAddress: sameParkingAddress ? 'Yes' : 'No',
      parkingAddress: sameParkingAddress
        ? ''
        : parkingAddress?.address || basicsTemplate.address.parkingAddress,
      parkingAddress2: sameParkingAddress
        ? ''
        : parkingAddress?.address2 || basicsTemplate.address.parkingAddress2,
      parkingCity: sameParkingAddress ? '' : parkingAddress?.city || basicsTemplate.address.parkingCity,
      parkingState: sameParkingAddress ? '' : parkingAddress?.state || basicsTemplate.address.parkingState,
      parkingZip: sameParkingAddress ? '' : parkingAddress?.zip || basicsTemplate.address.parkingZip,
    },
  };

  const driverIncidents = {} as { [key: string]: [{ [key: string]: string }] };
  data?.policy?.personal?.incidents?.map((incident: { [key: string]: string }) => {
    let date = null;
    if (incident.date) {
      const array = formatAPIDate(incident.date)?.split('/');
      date = array && array.length >= 3 ? `${array[0]}/${array[2]}` : null;
    }
    if (incident?.driverId in driverIncidents) {
      driverIncidents[incident?.driverId]?.push({
        incidentDescription: incident?.description || '',
        incidentDate: incident.date && date ? date : '',
      });
    } else {
      driverIncidents[incident?.driverId] = [
        {
          incidentDescription: incident?.description || '',
          incidentDate: incident.date && date ? date : '',
        },
      ];
    }
  });
  const drivers = data?.policy?.personalAutoLineBusiness?.drivers?.map((driver: any, index: number) => {
    return {
      details: {
        driverFirstName: driver?.general?.name?.firstName || '',
        driverLastName: driver?.general?.name?.lastName || '',
        driverDob: formatAPIDate(driver?.driver?.person?.dob) || '',
        homePhone: data?.applicant?.general?.communications?.phone?.home || '',
        driverGender: driver?.driver?.person?.gender || '',
        driverRelationshipToInsured: driver?.personalInfo?.relationshipToInsured || '',
        driverMaritalStatus: driver?.driver?.person?.maritalStatus || '',
        driverAgeLicensed: driver?.itemInfo?.other?.ageLicensed || '',
        driverLicenseStatus: driver?.driver?.licensceStatus || '',
        driverLicenseState: driver?.driver?.licensceState || '',
        driverEducation: driver?.driver?.person?.education || '',
        driverOccupation: driver?.driver?.person?.occupation || '',
      },
      discounts: {
        driverAaaMember: driver?.driver?.person?.AAAMember || driverTemplate.discounts.driverAaaMember,
        driverTrainingDiscount:
          driver?.personalInfo?.trainingDiscount || driverTemplate.discounts.driverTrainingDiscount,
        driverDefensiveDiscount:
          driver?.personalInfo?.defensiveDriver || driverTemplate.discounts.driverDefensiveDiscount,
        driverGoodStudentDiscount:
          driver?.personalInfo?.studentDiscount || driverTemplate.discounts.driverGoodStudentDiscount,
        driverStudentLivingAway:
          driver?.personalInfo?.studentAway || driverTemplate.discounts.driverStudentLivingAway,
        defensiveDriverCourseDate:
          driver?.personalInfo?.defensiveDriver === 'Y'
            ? formatAPIDate(driver?.personalInfo?.defensiveDriverCourseDate)
            : '',
      },
      history: {
        driverIncidents: `D${index + 1}` in driverIncidents ? 'Y' : 'N',
        driverSr22: driver?.personalInfo?.financialResponsibility?.SR22orFR44,
        driverSr22FilingState:
          driver?.personalInfo?.financialResponsibility?.SR22orFR44 === 'Y'
            ? driver?.personalInfo?.financialResponsibility?.sr22FSR22orFR44FilingStateilingState
            : '',
      },
      incidents: driverIncidents[`D${index + 1}`] || _.cloneDeep(driverTemplate.incidents),
    };
  });

  const vehicleOperators = new Map(
    data?.policy?.personal?.operators?.map((item: { [key: string]: string }) => {
      return [item?.vehicleId, item?.driverId];
    }),
  );

  const cars = data?.policy?.personalAutoLineBusiness?.vehicles?.map((car: any, index: number) => {
    let date = null;
    if (car?.purchaseDate && car?.purchaseDate !== 'Invalid Date') {
      const array = formatAPIDate(car?.purchaseDate)?.split('/');
      date = array && array.length >= 3 ? `${array[0]}/${array[2]}` : null;
    }
    return {
      details: {
        vehicleYear: car?.year || '',
        vehicleMake: car?.make || '',
        vehicleModel: car?.model || '',
        vehicleOwnership: car?.itemInfo?.other?.ownership || '',
        vehicleUsage: car?.usage || '',
        annualMileage: car?.annualMileage?.toLocaleString(),
        purchaseDate: car?.purchaseDate && date ? date : '',
        principalOperator: vehicleOperators?.get(`V${index + 1}`) || '',
        registeredTo: car?.itemInfo?.other?.registeredTo || '',
        existingDamage: car?.damage || carTemplate.details.existingDamage,
        rideSharing: car?.itemInfo?.other?.rideshare || carTemplate.details.rideSharing,
        salvagedTitle: car?.salvaged || carTemplate.details.salvagedTitle,
        carAlarm: car?.devices?.find((item: string) => item === 'A') ? 'A' : 'None',
        disablingDevice: car?.devices?.find((item: string) => item === 'D') ? 'D' : 'None',
        recoveryDevice: car?.devices?.find((item: string) => item === 'Y') ? 'Y' : 'None',
        steeringLock: car?.devices?.find((item: string) => item === 'L') ? 'L' : 'None',
      },
    };
  });

  const coverage = {
    existing: {
      insuranceCarrierDisplayName: data?.policy?.personal?.other?.pastProviderDisplayName || '',
      insuranceCarrier:
        address?.state && data?.policy?.personal?.other?.pastProviderDisplayName
          ? getCarrierByDisplayName(
              data?.policy?.personal?.other?.pastProviderDisplayName,
              data?.policy?.personal?.persApplicationInfo?.address?.state,
            )
          : data?.policy?.personal?.other?.pastProvider || '',
      premium: data?.policy?.personal?.other?.amount || '',
      paymentFrequency: data?.policy?.personal?.other?.frequency || 'Monthly',
      priorInsuranceBodilyInjuryLimits: data?.policy?.personal?.other?.priorInsuranceBodilyInjuryLimits || '',
      canceledBefore:
        data?.policy?.personal?.questions?.declinedInsurance || coverageTemplate.existing.canceledBefore,
      insuranceDuration: data?.policy?.personalAutoLineBusiness?.coverageDuration || '',
      priorPolicyExpDate: formatAPIDate(data?.policy?.personal?.other?.priorExpirationDate) || '',
    },
    credit: {
      creditScore: data?.applicant?.insured?.principal?.creditScore || coverageTemplate.credit.creditScore,
    },
    'new-coverage': {
      pip: data?.policy?.personalAutoLineBusiness?.vehicles[0]?.coverages?.pipCoverage?.amount || '',
      primaryBodilyInjuryLimit:
        data?.policy?.personalAutoLineBusiness?.vehicles[0]?.coverages?.bodilyInjuryLiability || '',
      primaryPropertyDamage:
        data?.policy?.personalAutoLineBusiness?.vehicles[0]?.coverages?.propertyDamageLiability || '',
      medPay: data?.policy?.personalAutoLineBusiness?.vehicles[0]?.coverages?.medPay || '',
      uninsuredBodilyInjuryLimit:
        data?.policy?.personalAutoLineBusiness?.vehicles[0]?.coverages?.uninsuredMotoristsBodilyInjury || '',
      uninsuredPropertyDamage:
        data?.policy?.personalAutoLineBusiness?.vehicles[0]?.coverages?.uninsuredMotoristsPropertyDamage ||
        '',
      underinsuredMotoristsBodilyInjury:
        data?.policy?.personalAutoLineBusiness?.vehicles[0]?.coverages?.underinsuredMotoristsBodilyInjury ||
        '',
      policyStartDate: formatAPIDate(data?.policy?.personal?.startDate || new Date().toString()),
    } as { [key: string]: string },
  };

  data?.policy?.personalAutoLineBusiness?.vehicles?.map((car: any, index: number) => {
    coverage['new-coverage'][`V${index + 1}_collisionDeductible`] = car?.coverages?.collisionDeductible || '';
    coverage['new-coverage'][`V${index + 1}_comprehensiveDeductible`] =
      car?.coverages?.comprehensiveDeductible || '';
    coverage['new-coverage'][`V${index + 1}_coveragesLoanLeaseGap`] = car?.coverages?.loanGap || '';
    coverage['new-coverage'][`V${index + 1}_coveragesRental`] = car?.coverages?.rental || '';
    coverage['new-coverage'][`V${index + 1}_coveragesTowing`] = car?.coverages?.towing || '';
  });

  return {
    basics: basics,
    drivers: drivers || [
      {
        ..._.cloneDeep(driverTemplate),
        details: {
          ..._.cloneDeep(driverTemplate).details,
          driverFirstName: data?.applicant?.general?.name?.firstName || '',
          driverLastName: data?.applicant?.general?.name?.lastName || '',
          driverRelationshipToInsured: 'IN',
          homePhone: data?.applicant?.general?.communications?.phone?.home || '',
        },
      },
    ],
    cars: cars || [_.cloneDeep(carTemplate)],
    coverage: coverage,
    quotes: {},
  };
};

export const sortCoveragesValues = (arr: any) => {
  return arr.sort(function (a: OptionType, b: OptionType) {
    let stringOptions = ['None', 'Not Available', 'Covered'];
    let first = !stringOptions.includes(a.value) ? parseInt(a.value.split('/')[0]) : 0;
    let second = !stringOptions.includes(a.value) ? parseInt(b.value.split('/')[0]) : 0;
    return first - second;
  });
};

export const updateLastCompletedAFIStep = (
  currentStepProgress: string,
  lastStepCompleted: string,
  setLastStepCompleted: Function,
  newValues: any,
) => {
  const indexCurrentStepProgress = stepsFlow.indexOf(currentStepProgress);
  const indexLastStep = stepsFlow.indexOf(lastStepCompleted);
  if (currentStepProgress === 'drivers/history') {
    if (newValues.driverIncidents === 'N') {
      stepsFlow.includes('drivers/incidents') && stepsFlow.splice(5, 2);
    }

    if (newValues.driverIncidents === 'Y') {
      if (!stepsFlow.includes('drivers/incidents'))
        stepsFlow.splice(5, 0, 'drivers/incidents', 'drivers/incidents-summary');
    }
  }
  if (indexCurrentStepProgress > indexLastStep) {
    setLastStepCompleted(`${currentStepProgress}`);
  }
};

/**
 * Function that checks if all fields for a certain driver have been filled
 * @param data current driver data.
 * @returns Array of elements of type {key: string, index?: number}. If the array is empty, the profile is complete
 */
export const getMissingDriverFields = (data: any) => {
  const optionalFields = ['defensiveDriverCourseDate', 'driverSr22FilingState'];
  // Returning an object with key and index instead of a string because of incidents, whose optional fields will vary based on the index
  const missingFields = [] as { key: string; index?: number }[];

  if (data.history.driverIncidents === 'N') {
    optionalFields.push('incidentDescription', 'incidentDate');
  }
  _.flatMap(data).map((item: any) => {
    Object.keys(item).map((key) => {
      if (item[key] === '' && !optionalFields.includes(key) && key !== 'incidentDescription') {
        missingFields.push({
          key: key,
        });
      }
    });
  });

  return missingFields;
};

/**
 * Function that checks if all fields for a certain car have been filled
 * @param data current car data.
 * @returns Array of elements of type {key: string}. The typing is consistent with the drivers function. If the array is empty, the profile is complete
 */
export const getMissingCarsFields = (data: any) => {
  const missingFields = [] as { key: string }[];

  Object.keys(data.details).map((key) => {
    data.details[key] === '' && missingFields.push({ key: key });
  });
  return missingFields;
};

/**
 * Will take the value received from Canopy and compare it to the AFI values.
 * If the Canopy value is a number, it will return the closest value from the AFI options,
 * and if it is a string, the default value returned will be "None".
 * @param canopyValue The coverage value received from Canopy.
 * @param afiValues An array of type { id?: string, displayName: string, value: string}.
 * @return  Returns a string with the afi value closest to the Canopy one
 * @example getClosestCoverageForCanopy("8000", customQuestions.pip.options)
 */

export const getClosestCoverageForCanopy = (canopyValue: string, afiValues: string[]) => {
  let sanitizedValue = canopyValue;
  let closestValue;

  if (sanitizedValue.includes('$') || sanitizedValue.includes('/') || sanitizedValue.includes(',')) {
    sanitizedValue = sanitizedValue.replaceAll('$', '');
    sanitizedValue = sanitizedValue.replaceAll(',', '');
    if (sanitizedValue.includes('/')) {
      sanitizedValue = sanitizedValue.split('/')[0];
    }
  }

  //Return value "None" if CanopyValue is a word or if afiValues has no number values
  if (isNaN(parseInt(sanitizedValue)) || afiValues.every((elem) => isNaN(parseInt(elem)))) {
    closestValue = 'None';
    return closestValue;
  }

  const valuesArr = afiValues.map((elem: string) => parseInt(elem));
  const canopyValueCopy = parseInt(sanitizedValue);

  closestValue = `${valuesArr
    .reduce(function (prev: number, curr: number) {
      return Math.abs(curr - canopyValueCopy) < Math.abs(prev - canopyValueCopy) ? curr : prev;
    })
    .toString()}`;
  return closestValue;
};

export const findClosestBodilyInjuryValue = (initialValue: any, valuesArray: any) => {
  const initialNum = initialValue.split('/')[0].replace(/[,|$]/g, '');
  if (isNaN(Number(initialNum))) {
    return valuesArray.includes('None') ? 'None' : valuesArray[0];
  }
  valuesArray = valuesArray.filter((elem: any) => elem !== 'None');
  const closestValue = valuesArray.reduce((closest: any, current: any) => {
    const closestDistance = Math.abs(initialNum - parseFloat(closest.split('/')[0].replace(/,/g, '')));
    const currentDistance = Math.abs(initialNum - parseFloat(current.split('/')[0].replace(/,/g, '')));
    return currentDistance < closestDistance ? current : closest;
  }, valuesArray[0]);
  return closestValue;
};

const deserializeCanopyHelper = (coverage: any, coverageName: string, valueKey: string) => {
  let data;
  let coverageItem = coverage.find((elem: any) => elem.name === coverageName);
  if (coverageName === 'BODILY_INJURY_LIABILITY' || coverageName === 'UNINSURED_MOTORISTS') {
    data = `${coverageItem?.per_person_limit_cents / 100 || ''}/${
      coverageItem?.per_incident_limit_cents / 100 || ''
    }`;
  } else {
    data = `${coverageItem?.[valueKey] / 100 || ''}`;
  }
  return data;
};

export const deserializePriorPolicyData = (data: any) => {
  let vehicles = [];
  if (data?.personalAutoLineBusiness?.vehicles?.length) {
    vehicles = data?.personalAutoLineBusiness?.vehicles.map((car: any) => ({
      comprehensiveDeductible: deserializeCanopyHelper(
        car.canopyCoverages,
        'COMPREHENSIVE',
        'deductible_cents',
      ),
      collisionDeductible: deserializeCanopyHelper(car.canopyCoverages, 'COLLISION', 'deductible_cents'),
      bodilyInjuryLiability: deserializeCanopyHelper(car.canopyCoverages, 'BODILY_INJURY_LIABILITY', ''),
      uninsuredMotoristsBodilyInjury: deserializeCanopyHelper(
        car.canopyCoverages,
        'UNINSURED_MOTORIST_BODILY_INJURY_LIABILITY',
        '',
      ),
      underinsuredMotoristsBodilyInjury: deserializeCanopyHelper(
        car.canopyCoverages,
        'UNDERINSURED_MOTORIST_BODILY_INJURY_LIABILITY',
        '',
      ),
      priorInsuranceBodilyInjuryLimits: data?.personal?.other?.pastProvider
        ? data?.personal?.other?.priorInsuranceBodilyInjuryLimits || 'None'
        : 'None',
      coveragesLoanLeaseGap: deserializeCanopyHelper(
        car.canopyCoverages,
        'LOAN_OR_LEASE_ASSISTANCE',
        'deductible_cents',
      ),
      coveragesRental: deserializeCanopyHelper(
        car.canopyCoverages,
        'RENTAL_REIMBURSEMENT',
        'deductible_cents',
      ),
      coveragesTowing: deserializeCanopyHelper(car.canopyCoverages, 'TOWING_AND_LABOR', 'deductible_cents'),
      propertyDamageLiability: deserializeCanopyHelper(
        car.canopyCoverages,
        'PROPERTY_DAMAGE_LIABILITY',
        'per_incident_limit_cents',
      ),
    }));
  } else {
    vehicles = [
      {
        comprehensiveDeductible: '',
        collisionDeductible: '',
        coveragesLoanLeaseGap: '',
        coveragesRental: '',
        coveragesTowing: '',
        bodilyInjuryLiability: '',
        uninsuredMotoristsBodilyInjury: '',
        propertyDamageLiability: '',
        priorInsuranceBodilyInjuryLimits: '',
      },
    ];
  }

  const newObj = {
    pastProvider: data?.personal?.other?.pastProvider || 'None',
    amount: data?.personal?.other?.amount.toString() || '',
    priorExpirationDate: data?.personal?.other?.priorExpirationDate || '',
    vehicles: vehicles,
  };
  return newObj;
};

export const processPriorPolicyData = (canopyData: PriorPolicyCoverageType, stateValue: string) => {
  const newObj = _.cloneDeep(canopyData);
  let state = stateValue || 'default';

  if (!(state in customStateOptions)) {
    state = 'default';
  }
  const customFields = _.omit(customStateOptions[state], ['insuranceCarrier']);
  const afiFields = Object.keys(customFields).concat([
    'underinsuredMotoristsBodilyInjury',
    'coveragesLoanLeaseGap',
    'coveragesRental',
    'coveragesTowing',
  ]);

  newObj.vehicles?.forEach((vehicle: any) => {
    afiFields.forEach((key) => {
      if (
        key === 'bodilyInjuryLiability' ||
        key === 'uninsuredMotoristsBodilyInjury' ||
        key === 'underinsuredMotoristsBodilyInjury' ||
        key === 'priorInsuranceBodilyInjuryLimits'
      ) {
        if (vehicle[key] && vehicle[key] !== '/') {
          vehicle[key] = findClosestBodilyInjuryValue(vehicle[key], Object.keys(customFields[key]));
        } else {
          const options = Object.keys(customFields[key] || {});
          vehicle[key] = options?.[0] || 'None';
        }
        return;
      }
      //if there is a value from canopy, select the closest afi value to it
      if (vehicle[key]) {
        vehicle[key] = `${getClosestCoverageForCanopy(vehicle[key], Object.keys(customFields[key]))}`;
      } else {
        const options = Object.keys(customFields[key] || {});
        vehicle[key] = options?.[0] || 'None';
      }
    });
  });
  return newObj;
};

export const addNewPolicyData = (newPolicyData: PriorPolicyCoverageType, answersObject: any) => {
  const existingCarrier = getCarrierByDisplayName(
    newPolicyData?.pastProvider,
    answersObject.basics.address.state,
  );
  const newObj = _.cloneDeep(answersObject);
  let existingCoverage = {
    insuranceCarrier: existingCarrier,
    insuranceCarrierDisplayName: newPolicyData?.pastProvider,
    priorInsuranceBodilyInjuryLimits: newPolicyData?.vehicles[0].priorInsuranceBodilyInjuryLimits,
  };
  let newCoverages = {
    medPay: newPolicyData.vehicles[0].medPay,
    pip: newPolicyData.vehicles[0].pip,
    primaryBodilyInjuryLimit: newPolicyData.vehicles[0].bodilyInjuryLiability,
    primaryPropertyDamage: newPolicyData.vehicles[0].propertyDamageLiability,
    underinsuredMotoristsBodilyInjury: newPolicyData.vehicles[0].underinsuredMotoristsBodilyInjury,
    uninsuredBodilyInjuryLimit: newPolicyData.vehicles[0].uninsuredMotoristsBodilyInjury,
    uninsuredPropertyDamage: newPolicyData.vehicles[0].uninsuredMotoristsPropertyDamage,
    policyStartDate: answersObject.coverage['new-coverage'].policyStartDate,
  } as any;
  newPolicyData.vehicles.map((car, index) => {
    newCoverages[`V${index + 1}_collisionDeductible`] = car.collisionDeductible;
    newCoverages[`V${index + 1}_comprehensiveDeductible`] = car.comprehensiveDeductible;
    newCoverages[`V${index + 1}_coveragesLoanLeaseGap`] = car.coveragesLoanLeaseGap;
    newCoverages[`V${index + 1}_coveragesRental`] = car.coveragesRental;
    newCoverages[`V${index + 1}_coveragesTowing`] = car.coveragesTowing;
  });
  _.set(newObj, ['coverage', 'existing'], {
    ...newObj.coverage.existing,
    ...existingCoverage,
  });
  return _.set(newObj, ['coverage', 'new-coverage'], newCoverages);
};

export const splitBodilyInjuryPrices = (value: string) => {
  let tmp: any = value || '';
  if (tmp === 'None') {
    return { perPerson: tmp, perAccident: tmp };
  }

  if (value?.charAt(value?.length - 1) === '$') {
    tmp = tmp.substring(0, value?.length - 1);
  }

  tmp = tmp?.split('/').map((item: string) => (item.includes('$') ? item : `$${item}`));
  return { perPerson: tmp[0], perAccident: tmp[1] };
};

export const formatPriorValue = (element: string, coverageType: string, key?: string) => {
  let item;
  if (coverageType === 'bodilyInjury') {
    item = splitBodilyInjuryPrices(element);
    return formatAFIPriceString(item[key as 'perPerson' | 'perAccident'], '');
  }
  if (element && element.toLocaleLowerCase() === 'covered') {
    return 'Covered';
  }
  if (element && element.includes('/')) {
    const tmp = element.replaceAll('$', '').split('/');
    item = `${formatAFIPriceString(tmp[0], '')}/${formatAFIPriceString(tmp[1], '')}`;
    return item;
  }
  item = element;
  return formatAFIPriceString(item, '');
};
