// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-card-container {
  padding: 24px 16px;
  font-family: var(--primary-font);
  color: var(--secondary-text-color);
  background-color: var(--secondary-background-color);
  border-radius: 8px;
  border: 1px solid var(--stroke-color);
  width: 100%;
  height: 100%;
}
.summary-card-container .section-container {
  margin-bottom: 12px;
  font-size: 18px;
  line-height: 36px;
}
.summary-card-container .section-container .section-title {
  font-weight: 400;
  font-size: --primary-font;
}
.summary-card-container .section-container .section-value {
  font-weight: 800;
  font-family: var(--header-font);
}
@media (max-width: 580px) {
  .summary-card-container .section-container {
    font-size: 16px;
    line-height: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/summary-card/summary-card.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gCAAA;EACA,kCAAA;EACA,mDAAA;EACA,kBAAA;EACA,qCAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,mBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AACI;EACE,gBAAA;EACA,yBAAA;AACN;AACI;EACE,gBAAA;EACA,+BAAA;AACN;AAGE;EACE;IACE,eAAA;IACA,iBAAA;EADJ;AACF","sourcesContent":[".summary-card-container {\n  padding: 24px 16px;\n  font-family: var(--primary-font);\n  color: var(--secondary-text-color);\n  background-color: var(--secondary-background-color);\n  border-radius: 8px;\n  border: 1px solid var(--stroke-color);\n  width: 100%;\n  height: 100%;\n\n  .section-container {\n    margin-bottom: 12px;\n    font-size: 18px;\n    line-height: 36px;\n\n    .section-title {\n      font-weight: 400;\n      font-size: (--primary-font);\n    }\n    .section-value {\n      font-weight: 800;\n      font-family: var(--header-font);\n    }\n  }\n\n  @media (max-width: 580px) {\n    .section-container {\n      font-size: 16px;\n      line-height: 30px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
