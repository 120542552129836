import { useContext } from "react";
import StepComponent from "../../../step-component/StepComponent";
import RadioButtonContainer from "../../../radio-button-container/RadioButtonContainer";
import { AutoInsuranceContext } from "../../../../../context/AutoInsuranceContext";
import { UserContext } from "../../../../../context/UserContext";
import { vehicleExtraImages } from "../../../../../constants/AutoConstants";


import { useNavigate } from "react-router";

export const VehicleExtraStep = () => {
  const {
    hasSecondVehicle,
    setHasSecondVehicle,
    setProgressStep,
    updateAutoInsuranceStep,
  } = useContext(AutoInsuranceContext);
  const { partnerName, device } = useContext(UserContext);
  const navigate = useNavigate();

  const formContent = {
    header: "Would you like to add a second vehicle?",
    cardImg: vehicleExtraImages(partnerName, device),
    buttonTxt: "Next",
    onSubmit: () => {
      if (hasSecondVehicle === "Yes") {
        navigate('/auto/model-2');
        return;
      }
      setProgressStep(4);
      updateAutoInsuranceStep('current-insurance');
      navigate('/auto/current-insurance');
    },
    fields: [
      <RadioButtonContainer
        options={["Yes", "No"]}
        size="large"
        value={hasSecondVehicle}
        handleChange={(val) => setHasSecondVehicle(val)}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.cardImg}
      />
    </>
  );
};
