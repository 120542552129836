import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendCanopyMFACode } from '../../../../api/apiFunctions';
import PrimaryButton from '../../../../components/common/buttons/PrimaryButton';
import InputField from '../../../../components/common/forms/InputField/InputField';
import { CanopyInsurerType, CanopyUserType } from '../../../../components/interfaces/AFIInterfaces';
import './canopy-code.scss';
import BackButton from '../../../../components/common/buttons/BackButton';

const CanopyCode = ({
  currentInsurer,
  canopyUser,
  startNewTimeout,
}: {
  currentInsurer: CanopyInsurerType;
  canopyUser: CanopyUserType;
  startNewTimeout: Function;
}) => {
  const [codeValue, setCodeValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const data = await sendCanopyMFACode(
        codeValue,
        canopyUser.pullId,
        canopyUser.pullJWT,
        canopyUser.canopyJWT,
      );
      startNewTimeout();
    } catch (error) {
      navigate('/full-quote-auto/import/failed');
    }
  };

  return (
    <div className="code-container">
      <h1 className="code-title">Enter Confirmation Code</h1>
      <div
        className="canopy-insurer-name"
        style={{
          color: currentInsurer.color || 'var(--secondary-text-color)',
        }}
      >
        {currentInsurer.name}
      </div>
      <p className="code-description">Confirmation Code</p>
      <InputField
        value={codeValue}
        name={''}
        placeholder={''}
        type="text"
        setErrors={() => null}
        onChange={(e) => setCodeValue(e.target.value)}
      />
      <div className="code-buttons-container">
        <div className="code-retry-button-container">
          <div className="code-retry-button" onClick={() => navigate('/full-quote-auto/import/login')}>
            Didn’t get it?
          </div>
        </div>
        <PrimaryButton
          text="Connect"
          disabled={!codeValue.length || isLoading}
          onClick={handleSubmit}
          loading={isLoading}
        />
        <BackButton />
      </div>
    </div>
  );
};

export default CanopyCode;
