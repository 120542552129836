import { DisclaimerTable } from '../components/common/flow-disclaimer/DisclaimerList';

export const VoluntaryIntroContent = {
  title: 'Get <span>extra coverage</span> in case the worst happens',
  description:
    'With policies starting at $3 a month, Accidental Death & Dismemberment (AD&D) insurance can help ensure financial stability for your loved ones if you suffer from a serious accident. We particularly recommend this product for people with debt or any expenses that would pass to a spouse or other family member.',
};

export const volIntroImage = (partner: string, device: string) => {
  const IntroImg = require(`../assets/images/partners/${partner}/voluntary/${device}/intro.svg`).default;
  return <IntroImg />;
};

export const voluntarySuccessImages = (partner: string, device: string) => {
  const VoluntarySuccesImg =
    require(`../assets/images/partners/${partner}/ad&d/${device}/no-thanks.svg`).default;
  return <VoluntarySuccesImg />;
};

export const coverageImages = (partner: string, device: string) => {
  if (device !== 'smallMobile' && device !== 'mobile') {
    const CoverageImg =
      require(`../assets/images/partners/${partner}/voluntary/${device}/coverage.svg`).default;

    return <CoverageImg />;
  }
  return null;
};

export const defaultVoluntaryUser = {
  firstName: '',
  lastName: '',
  dob: '',
  gender: '',
  phoneNumber: '',
  ssn: '',
  id: new Date().getTime().toString(),
};

export const relationOptions = [
  { id: '0', value: 'spouse', displayName: 'Spouse' },
  { id: '1', value: 'child', displayName: 'Child' },
];
export const displayVoluntaryAddQuotes = [
  { coverage: 50000, user: 3, family: 4.15 },
  { coverage: 150000, user: 9, family: 12.45 },
  { coverage: 300000, user: 18, family: 24.9 },
];

export const voluntaryAddQuotes = [
  { coverage: 50000, user: 3, family: 4.15 },
  { coverage: 100000, user: 6, family: 8.3 },
  { coverage: 150000, user: 9, family: 12.45 },
  { coverage: 200000, user: 12, family: 16.6 },
  { coverage: 250000, user: 15, family: 20.75 },
  { coverage: 300000, user: 18, family: 24.9 },
];

export const defaultCoveragePlan = {
  monthlyCost: 0,
  coverage: 0,
  interval: 0,
};

export const voluntaryAddStepIds = [
  '',
  'address',
  'family',
  'coverage',
  'beneficiaries',
  'beneficiary-split',
  'payment',
];

// The number of steps required to complete the form based on the amount of benefeciaries chosen
// 2 or more beneficiaries means adding the beneficiary-split step
export const VOL_ADD_FULL_STEPS = 6;
export const VOL_ADD_SHORT_STEPS = 5;

export const voluntaryAddDisclaimer = [
  {
    title: 'Eligible members',
    description: 'All association members in good standing',
  },
  {
    title: 'Premium Contribution',
    description: 'Non-contributory',
  },
  {
    title: 'Coverage Amount',
    description:
      'Member: $50,000 to $300,000 in $50,000 incremements, with minimum benefit of $50,000 <br/> Spouse/Domestic Partner: 60% of member amount <br/> Child(ren) only: 25% of member amount <br/> Spouse / Domestic Partner and Child(ren): 50%/20% of member amount',
  },
  {
    title: 'Age Reductions',
    description:
      'When you are age 70 or more, your amount of insurance is limited. Each age and the limited percent for that age are shown below: age 70 reduces to 50% of coverage amount; age 75, reduces to 25% of the amount inforce at age 69.',
  },
  {
    title: '100% of benefit amount is paid for loss of',
    description:
      'Life, both hands or both feet, sight of both eyes, speech and hearing in both ears, quadriplegia, and any two or more: one foot, one hand, sight of one eye, speech, hearing in both ears',
  },
  {
    title: '75% of benefit amount is paid for loss of',
    description: 'Paraplegia, triplegia, one arm or one leg',
  },
  {
    title: '50% of benefit amount is paid for loss of',
    description: 'One hand or one foot or sight of one eye or speech or hearing in both ears or hemiplegia',
  },
  {
    title: '25% of benefit amount is paid for loss of',
    description:
      'Uniplegia, four fingers of the same hand, thumb & index finger of same hand or hearing in one ear',
  },
  {
    title: '13% of benefit amount is paid for loss of',
    description: 'All toes on one foot',
  },
  {
    title: '5% of benefit amount is paid for loss of',
    description: 'Big toe',
  },
  {
    title: 'Coma Benefit',
    description:
      'The lesser of 2% per month and $1,000, up to 100 months; after 100 months a lump sum equal to 100% of the amount of insurance minus the amount already paid for coma',
  },
  {
    title: 'Seatbelt Benefit',
    description: 'Lesser of 10% of the coverage amount or $25,000',
  },
  {
    title: 'Airbag Benefit',
    description: 'Lesser of 10% of the coverage amount or $10,000',
  },
  {
    title: 'Repatriation of Remains Benefit',
    description: 'Lesser of the returns of remains expenses or $10,000',
  },
  {
    title: 'Conversion',
    description: 'Conversion option not available',
  },
  {
    title: 'Provider',
    description: 'Issued by The Prudential Insurance Company of America.',
  },
  {
    title: 'Child care expense benefit',
    description:
      'The lesser of: (1) the actual cost charged by the child care center per year, (2) 5% of the amount of insurance on the person, and (3) $5,000. This benefit is payable annually for up to 4 consecutive years, but not beyond the date the child reaches 13. Enrollment in a child care center prior to or within 365 days after the date of death.',
  },
  {
    title: 'Child tuition reimbursement benefit',
    description:
      'The lesser of: (1) the actual annual tuition, exclusive of room and board, books and fees, charged by the school; <br/> (2) 10% of the amount of insurance on the person, and <br/> (3) $15,000. This benefit is payable annually for up to 4 consecutive years, but not beyond the date the child reaches age 27. <br/><br/> If there is no dependent child eligible for this benefit, a benefit of $1,000 will be paid.',
  },
  {
    title: 'Monthly mortgage payment benefit',
    description:
      'The lesser of: (1) The amount of your monthly mortgage payment; and (2) $1,000 <br/> <br/> This benefit will be paid monthly until the first of these occurs: (1) Your spouse dies. (2) Your mortgage is paid in full. (3) Your house is sold. (4) The benefit has been paid for 12 consecutive months.',
  },
  {
    title: 'Parental care benefit',
    description: 'The lesser of: (1) 5% of your amount of insurance; and (2) $10,000',
  },
  {
    title: 'Return of remains benefit',
    description:
      "This additional benefit for return of remains only applies if the person suffers a loss of life and such loss occurs outside a 150-mile radius of the person's home. <br/> <br/>An amount equal to the lesser of: (1) the amount of return of remains expenses; and (2) $10,000.",
  },
  {
    title: 'Spouse tuition reimbursement benefit',
    description:
      'The lesser of: (1) the actual tuition charged for the program; (2) 10% of your amount of insurance; and (3) $10,000.  <br/> <br/> This benefit is payable for up to 2 consecutive years. <br/> <br/> If there is no dependent spouse eligible for this benefit, a benefit of $1,000 will be paid.',
  },
  {
    title: 'Bereavement and trauma counseling benefit',
    description:
      'The lesser of: (1) the actual cost charged for counseling sessions; and (2) $100. This benefit is payable for up to 12 sessions per person for sessions held within 1 year after the date of the accident.',
  },
];

export const PrudentialPolicyDetails = () => {
  return (
    <>
      <p className="policy-text">
        Prudential, the Prudential logo, and the Rock symbol are service marks of Prudential Financial, Inc.
        and its related entities, registered in many jurisdictions worldwide.
      </p>
      <p className="policy-text">
        The products issued by The Prudential Insurance Company of America may not be available in all states.
      </p>
      <p className="policy-text">
        This site may contain marketing language, on products issued by The Prudential Insurance Company of
        America, that has not yet been approved in all states.
      </p>

      <p className="policy-text">
        FOR NY RESIDENTS: This AD&D policy provides ACCIDENT insurance only. It does NOT provide basic
        hospital, basic medical or major medical insurance as defined by the New York State Department of
        Financial Services.
      </p>

      <p className="policy-text">IMPORTANT NOTICE: THIS POLICY DOES NOT PROVIDE COVERAGE FOR SICKNESS.</p>

      <p className="policy-text">
        Accidental Death & Dismemberment Insurance coverage is issued by The Prudential Insurance Company of
        America, Newark, NJ. The Booklet-Certificate contains all details including any policy exclusions,
        limitations and restrictions, which may apply. CA COA #1179, NAIC #68241. Contract Series: 83500.
      </p>

      <p className="policy-text">1058265-00001-00</p>
    </>
  );
};

export const voluntaryAddPlans = {
  user: 'Just Me',
  family: 'Whole family',
};

export const VoluntaryDisclaimerContent = () => {
  return (
    <>
      <DisclaimerTable info={voluntaryAddDisclaimer} />
      <>
        <p className="disclaimer-table-text">
          Prudential, the Prudential logo, and the Rock symbol are service marks of Prudential Financial, Inc.
          and its related entities, registered in many jurisdictions worldwide.
        </p>
        <p className="disclaimer-table-text">
          The products issued by The Prudential Insurance Company of America may not be available in all
          states.
        </p>
        <p className="disclaimer-table-text">
          This site may contain marketing language, on products issued by The Prudential Insurance Company of
          America, that has not yet been approved in all states.
        </p>

        <p className="disclaimer-table-text">
          FOR NY RESIDENTS: <br />
          This AD&D policy provides ACCIDENT insurance only. It does NOT provide basic hospital, basic medical
          or major medical insurance as defined by the New York State Department of Financial Services.
        </p>

        <p className="disclaimer-table-text">
          IMPORTANT NOTICE: THIS POLICY DOES NOT PROVIDE COVERAGE FOR SICKNESS.
        </p>

        <p className="disclaimer-table-text">
          Accidental Death & Dismemberment Insurance coverage is issued by The Prudential Insurance Company of
          America, Newark, NJ. The Booklet-Certificate contains all details including any policy exclusions,
          limitations and restrictions, which may apply. CA COA #1179, NAIC #68241. Contract Series: 83500.
        </p>

        <p className="disclaimer-table-text">1058265-00001-00</p>
      </>
    </>
  );
};
