import { Routes, Route } from 'react-router-dom';
import CarshieldAccidentInsurance from '../pages/accident-insurance/CarshieldAccidentInsurance';
import '../pages/aflac/aflacPlaceholder.scss';

const AflacRoutes = () => {
  return (
    <Routes>
      <Route path="/carshield-accident-insurance" element={<CarshieldAccidentInsurance />} />
    </Routes>
  );
};

export default AflacRoutes;
