import { useState, createContext, FC, useEffect } from 'react';
import {
  AddBeneficiary,
  SelectedBeneficiary,
} from '../components/interfaces/AddTypeInterfaces';
import { getProgressStep } from '../pages/add-insurance/constants';

export type AddContextType = {
  partnerName: string;
  setPartnerName: Function;
  beneficiariesInfo: AddBeneficiary[];
  setBeneficiariesInfo: React.Dispatch<React.SetStateAction<AddBeneficiary[]>>;
  progressStep: number;
  setProgressStep: Function;
  showInfoModal: boolean;
  setShowInfoModal: Function;
  totalSteps: number;
  totalUpsellSteps: number;
  updateAddInsuranceStep: Function;
  showBeneficiaryModal: boolean;
  setShowBeneficiaryModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBeneficiary: SelectedBeneficiary;
  setSelectedBeneficiary: React.Dispatch<
    React.SetStateAction<SelectedBeneficiary>
  >;
  completedAmounts: boolean;
  setCompletedAmounts: React.Dispatch<React.SetStateAction<boolean>>;
  upsellProgressStep: number;
  setUpsellProgressStep: React.Dispatch<React.SetStateAction<number>>;
  lastCompletedUpsellStep: string;
  setLastCompletedUpsellStep: React.Dispatch<React.SetStateAction<string>>;
};

const AddInsuranceContext = createContext<AddContextType>({
  partnerName: '',
  setPartnerName: Function,
  beneficiariesInfo: [],
  setBeneficiariesInfo: (data: React.SetStateAction<AddBeneficiary[]>) => {},
  progressStep: 0,
  setProgressStep: Function,
  showInfoModal: false,
  setShowInfoModal: Function,
  totalSteps: 5,
  totalUpsellSteps: 4,
  updateAddInsuranceStep: Function,
  showBeneficiaryModal: false,
  setShowBeneficiaryModal: (data: React.SetStateAction<boolean>) => {},
  selectedBeneficiary: { name: '', id: '' },
  setSelectedBeneficiary: (
    data: React.SetStateAction<SelectedBeneficiary>
  ) => {},
  completedAmounts: false,
  setCompletedAmounts: (data: React.SetStateAction<boolean>) => {},
  upsellProgressStep: 0,
  setUpsellProgressStep: (data: React.SetStateAction<number>) => {},
  lastCompletedUpsellStep: '',
  setLastCompletedUpsellStep: (data: React.SetStateAction<string>) => {},
});

interface Props {
  children: React.ReactNode;
}

const AddInsurnaceProvider: FC<Props> = ({ children }) => {
  const [lastCompletedUpsellStep, setLastCompletedUpsellStep] = useState(
    sessionStorage.getItem('lastCompletedUpsell') || ''
  );

  const [beneficiariesInfo, setBeneficiariesInfo] = useState<AddBeneficiary[]>(
    []
  );
  const [showBeneficiaryModal, setShowBeneficiaryModal] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState({
    name: '',
    id: '',
  });
  const [completedAmounts, setCompletedAmounts] = useState(false);
  const [progressStep, setProgressStep] = useState(0);
  const [partnerName, setPartnerName] = useState('');
  const [upsellProgressStep, setUpsellProgressStep] = useState(0);

  const [showInfoModal, setShowInfoModal] = useState(false);

  const totalSteps = 5;
  const totalUpsellSteps = 4;

  const updateAddInsuranceStep = (stepName: string) => {
    const value = getProgressStep(stepName);
    setProgressStep(value);
  };

  useEffect(() => {
    sessionStorage.setItem('lastCompletedUpsellStep', lastCompletedUpsellStep);
  }, [lastCompletedUpsellStep]);

  return (
    <AddInsuranceContext.Provider
      value={{
        partnerName,
        setPartnerName,
        beneficiariesInfo,
        setBeneficiariesInfo,
        progressStep,
        setProgressStep,
        showInfoModal,
        setShowInfoModal,
        totalSteps,
        totalUpsellSteps,
        showBeneficiaryModal,
        setShowBeneficiaryModal,
        selectedBeneficiary,
        setSelectedBeneficiary,
        completedAmounts,
        setCompletedAmounts,
        updateAddInsuranceStep,
        upsellProgressStep,
        setUpsellProgressStep,
        lastCompletedUpsellStep,
        setLastCompletedUpsellStep,
      }}
    >
      {children}
    </AddInsuranceContext.Provider>
  );
};

export { AddInsuranceContext, AddInsurnaceProvider };
