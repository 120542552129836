import { useFormik } from 'formik';
import _ from 'lodash';
import { object } from 'yup';
import { AFIQuestionInterface } from '../../../components/interfaces/AFIInterfaces';
import {
  address,
  apt,
  city,
  parkikngState,
  parkingAddress,
  parkingApt,
  parkingCity,
  parkingZip,
  state,
  zip,
} from '../questions/address';

/**
 * Returns the formik object for a given set of Questions with the AFIQuestionInterface structure.
 * @param arrayOfQuestions - An array with AFIQuestionInterface objects, the AFI questions needed for a particular step.
 * @param validateOnChange - Should all fields be validated on change, boolean. Default value true.
 * @param validateOnBlur - Should all fields be validated on blur, boolean. Default value false.
 
 * @returns the formik object for a given set of Questions with the AFIQuestionInterface structure
 */

export const formikConfigBuilder = (
  arrayOfQuestions: AFIQuestionInterface[],
  validateOnChange: boolean = true,
  validateOnBlur: boolean = false,
) => {
  const schemasFromQuestions = arrayOfQuestions.reduce((totalKeys, question: AFIQuestionInterface) => {
    let schemaObject = { ...totalKeys, [question.name]: question.validation };
    if (arrayOfQuestions.includes(address)) {
      schemaObject = {
        ...schemaObject,
        address2: apt.validation,
        city: city.validation,
        state: state.validation,
        zip: zip.validation,
      };
    }
    if (arrayOfQuestions.includes(parkingAddress)) {
      schemaObject = {
        ...schemaObject,
        parkingAddress2: parkingApt.validation,
        parkingCity: parkingCity.validation,
        parkingState: parkikngState.validation,
        parkingZip: parkingZip.validation,
      };
    }
    return schemaObject;
  }, {});

  const initialValuesFromQuestions = arrayOfQuestions.reduce((totalKeys, question: AFIQuestionInterface) => {
    let initialValues = { ...totalKeys };
    _.set(initialValues, question.name, question.value);
    if (arrayOfQuestions.includes(address)) {
      initialValues = {
        ...initialValues,
        address2: apt.value,
        city: city.value,
        state: state.value,
        zip: zip.value,
      };
    }
    if (arrayOfQuestions.includes(parkingAddress)) {
      initialValues = {
        ...initialValues,
        parkingAddress2: parkingApt.validation,
        parkingCity: parkingCity.validation,
        parkingState: parkikngState.validation,
        parkingZip: parkingZip.validation,
      };
    }
    return initialValues;
  }, {});

  const stepValidationSchema = object({
    ...schemasFromQuestions,
  });

  const formik = useFormik({
    initialValues: {
      ...initialValuesFromQuestions,
    },
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnBlur,
    enableReinitialize: true,
    validationSchema: stepValidationSchema,
    onSubmit: () => {},
  });

  return formik;
};
