// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.options-container {
  display: grid;
  align-items: center;
  gap: 12px;
  font-family: var(--primary-font);
  font-style: normal;
  color: var(--secondary-text-color);
}
.options-container .options-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
.options-container .options-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
}
.options-container .options-buttons-container {
  margin-top: 10px;
  width: 100%;
  display: grid;
  gap: 10px;
}
.options-container .options-buttons-container .secondary-button {
  color: var(--primary-font);
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  word-break: break-all;
  text-overflow: ellipsis;
}
@media (min-width: 580px) {
  .options-container .options-title {
    font-size: 24px;
  }
  .options-container .options-description {
    font-size: 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/answer-financial-insurance/canopy-connect/CanopyOptions/canopy-options.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,gCAAA;EACA,kBAAA;EACA,kCAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,SAAA;AADJ;AAGI;EACE,0BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,uBAAA;AADN;AAME;EACE;IACE,eAAA;EAJJ;EAME;IACE,eAAA;EAJJ;AACF","sourcesContent":[".options-container {\n  display: grid;\n  align-items: center;\n  gap: 12px;\n  font-family: var(--primary-font);\n  font-style: normal;\n  color: var(--secondary-text-color);\n\n  .options-title {\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 30px;\n    text-align: center;\n  }\n\n  .options-description {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 30px;\n    text-align: center;\n  }\n\n  .options-buttons-container {\n    margin-top: 10px;\n    width: 100%;\n    display: grid;\n    gap: 10px;\n\n    .secondary-button {\n      color: var(--primary-font);\n      font-weight: 700;\n      font-size: 16px;\n      line-height: 30px;\n      word-break: break-all;\n      text-overflow: ellipsis;\n    }\n  }\n\n  // Tablet\n  @media (min-width: 580px) {\n    .options-title {\n      font-size: 24px;\n    }\n    .options-description {\n      font-size: 18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
