import { Spinner } from 'react-bootstrap';
import './buttons.scss';
import { ButtonProps } from './PrimaryButton';

const SecondaryButton = ({ text, onClick, fontColor, disabled = false, loading = false }: ButtonProps) => {
  return (
    <button
      className={`secondary-button ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      style={{ color: fontColor }}
      disabled={disabled}
    >
      {loading ? <Spinner animation="border" role="status" aria-hidden="true" variant="primary" /> : text}
    </button>
  );
};

export default SecondaryButton;
