import { useState } from 'react';
import InfoModal from './InfoModal';
import InfoIcon from '../../../../assets/common/afi/info-icon.svg';

/**
 * Dynamic component which will always display an Info icon.
 *
 * Will always display the Info icon svg
 *
 * Title can be hidden in the main view and only displayed on the modal by passing the optional parameter hideTitle
 *
 * Modal content can have multiple elements, each can have an optional subtitle and a required description
 *
 */
const InfoField = ({
  title,
  content,
  hideTitle,
}: {
  title: string;
  content: { subtitle?: string; description: string }[];
  hideTitle?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="info-field">
      <InfoIcon onClick={() => setIsOpen(true)} />
      {!hideTitle && <div className="info-field-title">{title}</div>}
      {isOpen && <InfoModal title={title} content={content} close={() => setIsOpen(false)} />}
    </div>
  );
};

export default InfoField;
