// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.beneficiaries-button {
  width: 100%;
  margin: auto;
}
.beneficiaries-button button {
  margin-bottom: 20px;
  border-radius: 10px;
  color: var(--primary-button-text-color);
}

.beneficiary-cards-container {
  width: 100%;
}
.beneficiary-cards-container .beneficiary-card {
  background-color: var(--beneficiary-card-color);
  border: 1px solid var(--beneficiary-card-border-color);
  color: var(--tertiary-text-color);
  border: 1px solid var(--tertiary-text-color);
  border-radius: 8px;
  margin-bottom: 15px;
  padding-right: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}
.beneficiary-cards-container .beneficiary-card.voluntary.deselected {
  background-color: transparent;
}
.beneficiary-cards-container .beneficiary-card p.name {
  width: 93%;
  height: 100%;
  padding: 8px 16px;
  cursor: pointer;
}
.beneficiary-cards-container .beneficiary-card svg {
  cursor: pointer;
}
@media (max-height: 620px) {
  .beneficiary-cards-container .beneficiary-card {
    padding: 9px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/steps/common-steps/beneficiary/beneficiary.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AACE;EACE,mBAAA;EACA,mBAAA;EACA,uCAAA;AACJ;;AAGA;EACE,WAAA;AAAF;AACE;EACE,+CAAA;EACA,sDAAA;EACA,iCAAA;EACA,4CAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,0BAAA;AACJ;AAEM;EACE,6BAAA;AAAR;AAGI;EACE,UAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;AADN;AAII;EACE,eAAA;AAFN;AAKI;EAjCF;IAkCI,iBAAA;EAFJ;AACF","sourcesContent":[".beneficiaries-button {\n  width: 100%;\n  margin: auto;\n\n  button {\n    margin-bottom: 20px;\n    border-radius: 10px;\n    color: var(--primary-button-text-color);\n  }\n}\n\n.beneficiary-cards-container {\n  width: 100%;\n  .beneficiary-card {\n    background-color: var(--beneficiary-card-color);\n    border: 1px solid var(--beneficiary-card-border-color);\n    color: var(--tertiary-text-color);\n    border: 1px solid var(--tertiary-text-color);\n    border-radius: 8px;\n    margin-bottom: 15px;\n    padding-right: 8px;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 18px;\n    line-height: 30px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    text-transform: capitalize;\n\n    &.voluntary {\n      &.deselected {\n        background-color: transparent;\n      }\n    }\n    p.name {\n      width: 93%;\n      height: 100%;\n      padding: 8px 16px;\n      cursor: pointer;\n    }\n\n    svg {\n      cursor: pointer;\n    }\n\n    @media (max-height: 620px) {\n      padding: 9px 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
