import { useContext } from 'react';
import { AddInsuranceContext } from '../../../../context/AddInsuranceContext';
import { MembersContext } from '../../../../context/MembersContext';
import Amounts from '../common-steps/Amounts';

const AddAmounts = () => {
  const {
    beneficiariesInfo,
    setBeneficiariesInfo,
    showBeneficiaryModal,
    selectedBeneficiary,
    setSelectedBeneficiary,
    setShowBeneficiaryModal,
  } = useContext(MembersContext);
  const { completedAmounts, setCompletedAmounts } =
    useContext(AddInsuranceContext);
  return (
    <Amounts
      beneficiariesInfo={beneficiariesInfo}
      setBeneficiariesInfo={setBeneficiariesInfo}
      showBeneficiaryModal={showBeneficiaryModal}
      setShowBeneficiaryModal={setShowBeneficiaryModal}
      selectedBeneficiary={selectedBeneficiary}
      setSelectedBeneficiary={setSelectedBeneficiary}
      completedAmounts={completedAmounts}
      setCompletedAmounts={setCompletedAmounts}
      link={`/accidental-death/terms`}
    />
  );
};

export default AddAmounts;