import { errorMessages } from '../../../../services/validation';
import ErrorIcon from '../../../../assets/common/error-icon.svg';
import './input-field.scss';
import { stringsObject } from '../../../interfaces/InputInterfaces';
import { FormikErrors } from 'formik';
import { FamilyMemberInterface } from '../../../interfaces/CommonInterfaces';

type InputProps = {
  value: string;
  name: string;
  placeholder: string;
  type: string;
  setErrors: Function;
  onChange: (e: any) => void;
  onClick?: (e: any) => void;
  onBlur?: (e?: any) => void;
  onFocus?: () => void;
  disabled?: boolean;
  minLength?: number;
  maxLength?: number;
  errors?: stringsObject | null | FormikErrors<stringsObject> | FormikErrors<FamilyMemberInterface>;
  required?: boolean;
  enableSubmit?: boolean;
  smallSize?: boolean;
  inputMode?: 'text' | 'numeric' | 'email' | 'search' | 'tel';
  className?: string;
};

const InputField = ({
  name,
  type,
  value,
  placeholder,
  disabled,
  onChange,
  onClick,
  onBlur,
  onFocus,
  minLength,
  maxLength,
  errors,
  setErrors,
  inputMode,
  className,
  enableSubmit = true,
  required = false,
  smallSize = false,
}: InputProps) => {
  const handleSubmit = (event: any): void => {
    event.preventDefault();
    if (required && value?.length === 0) {
      setErrors((prev: any) => ({ ...prev, [name]: errorMessages.required }));
    } else {
      enableSubmit && onClick && onClick(event);
    }
  };

  return (
    <div className={`input-field-container ${name}`} id={smallSize ? 'small' : name}>
      <form onSubmit={handleSubmit}>
        <input
          className={`${errors?.[name] ? 'input-error' : ''}`}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          minLength={minLength}
          maxLength={maxLength}
          pattern={type === 'number' ? '^[0-9]*$' : undefined}
          inputMode={inputMode}
        />
        {errors?.[name] && <ErrorIcon />}
      </form>
      {errors && (
        <div className={`error ${className ? className : ''}`}>{`${errors[name] ? errors[name] : ''}`}</div>
      )}
    </div>
  );
};

export default InputField;
