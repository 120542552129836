import QuestionField from '../QuestionField/QuestionField';
import { FormikProps, FormikValues } from 'formik';
import { AFIQuestionInterface } from '../../../interfaces/AFIInterfaces';
import './form-questions.scss';
import AddressForm from '../../forms/AddressForm/AddressForm';
import InfoField from '../Info/InfoField';
import _ from 'lodash';
import Tooltip from '../../tooltip/Tooltip';

const FormQuestions = ({
  formTitle,
  questions,
  description,
  info,
  formikConfig,
  stepId,
  titleTooltip = null,
}: {
  formTitle?: string;
  description?: React.ReactNode;
  questions: AFIQuestionInterface[];
  info?: {
    anchorField: 'title' | string;
    title: string;
    hideTitle?: boolean;
    content: { subtitle?: string; description: string }[];
  };
  formikConfig: FormikProps<FormikValues>;
  stepId: string;
  titleTooltip: null | React.ReactElement;
}) => {
  const processQuestions = (questions: AFIQuestionInterface[]) => {
    const tmp = _.cloneDeep(questions);
    if (formikConfig.values.insuranceCarrier === 'None') {
      tmp.forEach((elem) => {
        if (elem.identifier !== 'insuranceCarrier') {
          elem.disabled = true;
        }
      });
    }
    return tmp;
  };

  const getField = (item: AFIQuestionInterface) => {
    switch (item.identifier) {
      case 'address':
        return (
          <div key={item.identifier}>
            <AddressForm questionObject={item} formikConfig={formikConfig} />
          </div>
        );
      case 'parkingAddress':
        return (
          <div key={item.identifier}>
            <AddressForm questionObject={item} formikConfig={formikConfig} customKey="parking" />
          </div>
        );
      default:
        return (
          <div
            key={item.identifier}
            className={`${item.className || ''} ${item.hasLabel === 'left' ? 'labeled-field-container' : ''}`}
          >
            <div className={`${item.hasLabel ? 'labeled-field-content' : ''}`}>
              {item.hasLabel && (
                <div className={item.hasLabel === 'top' ? 'field-label-top' : 'field-label-left'}>
                  <p>{item.title}</p>
                  {item.tooltip && <Tooltip content={item.tooltip} />}
                </div>
              )}
              <QuestionField questionObject={item} formikConfig={formikConfig} stepId={stepId} />
              {item.identifier === info?.anchorField && (
                <InfoField title={info?.title} hideTitle={info.hideTitle} content={info?.content} />
              )}
            </div>
            {item.hasLabel === 'left' &&
              formikConfig.touched[item.name] &&
              formikConfig.errors[item.name] && (
                <p className={`${item.hasLabel === 'left' ? 'labeled-field-error' : ''} `}>
                  {String(formikConfig?.errors[item?.name])}
                </p>
              )}
          </div>
        );
    }
  };

  return (
    <div className="form-question-container">
      <div className="form-title-container">
        {formTitle && <p className={`form-title ${description ? 'with-description' : ''}`}>{formTitle}</p>}{' '}
        {info?.anchorField === 'title' && <InfoField title={info.title} content={info?.content} hideTitle />}
        {titleTooltip && titleTooltip}
      </div>
      {description && <div className="form-description">{description}</div>}
      <div className="form-fields-container">
        {processQuestions(questions).map((item: AFIQuestionInterface) => getField(item))}
      </div>
    </div>
  );
};

export default FormQuestions;
