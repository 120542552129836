import { useContext, useState } from 'react';
import StepComponent from '../../step-component/StepComponent';
import AddressField from '../../forms/AddressField/AddressField';
import InputField from '../../forms/InputField/InputField';
import SelectField from '../../select-field/SelectField';
import { useNavigate } from 'react-router';
import { UserContext } from '../../../../context/UserContext';
import { useFormik } from 'formik';
import { allFieldsValid } from '../../../../services/validation';
import { states } from '../../../../constants';
import { getNumberInputs } from '../../../../services/helpers/inputFieldHelpers';
import { isAmalgamatedState } from '../../../../services/helpers/AddHelpers';
import { addressImages } from '../../../../constants/AddConstants';
import TimedErrorBanner from '../../error-banner/ErrorBanner';
import { userAddressSchema } from '../../../schemas/commonSchemas';

export const UserAddress = ({ nextStepUrl, updateStep }: { nextStepUrl: string; updateStep?: Function }) => {
  const navigate = useNavigate();
  const { addressInfo, setAddressInfo, setIsAmalgamated, device, partnerName } = useContext(UserContext);
  const [showAddressBanner, setShowAddressBanner] = useState(false);

  const formik = useFormik({
    initialValues: {
      address: addressInfo.address,
      address2: addressInfo.address2,
      city: addressInfo.city,
      state: addressInfo.state,
      zip: addressInfo.zip,
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: userAddressSchema,
    onSubmit: () => {},
  });

  const { values, handleChange, handleBlur, errors, touched, setErrors, setFieldValue } = formik;

  const setAddress = (value: string) => {
    setFieldValue('address', value);
  };
  const setCity = (value: string) => {
    setFieldValue('city', value);
  };
  const setZip = (value: string) => {
    setFieldValue('zip', value);
  };
  const setCurrentState = (value: string) => {
    setFieldValue('state', value);
  };

  const handleZipInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    getNumberInputs(e.target.value) && handleChange(e);
  };

  const formContent = {
    header: 'What’s your address?',
    buttonTxt: 'Continue',
    Img: addressImages(partnerName, device),
    onClick: () => {
      if (values.state === 'MT' || values.state === 'ME') {
        setShowAddressBanner(true);
        return true;
      }
      return false;
    },
    onSubmit: () => {
      setAddressInfo(values);
      setIsAmalgamated(isAmalgamatedState(values.state));
      updateStep && updateStep();
      navigate(nextStepUrl);
    },
    errors: {},
    fields: [
      <AddressField
        address={values.address}
        setAddress={setAddress}
        setCity={setCity}
        setZip={setZip}
        setShortState={setCurrentState}
      />,
      <InputField
        value={values.address2}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Apt/Suite"
        type="text"
        name="address2"
        setErrors={setErrors}
      />,
      <InputField
        value={values.city}
        onChange={handleChange}
        placeholder={'City'}
        type="text"
        name="city"
        setErrors={setErrors}
        errors={touched['city'] ? errors : {}}
      />,
      <SelectField
        value={values.state}
        options={states}
        handleChange={handleChange}
        placeholder={'State'}
        name="state"
      />,
      <InputField
        value={values.zip}
        onChange={handleZipInput}
        placeholder="Zip"
        type="text"
        name="zip"
        setErrors={setErrors}
        minLength={5}
        maxLength={5}
        errors={touched['zip'] ? errors : {}}
        inputMode="numeric"
      />,
      <>
        {showAddressBanner && (
          <TimedErrorBanner
            closeFunction={setShowAddressBanner}
            delay={4000}
            message="This offer is unavailable for customer in Montana or Maine at this time. Our apologies for the inconvenience."
          />
        )}
      </>,
    ],
    disabledButton: allFieldsValid(values, errors, 'address2'),
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        cardImage={formContent.Img}
        onClick={formContent.onClick}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        disabledButton={formContent.disabledButton}
      />
    </>
  );
};
