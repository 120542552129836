import './percentage-field.scss';
import Remove from '../../../../assets/common/delete-icon.svg';

type PercentageProps = {
  name: string;
  percentage: string;
  onClick: Function;
  onChange: (e: any) => void;
  handleBlur?: (e: any) => void;
  handleFocus?: (e: any) => void;
  isOnlyBeneficiary: boolean;
};

const PercentageField = ({
  name,
  percentage,
  onChange,
  handleBlur,
  onClick,
  isOnlyBeneficiary,
  handleFocus,
}: PercentageProps) => {
  return (
    <div className="percentage-field-container">
      <div className="percentage-field-title">
        <p>{name}</p>
        {!isOnlyBeneficiary && <Remove onClick={onClick} />}
      </div>
      <input
        value={`${percentage}`}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        inputMode="numeric"
        name="percentage"
        type="text"
      />
    </div>
  );
};

export default PercentageField;
