import { useContext, useState, useMemo } from 'react';
import StepComponent from '../../components/common/step-component/StepComponent';
import { useNavigate } from 'react-router';
import { VoluntaryAddContext } from '../../context/VoluntaryContext';
import UserForm from '../../components/common/forms/UserForm/UserForm';
import MemberForm from '../../components/common/forms/MemberForm/MemberForm';
import { getMonthlyPayment } from '../../services/helpers/VoluntaryHelpers';
import './voluntary.scss';
import { isMemberValid, isUserValid } from '../../services/validation';
import MemberFormContainer from '../../components/common/forms/MemberForm/MemberFormContainer';
import { FamilyMemberInterface, UserInterface } from '../../components/interfaces/CommonInterfaces';
import { UserContext } from '../../context/UserContext';
import { formatDate, handleFamilyUpdates } from '../../services/helpers/CommonHelpers';
import TimedErrorBanner from '../../components/common/error-banner/ErrorBanner';
import { createUser, removeFamilyMember } from '../../api/apiFunctions';
import PrimaryButtonWithIcon from '../../components/common/buttons/PrimaryButtonWithIcon';
import AddIcon from '../../assets/common/plus-sign.svg';
import { getAPIErrorMessage } from '../../api/helpers/response';
import { POLICY_IDENTIFIERS } from '../../constants/CommonConstants';
import { CreateUserInterface } from '../../components/interfaces/AddTypeInterfaces';

const getDefaultUser = (userInfo: UserInterface) => {
  let defaultUser = {
    ...userInfo,
    phone: userInfo.phone || '',
    ssn: userInfo.ssn || '',
  };
  return {
    ...defaultUser,
    isValid: isUserValid(defaultUser),
  };
};

const getFormatedFamilyMembers = (members: Array<FamilyMemberInterface>) => {
  return members.map((elem) => {
    return { ...elem, isValid: isMemberValid(elem) };
  });
};

const AddFamily = () => {
  const { setCoveredMembers, updateLastStep } = useContext(VoluntaryAddContext);
  const {
    isMobile,
    userInfo,
    setUserInfo,
    familyMembers,
    setFamilyMembers,
    authToken,
    setAuthToken,
    partnerName,
    addressInfo,
    trackingId,
  } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [voluntaryUser, setVoluntaryUser] = useState<UserInterface>(getDefaultUser(userInfo));
  const [userHasError, setUserHasError] = useState(false);
  const [voluntaryMembers, setVoluntaryMembers] = useState<FamilyMemberInterface[]>(
    getFormatedFamilyMembers([...familyMembers]),
  );
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const areFormsValid = useMemo(() => {
    return voluntaryMembers.every((elem) => elem.isValid) && voluntaryUser.isValid;
  }, [voluntaryMembers, voluntaryUser]);

  const navigate = useNavigate();

  const removeMember = async (index: number) => {
    // check if alreaded added to backend
    const familyMember = voluntaryMembers[index];
    if (familyMember.id) {
      try {
        await removeFamilyMember(familyMember.id, authToken).then((resp) => {
          setFamilyMembers(familyMembers.filter((member) => member.id !== familyMember.id));
        });
      } catch (err: any) {
        setErrorMessage(getAPIErrorMessage(err));
        return;
      }
    }
    const updatedMembers = voluntaryMembers.filter((_, memberIndex) => {
      return memberIndex !== index;
    });

    setVoluntaryMembers(updatedMembers);
  };

  const addMember = () => {
    if (areFormsValid) {
      const newMember: FamilyMemberInterface = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        kinship: '',
        isValid: false,
      };

      setVoluntaryMembers([...voluntaryMembers, newMember]);
    }
  };

  const handleCreateUser = async () => {
    let createUserInfo: CreateUserInterface = {
      user: {
        firstName: voluntaryUser.firstName,
        lastName: voluntaryUser.lastName,
        email: voluntaryUser.email,
        dob: voluntaryUser.dob,
        gender: voluntaryUser.gender,
        ssn: voluntaryUser.ssn,
        phone: voluntaryUser.phone,
      },
      address: {
        address: addressInfo.address,
        city: addressInfo.city,
        state: addressInfo.state,
        zip: addressInfo.zip,
      },
      policyName: POLICY_IDENTIFIERS.VOLUNTARY_ACCIDENTAL_DEATH,
    };
    if (partnerName) {
      createUserInfo.clientName = partnerName;
    }
    if (addressInfo.address2) {
      createUserInfo.address['address2'] = addressInfo.address2;
    }
    if (trackingId) {
      createUserInfo.clientIdentifier = trackingId;
    }
    try {
      const createNewUser = await createUser(createUserInfo);
      const token = await createNewUser.data.token;
      setAuthToken(token);
      return token;
    } catch (error) {
      return null;
    }
  };

  const updateMember = (index: number, field: string, value: string) => {
    const newMembers = [...voluntaryMembers];
    const familyMember = newMembers[index];
    familyMember[field] = value;
    familyMember.isValid = isMemberValid(familyMember);
    setVoluntaryMembers(newMembers);
  };

  const updateUser = (newUser: UserInterface) => {
    setVoluntaryUser({ ...newUser, isValid: isUserValid(newUser) });
  };

  const memberForms = useMemo(() => {
    return (
      voluntaryMembers.length >= 1 && (
        <MemberFormContainer>
          {voluntaryMembers.map((member, idx) => {
            return (
              <MemberForm
                key={idx}
                memberInfo={member}
                removeMember={() => removeMember(idx)}
                updateMember={(field: string, value: string) => updateMember(idx, field, value)}
              />
            );
          })}
        </MemberFormContainer>
      )
    );
  }, [removeMember, updateMember, voluntaryMembers]);

  const processRejectedResponses = (responses: PromiseRejectedResult[]) => {
    setErrorMessage(responses.reduce((acc, elem) => (acc += `\n${getAPIErrorMessage(elem.reason)}`), ''));
    setShowErrorBanner(true);
  };

  const apiOnErrorUpdate = (responses: PromiseSettledResult<any>[]) => {
    const tmp = [...voluntaryMembers];
    responses.forEach((elem, index) => {
      if (elem.status === 'rejected') {
        if (index === 0) {
          setUserHasError(true);
        } else {
          tmp[index - 1].hasError = true;
        }
      } else {
        if (index === 0) {
          setUserHasError(false);
        } else {
          tmp[index - 1].hasError = false;
        }
      }
    });
    setVoluntaryMembers(tmp);
  };

  const apiOnSuccessUpdate = (response: PromiseSettledResult<any>[]) => {
    const successResp = response.filter(
      (result): result is PromiseFulfilledResult<any> => result.status === 'fulfilled',
    );
    const newUserInfo = {
      ...successResp[0].value.data,
      dob: formatDate(successResp[0].value.data.dob),
    };
    const newMembers = successResp.slice(1).map((elem) => {
      return { ...elem.value.data, dob: formatDate(elem.value.data.dob) };
    });
    setUserInfo(newUserInfo);
    setFamilyMembers([...newMembers]);
    setCoveredMembers([...newMembers]);
  };

  const formContent = {
    header: 'Tell us about yourself',
    buttonTxt: `Continue${!isMobile ? ` at ${getMonthlyPayment(voluntaryMembers.length)} / mo` : ''}`,
    disabledButton: !areFormsValid,
    onAPIClick: async (onSubmit: Function) => {
      setIsLoading(true);
      const updatedMembers = [
        { ...voluntaryUser } as UserInterface,
        ...(voluntaryMembers as FamilyMemberInterface[]),
      ];
      const token = authToken || (await handleCreateUser());
      const resp = await handleFamilyUpdates(updatedMembers, token);
      const hasError = resp.some((elem) => elem.status === 'rejected');
      if (hasError) {
        const errorResponses = resp.filter(
          (result): result is PromiseRejectedResult => result.status === 'rejected',
        );
        processRejectedResponses(errorResponses);
        apiOnErrorUpdate(resp);
      } else {
        apiOnSuccessUpdate(resp);
        onSubmit();
      }
      setIsLoading(false);
    },
    onSubmit: () => {
      updateLastStep('family');
      navigate('/accidental-death-voluntary/coverage');
    },
    fields: [
      <UserForm
        key={voluntaryUser.id}
        userInfo={voluntaryUser}
        name={voluntaryUser.id}
        updateMember={updateUser}
      />,
      memberForms,
      <>
        <PrimaryButtonWithIcon
          icon={<AddIcon />}
          disabled={!areFormsValid}
          text="Add an additional family member"
          onClick={addMember}
        />
      </>,
    ],
  };

  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        hasApiCall={true}
        onAPIClick={formContent.onAPIClick}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        disabledButton={formContent.disabledButton}
        isLoading={isLoading}
      />
      {showErrorBanner && (
        <TimedErrorBanner closeFunction={setShowErrorBanner} delay={8000} message={errorMessage} />
      )}
    </>
  );
};

export default AddFamily;
