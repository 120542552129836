import NumberPoint from './bullet-point/BulletPoint';
import './bullet-list.scss';

const BulletList = (props: { items: string[] }) => {
  const { items } = props;
  return (
    <div className="bullet-list-container">
      {items.map((item: string, idx: number) => {
        let number = (idx + 1).toString();
        return (
          <div key={idx} className="bullet-list-item">
            <NumberPoint listNumber={number} />
            <p className="bullet-list-text">{item}</p>
          </div>
        );
      })}
    </div>
  );
};

export default BulletList;
