import './aflac-card.scss';

type CardProps = {
  title: string;
  icon: React.ReactNode;
  text: string;
};
const AflacCard = ({ title, icon, text }: CardProps) => {
  return (
    <div className="aflac-card-container">
        <div className="aflac-card-icon">{icon}</div>
      <h3 className="aflac-card-title">{title}</h3>
      <p className="aflac-card-text">{text}</p>
    </div>
  );
};

export default AflacCard;
