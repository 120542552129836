// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-dropdown-container {
  width: 100%;
  font-family: var(--primary-font);
  color: var(--secondary-text-color);
}
.toggle-dropdown-container .dropdown-item {
  border-bottom: 1px solid #dddddd;
  padding: 10px 5px 10px 0px;
}
.toggle-dropdown-container .dropdown-item .dropdown-title {
  font-family: var(--tertiary-font), sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: var(--secondary-text-color);
  cursor: pointer;
}
.toggle-dropdown-container .dropdown-item .dropdown-title p {
  width: 26ch;
}
.toggle-dropdown-container .dropdown-item .dropdown-title svg {
  flex-shrink: 0;
}
.toggle-dropdown-container .dropdown-item .dropdown-detail {
  font-size: 14px;
  line-height: 27px;
}
.toggle-dropdown-container .dropdown-item .dropdown-detail.show {
  display: initial;
}
.toggle-dropdown-container .dropdown-item .dropdown-detail.hide {
  display: none;
}
@media (min-width: 580px) {
  .toggle-dropdown-container .dropdown-item .dropdown-title p {
    width: 35ch;
  }
  .toggle-dropdown-container .dropdown-item .dropdown-title {
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
  }
}
@media (min-width: 840px) {
  .toggle-dropdown-container .dropdown-item .dropdown-title p {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/forms/toggle-dropdown/toggle-dropdown.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,gCAAA;EACA,kCAAA;AACF;AAAE;EACE,gCAAA;EACA,0BAAA;AAEJ;AAAI;EACE,6CAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kCAAA;EACA,eAAA;AAEN;AAAM;EACE,WAAA;AAER;AACM;EACE,cAAA;AACR;AAGI;EACE,eAAA;EACA,iBAAA;AADN;AAII;EACE,gBAAA;AAFN;AAII;EACE,aAAA;AAFN;AAOE;EACE;IACE,WAAA;EALJ;EAQE;IACE,gCAAA;IACA,gBAAA;IACA,eAAA;IACA,iBAAA;EANJ;AACF;AAUE;EAEI;IACE,WAAA;EATN;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');\n.toggle-dropdown-container {\n  width: 100%;\n  font-family: var(--primary-font);\n  color: var(--secondary-text-color);\n  .dropdown-item {\n    border-bottom: 1px solid #dddddd;\n    padding: 10px 5px 10px 0px;\n\n    .dropdown-title {\n      font-family: var(--tertiary-font), sans-serif;\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      font-weight: 600;\n      font-size: 14px;\n      line-height: 27px;\n      color: var(--secondary-text-color);\n      cursor: pointer;\n\n      p {\n        width: 26ch;\n      }\n\n      svg {\n        flex-shrink: 0;\n      }\n    }\n\n    .dropdown-detail {\n      font-size: 14px;\n      line-height: 27px;\n    }\n\n    .dropdown-detail.show {\n      display: initial;\n    }\n    .dropdown-detail.hide {\n      display: none;\n    }\n  }\n\n  //tablet\n  @media (min-width: 580px) {\n    .dropdown-item .dropdown-title p {\n      width: 35ch;\n    }\n\n    .dropdown-item .dropdown-title {\n      font-family: var(--primary-font);\n      font-weight: 400;\n      font-size: 17px;\n      line-height: 26px;\n    }\n  }\n\n  //desktop\n  @media (min-width: 840px) {\n    .dropdown-item .dropdown-title {\n      p {\n        width: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
