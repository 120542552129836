import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { partnerNames } from '../../constants/CommonConstants';

export const usePartnerStyling = () => {
  let [searchParams] = useSearchParams();

  const [partnerName, setPartnerName] = useState('default');
  let queryPartner = searchParams.get('utm_source') || '';
  let storedPartnerName = sessionStorage.getItem('partner');

  useEffect(() => {
    if (partnerNames[queryPartner]) {
      setPartnerName(partnerNames[queryPartner]);
      sessionStorage.setItem('partner', partnerNames[queryPartner]);
      require(`../../styles/partners/${partnerNames[queryPartner]}.scss`);
      return;
    }
    if (storedPartnerName) {
      setPartnerName(storedPartnerName);
      require(`../../styles/partners/${storedPartnerName}.scss`);
      return;
    }
  }, []);
  return partnerName;
};
