import { Fragment, useState } from 'react';
import './afi-step-component.scss';
import PrimaryButton from '../../buttons/PrimaryButton';
import InfoIcon from '../../../../assets/common/info-icon.svg';
import InfoModal from '../../afi/Info/InfoModal';
import { Disclaimer } from '../../disclaimer/Disclaimer';
import BackButton from '../../buttons/BackButton';

type StepComponentProps = {
  header?: string;
  subHeader: string | null;
  image?: React.ReactNode;
  className?: string;
  fields: React.ReactNode[];
  buttonTxt: string;
  disabledButton?: boolean;
  hideButton?: boolean;
  onSubmit: any;
  error?: string;
  isLoading?: boolean;
  infoIcon: true | null;
  infoContent: { title: string; content: { subtitle?: string; description: string }[] } | null;
  disclaimer?: string | undefined;
};

const AfiStepComponent = ({
  header,
  subHeader,
  image,
  className,
  fields,
  buttonTxt,
  onSubmit,
  disabledButton,
  infoIcon,
  infoContent,
  isLoading = false,
  disclaimer,
}: StepComponentProps) => {
  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const toggleModal = () => setShowModal((prev) => !prev);

  return (
    <div className={`afi-step-component-container ${className ? className : ''} `}>
      {/* Step Heading */}
      <div className="afi-step-component-heading">
        {header && (
          <h1 className="afi-step-component-header">
            {header}
            {infoIcon && <InfoIcon onClick={toggleModal} />}
          </h1>
        )}
        {subHeader && <p className="afi-step-component-subheader">{subHeader}</p>}
      </div>

      {/* Step Image */}
      {image && <div className="afi-step-component-image">{image}</div>}

      {/* Step Fields */}
      <div className="afi-step-component-fields">
        {fields.map((field, idx) => {
          return <Fragment key={idx}>{field}</Fragment>;
        })}
        {disclaimer && (
          <div className="afi-step-component-disclaimer">
            <Disclaimer
              setChecked={setIsChecked}
              checked={isChecked}
              labelText={disclaimer}
              labelAction={() => {
                setIsChecked((prev) => !prev);
              }}
            />
          </div>
        )}
        <div className="buttons-container">
          <PrimaryButton
            text={buttonTxt}
            loading={isLoading}
            onClick={onSubmit}
            disabled={disabledButton || (!!disclaimer && !isChecked)}
          />
          <BackButton />
        </div>
      </div>

      {/* Display Info Modal */}
      {showModal && infoContent && (
        <InfoModal title={infoContent.title} content={infoContent.content} close={toggleModal} />
      )}
    </div>
  );
};

export default AfiStepComponent;
