import { useContext } from 'react';
import StepComponent from '../../../step-component/StepComponent';
import { AutoInsuranceContext } from '../../../../../context/AutoInsuranceContext';
import RadioButtonContainer from '../../../radio-button-container/RadioButtonContainer';
import { useNavigate } from 'react-router';
import { UserContext } from '../../../../../context/UserContext';
import { drivingHistoryImages } from '../../../../../constants/AutoConstants';

export const DrivingHistoryStep = () => {
  const {
    hasTickets,
    setHasTickets,
    hasAccident,
    setHasAccident,
    hasDui,
    setHasDui,
    updateAutoInsuranceStep,
  } = useContext(AutoInsuranceContext);
  const { partnerName, device } = useContext(UserContext);
  const navigate = useNavigate();
  const formContent = {
    header: 'During the past three years have you had....',
    Img: drivingHistoryImages(partnerName, device),
    buttonTxt: 'Next',
    onSubmit: () => {
      updateAutoInsuranceStep('homeowner');
      navigate('/auto/homeowner');
    },
    fields: [
      <RadioButtonContainer
        title="any tickets?"
        options={['Yes', 'No']}
        size="small"
        value={hasTickets}
        handleChange={(val) => setHasTickets(val)}
      />,
      <RadioButtonContainer
        title="at-fault accidents?"
        options={['Yes', 'No']}
        size="small"
        value={hasAccident}
        handleChange={(val) => setHasAccident(val)}
      />,
      <RadioButtonContainer
        title="DUIs?"
        options={['Yes', 'No']}
        size="small"
        value={hasDui}
        handleChange={(val) => setHasDui(val)}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        cardImage={formContent.Img}
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
      />
    </>
  );
};
