import { useContext, useState } from 'react';
import StepComponent from '../../../step-component/StepComponent';
import { useNavigate } from 'react-router';
import RadioButtonContainer from '../../../radio-button-container/RadioButtonContainer';
import { AddInsuranceContext } from '../../../../../context/AddInsuranceContext';
import getUpsellRecommendation from '../../../../../api/upsell';
import { UserContext } from '../../../../../context/UserContext';
import { getUpsellRecommendationLink } from '../../../../../pages/add-insurance/constants';
import { smokeImages } from '../../../../../constants/UpsellConstants';

export const Smoke = () => {
  const { setLastCompletedUpsellStep } = useContext(AddInsuranceContext);
  const { authToken, upsellInfo, setUpsellInfo, device, partnerName } =
    useContext(UserContext);
  const [smokerResp, setSmokerResp] = useState(
    upsellInfo.smoker === true ? 'Yes' : 'No'
  );

  const navigate = useNavigate();
  const formContent = {
    header: 'Do you smoke?',
    buttonTxt: 'Continue',
    Img: smokeImages(partnerName, device),
    onAPIClick: async (onSubmit: Function) => {
      let updatedInfo = {
        ...upsellInfo,
        smoker: smokerResp === 'Yes',
      };
      setUpsellInfo(updatedInfo);
      setLastCompletedUpsellStep('smoke');
      if (!upsellInfo.debt && !upsellInfo.hasDependents) {
        onSubmit(false);
        return;
      }

      try {
        const res = await getUpsellRecommendation(
          { ...updatedInfo },
          authToken
        );
        if (res?.data?.recommendation?.identifier) {
          const recommendationLink = getUpsellRecommendationLink(
            res?.data?.recommendation?.identifier
          );
          onSubmit(true, recommendationLink);
          return;
        }

        onSubmit(false);
      } catch {
        onSubmit(false);
      }
    },
    onSubmit: (flag: boolean, recommendationLink: string) => {
      flag
        ? navigate(`/${recommendationLink}`)
        : navigate('/accidental-death/no-recommendations');
    },
    fields: [
      <RadioButtonContainer
        options={['Yes', 'No']}
        size="large"
        value={smokerResp}
        handleChange={(val: string) => setSmokerResp(val)}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.Img}
        onAPIClick={formContent.onAPIClick}
        hasApiCall={true}
      />
    </>
  );
};
