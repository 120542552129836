import './title.scss';

interface TitleProps {
  text: string;
}

const PageTitle = ({ text }: TitleProps) => {
  return (
    <div
      className="page-title-container"
      dangerouslySetInnerHTML={{ __html: text }}
    ></div>
  );
};

export default PageTitle;
