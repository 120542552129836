import { vehicleMakers } from '../../constants';

export const generateArrayOfYears = (min: number) => {
  let max = new Date().getFullYear();
  let count = 1;
  let years = [];

  for (var i = max; i >= min; i--) {
    years.push({ id: count.toString(), value: i.toString() });
    count++;
  }
  return years;
};

//   generate select optoins from array of values
export const generateSelectOptions = (values: string[]) => {
  return values.map((value, idx) => {
    return {
      id: idx.toString(),
      value: value,
    };
  });
};

export const generateModels = (make: string) => {
  let models = vehicleMakers[make];
  if (!models) {
    return [];
  }
  return models.map((model: string, idx: number) => {
    return {
      id: idx.toString(),
      value: model,
    };
  });
};

export const getOwnerValue = (type: string) => {
  if (type === 'Owned') {
    return 1;
  }
  if (type === 'Financed') {
    return 2;
  }
  if (type === 'Leased') {
    return 3;
  }
  return 1;
};
