import { formatAFIPriceString } from '../../../../services/helpers/AFIHelpers';
import './quote-info.scss';

type QuoteInfoCardProps = {
  title?: string;
  details: any;
  products: any;
};

const QuoteInfoCard = ({ title, details, products }: QuoteInfoCardProps) => {
  return (
    <>
      {title ? (
        <div className="afi-quotes-card-container ">
          <p className="afi-quotes-info-card-title">{title}</p>
          {details.map((elem: any) => (
            <div className="afi-quotes-info-card-detail" key={elem.label}>
              <p className="detail-label">{elem.label}</p>
              <p>{formatAFIPriceString(products[0].coverages[elem.key], elem.key)}</p>
            </div>
          ))}
        </div>
      ) : (
        <>
          {products.map((product: any, index: number) => {
            return (
              <div key={index}>
                <div className="afi-quotes-card-container ">
                  <p className="afi-quotes-info-card-title">
                    {`${product.info.modelYear} ${product.info.manufacturer} ${product.info.model} coverage`}
                  </p>
                  {details.map((elem: any) => (
                    <div className="afi-quotes-info-card-detail" key={elem.label}>
                      <p className="detail-label">{elem.label}</p>
                      <p>{formatAFIPriceString(product.coverages[elem.key], elem.key)}</p>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default QuoteInfoCard;
