import SecondaryButton from './SecondaryButton';
import { useNavigate } from 'react-router-dom';
import './buttons.scss';

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div className="back-button-container">
      <SecondaryButton
        text="Back"
        onClick={() => {
          navigate(-1);
        }}
      />{' '}
    </div>
  );
};

export default BackButton;
