// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canopy-tos-modal-container {
  position: absolute;
  top: 0px;
  background-color: var(--secondary-background-color);
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-y: scroll;
  padding: 32px;
}
.canopy-tos-modal-container header.canopy-tos-modal-header {
  display: flex;
  justify-content: flex-end;
}
.canopy-tos-modal-container header.canopy-tos-modal-header svg {
  cursor: pointer;
}
.canopy-tos-modal-container header.canopy-tos-modal-header svg path {
  fill: var(--secondary-text-color);
}
.canopy-tos-modal-container .canopy-tos-content table {
  margin: 24px 0px;
  display: block;
  overflow-x: auto;
}
@media (min-width: 580px) {
  .canopy-tos-modal-container {
    padding: 64px 72px;
  }
}
@media (min-width: 840px) {
  .canopy-tos-modal-container .canopy-tos-content {
    padding: 64px 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/afi/CanopyTOS/canopy-tos.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,QAAA;EACA,mDAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,yBAAA;AACJ;AAAI;EAIE,eAAA;AADN;AAFM;EACE,iCAAA;AAIR;AAGI;EACE,gBAAA;EACA,cAAA;EACA,gBAAA;AADN;AAKE;EA7BF;IA8BI,kBAAA;EAFF;AACF;AAKE;EACE;IACE,mBAAA;EAHJ;AACF","sourcesContent":[".canopy-tos-modal-container {\n  position: absolute;\n  top: 0px;\n  background-color: var(--secondary-background-color);\n  left: 0px;\n  right: 0px;\n  bottom: 0px;\n  overflow-y: scroll;\n  padding: 32px;\n\n  header.canopy-tos-modal-header {\n    display: flex;\n    justify-content: flex-end;\n    svg {\n      path {\n        fill: var(--secondary-text-color);\n      }\n      cursor: pointer;\n    }\n  }\n\n  .canopy-tos-content {\n    table {\n      margin: 24px 0px;\n      display: block;\n      overflow-x: auto;\n    }\n  }\n  // tablet\n  @media (min-width: 580px) {\n    padding: 64px 72px;\n  }\n\n  //desktop\n  @media (min-width: 840px) {\n    .canopy-tos-content {\n      padding: 64px 100px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
