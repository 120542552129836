import './beneficiary.scss';
import { AddBeneficiary } from '../../../../interfaces/AddTypeInterfaces';
import { BeneficiaryCard } from './BeneficiaryCard';

type BeneficiaryCardContainerProps = {
  beneficiaries: Array<AddBeneficiary>;
  setSelectedBeneficiary: ({
    name,
    id,
  }: {
    name: string;
    id: string;
  }) => void;
  setShowBeneficiaryModal: (_: boolean) => void;
  setBeneficiaries: (_: any) => void;
};

export const BeneficiariesCardContainer = ({
  beneficiaries,
  setBeneficiaries,
  setShowBeneficiaryModal,
  setSelectedBeneficiary,
}: BeneficiaryCardContainerProps) => {
  const onDelete = async (
    index: number,
    name: string,
    beneficiaryId?: string
  ) => {
    setSelectedBeneficiary({
      name,
      id: beneficiaryId || '',
    });
    setShowBeneficiaryModal(true);
  };

  const onSelect = (index: number) => {
    const newBeneficiaries = [...beneficiaries];
    newBeneficiaries[index].isSelected = !newBeneficiaries[index].isSelected;
    setBeneficiaries(newBeneficiaries);
  };

  return (
    <div className="beneficiary-cards-container">
      {beneficiaries.map(
        (beneficiary, index) =>
          beneficiary.firstName !== '' &&
          beneficiary.lastName !== '' && (
            <BeneficiaryCard
              key={`${beneficiary.firstName + index}`}
              beneficiary={beneficiary}
              onDelete={() =>
                onDelete(index, beneficiary.firstName, beneficiary.id)
              }
              onSelect={() => onSelect(index)}
            />
          )
      )}
    </div>
  );
};
