// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-form-container {
  width: 100%;
}
.user-form-container div.select-container .select-field:disabled {
  background-color: rgba(239, 239, 239, 0.3019607843);
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/common/forms/UserForm/user-form.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,mDAAA;EACA,UAAA;AACJ","sourcesContent":[".user-form-container {\n  width: 100%;\n\n  div.select-container .select-field:disabled {\n    background-color: #efefef4d;\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
