// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  background-color: var(--header-background-color);
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  color: var(--header-text-color);
  font-family: var(--primary-font);
  margin-bottom: 16px;
}
.header-container .header-image {
  padding-bottom: 12px;
}
.header-container .header-content {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 28px;
  white-space: nowrap;
}
.header-container .header-content div svg {
  margin-left: 16px;
  cursor: pointer;
}

@media (min-width: 580px) {
  .header-container {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .header-container .header-image {
    padding-bottom: 0px;
  }
  .header-container .header-content {
    font-size: 14px;
  }
}
@media (min-width: 840px) {
  .header-container {
    padding: 24px 64px;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
    min-height: 80px;
  }
  .header-container .header-content div svg {
    margin-left: 24px;
    cursor: pointer;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Header/header.scss"],"names":[],"mappings":"AAAA;EACE,gDAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,8BAAA;EACA,+BAAA;EACA,gCAAA;EACA,mBAAA;AACF;AACE;EACE,oBAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;AAGM;EACE,iBAAA;EACA,eAAA;AADR;;AAQA;EACE;IACE,iBAAA;IACA,oBAAA;EALF;EAOE;IACE,mBAAA;EALJ;EAQE;IACE,eAAA;EANJ;AACF;AAWA;EACE;IACE,kBAAA;IACA,mBAAA;IACA,mBAAA;IACA,mBAAA;IACA,gBAAA;EATF;EAYM;IACE,iBAAA;IACA,eAAA;EAVR;AACF","sourcesContent":[".header-container {\n  background-color: var(--header-background-color);\n  padding: 16px 0px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  color: var(--header-text-color);\n  font-family: var(--primary-font);\n  margin-bottom: 16px;\n\n  .header-image {\n    padding-bottom: 12px;\n  }\n\n  .header-content {\n    display: flex;\n    align-items: center;\n    font-size: 12px;\n    line-height: 28px;\n    white-space: nowrap;\n\n    div {\n      svg {\n        margin-left: 16px;\n        cursor: pointer;\n      }\n    }\n  }\n}\n\n//tablet\n@media (min-width: 580px) {\n  .header-container {\n    padding-top: 24px;\n    padding-bottom: 24px;\n\n    .header-image {\n      padding-bottom: 0px;\n    }\n\n    .header-content {\n      font-size: 14px;\n    }\n  }\n}\n\n//desktop\n@media (min-width: 840px) {\n  .header-container {\n    padding: 24px 64px;\n    flex-direction: row;\n    align-items: center;\n    margin-bottom: 32px;\n    min-height: 80px;\n    .header-content {\n      div {\n        svg {\n          margin-left: 24px;\n          cursor: pointer;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
