// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.afi-quote-card-container {
  padding: 24px 16px 15px;
  border: 1px solid var(--title-color);
  border-radius: 20px;
}
.afi-quote-card-container .afi-quote-card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.afi-quote-card-container .afi-quote-card-logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  width: 80%;
  height: 60px;
  font-weight: 700;
  font-size: 20px;
}
.afi-quote-card-container .afi-quote-card-logo svg {
  cursor: pointer;
}
.afi-quote-card-container .afi-quote-price .price {
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
}
.afi-quote-card-container .afi-quote-price .description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.afi-quote-card-container:not(:last-of-type) {
  margin-bottom: 9px;
}
@media (min-width: 580px) {
  .afi-quote-card-container .afi-quote-card-top {
    align-items: flex-start;
  }
  .afi-quote-card-container .afi-quote-card-top button {
    max-width: 164px;
    position: relative;
    top: 45px;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    height: 46px;
    color: var(--secondary-text-color);
  }
}
@media (min-width: 840px) {
  .afi-quote-card-container {
    padding: 0px;
    border: none;
  }
  .afi-quote-card-container:not(:last-of-type) {
    margin-bottom: 0px;
    margin-right: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/afi/QuoteCard/quote-card.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,oCAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AAEE;EACE,wBAAA;EACA,4BAAA;EACA,yBAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AAAJ;AACI;EACE,eAAA;AACN;AAII;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAFN;AAKI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAHN;AAME;EACE,kBAAA;AAJJ;AAQE;EACE;IACE,uBAAA;EANJ;EAOI;IACE,gBAAA;IACA,kBAAA;IACA,SAAA;IACA,gBAAA;IACA,eAAA;IACA,iBAAA;IACA,YAAA;IACA,kCAAA;EALN;AACF;AAUE;EA3DF;IA4DI,YAAA;IACA,YAAA;EAPF;EAQE;IACE,kBAAA;IACA,kBAAA;EANJ;AACF","sourcesContent":[".afi-quote-card-container {\n  padding: 24px 16px 15px;\n  border: 1px solid var(--title-color);\n  border-radius: 20px;\n\n  .afi-quote-card-top {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n  .afi-quote-card-logo {\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: left;\n    width: 80%;\n    height: 60px;\n    font-weight: 700;\n    font-size: 20px;\n    svg {\n      cursor: pointer;\n    }\n  }\n\n  .afi-quote-price {\n    .price {\n      font-weight: 700;\n      font-size: 20px;\n      line-height: 34px;\n    }\n\n    .description {\n      font-weight: 400;\n      font-size: 14px;\n      line-height: 20px;\n    }\n  }\n  &:not(:last-of-type) {\n    margin-bottom: 9px;\n  }\n\n  //tablet\n  @media (min-width: 580px) {\n    .afi-quote-card-top {\n      align-items: flex-start;\n      button {\n        max-width: 164px;\n        position: relative;\n        top: 45px;\n        font-weight: 700;\n        font-size: 16px;\n        line-height: 30px;\n        height: 46px;\n        color: var(--secondary-text-color);\n      }\n    }\n  }\n\n  //desktop\n  @media (min-width: 840px) {\n    padding: 0px;\n    border: none;\n    &:not(:last-of-type) {\n      margin-bottom: 0px;\n      margin-right: 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
