import React from 'react';
import './coverage-table.scss';
import Tooltip from '../../../tooltip/Tooltip';

type RowType = {
  rowTitle: string;
  subcategory?: boolean;
  prevValue: string;
  newValue: string | React.ReactNode;
  tooltip?: string[];
};

const CanopyCoverageTable = ({
  title,
  tooltip,
  data,
}: {
  title: string;
  tooltip: string[] | null;
  data: RowType[];
}) => {
  const cellType = (index: number) => {
    if (index === 0) {
      return 'first-row';
    }
    if (index === data.length - 1) {
      return 'last-row';
    }
    return '';
  };
  return (
    <div className="canopy-coverage-table">
      <h2 className="coverage-table-title">
        {title} {tooltip && <Tooltip content={tooltip} />}
      </h2>
      <table rules="rows">
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className={`row-title ${row.subcategory ? 'subcategory' : ''}`}>
                <p>{row.rowTitle}</p>
                {row.tooltip && <Tooltip content={row.tooltip} />}
              </td>
              <td className={`second-cell ${cellType(index)}`}>{row.prevValue}</td>
              <td className="gap"></td>
              <td className={`third-cell ${cellType(index)}`}>{row.newValue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CanopyCoverageTable;
