import { createContext, FC, useState } from 'react';
import {
  AddBeneficiary,
  SelectedBeneficiary,
} from '../components/interfaces/AddTypeInterfaces';

type MembersContextType = {
  beneficiariesInfo: AddBeneficiary[];
  setBeneficiariesInfo: React.Dispatch<React.SetStateAction<AddBeneficiary[]>>;
  showBeneficiaryModal: boolean;
  setShowBeneficiaryModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBeneficiary: SelectedBeneficiary | null;
  setSelectedBeneficiary: React.Dispatch<
    React.SetStateAction<SelectedBeneficiary | null>
  >;
};

const MembersContext = createContext<MembersContextType>({
  beneficiariesInfo: [],
  setBeneficiariesInfo: (data: React.SetStateAction<AddBeneficiary[]>) => {},
  showBeneficiaryModal: false,
  setShowBeneficiaryModal: (data: React.SetStateAction<boolean>) => {},
  selectedBeneficiary: null,
  setSelectedBeneficiary: (
    data: React.SetStateAction<SelectedBeneficiary | null>
  ) => {},
});

interface Props {
  children: React.ReactNode;
}

const MembersProvider: FC<Props> = ({ children }) => {
  const [beneficiariesInfo, setBeneficiariesInfo] = useState<AddBeneficiary[]>(
    []
  );
  const [showBeneficiaryModal, setShowBeneficiaryModal] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] =
    useState<SelectedBeneficiary | null>(null);

  return (
    <MembersContext.Provider
      value={{
        beneficiariesInfo,
        setBeneficiariesInfo,
        showBeneficiaryModal,
        setShowBeneficiaryModal,
        selectedBeneficiary,
        setSelectedBeneficiary,
      }}
    >
      {children}
    </MembersContext.Provider>
  );
};

export { MembersContext, MembersProvider };
