// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policy-disclaimer-container {
  padding: 0 24px;
}
.policy-disclaimer-container .policy-disclaimer {
  max-width: 600px;
  font-size: 14px;
  line-height: 28px;
  color: var(--primary-text-color);
  font-family: var(--primary-font);
  margin: 24px auto auto auto;
}
.policy-disclaimer-container .policy-disclaimer .policy-text {
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/policy-disclaimer/policy-disclaimer.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AAAE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,gCAAA;EACA,2BAAA;AAEJ;AAAI;EACE,mBAAA;AAEN","sourcesContent":[".policy-disclaimer-container {\n    padding: 0 24px;\n  .policy-disclaimer {\n    max-width: 600px;\n    font-size: 14px;\n    line-height: 28px;\n    color: var(--primary-text-color);\n    font-family: var(--primary-font);\n    margin: 24px auto auto auto;\n\n    .policy-text {\n      margin-bottom: 24px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
