import { useContext } from 'react';
import { AddInsuranceContext } from '../../../../context/AddInsuranceContext';
import { UserAddress } from '../common-steps/UserAddress';

export const AddUserAddress = () => {
  const { updateAddInsuranceStep } = useContext(AddInsuranceContext);
  return (
    <UserAddress nextStepUrl="/accidental-death/birthday" updateStep={updateAddInsuranceStep('birthday')} />
  );
};
