import './checkbox.scss';
import CheckMark from '../../../../assets/common/check.svg';
import { OptionType } from '../../../interfaces/AFIInterfaces';

type CheckboxProps = {
  label: string;
  value: string;
  options: OptionType[];
  onSelect: Function;
};
const CheckboxOption = ({ label, value, onSelect, options }: CheckboxProps) => {
  const isChecked = () => value === options[1].value;

  const handleToggleOptions = () => {
    // the select options are of "Yes" or "No" type, where the negative answer is the 
    // first one in the array and the default "deselected" answer. On click, the value toggles between the two options
    onSelect(value === options[0].value ? options[1].value : options[0].value);
  };
  return (
    <button className="checkbox-option-container" onClick={handleToggleOptions}>
      <div className="checkbox">{isChecked() && <CheckMark />}</div>
      {label}
    </button>
  );
};

export default CheckboxOption;
