import { string, number } from 'yup';
import { vehicleMakers } from '../../../constants';
import { generateSelectOptionsArray } from '../../../services/helpers/AFIHelpers';
import { questionBuilder } from '../../../services/helpers/AFIQuestionBuilder';
import { generateArrayOfYears } from '../../../services/helpers/selectFieldHelpers';
import { yupMessages, yupValidations } from '../../../services/yupValidations';

export const vehicleYear = questionBuilder(
  'vehicleYear',
  'Year',
  'Year',
  string()
    .oneOf(
      generateArrayOfYears(1983).map((item) => {
        return item.value;
      }),
    )
    .required('Year is required'),
  'year',
  undefined,
  generateArrayOfYears(1983),
);

const carMakersArray: { [key: string]: string } = Object.assign(
  {},
  ...Object.keys(vehicleMakers).map((elem) => ({ [elem]: elem })),
) as { [key: string]: string };

export const make = questionBuilder(
  'vehicleMake',
  'Make',
  'Make',
  string().oneOf(Object.keys(carMakersArray)).required(yupMessages.required),
  'select',
  undefined,
  generateSelectOptionsArray(carMakersArray),
);

export const model = questionBuilder(
  'vehicleModel',
  'Model',
  'Model',
  string().required(yupMessages.required),
  'select',
  undefined,
  generateSelectOptionsArray({"": ""}),
);

export const vin = questionBuilder(
  'vin',
  'Vehicle Identification Number',
  'Vehicle Identification Number',
  string()
    .test('len', 'VIN must have 10 or 17 characters', (value) => value?.length === 17 || value?.length === 10)
    .required(yupMessages.required),
  'input',
);

export const vehicleUsageOptions: { [key: string]: string } = {
  DW: 'Commute',
  SR: 'Artisan',
  BU: 'Business',
  CM: 'Delivery',
  PL: 'Pleasure',
  E: 'School',
  FM: 'Farm',
} as { [key: string]: string };

export const usage = questionBuilder(
  'vehicleUsage',
  'Primary use',
  'Primary use',
  string().oneOf(Object.keys(vehicleUsageOptions)).required(yupMessages.required),
  'select',
  undefined,
  generateSelectOptionsArray(vehicleUsageOptions),
);

export const dailyCommute = questionBuilder(
  'daily_commute',
  'Daily Commute',
  'Distance one way to work',
  number().required(yupMessages.required),
  'input',
);

export const annualMileage = questionBuilder(
  'annualMileage',
  'Annual mileage',
  'Annual mileage',
  yupValidations.annualMileage,
  'input',
);

export const purchaseDate = questionBuilder(
  'purchaseDate',
  'Purchase Date',
  'MM/YYYY',
  yupValidations.minYearRequired(1982),
  'short-date',
);

export const vehicleOwnershipOptions: { [key: string]: string } = {
  OWNED: 'Owned',
  LIEN: 'Financed',
  LEASD: 'Leased',
};

export const ownership = questionBuilder(
  'vehicleOwnership',
  'Ownership',
  'Ownership',
  string().oneOf(Object.keys(vehicleOwnershipOptions)).required(yupMessages.required),
  'select',
  undefined,
  generateSelectOptionsArray(vehicleOwnershipOptions),
);

const registeredToOptions = {
  App: 'Only Applicant',
  Spouse: 'Only Spouse',
  Both: 'Both Applicant and Spouse',
  Child: 'Applicant and/or Spouse and child under age 23',
  Lessor: 'Leasing Company',
  Other: 'Other',
};
export const registeredTo = questionBuilder(
  'registeredTo',
  'Vehicle Registered To',
  'Select',
  string().oneOf(Object.keys(registeredToOptions)).required(yupMessages.required),
  'select',
  undefined,
  generateSelectOptionsArray(registeredToOptions),
);

const principalOperatorOptions = {
  Principal: 'Principal',
  Occasional: 'Occasional',
};

export const principalOperator = questionBuilder(
  'principalOperator',
  'Principal Operator',
  'Select',
  string().oneOf(Object.keys(principalOperatorOptions)).required(yupMessages.required),
  'select',
  undefined,
  generateSelectOptionsArray(principalOperatorOptions),
);

const existingDamageOptions = {
  Yes: 'Yes',
  No: 'No',
};
export const existingDamage = questionBuilder(
  'existingDamage',
  'Significant Damage',
  'Significant Damage',
  string().oneOf(Object.keys(existingDamageOptions)).required(yupMessages.required),
  'select',
  'No',
  generateSelectOptionsArray(existingDamageOptions),
);

const salvagedTitleOptions = {
  Yes: 'Yes',
  No: 'No',
};

export const salvagedTitle = questionBuilder(
  'salvagedTitle',
  'Salvaged Title',
  'Salvaged Title',
  string().oneOf(Object.keys(salvagedTitleOptions)).required(yupMessages.required),
  'radio',
  'No',
  generateSelectOptionsArray(salvagedTitleOptions),
);

const rideSharingOptions = {
  Yes: 'Yes',
  No: 'No',
};

export const rideSharing = questionBuilder(
  'rideSharing',
  'Salvaged Title',
  'Salvaged Title',
  string().oneOf(Object.keys(rideSharingOptions)).required(yupMessages.required),
  'radio',
  'No',
  generateSelectOptionsArray(rideSharingOptions),
);

export const carAlarm = questionBuilder(
  'carAlarm',
  'Car alarm',
  'Car alarm',
  yupValidations.optionRequired,
  'checkbox',
  'None',
  [
    { displayName: 'No', value: 'None' },
    { displayName: 'Yes', value: 'A' },
  ],
);

export const disablingDevice = questionBuilder(
  'disablingDevice',
  'Vehicle disabling device',
  'Vehicle disabling device',
  yupValidations.optionRequired,
  'checkbox',
  'None',
  [
    { displayName: 'No', value: 'None' },
    { displayName: 'Yes', value: 'D' },
  ],
);

export const recoveryDevice = questionBuilder(
  'recoveryDevice',
  'Vehicle recovery device',
  'Vehicle recovery device',
  yupValidations.optionRequired,
  'checkbox',
  'None',
  [
    { displayName: 'No', value: 'None' },
    { displayName: 'Yes', value: 'Y' },
  ],
);

export const steeringLock = questionBuilder(
  'steeringLock',
  'Steering Lock',
  'Steering Lock',
  yupValidations.optionRequired,
  'checkbox',
  'None',
  [
    { displayName: 'No', value: 'None' },
    { displayName: 'Yes', value: 'L' },
  ],
);
