import './quote-info.scss';
import ChevronLeft from '../../../../assets/common/chevron-left.svg';
import { formatAFIPriceString } from '../../../../services/helpers/AFIHelpers';
import { coverageContent } from '../../../../constants/AFIConstants';
import QuoteInfoCard from './QuoteInfoCard';
import PrimaryButton from '../../buttons/PrimaryButton';
import { useContext } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { AfiQuoteType } from '../../../interfaces/AFIInterfaces';
import mixpanelWrapper from '../../../../utils/tools/mixpanel';
import { AnswerFinancialContext } from '../../../../context/AnswerFinancialContext';

type QuoteInfo = {
  closeModal: React.MouseEventHandler<HTMLDivElement>;
  product: AfiQuoteType;
  logo: string;
};

const QuoteInfo = ({ closeModal, product, logo }: QuoteInfo) => {
  const { isMobile, device, trackingId } = useContext(UserContext);
  const { requestId } = useContext(AnswerFinancialContext);

  const onQuoteClick = (brandUrl: string, brandName: string) => {
    mixpanelWrapper.track('AFI Provider Clicked', {
      providerName: brandName,
      userTrackingId: trackingId || '',
      requestId: requestId,
    });
    window.open(brandUrl, '_blank');
  };

  const buttonContainer = (
    <section className="afi-quotes-info-button-container">
      <PrimaryButton
        onClick={() => onQuoteClick(product?.insurer?.returnUrl, product?.insurer?.name)}
        text="View Policy"
      />
    </section>
  );

  return (
    <div className="afi-quote-info-container">
      {device !== 'desktop' && (
        <div className="afi-quote-info-back-button" onClick={closeModal}>
          <ChevronLeft />
          back
        </div>
      )}
      <div className="afi-quotes-info-content">
        <section className="afi-quotes-description">
          <div className="afi-quotes-info-title" style={{ backgroundImage: `url(${logo || ''})` }}>
            {!logo && product?.insurer?.name}
          </div>
          <div className="afi-quotes-price">
            <span className="price">
              {formatAFIPriceString(product?.policyInfo?.fullAmount, product?.policyInfo?.duration)}
            </span>
            <span className="description"> monthly</span>
          </div>
          <ul className="afi-quotes-bullet-list">
            {[
              'Take advantage of Safe Driver',
              'Fast, easy and reliable claims and service available',
              'Bundle your auto and home policies',
            ].map((elem: string, index: number) => (
              <li key={index}>
                <span key={`${index}-${elem}`}>{elem}</span>
              </li>
            ))}
          </ul>
        </section>
        {!isMobile && buttonContainer}
        <section className="afi-quotes-info-cards-container">
          {coverageContent.map((elem, index) => (
            <QuoteInfoCard
              key={index}
              title={elem.title}
              details={elem.details}
              products={product.vehicles}
            />
          ))}
        </section>
        {isMobile && buttonContainer}
      </div>
    </div>
  );
};

export default QuoteInfo;
