// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aflac-placeholder {
  background: #ffffff;
  height: 100vh;
  width: 100vw;
  overflow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/aflac/aflacPlaceholder.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF","sourcesContent":[".aflac-placeholder {\r\n  background: #ffffff;\r\n  height: 100vh;\r\n  width: 100vw;\r\n  overflow: none;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
