import { useContext, useState } from 'react';
import StepComponent from '../../../step-component/StepComponent';
import { useNavigate } from 'react-router';
import RadioButtonContainer from '../../../radio-button-container/RadioButtonContainer';
import { AddInsuranceContext } from '../../../../../context/AddInsuranceContext';
import { UserContext } from '../../../../../context/UserContext';
import { healthImages } from '../../../../../constants/UpsellConstants';

export const Health = () => {
  const { setLastCompletedUpsellStep } = useContext(AddInsuranceContext);
  const { upsellInfo, setUpsellInfo, device, partnerName } = useContext(UserContext);
  const [healthStatusResp, setHealthStatusResp] = useState(upsellInfo.health);
  const navigate = useNavigate();

  const formContent = {
    header: 'How would you self identify your level of health?',
    buttonTxt: 'Continue',
    Img: healthImages(partnerName, device),
    onSubmit: () => {
      let updatedInfo = {
        ...upsellInfo,
        health: healthStatusResp,
      };
      setUpsellInfo(updatedInfo);
      setLastCompletedUpsellStep('health');
      navigate('/accidental-death/smoke');
    },
    fields: [
      <RadioButtonContainer
        options={['Excellent', 'Good', 'Average']}
        size="large"
        value={healthStatusResp}
        handleChange={(val: string) => setHealthStatusResp(val)}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.Img}
      />
    </>
  );
};
