import './calculator.scss';
import SelectField from '../select-field/SelectField';
import PrimaryButton from '../buttons/PrimaryButton';
import { SelectOptionInterface } from '../../interfaces/InputInterfaces';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';

type CalculatorType = {
  title: string;
  values: { termLength: string; amount: string; monthlyCost: string };
  handleYears: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleAmounts: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  calculateFunction: Function;
  calculatorYearOptions: SelectOptionInterface[];
  calculatorAmountOptions: SelectOptionInterface[];
  isLoading?: boolean;
};

const Calculator = ({
  title,
  values,
  calculateFunction,
  calculatorYearOptions,
  calculatorAmountOptions,
  handleYears,
  handleAmounts,
  isLoading = false,
}: CalculatorType) => {
  const { partnerName } = useContext(UserContext);
  const { termLength, amount } = values;
  return (
    <div className="calculator-container">
      <p className="calculator-heading">Try other configurations! </p>
      <p className="calculator-title">{title}</p>
      <div className="section-container">
        <span className="section-label">Amount:</span>
        <SelectField
          name="amount"
          handleChange={handleAmounts}
          value={amount}
          options={calculatorAmountOptions}
        />
      </div>
      <div className="section-container">
        <span className="section-label">Duration:</span>
        <SelectField
          name="termLength"
          handleChange={handleYears}
          value={termLength}
          options={calculatorYearOptions}
        />
      </div>

      <PrimaryButton
        text="Calculate"
        onClick={() => calculateFunction()}
        loading={isLoading}
      />
    </div>
  );
};

export default Calculator;
