import { useContext, useEffect, useState } from 'react';
import './add.scss';
import { policyIdentifier, termsDisclaimer } from './constants';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import VerticalPageLayout from '../../components/layouts/vertical-layout/VerticalLayout';
import { useNavigate } from 'react-router-dom';
import { Disclaimer } from '../../components/common/disclaimer/Disclaimer';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import { DisclaimerList } from '../../components/common/flow-disclaimer/DisclaimerList';
import { safetyNetInfo } from './constants';
import { UserContext } from '../../context/UserContext';
import { AddInsuranceContext } from '../../context/AddInsuranceContext';
import { finalizeAdd } from '../../api/apiFunctions';
import { formatSplit } from '../../services/helpers/AddHelpers';
import { DisclaimerTable } from '../../components/common/flow-disclaimer/DisclaimerList';
import { getSafetyNetPolicyInfo } from './constants';
import { termsImages, termsTitles } from '../../constants/AddConstants';
import { MembersContext } from '../../context/MembersContext';
import mixpanelWrapper from '../../utils/tools/mixpanel';

const TermsPage = () => {
  const {
    device,
    isMobile,
    authToken,
    setShowErrorBanner,
    isAmalgamated,
    partnerName,
    showDisclaimer,
    setShowDisclaimer,
    headerContent,
  } = useContext(UserContext);
  const { progressStep } = useContext(AddInsuranceContext);
  const { beneficiariesInfo } = useContext(MembersContext);
  const [confirmedDisclaimer, setConfirmedDisclaimer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
    //route blocking
    progressStep === 0 && navigate('/accidental-death/start');
  }, []);

  const handleClick = async () => {
    const beneficiaries = beneficiariesInfo
      .filter((ben) => ben.isSelected)
      .map((beneficiary) => ({
        id: beneficiary.id,
        split: formatSplit(beneficiary.percentage),
      }));
    setIsLoading(true);
    try {
      const payload = partnerName
        ? {
            policyIdentifier,
            beneficiaries,
            partnerName,
          }
        : {
            policyIdentifier,
            beneficiaries,
          };
      const res = await finalizeAdd(payload, authToken);
      if (res?.status === 201) {
        navigate('/accidental-death/congrats');
      } else {
        setShowErrorBanner(true);
      }
      return;
    } catch {
      setShowErrorBanner(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="confirmation-page">
        <VerticalPageLayout
          title={termsTitles[device]}
          img={termsImages(partnerName, device)}
          // manually setting headerinfo for info button function
          headerContent={headerContent}
          showInfoIcon={true}
          onIconClick={() => {
            setModalInfo('table');
            setShowDisclaimer(true);
          }}
          content={
            <div className="confirmation-content-container">
              <Disclaimer
                checked={confirmedDisclaimer}
                setChecked={setConfirmedDisclaimer}
                labelText={
                  <>
                    I agree to join the{' '}
                    <u
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalInfo('list');
                        setShowDisclaimer(true);
                      }}
                    >
                      Safety Net Alliance
                    </u>
                    , the non-profit organization that helps people prepare for their future.
                  </>
                }
                labelAction={() => setConfirmedDisclaimer((prev) => !prev)}
              />
              <div className="confirmation-button-container">
                <PrimaryButton
                  text="Activate My Policy"
                  disabled={!confirmedDisclaimer || isLoading}
                  onClick={handleClick}
                  loading={isLoading}
                />
              </div>
              {isMobile && <p className="terms-text"> {termsDisclaimer}</p>}
            </div>
          }
        />
        {!isMobile && <p className="terms-text">{termsDisclaimer}</p>}
      </div>
      {showDisclaimer && (
        <FlowDisclaimer
          headerContent={headerContent}
          className={modalInfo === 'table' ? 'add-table' : 'add-list'}
          subtitle={modalInfo === 'list' ? 'Disclaimer' : ''}
          content={
            modalInfo === 'list' ? (
              <DisclaimerList info={safetyNetInfo} />
            ) : (
              <>
                <DisclaimerTable info={getSafetyNetPolicyInfo(isAmalgamated)} />
                {!isAmalgamated && (
                  <>
                    <p className="disclaimer-table-text">
                      FOR NY RESIDENTS:
                      <br />
                      AD&D policy provides ACCIDENT insurance only. It does NOT provide basic hospital, basic
                      medical or major medical insurance as defined by the New York State Department of
                      Financial Services.
                    </p>
                    <p className="disclaimer-table-text">
                      IMPORTANT NOTICE: THIS POLICY DOES NOT PROVIDE COVERAGE FOR SICKNESS.
                      <br /> <br />
                      Accidental Death & Dismemberment insurance coverage is issued by The Prudential
                      Insurance Company of America, Newark, NJ. The Booklet-Certificate contains all details
                      including any policy exclusions, limitations and restrictions, which may apply. CA COA
                      #1179, NAIC #68241. Contract Series: 83500.
                      <br />
                      1061616-00001-00
                    </p>
                  </>
                )}
              </>
            )
          }
        />
      )}
    </>
  );
};

export default TermsPage;
