// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background-color: var(--primary-background-color);
}
@media (max-width: 580px) {
  html,
  body,
  #root {
    background-color: var(--ios-header-color);
  }
}

.app {
  margin: 0 auto;
  min-height: 100%;
  background-color: var(--primary-background-color);
}

.secondary-cta {
  width: 100%;
  margin-bottom: 16px;
}
.secondary-cta.disabled {
  opacity: 0.5;
}
.secondary-cta .primary-button {
  background-color: var(--secondary-background-color);
  color: var(--secondary-text-color);
  border: solid 2px var(--stroke-color);
}
@media (min-width: 420px) {
  .secondary-cta .primary-button:hover:not(.disabled) {
    background-color: var(--secondary-cta-hover-color);
  }
  .secondary-cta .primary-button:active:not(.disabled) {
    background-color: var(--secondary-cta-active-color);
  }
}

.modal .modal-header {
  height: 42px;
}`, "",{"version":3,"sources":["webpack://./src/styles/global.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,uBAAA;EACA,yBAAA;AACF;;AAEA;;;EAGE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,iDAAA;AACF;AACE;EARF;;;IASI,yCAAA;EAIF;AACF;;AADA;EACE,cAAA;EAEA,gBAAA;EACA,iDAAA;AAGF;;AAAA;EACE,WAAA;EACA,mBAAA;AAGF;AADE;EACE,YAAA;AAGJ;AAAE;EACE,mDAAA;EACA,kCAAA;EACA,qCAAA;AAEJ;AAAI;EACE;IACE,kDAAA;EAEN;EACI;IACE,mDAAA;EACN;AACF;;AAKE;EACE,YAAA;AAFJ","sourcesContent":["* {\n  box-sizing: border-box;\n  margin: 0;\n}\n\nhtml {\n  scroll-behavior: smooth;\n  overscroll-behavior: none;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n  width: 100%;\n  overflow-x: hidden;\n  background-color: var(--primary-background-color);\n\n  @media (max-width: 580px) {\n    background-color: var(--ios-header-color);\n  }\n}\n\n.app {\n  margin: 0 auto;\n\n  min-height: 100%;\n  background-color: var(--primary-background-color);\n}\n\n.secondary-cta {\n  width: 100%;\n  margin-bottom: 16px;\n\n  &.disabled {\n    opacity: 0.5;\n  }\n\n  .primary-button {\n    background-color: var(--secondary-background-color);\n    color: var(--secondary-text-color);\n    border: solid 2px var(--stroke-color);\n\n    @media (min-width: 420px) {\n      &:hover:not(.disabled) {\n        background-color: var(--secondary-cta-hover-color);\n      }\n\n      &:active:not(.disabled) {\n        background-color: var(--secondary-cta-active-color);\n      }\n    }\n  }\n}\n\n.modal {\n  .modal-header {\n    height: 42px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
