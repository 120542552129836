import { useFormik } from 'formik';
import { FormikErrors } from 'formik';
import { getNumberInputs } from '../../../../services/helpers/inputFieldHelpers';
import ErrorIcon from '../../../../assets/common/error-icon.svg';
import './short-date-field.scss';
import { useEffect, useMemo, useRef, KeyboardEvent } from 'react';
import { shortDateSchema } from '../../../schemas/afiSchema';

const ShortDateField = ({
  fieldName,
  fieldError,
  value,
  setDateValue,
  setFieldError,
  setDateFieldTouched,
  validateField,
}: {
  fieldName: string;
  fieldError: string | string[] | FormikErrors<any> | FormikErrors<any>[] | null | undefined;
  value: string;
  setDateValue: Function;
  setFieldError: Function;
  setDateFieldTouched: Function;
  validateField: Function;
}) => {
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);
  const [initialMonthVal, initialYearVal] = value?.split('/');

  const formik = useFormik({
    initialValues: {
      month: initialMonthVal || '',
      year: initialYearVal || '',
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: shortDateSchema,
    onSubmit: () => {},
  });

  const { values, setFieldValue, errors, handleChange, handleBlur, touched, setFieldTouched } = formik;

  useEffect(() => {
    if ((initialMonthVal && initialYearVal) || (touched.month && touched.year)) {
      if (values.month.length >= 2 && values.year.length >= 4 && !errors.month && !errors.year) {
        setDateValue(`${values.month}/${values.year}`);
        setDateFieldTouched();
        validateField(fieldName);
      }
    }
  }, [values, errors, touched]);

  useEffect(() => {
    if (value) {
      if (errors) {
        let key: 'month' | 'year' = 'year';
        errors.month && (key = 'month');
        setFieldError(fieldName, errors[key]);
      }
    }
  }, [values, errors]);

  const hasError = useMemo(() => {
    if ((touched.month && errors.month) || fieldError) {
      return true;
    }
    if (touched.year && values.year && errors.year) {
      return true;
    }
    return false;
  }, [touched, errors, fieldError]);

  const errorMessage = useMemo(() => {
    if (touched.month || touched.year)
      return `${errors.month ? errors.month : ''}${
        touched.year && errors.year ? (errors.month ? '. ' + errors.year : errors.year) : ''
      }`;
  }, [touched, errors, fieldError]);

  const focusMonthRef = () => {
    if (monthRef.current != null) {
      monthRef?.current.focus();
    }
  };

  const focusYearRef = () => {
    if (yearRef.current != null) {
      yearRef?.current.focus();
    }
  };

  return (
    <div className="short-date-container">
      <div className={`double-input-field-container ${hasError ? 'error' : ''}`}>
        <input
          ref={monthRef}
          id="short-date"
          name={`month`}
          type="text"
          inputMode="numeric"
          value={values.month}
          onChange={(e) => {
            const { value } = e.target;
            const max = 2;
            if (value.length >= max || monthRef.current?.selectionStart === 3) {
              setFieldTouched(`month`, true);
              setFieldTouched('year', false);
              focusYearRef();
            }
            if (value.length <= max) {
              getNumberInputs(value) && handleChange(e);
            }
          }}
          onKeyUp={(e) => {
            if (e.key === 'ArrowRight' && monthRef.current?.selectionStart === 2) {
              focusYearRef();
            }
          }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            const max = 2;
            if (
              (e?.nativeEvent?.target as HTMLInputElement)?.value.length >= max &&
              getNumberInputs(e.key) &&
              monthRef.current?.selectionStart === 2
            ) {
              setFieldValue('year', e.key);
            }
          }}
          onBlur={handleBlur}
          placeholder="MM"
          maxLength={3}
          minLength={2}
        />{' '}
        <span className={values.month.length < 2 ? 'placeholder' : ''}>/</span>{' '}
        <input
          ref={yearRef}
          id="short-date"
          name={`year`}
          type="text"
          inputMode="numeric"
          value={values.year}
          onFocus={() => {
            !values.month ? focusMonthRef() : focusYearRef();
          }}
          onChange={(e) => {
            const { value, maxLength } = e.target;
            if (value.length < 1) {
              focusMonthRef();
            }
            getNumberInputs(value) && handleChange(e);
            if (value.length === maxLength) {
              setFieldTouched('year', true);
            }
          }}
          onKeyUp={(e) => {
            if (['Backspace', 'ArrowLeft'].includes(e.key) && yearRef.current?.selectionStart === 0) {
              focusMonthRef();
            }
          }}
          onBlur={handleBlur}
          placeholder="YYYY"
          maxLength={4}
          minLength={4}
        />
        {hasError && <ErrorIcon />}
      </div>
      <div className="error-message">{(hasError && errorMessage) || fieldError?.toString()}</div>
    </div>
  );
};

export default ShortDateField;
