import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../../../components/common/buttons/PrimaryButton';
import { CanopyInsurerType } from '../../../../components/interfaces/AFIInterfaces';
import { canopySuccessImages } from '../../../../constants/AFIConstants';
import { AnswerFinancialContext } from '../../../../context/AnswerFinancialContext';
import { UserContext } from '../../../../context/UserContext';
import './canopy-success.scss';
import BackButton from '../../../../components/common/buttons/BackButton';

const CanopySuccess = ({ currentInsurer }: { currentInsurer: CanopyInsurerType }) => {
  const { device, partnerName } = useContext(UserContext);
  const { setLastStepCompleted } = useContext(AnswerFinancialContext);
  const navigate = useNavigate();

  return (
    <div className="success-container">
      <h1 className="success-title">Connection success!</h1>
      <p className="success-description">
        We imported all your policy information from {currentInsurer.name}. Let’s review it to make sure
        everything looks right
      </p>
      <div className="success-svg-container">{canopySuccessImages(partnerName, device)}</div>
      <div className="success-buttons-container">
        <PrimaryButton
          text="Next"
          onClick={() => {
            setLastStepCompleted('start');
            sessionStorage.removeItem('afi_import_steps');
            sessionStorage.removeItem('afi_sse');
            sessionStorage.removeItem('canopy_user');
            navigate('/full-quote-auto/basics/name');
          }}
        />
        <BackButton />
      </div>
    </div>
  );
};

export default CanopySuccess;
