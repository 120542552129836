import { Routes, Route, Navigate } from 'react-router-dom';
import AfiLoading from '../pages/answer-financial-insurance/AfiLoading';
import AfiIntro from '../pages/answer-financial-insurance/AfiIntro';
import AfiStepNavigation from '../pages/answer-financial-insurance/AfiStepNavigation';
import AfiQuotes from '../pages/answer-financial-insurance/AfiQuotes';
import CanopyLayout from '../components/common/afi/CanopyLayout/CanopyLayout';

const AnswerFinancialRoutes = () => {
  return (
    <Routes>
      <Route path="/full-quote-auto/start" element={<AfiIntro />} />
      <Route path="/full-quote-auto/import/*" element={<CanopyLayout />} />
      <Route path="/full-quote-auto/:sectionId/:stepId" element={<AfiStepNavigation />} />
      <Route path="/full-quote-auto/quotes/loading" element={<AfiLoading />} />
      <Route path="/full-quote-auto/quotes/carriers" element={<AfiQuotes />} />
      <Route path="/full-quote-auto/" element={<Navigate to="/full-quote-auto/start" replace />} />
    </Routes>
  );
};

export default AnswerFinancialRoutes;
