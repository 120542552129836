type DetailCardListTypes = {
  items: string[];
};

const DetailCardList = ({ items }: DetailCardListTypes) => {
  return (
    <ul className="detail-card-list">
      {items.map((item, i) => (
        <li key={i}>{item}</li>
      ))}
    </ul>
  );
};

export default DetailCardList;
