import { useMemo } from 'react';
import './progress-bar.scss';
import { ProgressBar } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { getProgressBars } from '../../../../services/helpers/CommonHelpers';

type ProgressBarProps = {
  title?: string;
  barColor?: string;
  totalSteps: number;
  currentStep: number;
};
const OnboardingProgressBar = ({
  title = 'Step',
  totalSteps,
  currentStep,
}: ProgressBarProps) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 580px)',
  });

  const bars = useMemo(() => {
    return getProgressBars(totalSteps, currentStep);
  }, [totalSteps, currentStep]);

  return (
    <div className="progress-bar-wrapper">
      <div className="progress-bar-container">
        <span className="progress-bar-title">
          {title} {currentStep} of {totalSteps}
        </span>
        {isDesktop ? (
          <div className="segmented-bar-container">
            {bars.map((bar, idx) => {
              return (
                <div
                  key={idx}
                  className={`segmented-progress-bar ${
                    bar === 'filled' ? 'filled' : ''
                  }`}
                  style={{ width: `${100 / totalSteps}%` }}
                ></div>
              );
            })}
          </div>
        ) : (
          <ProgressBar now={(currentStep / totalSteps) * 100} />
        )}
      </div>
    </div>
  );
};

export default OnboardingProgressBar;
