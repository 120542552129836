import { formatAFIPriceString } from '../../../../../services/helpers/AFIHelpers';
import {
  AfiQuoteType,
  CanopyInsurerType,
  PriorPolicyCoverageType,
} from '../../../../interfaces/AFIInterfaces';
import './comparison-cards.scss';

const CanopyComparisonCards = ({
  currentCanopyInsurer,
  priorPolicyCoverage,
  carrier,
}: {
  currentCanopyInsurer: CanopyInsurerType;
  priorPolicyCoverage: PriorPolicyCoverageType;
  carrier: AfiQuoteType;
}) => {
  return (
    <div className="canopy-coverages-comparison">
      <div className="prev-policy">
        <div className="policy-title">{currentCanopyInsurer.name}</div>
        {priorPolicyCoverage?.amount && (
          <div className="policy-price">
            <span>${priorPolicyCoverage?.amount}</span>
            monthly
          </div>
        )}
        <p>Current Plan</p>
      </div>
      <div className="new-policy">
        <div className="policy-title">{carrier.insurer.name}</div>
        <div className="policy-price">
          <span>{formatAFIPriceString(carrier.policyInfo.fullAmount, carrier.policyInfo.duration)}</span>
          monthly
        </div>
        <p>Proposed Coverage</p>
      </div>
    </div>
  );
};

export default CanopyComparisonCards;
