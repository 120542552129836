import { useContext, useEffect, useMemo } from 'react';
import StepComponent from '../../step-component/StepComponent';
import { useNavigate } from 'react-router-dom';
import PercentageField from '../../forms/PercentageField/PercentageField';
import { UserContext } from '../../../../context/UserContext';
import BeneficiaryModal from '../../modals/custom-modals/beneficiary-modal/BeneficiaryModal';
import { handleSplitPercentageAmount } from '../../../../services/helpers/AddHelpers';
import {
  handlePercentageChange,
  handlePercentageBlur,
  handlePercentageFocus,
} from '../../../../services/helpers/inputFieldHelpers';
import { amountsImages } from '../../../../constants/AddConstants';
import { AddBeneficiary, SelectedBeneficiary } from '../../../interfaces/AddTypeInterfaces';

type AmountProps = {
  beneficiariesInfo: AddBeneficiary[];
  setBeneficiariesInfo: React.Dispatch<React.SetStateAction<AddBeneficiary[]>>;
  showBeneficiaryModal: boolean;
  selectedBeneficiary: SelectedBeneficiary | null;
  setShowBeneficiaryModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBeneficiary: React.Dispatch<React.SetStateAction<SelectedBeneficiary | null>>;
  completedAmounts: boolean;
  setCompletedAmounts: React.Dispatch<React.SetStateAction<boolean>>;
  link: string;
  buttonTxt?: string;
  setProgressStep?: Function;
  nextProgressStep?: Number;
  onSpecialSubmit?: Function | null;
};

const Amounts = ({
  beneficiariesInfo,
  setBeneficiariesInfo,
  showBeneficiaryModal,
  setShowBeneficiaryModal,
  selectedBeneficiary,
  setSelectedBeneficiary,
  link,
  completedAmounts,
  setCompletedAmounts,
  buttonTxt,
  setProgressStep,
  nextProgressStep,
  onSpecialSubmit = null,
}: AmountProps) => {
  const { device, partnerName } = useContext(UserContext);
  const navigate = useNavigate();
  const selectedBeneficiaries = useMemo(() => {
    return beneficiariesInfo.filter((ben) => ben.isSelected);
  }, [beneficiariesInfo]);

  //Generate percenatges
  useEffect(() => {
    //don't overwrite the final selection if the user navigates back to this page with the back button
    if (!completedAmounts) {
      setBeneficiariesInfo(handleSplitPercentageAmount(beneficiariesInfo));
    }
  }, []);

  const handleError = () => {
    const distributions = beneficiariesInfo
      .filter((ben) => ben.isSelected)
      .map((member) => {
        const percentageAsNumber = parseInt(member.percentage);
        return percentageAsNumber * 1;
      });

    const total = distributions.reduce((a, b) => a + b, 0);
    return total !== 100;
  };

  const beneficiariesPercentages = useMemo(() => {
    return selectedBeneficiaries.map((beneficiary, index) => (
      <PercentageField
        key={`${beneficiary.firstName}-${index}`}
        name={`${beneficiary.firstName} ${beneficiary.lastName} (${beneficiary.kinship})`}
        percentage={beneficiary.percentage}
        onClick={() => {
          setSelectedBeneficiary({
            name: beneficiary.firstName,
            id: beneficiary.id || '',
          });
          setShowBeneficiaryModal(true);
        }}
        isOnlyBeneficiary={selectedBeneficiaries.length === 1}
        onChange={(e) =>
          handlePercentageChange(e, beneficiary.id || '', beneficiariesInfo, setBeneficiariesInfo)
        }
        handleBlur={(e) =>
          handlePercentageBlur(e, beneficiary.id || '', beneficiariesInfo, setBeneficiariesInfo)
        }
        handleFocus={(e) => handlePercentageFocus(e)}
      />
    ));
  }, [beneficiariesInfo]);

  const beneficiariesImage = useMemo(() => {
    if (selectedBeneficiaries.length <= 2) {
      return amountsImages(partnerName, device);
    }
    return;
  }, [beneficiariesInfo, device]);

  const formContent = {
    header: 'How do you want to split the insurance payout?',
    Image: beneficiariesImage,
    buttonTxt: buttonTxt || 'Continue',
    disabledButton: handleError(),
    onSubmit: () => {
      if (setProgressStep && nextProgressStep) {
        setProgressStep(nextProgressStep);
      }
      onSpecialSubmit && onSpecialSubmit();
      navigate(link);
      setCompletedAmounts(true);
    },
    fields: [
      beneficiariesPercentages,
      <div>{handleError() && <p className="percentage-field-error">Total must be 100%</p>}</div>,
      <>
        {showBeneficiaryModal && (
          <BeneficiaryModal
            setShowBeneficiaryModal={setShowBeneficiaryModal}
            selectedBeneficiary={selectedBeneficiary}
            setSelectedBeneficiary={setSelectedBeneficiary}
            beneficiariesInfo={beneficiariesInfo}
            setBeneficiariesInfo={setBeneficiariesInfo}
            isAmount={true}
          />
        )}
      </>,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.Image}
        disabledButton={formContent.disabledButton}
        className={'amounts'}
      />
    </>
  );
};

export default Amounts;
