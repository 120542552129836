import { Routes, Route } from 'react-router-dom';
import AutoIntro from '../pages/auto-insurance/AutoIntro';
import AutoQuestionsPage from '../pages/auto-insurance/AutoQuestionsPage';
import RecommendationsPage from '../pages/auto-insurance/RecommendationsPage';

type RouteProps = {
  path: string;
};

const AutoInsurance = ({ path }: RouteProps) => {
  return (
    <Routes>
      <Route path="/auto">
        <Route path="start" element={<AutoIntro />} />
        <Route path="results" element={<RecommendationsPage />} />
        <Route path=":stepId" element={<AutoQuestionsPage />} />
      </Route>
    </Routes>
  );
};

export default AutoInsurance;
