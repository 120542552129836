// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes reveal {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.loading-screen-text {
  color: var(--secondary-text-color);
  font-family: var(--primary-font);
  font-style: normal;
  text-align: center;
}
.loading-screen-text span.first-dot, .loading-screen-text span.second-dot, .loading-screen-text span.third-dot {
  opacity: 0;
  animation: reveal 1.5s infinite;
  animation-fill-mode: forwards;
}
.loading-screen-text span.second-dot {
  animation-delay: 0.1s;
}
.loading-screen-text span.third-dot {
  animation-delay: 0.2s;
}
@media (min-width: 580px) {
  .loading-screen-text {
    font-size: 28px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/loading-screen/LoadingMessage/loading-message.scss"],"names":[],"mappings":"AAAA;EACE;IACE,UAAA;EACF;EACA;IACE,UAAA;EACF;EAEA;IACE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF;AAGA;EACE,kCAAA;EACA,gCAAA;EACA,kBAAA;EACA,kBAAA;AADF;AAII;EAGE,UAAA;EACA,+BAAA;EACA,6BAAA;AAJN;AAMI;EACE,qBAAA;AAJN;AAMI;EACE,qBAAA;AAJN;AASE;EAvBF;IAwBI,eAAA;EANF;AACF","sourcesContent":["@keyframes reveal {\n  0% {\n    opacity: 0;\n  }\n  40% {\n    opacity: 1;\n  }\n\n  60% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n\n.loading-screen-text {\n  color: var(--secondary-text-color);\n  font-family: var(--primary-font);\n  font-style: normal;\n  text-align: center;\n\n  span {\n    &.first-dot,\n    &.second-dot,\n    &.third-dot {\n      opacity: 0;\n      animation: reveal 1.5s infinite;\n      animation-fill-mode: forwards;\n    }\n    &.second-dot {\n      animation-delay: 0.1s;\n    }\n    &.third-dot {\n      animation-delay: 0.2s;\n    }\n  }\n\n  //tablet\n  @media (min-width: 580px) {\n    font-size: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
