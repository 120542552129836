// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brand-card {
  height: 64px;
  background-color: var(--secondary-background-color);
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 200px;
  width: 100%;
  margin-bottom: 16px;
}
.brand-card:first-child {
  margin-top: 10px;
}
.brand-card .brand-card-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 64px;
}

.brand-card.disabled {
  opacity: 0.5;
}

.brand-card.selected {
  transform: scale(1.1);
}

.brand-card:hover {
  transform: scale(1.1);
  transition: 0.5s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/common/brand-card/brand-card.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,mDAAA;EACA,mBAAA;EACA,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,wBAAA;EACA,4BAAA;EACA,2BAAA;EACA,YAAA;AAAJ;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,qBAAA;AADF;;AAIA;EACE,qBAAA;EACA,qBAAA;AADF","sourcesContent":[".brand-card {\n  height: 64px;\n  background-color: var(--secondary-background-color);\n  border-radius: 20px;\n  transition: all 0.2s ease-in-out;\n  cursor: pointer;\n  padding-left: 16px;\n  padding-right: 16px;\n  max-width: 200px;\n  width: 100%;\n  margin-bottom: 16px;\n\n  &:first-child {\n    margin-top: 10px;\n  }\n\n  .brand-card-image {\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: center;\n    height: 64px;\n  }\n}\n\n.brand-card.disabled {\n  opacity: 0.5;\n}\n\n.brand-card.selected {\n  transform: scale(1.1);\n}\n\n.brand-card:hover {\n  transform: scale(1.1);\n  transition: 0.5s ease;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
