import { useState, useContext, useEffect } from 'react';
import PrimaryButton from '../../../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../../../components/common/buttons/SecondaryButton';
import { AnswerFinancialContext } from '../../../../context/AnswerFinancialContext';
import './canopy-coverages.scss';
import _ from 'lodash';
import { useFormik } from 'formik';
import { AfiQuoteType } from '../../../../components/interfaces/AFIInterfaces';
import { afiQuotesWrapper, parseAfiPayload, parseAfiResponse } from '../../../../api/apiFunctions';
import { UserContext } from '../../../../context/UserContext';
import {
  findClosestBodilyInjuryValue,
  formatAFIPriceString,
  formatPriorValue,
  getClosestCoverageForCanopy,
  serializeAfiData,
  generateSelectOptionsArray,
  splitBodilyInjuryPrices,
} from '../../../../services/helpers/AFIHelpers';
import { customStateOptions } from '../../../../constants/AfiStateQuestions';
import mixpanelWrapper from '../../../../utils/tools/mixpanel';
import { sortAFIQuotesByPrice } from '../../../../constants/AFIConstants';
import CanopyCardsCarousel from '../../../../components/common/afi/CanopyCoveragesGrid/canopy-cards-carousel/CanopyCardsCarousel';
import CanopyComparisonCards from '../../../../components/common/afi/CanopyCoveragesGrid/canopy-comparison-cards/CanopyComparisonCards';
import CanopyCoverageTable from '../../../../components/common/afi/CanopyCoveragesGrid/canopy-coverage-table/CanopyCoverageTable';
import SelectField from '../../../../components/common/select-field/SelectField';
import { policyStartDate, tooltipTexts } from '../../../../utils/afi/questions/coverage';
import FormQuestions from '../../../../components/common/afi/FormQuestions/FormQuestions';
import { object } from 'yup';
import { yupValidations } from '../../../../services/yupValidations';

const keyOptions: { [key: string]: string } = {
  primaryPropertyDamage: 'property damage',
  uninsuredPropertyDamage: 'property damage',
  underinsuredMotoristsBodilyInjury: 'bodilyInjury',
};

const CanopyCoverages = () => {
  const { AFIAnswersObject, quotes, setQuotes, currentCanopyInsurer, priorPolicyCoverage, requestId } =
    useContext(AnswerFinancialContext);
  const [toggleAdjust, setToggleAdjust] = useState<boolean>(false);
  const [canopyQuotesError, setCanopyQuotesError] = useState(false);
  const [carrier, setCarrier] = useState(quotes[0]);
  const [isLoading, setIsLoading] = useState(false);
  const { authToken, trackingId, device } = useContext(UserContext);
  const [policyStartDateField, setPolicyStartDateField] = useState(_.cloneDeep(policyStartDate));

  const onCarrierClick = (brandUrl: string, brandName: string) => {
    mixpanelWrapper.track('AFI Provider Clicked', {
      providerName: brandName,
      userTrackingId: trackingId || '',
      requestId: requestId,
    });
    localStorage.clear();
    sessionStorage.clear();
    window.open(brandUrl, '_blank');
  };

  const getKeyValue = (initialValue: any, keyName: string, customFields: any) => {
    if (customFields[keyName] || customStateOptions['default'][keyName]) {
      if (initialValue) {
        const closestValue = getClosestCoverageForCanopy(
          initialValue.replaceAll('$', ''),
          Object.keys(customFields[keyName] || customStateOptions['default'][keyName]),
        );
        if (closestValue) {
          return customFields[keyName][closestValue];
        }
      }
      const options = Object.values(customFields[keyName] || customStateOptions['default'][keyName]);
      return options[Math.floor((options.length - 1) / 2)];
    }
    return 'None';
  };

  const getBIKeyValue = (initialValue: any, keyName: string, customFields: any) => {
    if (customFields[keyName] || customStateOptions['default'][keyName]) {
      if (initialValue) {
        const closestValue = findClosestBodilyInjuryValue(
          initialValue.replaceAll('$', ''),
          Object.keys(customFields[keyName] || customStateOptions['default'][keyName]),
        );
        if (closestValue) {
          return customFields[keyName][closestValue];
        }
      }
      const options = Object.values(customFields[keyName] || customStateOptions['default'][keyName]);
      return options[Math.floor((options.length - 1) / 2)];
    }
    return 'None';
  };

  const checkValueWithNoDefaultOptions = (value: string) => {
    if (value && value === 'None') {
      return 'None';
    }
    return value ? 'Covered' : 'None';
  };

  const getInitialValues = (carrierValues: AfiQuoteType) => {
    let currentState = AFIAnswersObject.basics.address.state;
    if (!currentState || !(currentState in customStateOptions)) {
      currentState = 'default';
    }
    const customFields = _.omit(customStateOptions[currentState], ['insuranceCarrier']);
    const valuesObj: { [key: string]: any } = {
      policyStartDate: AFIAnswersObject.coverage['new-coverage'].policyStartDate,
      medPay: getKeyValue(carrierValues?.vehicles[0]?.coverages.medicalPayments, 'medPay', customFields),
      pip: getKeyValue(carrierValues?.vehicles[0]?.coverages.pip, 'pip', customFields),
      uninsuredPropertyDamage: getKeyValue(
        carrierValues?.vehicles[0]?.coverages.uninsuredMotoristPropertyDamage,
        'uninsuredMotoristsPropertyDamage',
        customFields,
      ),
      primaryPropertyDamage: getKeyValue(
        carrierValues?.vehicles[0]?.coverages.propertyDamage,
        'propertyDamageLiability',
        customFields,
      ),
      primaryBodilyInjuryLimit: getBIKeyValue(
        carrierValues?.vehicles[0]?.coverages.bodilyInjury,
        'bodilyInjuryLiability',
        customFields,
      ),
      uninsuredBodilyInjury: getBIKeyValue(
        carrierValues?.vehicles[0]?.coverages.uninsuredMotoristBodilyInjury,
        'uninsuredMotoristsBodilyInjury',
        customFields,
      ),
      underinsuredMotoristsBodilyInjury: getBIKeyValue(
        carrierValues?.vehicles[0]?.coverages.uninsuredMotoristBodilyInjury,
        'uninsuredMotoristsBodilyInjury',
        customFields,
      ),
    };
    AFIAnswersObject?.cars?.forEach((_: any, index: number) => {
      valuesObj[`V${index + 1}_collisionDeductible`] = getKeyValue(
        carrierValues.vehicles[index].coverages.collision,
        'collisionDeductible',
        customFields,
      );
      valuesObj[`V${index + 1}_comprehensiveDeductible`] = getKeyValue(
        carrierValues.vehicles[index].coverages.comprehensive,
        'comprehensiveDeductible',
        customFields,
      );
      valuesObj[`V${index + 1}_coveragesLoanLeaseGap`] = checkValueWithNoDefaultOptions(
        carrierValues.vehicles[index].coverages.loanLeaseGap,
      );
      valuesObj[`V${index + 1}_coveragesRental`] = checkValueWithNoDefaultOptions(
        carrierValues.vehicles[index].coverages.rentalReimbursement,
      );
      valuesObj[`V${index + 1}_coveragesTowing`] = checkValueWithNoDefaultOptions(
        carrierValues.vehicles[index].coverages.towing,
      );
    });
    return valuesObj;
  };

  const formik = useFormik({
    initialValues: getInitialValues(carrier),
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: false,
    validationSchema: object({
      policyStartDate: yupValidations.policyDate,
    }),
    onSubmit: () => {},
  });
  const { values, errors, setValues, handleChange, setFieldTouched, setFieldError } = formik;

  useEffect(() => {
    setValues(getInitialValues(carrier));
  }, [carrier]);

  useEffect(() => {
    setPolicyStartDateField((prev) => ({ ...prev, disabled: !toggleAdjust || isLoading }));
  }, [toggleAdjust, isLoading]);

  const callSequence = async (newData: any) => {
    setIsLoading(true);
    try {
      const xmlData = await parseAfiPayload(authToken, newData);
      const startTime = new Date();
      const quotes = await afiQuotesWrapper(authToken, xmlData.data);
      const endTime = new Date();
      const { urls } = quotes.data;
      const reportingData: { [key: string]: string | number } = {};
      reportingData.firstName = newData.applicant.general.name.firstName;
      reportingData.lastName = newData.applicant.general.name.lastName;
      reportingData.state = newData.applicant.general.address.state;
      reportingData.requestDuration = Number(endTime) - Number(startTime);
      if (urls?.payloadUrl && urls?.responseUrl) {
        reportingData.payloadUrl = urls.payloadUrl;
        reportingData.responseUrl = urls.responseUrl;
      }
      const response = await parseAfiResponse(authToken, quotes.data.data, undefined, reportingData);
      if (['success', 'successwithwarning', 'successwithinfo'].includes(response.data.status)) {
        setQuotes(sortAFIQuotesByPrice(response.data.policies));
        setCarrier(response.data.policies[0]);
        setCanopyQuotesError(false);
      } else {
        setCanopyQuotesError(true);
      }
    } catch (error) {
      setCanopyQuotesError(true);
    } finally {
      setToggleAdjust(false);
      setIsLoading(false);
    }
  };
  const handleOnClickSave = () => {
    if (toggleAdjust) {
      //Policy start date error handling
      const priorPolicyEndDate = new Date(priorPolicyCoverage.priorExpirationDate);
      const newPolicyStartDate = new Date(String(values.policyStartDate));
      const previousDay = new Date();
      previousDay.setDate(previousDay.getDate() - 1);

      if (priorPolicyEndDate >= previousDay) {
        if (newPolicyStartDate > priorPolicyEndDate) {
          const timeDiff = newPolicyStartDate.getTime() - priorPolicyEndDate.getTime();
          // Calculate the number of milliseconds in a day
          const oneDay = 1000 * 60 * 60 * 24;
          const daysDiff = timeDiff / oneDay;
          if (daysDiff > 0) {
            //Show Error
            setFieldError(
              'policyStartDate',
              `Start date must be the same day or earlier than your existing policy end date (${priorPolicyCoverage.priorExpirationDate})`,
            );
            setFieldTouched('policyStartDate', true);
            return;
          }
        }
      }
      const afiObjectCopy = _.cloneDeep(AFIAnswersObject);
      const tmpCoverages = afiObjectCopy.coverage['new-coverage'];
      Object.keys(values).forEach((key) => (tmpCoverages[key] = values[key]));
      _.set(afiObjectCopy, 'coverage["new-coverage"]', tmpCoverages);
      const newData = serializeAfiData(afiObjectCopy);
      callSequence(newData);
      return;
    }
    setToggleAdjust((prev) => !prev);
  };
  const fillGridCell = (toggleAdjust: boolean, name: string, key: string) => {
    let options;
    const formatKey = keyOptions[key] || '';
    const formatedValue = formatAFIPriceString(values[key], formatKey);

    if (name === 'rental' || name === 'towing') {
      options = [
        { displayName: 'Not covered', value: 'None' },
        { displayName: 'Covered', value: 'Covered' },
      ];
    } else {
      options = generateSelectOptionsArray(customStateOptions[AFIAnswersObject.basics.address.state][name]);
    }

    return toggleAdjust ? (
      <SelectField
        disabled={isLoading}
        options={options}
        name={key}
        placeholder={formatedValue}
        handleChange={handleChange}
        value={values[key] === 'None' ? 'Not covered' : values[key]}
      />
    ) : (
      <span className="value">{formatedValue}</span>
    );
  };

  const fillBIGridCell = (key: string, toggleAdjust: boolean, fieldName: string) => {
    const options = generateSelectOptionsArray(
      customStateOptions[AFIAnswersObject.basics.address.state].bodilyInjuryLiability,
    );
    const tmp = splitBodilyInjuryPrices(values[key]);
    const perPerson = formatAFIPriceString(tmp.perPerson, '');
    const perAccident = formatAFIPriceString(tmp.perAccident, '');
    const formatedValue = formatAFIPriceString(values[key], 'bodilyInjury');
    if (toggleAdjust && !fieldName) {
      return (
        <SelectField
          disabled={isLoading}
          options={options}
          name={key}
          placeholder={formatedValue}
          handleChange={(e) => {
            handleChange(e);
          }}
          value={values[key] === 'None' ? 'Not covered' : values[key]}
        />
      );
    }
    if (!toggleAdjust && fieldName) {
      return fieldName === 'perPerson' ? perPerson : perAccident;
    }

    return '';
  };
  const disableSaveButton = () => {
    const hasError = Boolean(errors.policyStartDate);
    return hasError || isLoading;
  };

  return (
    <div className="canopy-coverages-container">
      <section className="coverages-content">
        <h1 className="coverages-title">
          Here’s a comparable policy.{device === 'desktop' && <br />} Adjust coverage and pricing.
        </h1>
        <CanopyCardsCarousel quotes={quotes} selectedCarrier={carrier.insurer.name} setCarrier={setCarrier} />

        <CanopyComparisonCards
          carrier={carrier}
          priorPolicyCoverage={priorPolicyCoverage}
          currentCanopyInsurer={currentCanopyInsurer}
        />
        {canopyQuotesError && <p className="canopy-quotes-error">No quotes for your selection.</p>}

        {/* Primary liability Table */}
        <CanopyCoverageTable
          title={'Primary Liability'}
          tooltip={[
            'This coverage amount covers damages or injuries you cause to others while driving but not your own injuries or damages to your vehicle.',
          ]}
          data={[
            {
              rowTitle: 'Bodily injury (BI) limit',
              prevValue: '',
              newValue: fillBIGridCell('primaryBodilyInjuryLimit', toggleAdjust, ''),
              tooltip: tooltipTexts.primaryBodilyInjury,
            },
            {
              rowTitle: 'Per person',
              subcategory: true,
              prevValue: formatPriorValue(
                priorPolicyCoverage?.vehicles[0]?.bodilyInjuryLiability,
                'bodilyInjury',
                'perPerson',
              ),
              newValue: fillBIGridCell('primaryBodilyInjuryLimit', toggleAdjust, 'perPerson'),
            },
            {
              rowTitle: 'Per accident',
              subcategory: true,
              prevValue: formatPriorValue(
                priorPolicyCoverage?.vehicles[0]?.bodilyInjuryLiability,
                'bodilyInjury',
                'perAccident',
              ),
              newValue: fillBIGridCell('primaryBodilyInjuryLimit', toggleAdjust, 'perAccident'),
            },
            {
              rowTitle: 'Property damage',
              prevValue: formatPriorValue(priorPolicyCoverage?.vehicles[0]?.propertyDamageLiability, ''),
              newValue: fillGridCell(toggleAdjust, 'propertyDamageLiability', 'primaryPropertyDamage'),
              tooltip: tooltipTexts.primaryPropertyDamage,
            },
            {
              rowTitle: 'Medical payments',
              prevValue: formatPriorValue(priorPolicyCoverage?.vehicles[0]?.medPay, ''),
              newValue: fillGridCell(toggleAdjust, 'medPay', 'medPay'),
              tooltip: tooltipTexts.medicalPayments,
            },
          ]}
        />

        {/* Uninsured liability Table */}
        <CanopyCoverageTable
          title={'Uninsured Liability'}
          tooltip={[
            'This coverage amount protects you financially if you’re in an accident caused by an uninsured driver.',
          ]}
          data={[
            {
              rowTitle: 'Bodily injury (BI) limit',
              prevValue: '',
              newValue: fillBIGridCell('uninsuredBodilyInjury', toggleAdjust, ''),
              tooltip: tooltipTexts.uninsuredBodilyInjury,
            },
            {
              rowTitle: 'Per person',
              subcategory: true,
              prevValue: formatPriorValue(
                priorPolicyCoverage?.vehicles[0].uninsuredMotoristsBodilyInjury,
                'bodilyInjury',
                'perPerson',
              ),
              newValue: fillBIGridCell('uninsuredBodilyInjury', toggleAdjust, 'perPerson'),
            },
            {
              rowTitle: 'Per accident',
              subcategory: true,
              prevValue: formatPriorValue(
                priorPolicyCoverage?.vehicles[0].uninsuredMotoristsBodilyInjury,
                'bodilyInjury',
                'perAccident',
              ),
              newValue: fillBIGridCell('uninsuredBodilyInjury', toggleAdjust, 'perAccident'),
            },
            {
              rowTitle: 'Property damage',
              prevValue: formatPriorValue(
                priorPolicyCoverage?.vehicles[0].uninsuredMotoristsPropertyDamage,
                '',
              ),
              newValue: fillGridCell(
                toggleAdjust,
                'uninsuredMotoristsPropertyDamage',
                'uninsuredPropertyDamage',
              ),
              tooltip: tooltipTexts.uninsuredPropertyDamage,
            },
            {
              rowTitle: 'Underinsured bodily injury limit',
              prevValue: formatPriorValue(
                priorPolicyCoverage?.vehicles[0]?.uninsuredMotoristsBodilyInjury,
                '',
              ),
              newValue: fillGridCell(
                toggleAdjust,
                'uninsuredMotoristsBodilyInjury',
                'underinsuredMotoristsBodilyInjury',
              ),
              tooltip: tooltipTexts.underinsuredBodilyInjuryLimit,
            },
          ]}
        />

        {/* Car Coverage Tables */}
        {AFIAnswersObject?.cars[0].details.vehicleMake !== '' &&
          AFIAnswersObject?.cars.map((car: any, index: number) => (
            <CanopyCoverageTable
              title={`${car.details.vehicleYear} ${car.details.vehicleMake}  ${car.details.vehicleModel}`}
              tooltip={null}
              data={[
                {
                  rowTitle: 'Comprehensive deductible',
                  prevValue: formatPriorValue(
                    priorPolicyCoverage?.vehicles[index]?.comprehensiveDeductible ||
                      priorPolicyCoverage?.vehicles[0]?.comprehensiveDeductible,
                    '',
                  ),
                  newValue: fillGridCell(
                    toggleAdjust,
                    'comprehensiveDeductible',
                    `V${index + 1}_comprehensiveDeductible`,
                  ),
                  tooltip: tooltipTexts.comprehensiveDeductible,
                },
                {
                  rowTitle: 'Collision deductible',
                  prevValue: formatPriorValue(
                    priorPolicyCoverage?.vehicles[index]?.collisionDeductible ||
                      priorPolicyCoverage?.vehicles[0]?.collisionDeductible,
                    '',
                  ),
                  newValue: fillGridCell(
                    toggleAdjust,
                    'collisionDeductible',
                    `V${index + 1}_collisionDeductible`,
                  ),
                  tooltip: tooltipTexts.collisionDeductible,
                },
                {
                  rowTitle: 'Rental reimbursements',
                  prevValue: formatPriorValue(
                    priorPolicyCoverage?.vehicles[index]?.coveragesRental ||
                      priorPolicyCoverage?.vehicles[0]?.coveragesRental,
                    '',
                  ),
                  newValue: fillGridCell(toggleAdjust, 'rental', `V${index + 1}_coveragesRental`),
                  tooltip: tooltipTexts.coveragesRental,
                },
                {
                  rowTitle: 'Towing & labor',
                  prevValue: formatPriorValue(
                    priorPolicyCoverage?.vehicles[index]?.coveragesTowing ||
                      priorPolicyCoverage?.vehicles[0]?.coveragesTowing,
                    '',
                  ),
                  newValue: fillGridCell(toggleAdjust, 'towing', `V${index + 1}_coveragesTowing`),
                  tooltip: tooltipTexts.coveragesTowing,
                },
              ]}
            />
          ))}

        <FormQuestions
          formTitle="Policy Start Date"
          titleTooltip={null}
          questions={[policyStartDateField]}
          stepId=""
          formikConfig={formik}
        />

        <div className="coverages-button-container">
          {toggleAdjust ? (
            <SecondaryButton
              text="Save"
              loading={isLoading}
              onClick={handleOnClickSave}
              disabled={disableSaveButton()}
            />
          ) : (
            <SecondaryButton
              text="Adjust"
              loading={isLoading}
              onClick={handleOnClickSave}
              disabled={isLoading}
            />
          )}
          <PrimaryButton
            text="Finish sign up"
            disabled={toggleAdjust}
            onClick={() => onCarrierClick(carrier?.insurer?.returnUrl, carrier?.insurer?.name)}
          />
        </div>
      </section>
    </div>
  );
};

export default CanopyCoverages;
