// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bullet-list-container {
  margin: auto;
  width: 100%;
}
.bullet-list-container .bullet-list-item {
  display: flex;
  align-items: center;
}
.bullet-list-container .bullet-list-item .bullet-point {
  margin-right: 12px;
}
.bullet-list-container .bullet-list-item .bullet-list-text {
  font-family: var(--primary-font);
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  color: var(--primary-text-color);
}
.bullet-list-container .bullet-list-item:not(:last-child) {
  margin-bottom: 16px;
}
@media (min-width: 580px) {
  .bullet-list-container .bullet-list-item .bullet-list-text {
    font-size: 18px;
    line-height: 36px;
  }
}
@media (min-width: 840px) {
  .bullet-list-container .bullet-list-item .bullet-list-text {
    font-size: 24px;
    line-height: 36px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/bullet-list/bullet-list.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;AAEJ;AAAI;EACE,kBAAA;AAEN;AACI;EACE,gCAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,gCAAA;AACN;AAGE;EACE,mBAAA;AADJ;AAKE;EACE;IACE,eAAA;IACA,iBAAA;EAHJ;AACF;AAME;EACE;IACE,eAAA;IACA,iBAAA;EAJJ;AACF","sourcesContent":[".bullet-list-container {\n  margin: auto;\n  width: 100%;\n  .bullet-list-item {\n    display: flex;\n    align-items: center;\n\n    .bullet-point {\n      margin-right: 12px;\n    }\n\n    .bullet-list-text {\n      font-family: var(--primary-font);\n      font-size: 16px;\n      line-height: 30px;\n      font-weight: 700;\n      color: var(--primary-text-color);\n    }\n  }\n\n  .bullet-list-item:not(:last-child) {\n    margin-bottom: 16px;\n  }\n\n  //tablet\n  @media (min-width: 580px) {\n    .bullet-list-item .bullet-list-text {\n      font-size: 18px;\n      line-height: 36px;\n    }\n  }\n\n  @media (min-width: 840px) {\n    .bullet-list-item .bullet-list-text {\n      font-size: 24px;\n      line-height: 36px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
