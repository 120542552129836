import { FormikValues, FormikErrors } from 'formik';
import { useState } from 'react';
import {
  storeDollarValue,
  formatDollarValue,
} from '../../../../services/helpers/inputFieldHelpers';
import './amount-field.scss';

type AmountProps = {
  placeholder: string;
  name: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  value: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
};

const AmountField = ({
  placeholder,
  name,
  onSubmit,
  value,
  setFieldValue,
}: AmountProps) => {
  const [displayValue, setDisplayValue] = useState(
    value ? formatDollarValue(+value) : ''
  );

  const handleChange = (fieldValue: string) => {
    // add dollar sign, replacing the one from onClick
    if (fieldValue.charAt(0) === '') {
      setFieldValue(name, '');
      setDisplayValue('$');
      return;
    }
    if (fieldValue.charAt(0) === '$' && fieldValue.length === 1) {
      setFieldValue(name, '');
      setDisplayValue(`${fieldValue}`);
      return;
    }

    const reg = /^\d+$/;
    let cleanValue = storeDollarValue(fieldValue);
    if (reg.test(cleanValue)) {
      if (cleanValue.charAt(0) === '0' && cleanValue.length > 1) {
        // uncomment and add return if Avram wants to block input after 0
        cleanValue = cleanValue.substring(1);
        // return;
      }
      setFieldValue(name, cleanValue);
      let dollarFormat = formatDollarValue(+cleanValue);
      setDisplayValue(dollarFormat);
    }
  };

  return (
    <div className="amount-field-container">
      <form onSubmit={onSubmit}>
        <input
          value={displayValue}
          name={name}
          type="string"
          inputMode="numeric"
          placeholder={placeholder}
          onChange={(e) => handleChange(e.target.value)}
          onClick={(e) => {
            if (displayValue.length === 0) {
              setDisplayValue('$');
            }
          }}
          onBlur={(e) => {
            if (displayValue.length === 1) {
              setDisplayValue('');
            }
          }}
        />
      </form>
    </div>
  );
};

export default AmountField;
