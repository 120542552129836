import { Routes, Route } from 'react-router-dom';
import LifeRecommendations from '../pages/life-insurance/LifeRecommendations';
import { LifeInsuranceProvider } from '../context/LifeInsuranceContext';
import LifeIntro from '../pages/life-insurance/LifeIntroPage';
import LifeQuestionPage from '../pages/life-insurance/LifeQuestionPage';

const LifeInsurance = () => {
  return (
    <LifeInsuranceProvider>
      <Routes>
        <Route path="/life/start" element={<LifeIntro/>}/>
        <Route path="/life/:stepId" element={<LifeQuestionPage />}/>
        <Route path="/life/summary" element={<LifeRecommendations />} />
      </Routes>
    </LifeInsuranceProvider>
  );
};

export default LifeInsurance;
