import { useContext, useState } from 'react';
import StepComponent from '../../step-component/StepComponent';
import { LifeInsuranceContext } from '../../../../context/LifeInsuranceContext';
import { useNavigate } from 'react-router-dom';
import SelectField from '../../select-field/SelectField';
import { dependentChildrenOptions } from '../../../../constants/LifeConstants';
import { UserContext } from '../../../../context/UserContext';
import { dependentsImages } from '../../../../constants/LifeConstants';

const Dependents = () => {
  const { lifeResponses, setLifeResponses, handleLifeCalculations } =
    useContext(LifeInsuranceContext);
  const { partnerName, device, authToken } = useContext(UserContext);
  const [dependentChildren, setDependentChildren] = useState(
    typeof lifeResponses.dependents === 'number'
      ? `${lifeResponses.dependents}`
      : ''
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (onSubmit: Function) => {
    let childrenAmount = parseInt(dependentChildren);
    if (childrenAmount === 0) {
      let updatedAmounts = {
        ...lifeResponses,
        dependents: childrenAmount,
        education: 0,
      };
      handleLifeCalculations(
        updatedAmounts,
        authToken,
        onSubmit,
        setIsLoading,
        'summary'
      );

      return;
    }
    setLifeResponses((prev) => {
      return {
        ...prev,
        dependents: childrenAmount,
      };
    });
    onSubmit('college');
  };

  const submitCallback = (link: string) => {
    navigate(`/life/${link}`);
  };

  const formContent = {
    header: 'How many dependent children do you have?',
    img: dependentsImages(partnerName, device),
    buttonTxt: 'Continue',
    disableButton: dependentChildren === '',
    onAPIClick: handleSubmit,
    onSubmit: submitCallback,
    fields: [
      <SelectField
        options={dependentChildrenOptions}
        handleChange={(e) => setDependentChildren(e.target.value)}
        handleBlur={() => {}}
        name="dependents"
        placeholder="Enter Children"
        value={dependentChildren}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        hasApiCall={true}
        onAPIClick={formContent.onAPIClick}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.img}
        isLoading={isLoading}
        disabledButton={formContent.disableButton}
      />
    </>
  );
};

export default Dependents;
