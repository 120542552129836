import { useContext, useState } from 'react';
import StepComponent from '../../step-component/StepComponent';
import { LifeInsuranceContext } from '../../../../context/LifeInsuranceContext';
import { useNavigate } from 'react-router-dom';
import AmountField from '../../forms/AmountField/AmountField';
import { useFormik } from 'formik';
import { amountFieldSchema } from '../../../schemas/lifeSchemas';
import { UserContext } from '../../../../context/UserContext';
import { collegeImages } from '../../../../constants/LifeConstants';

const education = '';

const College = () => {
  const { lifeResponses, handleLifeCalculations } =
    useContext(LifeInsuranceContext);
  const { partnerName, device, authToken } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      education:
        typeof lifeResponses.education === 'number'
          ? `${lifeResponses.education}`
          : education,
    },
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    validationSchema: amountFieldSchema,
    onSubmit: () => {},
  });

  const { values, setFieldValue } = formik;

  const handleSubmit = async (onSubmit: Function) => {
    const updatedAmounts = {
      ...lifeResponses,
      education: parseInt(values.education),
    };
    handleLifeCalculations(
      updatedAmounts,
      authToken,
      onSubmit,
      setIsLoading,
      'summary'
    );
  };
  const submitCallback = () => {
    navigate('/life/summary');
  };

  const formContent = {
    header: 'Per child, how much do you plan to contribute to their tuition?',
    img: collegeImages(partnerName, device),
    buttonTxt: 'Continue',
    disableButton: values.education.length < 1,
    onAPIClick: handleSubmit,
    onSubmit: submitCallback,
    fields: [
      <AmountField
        placeholder="Enter Amount"
        name="education"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submitCallback);
        }}
        value={values.education}
        setFieldValue={setFieldValue}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        hasApiCall={true}
        onAPIClick={formContent.onAPIClick}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.img}
        isLoading={isLoading}
        disabledButton={formContent.disableButton}
      />
    </>
  );
};

export default College;
