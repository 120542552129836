import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import SecondaryButton from '../../components/common/buttons/SecondaryButton';
import { DisclaimerTable } from '../../components/common/flow-disclaimer/DisclaimerList';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import VerticalPageLayout from '../../components/layouts/vertical-layout/VerticalLayout';
import { introImages } from '../../constants/AddConstants';
import { AddInsuranceContext } from '../../context/AddInsuranceContext';
import { UserContext } from '../../context/UserContext';
import { getSafetyNetPolicyInfo } from './constants';
import mixpanelWrapper from '../../utils/tools/mixpanel';

const AddAlreadyHave = (props: { isUpsellAvailable: boolean }) => {
  const {
    device,
    isMobile,
    isAmalgamated,
    partnerName,
    showDisclaimer,
    headerContent,
    setShowDisclaimer,
    setIsAddCompleted,
  } = useContext(UserContext);
  const { progressStep, setUpsellProgressStep, setLastCompletedUpsellStep } = useContext(AddInsuranceContext);

  const { isUpsellAvailable } = props;

  const navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
    //route blocking
    progressStep === 0 && navigate('/accidental-death/start');
    setLastCompletedUpsellStep('dependents');
    setIsAddCompleted(true);
  }, []);

  return (
    <>
      <div className="add-already-have-page">
        <VerticalPageLayout
          img={introImages(partnerName, device)}
          headerContent={headerContent}
          showInfoIcon={true}
          onIconClick={() => setShowDisclaimer(true)}
          title={
            isMobile
              ? '<span>You already have</span><br> a policy'
              : device === 'tablet'
              ? 'You already <span>have a policy</span>'
              : 'You alrea<span>dy have a policy</span>'
          }
          description={
            isUpsellAvailable
              ? 'You already have a policy in place. We also have a life insurance policy that we can check to see if you are a good fit for. Are you interested?'
              : 'You already have a policy in place. Feel confident in your coverage and don’t forget to renew when the time comes!'
          }
          content={
            isUpsellAvailable && (
              <div className="add-button-container">
                <PrimaryButton
                  text={'Yes, please!'}
                  onClick={() => {
                    setUpsellProgressStep(1);
                    navigate('/accidental-death/dependents');
                    return;
                  }}
                />
                <SecondaryButton
                  text="No, thanks. I am all set for now."
                  onClick={() => {
                    navigate('/accidental-death/no-thanks');
                  }}
                />
              </div>
            )
          }
        />
        {showDisclaimer && (
          <FlowDisclaimer
            className="add-table"
            headerContent={headerContent}
            content={
              <>
                <DisclaimerTable info={getSafetyNetPolicyInfo(isAmalgamated)} />
                {!isAmalgamated && (
                  <>
                    <p className="disclaimer-table-text">
                      FOR NY RESIDENTS: AD&D policy provides ACCIDENT insurance only. It does NOT provide
                      basic hospital, basic medical or major medical insurance as defined by the New York
                      State Department of Financial Services.
                    </p>
                    <p className="disclaimer-table-text">
                      IMPORTANT NOTICE: THIS POLICY DOES NOT PROVIDE COVERAGE FOR SICKNESS.
                      <br /> <br />
                      Accidental Death & Dismemberment insurance coverage is issued by The Prudential
                      Insurance Company of America, Newark, NJ. The Booklet-Certificate contains all details
                      including any policy exclusions, limitations and restrictions, which may apply. CA COA
                      #1179, NAIC #68241. Contract Series: 83500.
                      <br />
                      1061616-00001-00
                    </p>
                  </>
                )}
              </>
            }
          />
        )}
      </div>
    </>
  );
};

export default AddAlreadyHave;
