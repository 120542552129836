import './aflac-inputs.scss';
import ChevronDown from '../../../../assets/common/chevron-down.svg';
import ChevronUp from '../../../../assets/common/chevron-up.svg';
import CheckMark from '../../../../assets/common/check.svg';
import { KeyboardEventHandler, useEffect, useState } from 'react';

type DropdownProps = {
  label: string;
  options: { [key: string]: string }[];
  value: string;
  placeholder: string;
  onChange: Function;
  closeDropdown: boolean;
  onClick: Function;
  setActiveField: Function;
};

const AflacDropdown = ({
  label,
  options,
  value,
  placeholder,
  onChange,
  onClick,
  setActiveField,
  closeDropdown = false,
}: DropdownProps) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [highlightedItem, setHighlightedItem] = useState(-1);
  const [selectedOption, setSelectedOption] = useState(-1);

  useEffect(() => {
    closeDropdown && setToggleDropdown(false);
  }, [closeDropdown]);

  useEffect(() => {
    //Scroll to options in dropdown if they are out of view
    const outOfViewOption = document.getElementById(`${highlightedItem}`);
    outOfViewOption?.scrollIntoView({ block: 'end' });
  }, [highlightedItem]);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!['Tab'].includes(event.key)) {
      event.preventDefault();
    }

    if (!toggleDropdown && ['Enter', 'ArrowDown'].includes(event.key)) {
      setToggleDropdown(true);
      setActiveField(label);
      selectedOption === -1 && setHighlightedItem((prev) => prev + 1);
    }

    if (['ArrowDown'].includes(event.key)) {
      setHighlightedItem((prev) => Math.min(prev + 1, options.length - 1));
    }

    if (['ArrowUp'].includes(event.key)) {
      setHighlightedItem((prev) => Math.max(prev - 1, 0));
    }

    if (toggleDropdown && ['Enter'].includes(event.key)) {
      if (!highlightedItem) {
        setToggleDropdown(false);
        return;
      }
      onChange(options[highlightedItem], setSelectedOption, highlightedItem);
      setToggleDropdown(false);
      return;
    }

    //Make the dropdown have a filter based on the first letter typed
    const searchedOption = Object.values(options).find(
      (elem) => elem.value[0].toLowerCase() === event.key.toLowerCase(),
    )?.value;
    let searchedOptionIndex = options.findIndex((elem) => elem.value === searchedOption);
    if (searchedOption) setHighlightedItem(searchedOptionIndex);
  };

  return (
    <button
      onKeyDown={handleKeyDown}
      aria-haspopup={true}
      aria-expanded={toggleDropdown || undefined}
      aria-controls={`${placeholder}-select-list`}
      className="aflac-dropdown-container"
      onClick={() => {
        setToggleDropdown((prevState) => !prevState);
        onClick(label);
      }}
    >
      <div className="dropdown-label">
        <h3>{label}</h3> {toggleDropdown ? <ChevronUp /> : <ChevronDown />}
      </div>
      {toggleDropdown && (
        <div className="opened-dropdown" id={`${placeholder}-select-list`} role="menu">
          {options.map((elem, index) => (
            <p
              role="menuitemradio"
              aria-label={elem.value}
              aria-checked={selectedOption === index || undefined}
              key={elem.id}
              id={`${index}`}
              className={`option ${selectedOption === index ? 'selected' : ''} ${
                highlightedItem === index ? 'highlighted-item' : ''
              }`}
              onClick={() => {
                onChange(elem, setSelectedOption, index);
                setHighlightedItem(index);
              }}
            >
              {selectedOption === index && <CheckMark />}
              {elem.value}
            </p>
          ))}
        </div>
      )}
      <div className={`dropdown-value ${value ? '' : 'placeholder'}`}>{value || placeholder}</div>
    </button>
  );
};

export default AflacDropdown;
