import { useContext, useEffect, useMemo } from 'react';
import { LifeInsuranceContext } from '../../context/LifeInsuranceContext';
import { UserContext } from '../../context/UserContext';
import { useParams, useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import TimedErrorBanner from '../../components/common/error-banner/ErrorBanner';
import OnboardingProgressBar from '../../components/common/progress-bar/ProgressBar/ProgressBar';
import Policy from '../../components/common/steps/life-insurance/Policy';
import InsuranceProvider from '../../components/common/steps/life-insurance/InsuranceProvider';
import { getLifeProgressStep } from '../../constants/LifeConstants';
import Income from '../../components/common/steps/life-insurance/Income';
import Dependents from '../../components/common/steps/life-insurance/Dependents';
import Debt from '../../components/common/steps/life-insurance/Debt';
import College from '../../components/common/steps/life-insurance/College';
import Savings from '../../components/common/steps/life-insurance/Savings';
import Header from '../../components/common/Header/Header';
import mixpanelWrapper from '../../utils/tools/mixpanel';

const LifeQuestionPage = () => {
  const { totalSteps, progressStep, setProgressStep } = useContext(LifeInsuranceContext);
  const { showErrorBanner, setShowErrorBanner, setStepAnimationType, headerContent, isUpsellCompleted } =
    useContext(UserContext);

  let { stepId } = useParams();
  const navigate = useNavigate();
  let history = createBrowserHistory({ window });

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
    if (progressStep === 0) {
      navigate('/life/start');
      return;
    }
    let newProgress = progressStep;
    setProgressStep(getLifeProgressStep(stepId || ''));
    newProgress = getLifeProgressStep(stepId || '');

    if (!isUpsellCompleted) {
      navigate('/accidental-death/start');
      return;
    }
    if (newProgress - 1 > progressStep) {
      navigate('/life/start');
      return;
    }
  }, [stepId]);

  useEffect(() => {
    history.listen(({ action }) => {
      action === 'POP' && setStepAnimationType('slide-in_navback');
    });
  }, [history]);

  const checkStep = useMemo(() => {
    switch (stepId) {
      case 'existing-policy':
        return <Policy />;
      case 'source':
        return <InsuranceProvider />;
      case 'income':
        return <Income />;
      case 'savings':
        return <Savings />;
      case 'debt':
        return <Debt />;
      case 'dependents':
        return <Dependents />;
      case 'college':
        return <College />;

      default:
        navigate('/life/start');
        break;
    }
  }, [stepId]);

  return (
    <>
      <div className="auto-questionnaire-page life">
        <Header headerContent={headerContent} showIcon={false} />
        {showErrorBanner && (
          <TimedErrorBanner
            closeFunction={setShowErrorBanner}
            delay={3000}
            message="Uh oh! Something unexpected occurred. We were not able to process your request."
          />
        )}

        <OnboardingProgressBar currentStep={progressStep} totalSteps={totalSteps} title="Question" />
        {checkStep}
      </div>
    </>
  );
};

export default LifeQuestionPage;
