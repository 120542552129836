import { useContext, useEffect, useMemo, useState } from 'react';
import './auto.scss';
import { useNavigate } from 'react-router-dom';
import { AutoInsuranceContext } from '../../context/AutoInsuranceContext';
import { getAutoProviders } from '../../api/auto';
import { UserContext } from '../../context/UserContext';
import Header from '../../components/common/Header/Header';
import DesktopRecommendationLayout from '../../components/layouts/desktop-recommendations-layout/DesktopRecommendationsLayout';
import BrandCard from '../../components/common/brand-card/BrandCard';
import BrandFocusCard from '../../components/common/brand-card/BrandFocusCard';
import { RecommendationInterface } from '../../components/interfaces/VehicleInterfaces';
import { FlowDisclaimer } from '../../components/common/flow-disclaimer/FlowDisclaimer';
import { getAutoDisclaimerText } from '../../services/helpers/AutoHelpers';
import mixpanelWrapper from '../../utils/tools/mixpanel';

const RecommendationsPage = () => {
  const autoContext = useContext(AutoInsuranceContext);
  const { device, headerContent, setShowDisclaimer, showDisclaimer, partnerName, trackingId } =
    useContext(UserContext);

  let navigate = useNavigate();
  const { driverInfo, progressStep } = useContext(AutoInsuranceContext);
  const [isLoadingRecs, setIsLoadingRecs] = useState(true);
  const [recommendations, setRecommendations] = useState<RecommendationInterface[] | []>([]);
  const [hasUserSelectedCard, setHasUserSelectedCard] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<RecommendationInterface | null>(null);

  const handleHover = (_: any, card: RecommendationInterface) => {
    if (!hasUserSelectedCard) {
      setSelectedCard(card);
    }
  };

  const handleClick = (_: any, card: RecommendationInterface) => {
    if (!hasUserSelectedCard) {
      setHasUserSelectedCard(true);
    }
    setSelectedCard(card);
  };

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
    const getProvidersList = async () => {
      try {
        const response = await getAutoProviders({ ...autoContext });
        setRecommendations(response?.data?.result);
        setSelectedCard(response?.data?.result?.[0]);
      } finally {
        setIsLoadingRecs(false);
      }
    };
    if (progressStep < 10) {
      navigate('/auto/start');
    }
    getProvidersList();
  }, []);

  const brandCards = useMemo(() => {
    return recommendations.map((rec, idx) => {
      return (
        <BrandCard
          brandInfo={rec}
          handleHover={handleHover}
          handleClick={handleClick}
          key={idx}
          isSelected={rec.brandName === selectedCard?.brandName}
          isDisabled={hasUserSelectedCard && rec.brandName !== selectedCard?.brandName}
        />
      );
    });
  }, [recommendations, selectedCard, hasUserSelectedCard]);
  return (
    <div className="auto-recommendations-page">
      <Header headerContent={headerContent} onIconClick={() => setShowDisclaimer(true)} showIcon={true} />
      <div className="auto-recommendations-page-container">
        <h1 className="auto-recommendations-page-title"> Congrats {driverInfo?.[0].firstName}! </h1>

        <p className="auto-recommendations-description">
          Based on your responses, these are the carriers we recommend
        </p>
        <div className="recommendations-container">
          {isLoadingRecs ? (
            <p className="results-message">Loading...</p>
          ) : recommendations.length > 0 ? (
            device !== 'desktop' ? (
              recommendations.map((product, index) => (
                <BrandFocusCard brandInfo={product} key={index} trackingId={trackingId} />
              ))
            ) : (
              <DesktopRecommendationLayout
                sideContent={brandCards}
                isScrollable={brandCards.length > 6}
                mainContent={<BrandFocusCard brandInfo={selectedCard} trackingId={trackingId} />}
              />
            )
          ) : (
            <p className="results-message">No recommendations yet ☹</p>
          )}
        </div>
      </div>
      {showDisclaimer && (
        <FlowDisclaimer
          subtitle="Disclaimer"
          headerContent={headerContent}
          content={getAutoDisclaimerText(partnerName)}
        />
      )}
    </div>
  );
};

export default RecommendationsPage;
