import { RecommendationInterface } from '../../interfaces/VehicleInterfaces';
import PrimaryButton from '../buttons/PrimaryButton';
import './brand-focus-card.scss';
import mixpanelWrapper from '../../../utils/tools/mixpanel';

const BrandFocusCard = (props: { brandInfo: RecommendationInterface | null; trackingId?: string }) => {
  const { brandInfo, trackingId } = props;

  const onBrandClick = (brandUrl: string, brandName: string) => {
    mixpanelWrapper.track('Auto Provider Clicked', {
      providerName: brandName,
      userTrackingId: trackingId || '',
    });
    window.open(brandUrl, '_blank');
  };

  return (
    <div className="brand-focus-card">
      <div className="brand-focus-image" style={{ backgroundImage: `url(${brandInfo?.logoUrl})` }}></div>
      <div className="brand-focus-content-container">
        <div className="brand-focus-descritpion">{brandInfo?.title}</div>
        {(brandInfo?.bulletedDescription?.length || 0) > 0 && (
          <ul
            className={`brand-focus-points ${
              (brandInfo?.bulletedDescription?.length || 0) >= 4 ? 'show-border' : ''
            }`}
          >
            {brandInfo?.bulletedDescription.map((description: string, idx: any) => {
              return (
                <li key={idx}>
                  <span>{description}</span>
                </li>
              );
            })}
          </ul>
        )}
        <div className="brand-focus-button-container">
          <PrimaryButton
            onClick={() => onBrandClick(brandInfo?.clickUrl || '', brandInfo?.brandName || '')}
            text="View Rate"
          />
        </div>
      </div>
    </div>
  );
};

export default BrandFocusCard;
