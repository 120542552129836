export const introImages = (partner: string, device: string) => {
  const AutoIntroImg =
    require(`../assets/images/partners/${partner}/auto/${device}/intro.svg`).default;

  return <AutoIntroImg />;
};

export const carImages = (partner: string, device: string) => {
  const CarImg =
    require(`../assets/images/partners/${partner}/auto/${device}/car.svg`).default;

  return <CarImg />;
}

export const vehicleExtraImages = (partner: string, device: string) => {
  const VehicleExtraImg =
    require(`../assets/images/partners/${partner}/auto/${device}/vehicle-extra.svg`).default;

  return <VehicleExtraImg />;
};

export const currentlyInsuredImages = (partner: string, device: string) => {
  const CurrentlyInsuredImg =
    require(`../assets/images/partners/${partner}/auto/${device}/currently-insured.svg`).default;

  return <CurrentlyInsuredImg />;
};

export const currentProviderImages = (partner: string, device: string) => {
  const CurrentProviderImg =
    require(`../assets/images/partners/${partner}/auto/${device}/current-provider.svg`).default;

  return <CurrentProviderImg />;
};

export const drivingHistoryImages = (partner: string, device: string) => {
  if (device !== 'smallMobile' && device !== 'mobile') {
    const DrivingHistoryImg =
      require(`../assets/images/partners/${partner}/auto/${device}/driving-history.svg`).default;
    return <DrivingHistoryImg />;
  }
  return null;
};

export const homeOwnerImages = (partner: string, device: string) => {
  const HomeOwnerImg =
    require(`../assets/images/partners/${partner}/auto/${device}/home-owner.svg`).default;

  return <HomeOwnerImg />;
};

export const creditScoreImages = (partner: string, device: string) => {
  const CreditScoreImg =
    require(`../assets/images/partners/${partner}/auto/${device}/credit-score.svg`).default;

  return <CreditScoreImg />;
};

export const extraDriverImages = (partner: string, device: string) => {
  const ExtraDriverImg =
    require(`../assets/images/partners/${partner}/auto/${device}/extra-driver.svg`).default;

  return <ExtraDriverImg />;
};

export const contactDetailsImages = (partner: string, device: string) => {
  const ContactDetailsImg =
    require(`../assets/images/partners/${partner}/auto/${device}/contact-details.svg`).default;

  return <ContactDetailsImg />;
};

export const ageImages = (partner: string, device: string) => {
  if (device !== 'smallMobile' && device !== 'mobile') {
    const AgeImg =
      require(`../assets/images/partners/${partner}/auto/${device}/age.svg`).default;
    return <AgeImg />;
  }
  
  return null;
};

export const extraContactDetailsImages = (partner: string, device: string) => {
  const ExtraContactDetailsImg =
    require(`../assets/images/partners/${partner}/auto/${device}/extra-contact-details.svg`).default;

  return <ExtraContactDetailsImg />;
};

export const AUTO_DISCLAIMER = `By clicking "Get My Quotes" I give my express consent to receive marketing communications via calls, texts, and emails from 
Second Bank, its marketing and re-marketing network, and up to 5 marketing partners or their affiliates at the above phone number, of which I am the regular user, via auto dialers, prerecorded messages, artificial voice or other electronic means. I am providing this consent even if my number is wireless or VOIP and/or registered on a Do-Not-Call Registry. Message & data rates may apply. I understand that my consent is not a condition of purchase and that I may revoke my consent at any time by visiting our Contact Us page, selecting "Do not call" under the "Opt-Out" section and submitting your information. Also, by providing my information and clicking "Get My Quotes" I am directing cheapest-auto-insurance.com to share my information with their network of partners to help me obtain insurance quotes.`