import './canopy-layout.scss';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AnswerFinancialContext } from '../../../../context/AnswerFinancialContext';
import { UserContext } from '../../../../context/UserContext';
import CurrentInsurer from '../../../../pages/answer-financial-insurance/canopy-connect/CurrentInsurer/CurrentInsurer';
import Header from '../../Header/Header';
import AfiProgressBar from '../../progress-bar/AfiProgressBar/AfiProgressBar';
import SideProgressBar from '../../progress-bar/SideProgressBar/SideProgressBar';
import CanopyLogin from '../../../../pages/answer-financial-insurance/canopy-connect/CanopyLogin/CanopyLogin';
import { canopyLinkUser, getCanopyCarriers, getCanopyPull, getCanopyTOS } from '../../../../api/apiFunctions';
import TimedErrorBanner from '../../error-banner/ErrorBanner';
import { PriorPolicyCoverageType, CanopyUserType, ServerEventType } from '../../../interfaces/AFIInterfaces';
import CanopyLoading from '../../../../pages/answer-financial-insurance/canopy-connect/CanopyLoading/CanopyLoading';
import CanopySuccess from '../../../../pages/answer-financial-insurance/canopy-connect/CanopySuccess/CanopySuccess';
import CanopyFailure from '../../../../pages/answer-financial-insurance/canopy-connect/CanopyFailure/CanopyFailure';
import CanopyOptions from '../../../../pages/answer-financial-insurance/canopy-connect/CanopyOptions/CanopyOptions';
import CanopyCode from '../../../../pages/answer-financial-insurance/canopy-connect/CanopyCode/CanopyCode';
import endpoints from '../../../../api/endpoints';
import { AFI_ERROR_BANNER_DURATION, canopyTimeoutDuration } from '../../../../constants/AFIConstants';
import {
  addNewPolicyData,
  deserializeAfiData,
  deserializePriorPolicyData,
  processPriorPolicyData,
} from '../../../../services/helpers/AFIHelpers';
import mixpanelWrapper from '../../../../utils/tools/mixpanel';

const getDefaultAllowedSteps = () => {
  try {
    return new Map(JSON.parse(sessionStorage.getItem('afi_import_steps') as string));
  } catch (error) {
    return new Map();
  }
};
// Map of type ["SSE_message_code", "navigation_url"]
const allowedSteps = getDefaultAllowedSteps();
const currentSection = 'basics';

const CanopyLayout = () => {
  const { headerContent, isMobile, showErrorBanner, setShowErrorBanner, authToken } = useContext(UserContext);
  const {
    AfiNavigation,
    indexes,
    isCanopy,
    currentCanopyInsurer,
    setIsCanopy,
    setCurrentCanopyInsurer,
    setAFIAnswersObject,
    setPriorPolicyCoverage,
    setCanopyPullId,
    lastStepCompleted,
  } = useContext(AnswerFinancialContext);
  const params = useParams()['*'] as string;
  const navigate = useNavigate();
  const sections = Object.keys(AfiNavigation);
  const [currentStep, setCurrentStep] = useState<string>('name');
  const location = useLocation();

  const [serverEvent, setServerEvent] = useState<ServerEventType | undefined>(
    JSON.parse(sessionStorage.getItem('afi_sse') as string) || '',
  );

  // Carrier list logic
  const [carrierList, setCarrierList] = useState<{
    status: 'error' | 'success' | 'loading';
    data: any[];
  }>({
    status: 'loading',
    data: [],
  });

  const getCarrierList = async () => {
    setCarrierList({
      status: 'loading',
      data: [],
    });
    try {
      const data = await getCanopyCarriers();
      setCarrierList({
        status: 'success',
        data: data.data,
      });
    } catch (error) {
      setShowErrorBanner(true);
      setCarrierList({
        status: 'error',
        data: [],
      });
    }
  };
  // TOS logic
  const [canopyTOS, setCanopyTOS] = useState('');
  const fetchTOS = async () => {
    try {
      const res = await getCanopyTOS();
      setCanopyTOS(res.data.terms_html);
    } catch {}
  };

  const [canopyUser, setCanopyUser] = useState<CanopyUserType | null>(
    JSON.parse(sessionStorage.getItem('canopy_user') as string) || null,
  );

  useEffect(() => {
    !canopyTOS && fetchTOS();
    carrierList.status !== 'success' && getCarrierList();
  }, []);

  // State variable for the event source object. It contains all functions such as .onmessage or .onclose
  // The state variable allows access to the event source outside this useEffect function
  const [eventSource, setEventSource] = useState<EventSource | undefined>(undefined);
  useEffect(() => {
    if (canopyUser) {
      const newEventSource = new EventSource(
        `${endpoints.canopyEventSource}/${canopyUser.pullId}?token=${canopyUser.eventJWT}`,
      );
      setEventSource(newEventSource);
      return () => {
        newEventSource.close();
      };
    }
  }, [canopyUser]);

  // Timeout logic, for every API call, the page needs to receive a server event, if that doesn't happen, the user should be redirected to the failure page
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
  const startNewTimeout = () => {
    const newTimeoutID = setTimeout(() => {
      navigate('/full-quote-auto/import/failed');
    }, canopyTimeoutDuration);
    setTimeoutId(newTimeoutID);
  };

  // Whenever we're creating a new time interval, we need to update the eventSource onMessage function with the new ID
  useEffect(() => {
    if (eventSource) {
      eventSource.onmessage = (e) => {
        if (JSON.parse(e.data)?.status !== 'KEEP_ALIVE') {
          clearTimeout(timeoutId);
          updateSSE(JSON.parse(e.data));
        }
      };
    }
  }, [eventSource, timeoutId]);

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
    // For the SideProgress Bar on tablet and desktop,
    // the first page of canopy will be the "About yourself"("name") step,
    // and all the other steps will be part of "Connect your account"("address")
    // On mobile, progress will always be 100% complete for "Basics" so currentStep is irrelevant
    setCurrentStep(params === 'current-insurer' ? 'name' : 'address');
  }, [params]);

  // Redirect Rules
  useEffect(() => {
    if (lastStepCompleted !== 'start') {
      navigate('/full-quote-auto/start');
    }
    if (
      !getStepElement(params) || // The url was manually edited and does not exist inside the steps object
      (!sessionStorage.getItem('afi_current_insurer') && params !== 'current-insurer') // The user didn't fill the Current Insurer field and is trying to navigate to another step
    ) {
      navigate('/full-quote-auto/import/current-insurer', { replace: true });
    } else if (
      params !== 'current-insurer' &&
      params !== 'login' &&
      params !== 'failed' &&
      !Array.from(allowedSteps.values()).includes(params)
    ) {
      // The user is past the first 2 steps, navigation should be led by serverEvent
      const lastStep = Array.from(allowedSteps.values()).pop() || 'login';
      navigate('/full-quote-auto/import/' + lastStep, { replace: true });
    }
    if (params === 'failed' || params === 'success') {
      eventSource?.close();
    }
  }, [params]);

  const getStepElement = (url: string) => {
    // Will pass elementProps to components that need them (2fa/selection, connecting, success)
    switch (url) {
      case 'current-insurer':
        return (
          <CurrentInsurer
            currentInsurer={currentCanopyInsurer}
            setCurrentInsurer={setCurrentCanopyInsurer}
            carrierList={carrierList}
          />
        );
      case 'login':
        return (
          <CanopyLogin
            currentInsurer={currentCanopyInsurer}
            updateCanopy={setIsCanopy}
            setCanopyUser={setCanopyUser}
            canopyTOS={canopyTOS}
            startNewTimeout={startNewTimeout}
          />
        );
      case 'login/2-factor/selection':
        return (
          <CanopyOptions
            user={canopyUser as CanopyUserType}
            currentInsurer={currentCanopyInsurer}
            options={(serverEvent?.data?.mfa_options as { [key: string]: string }) || {}}
            startNewTimeout={startNewTimeout}
          />
        );
      case 'login/2-factor/code':
        return (
          <CanopyCode
            currentInsurer={currentCanopyInsurer}
            canopyUser={canopyUser as CanopyUserType}
            startNewTimeout={startNewTimeout}
          />
        );
      case 'connecting':
        return <CanopyLoading startNewTimeout={startNewTimeout} />;
      case 'success':
        return <CanopySuccess currentInsurer={currentCanopyInsurer} />;
      case 'failed':
        return <CanopyFailure />;
      default:
        return <></>;
    }
  };

  const updateSSE = async (newEvent: ServerEventType) => {
    setServerEvent(newEvent);
    sessionStorage.setItem('afi_sse', JSON.stringify(newEvent));
    switch (newEvent.status) {
      case 'IDENTITY_VERIFICATION_OPTIONS':
        allowedSteps.set('IDENTITY_VERIFICATION_OPTIONS', 'login/2-factor/selection');
        navigate('/full-quote-auto/import/login/2-factor/selection');
        break;
      case 'IDENTITY_VERIFICATION':
        allowedSteps.clear();
        allowedSteps.set('IDENTITY_VERIFICATION', 'login/2-factor/code');
        navigate('/full-quote-auto/import/login/2-factor/code', { replace: true });
        break;
      case 'NOT_AUTHENTICATED':
      case 'INTERNAL_ERROR':
      case 'PROVIDER_ERROR':
        allowedSteps.clear();
        allowedSteps.set('NOT_AUTHENTICATED', 'failed');
        navigate('/full-quote-auto/import/failed', { replace: true });
        break;
      case 'GETTING_CONSUMERS':
        allowedSteps.set('GETTING_CONSUMERS', 'connecting');
        allowedSteps.delete('IDENTITY_VERIFICATION_OPTIONS');
        allowedSteps.delete('IDENTITY_VERIFICATION');
        navigate('/full-quote-auto/import/connecting', { replace: true });
        break;
      case 'PULLING_DATA':
        const getPullData = async () => {
          try {
            const data = await getCanopyPull(canopyUser?.pullId as string, canopyUser?.canopyJWT as string);
            if (!data?.data?.pullInfo) {
              allowedSteps.clear();
              allowedSteps.set('NOT_AUTHENTICATED', 'failed');
              navigate('/full-quote-auto/import/failure', { replace: true });
            }

            if (data?.data?.pullInfo?.policy) {
            // if (data.data.pullInfo && data.data.pullInfo?.policy) {
              setCanopyPullId(canopyUser?.pullId || '');
              const newData = deserializeAfiData(data.data.pullInfo);
              const newPolicyData = processPriorPolicyData(
                deserializePriorPolicyData(data.data.pullInfo.policy) as PriorPolicyCoverageType,
                newData.basics.address.state,
              );
              await canopyLinkUser(canopyUser?.pullId as string, authToken);
              eventSource?.close();
              setAFIAnswersObject(addNewPolicyData(newPolicyData, newData));
              setPriorPolicyCoverage(newPolicyData as PriorPolicyCoverageType);
              sessionStorage.setItem('canopy_coverages', JSON.stringify(newPolicyData));
              sessionStorage.setItem('afi_answers', JSON.stringify(newData));
              allowedSteps.clear();
              allowedSteps.set('PULLING_DATA', 'success');
              navigate('/full-quote-auto/import/success', { replace: true });
            } else {
              allowedSteps.clear();
              allowedSteps.set('PULLING_DATA', 'success');
              navigate('/full-quote-auto/import/success', { replace: true });
            }
          } catch (error) {
            allowedSteps.clear();
            allowedSteps.set('NOT_AUTHENTICATED', 'failed');
            navigate('/full-quote-auto/import/failure', { replace: true });
          }
          sessionStorage.setItem('afi_import_steps', JSON.stringify(Array.from(allowedSteps.entries())));
        };
        getPullData();
        break;
      default:
        setServerEvent(undefined);
        break;
    }
    sessionStorage.setItem('afi_import_steps', JSON.stringify(Array.from(allowedSteps.entries())));
  };

  useEffect(() => {
    //redirect if isCanopy is false
    if (!isCanopy) {
      navigate('/full-quote-auto/basics/name', { replace: true });
      return;
    }
  }, [isCanopy]);

  return (
    <div className="canopy-layout-container">
      <Header headerContent={headerContent} showIcon={false} />
      {showErrorBanner && (
        <TimedErrorBanner
          closeFunction={setShowErrorBanner}
          delay={AFI_ERROR_BANNER_DURATION}
          message="Uh oh! Something unexpected occurred. We were not able to process your data."
        />
      )}
      {isMobile && (
        <AfiProgressBar
          sections={sections}
          sectionId={currentSection}
          currentSection={currentSection}
          currentStep={currentStep}
          stepId={currentStep}
          indexes={indexes}
          customSetProgress={100 / Object.keys(AfiNavigation).length}
        />
      )}
      <div className="canopy-layout-content-container">
        {!isMobile && (
          <SideProgressBar
            navigationData={AfiNavigation}
            currentStep={currentStep}
            currentSection={currentSection}
          />
        )}
        <div className="canopy-layout-content">{getStepElement(params)}</div>
      </div>
    </div>
  );
};

export default CanopyLayout;
