import { Routes, Route } from 'react-router-dom';
import { VoluntaryAddProvider } from '../context/VoluntaryContext';
import VoluntaryAddQuestionsPage from '../pages/voluntary-add-insurance/VoluntaryQuestionPage';
import VoluntarySuccess from '../pages/voluntary-add-insurance/VoluntarySuccess';
import LoadVoluntaryFlow from '../pages/voluntary-add-insurance/LoadVoluntaryFlow';
import VoluntaryIntro from '../pages/voluntary-add-insurance/VoluntaryIntro';

const VoluntaryAddInsuranceRoutes = () => {
  return (
    <VoluntaryAddProvider>
      <Routes>
        <Route path="/accidental-death-voluntary/loading" element={<LoadVoluntaryFlow />} />
        <Route path="/accidental-death-voluntary/start" element={<VoluntaryIntro />} />
        <Route path="/accidental-death-voluntary/:stepId" element={<VoluntaryAddQuestionsPage />} />
        <Route path="/accidental-death-voluntary/success" element={<VoluntarySuccess />} />
      </Routes>
    </VoluntaryAddProvider>
  );
};

export default VoluntaryAddInsuranceRoutes;
