import './info.scss';
import CloseIcon from '../../../../assets/common/afi/close-button.svg';
import { Fragment } from 'react';

const InfoModal = ({
  title,
  content,
  close,
}: {
  title: string;
  content: { subtitle?: string; description: string }[];
  close: () => void;
}) => {
  return (
    <>
      <div className="info-modal-overlay" onClick={close}>
        <div className="info-modal" onClick={(e) => e.stopPropagation()}>
          <div className="info-modal-header">
            {title && <div className="info-modal-title">{title}</div>}
            <CloseIcon onClick={close} />
          </div>
          <div className="info-modal-content">
            {content.map((item, index) => {
              return (
                <Fragment key={`info-item-${index}`}>
                  {item.subtitle && (
                    <div className="info-modal-content-subtitle" key={index}>
                      {item.subtitle}
                    </div>
                  )}
                  <div className="info-modal-content-description" key={`info-item-desc-${index}`}>
                    {item.description}
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoModal;
