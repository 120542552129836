// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.amount-field-container {
  width: 100%;
  margin-bottom: 20px;
}
.amount-field-container input {
  background: var(--secondary-background-color);
  border: 1px solid var(--form-border-color);
  border-radius: 10px;
  padding: 12px 20px 12px 20px;
  height: 48px;
  width: 100%;
  margin-top: 10px;
  color: var(--secondary-text-color);
  font-size: 16px;
  line-height: 24px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.amount-field-container input:focus {
  outline: none;
}
.amount-field-container ::-webkit-input-placeholder {
  color: var(--placeholder-text-color);
}
.amount-field-container ::placeholder {
  color: var(--placeholder-text-color);
}

@media (min-width: 576px) {
  .amount-field-container input {
    height: 60px;
    padding: 17.5px 20px 18.5px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/forms/AmountField/amount-field.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;AACF;AACE;EACE,6CAAA;EACA,0CAAA;EACA,mBAAA;EACA,4BAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,kCAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;AACJ;AACI;EACE,aAAA;AACN;AAGE;EACE,oCAAA;AADJ;AAIE;EACE,oCAAA;AAFJ;;AAOA;EAEI;IACE,YAAA;IACA,gCAAA;EALJ;AACF","sourcesContent":[".amount-field-container {\n  width: 100%;\n  margin-bottom: 20px;\n\n  input {\n    background: var(--secondary-background-color);\n    border: 1px solid var(--form-border-color);\n    border-radius: 10px;\n    padding: 12px 20px 12px 20px;\n    height: 48px;\n    width: 100%;\n    margin-top: 10px;\n    color: var(--secondary-text-color);\n    font-size: 16px;\n    line-height: 24px;\n    appearance: none;\n    -moz-appearance: none;\n    -webkit-appearance: none;\n\n    &:focus {\n      outline: none;\n    }\n  }\n\n  ::-webkit-input-placeholder {\n    color: var(--placeholder-text-color);\n  }\n\n  ::placeholder {\n    color: var(--placeholder-text-color);\n  }\n}\n\n//tablet\n@media (min-width: 576px) {\n  .amount-field-container {\n    input {\n      height: 60px;\n      padding: 17.5px 20px 18.5px 20px;  \n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
