import { useContext } from 'react';
import StepComponent from '../../../step-component/StepComponent';
import RadioButtonContainer from '../../../radio-button-container/RadioButtonContainer';
import { AutoInsuranceContext } from '../../../../../context/AutoInsuranceContext';
import { UserContext } from '../../../../../context/UserContext';
import { useNavigate } from 'react-router';

// Images
import { currentlyInsuredImages } from '../../../../../constants/AutoConstants';

export const CurrentlyInsured = () => {
  const { isInsured, setIsInsured, updateAutoInsuranceStep } =
    useContext(AutoInsuranceContext);
  const { partnerName, device } = useContext(UserContext);
  const navigate = useNavigate();

  const formContent = {
    header: 'Are you currently insured?',
    cardImg: currentlyInsuredImages(partnerName, device),
    buttonTxt: 'Next',
    onSubmit: () => {
      if (isInsured === 'Yes') {
        updateAutoInsuranceStep('insurer');
        navigate('/auto/insurer');
        return;
      }
      updateAutoInsuranceStep('derogatories');
      navigate('/auto/derogatories');
    },
    fields: [
      <RadioButtonContainer
        options={['Yes', 'No']}
        size="large"
        value={isInsured}
        handleChange={(val) => setIsInsured(val)}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.cardImg}
      />
    </>
  );
};
