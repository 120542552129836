import { useContext, useEffect, useMemo, useState } from 'react';
import SummaryCard from '../../components/common/summary-card/SummaryCard';
import { VoluntaryAddContext } from '../../context/VoluntaryContext';
import PrudentialLogo from '../../assets/common/prudential-logo.png';
import { getPaymentMonths, getSelectedBensText } from '../../services/helpers/VoluntaryHelpers';
import { UserContext } from '../../context/UserContext';
import CustomIframe from '../../components/common/steps/voluntary-add-insurance/payment-iframe/CustomIframe';
import StepComponent from '../../components/common/step-component/StepComponent';
import { PrudentialPolicyDetails } from '../../constants/VoluntaryConstants';
import PolicyDisclaimer from '../../components/common/policy-disclaimer/PolicyDisclaimer';
import { calculateMonthlyPayments } from '../../services/helpers/VoluntaryHelpers';
import './voluntary.scss';
import { createPolicy, generateIframeUrl } from '../../api/voluntaryAdd';
import { POLICY_IDENTIFIERS } from '../../constants/CommonConstants';
import { getAPIErrorMessage } from '../../api/helpers/response';
import { AddBeneficiary } from '../../components/interfaces/AddTypeInterfaces';
import { MemberToPolicyInterface } from '../../components/interfaces/VoluntaryInterfaces';
import { FamilyMemberInterface, UserInterface } from '../../components/interfaces/CommonInterfaces';
import TimedErrorBanner from '../../components/common/error-banner/ErrorBanner';
import { getDisplaySSN } from '../../services/helpers/CommonHelpers';
import SummaryContentContainer from '../../components/common/steps/voluntary-add-insurance/summary-content/SummaryContentContainer';
import ModalCardContainer from '../../components/common/modal-card/ModalCard';

const processBeneficiaries = (beneficiaries: AddBeneficiary[]) => {
  return beneficiaries
    .filter((ben) => {
      return ben.isSelected;
    })
    .map((ben) => {
      const info: MemberToPolicyInterface = {
        id: ben.id || '',
        split: parseInt(ben.percentage),
      };
      return info;
    });
};

const processMembers = (members: (FamilyMemberInterface | UserInterface)[]) => {
  return members.map((elem) => {
    const info: MemberToPolicyInterface = {
      id: elem.id || '',
    };
    return info;
  });
};

const VoluntaryReviewPage = () => {
  const { coveredMembers, selectedCoveragePlan, setPolicyId, voluntaryBeneficiaries } =
    useContext(VoluntaryAddContext);
  const { userInfo, authToken, setShowDisclaimer } = useContext(UserContext);
  const { nextMonth, lastDayOfNextMonth } = getPaymentMonths(new Date());
  const [iframeURL, setIframeURL] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showIframe, setShowIframe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setErrorMessage('');
    const getIframeURL = async () => {
      try {
        const policyResp = await createPolicy(authToken, {
          policyIdentifier: POLICY_IDENTIFIERS.VOLUNTARY_ACCIDENTAL_DEATH,
          coverageAmount: selectedCoveragePlan.coverage,
        });
        setPolicyId(policyResp.data.id);
        const iframeResp = await generateIframeUrl(
          authToken,
          policyResp.data.id,
          processMembers([...coveredMembers]),
          processBeneficiaries(voluntaryBeneficiaries),
        );
        setIframeURL(`${iframeResp.data.iframeURL}&SafetyNetToken=23234234`);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setErrorMessage(getAPIErrorMessage(e));
      }
    };
    getIframeURL();
  }, []);

  const planMembers = [userInfo, ...coveredMembers];
  const cardSectionDetails = [
    { title: '', value: planMembers.length > 1 ? 'Whole Family' : 'Just Me' },
    {
      title: "Who's covered:",
      value: planMembers.map((member) => `${member.firstName} ${member.lastName}`),
    },
    {
      title: 'Plan type:',
      value: `Premium at $${selectedCoveragePlan.monthlyCost.toFixed(2)}`,
    },
    {
      title: 'Beneficiaries:',
      value: getSelectedBensText(voluntaryBeneficiaries),
    },
    {
      title: 'SSN:',
      value: getDisplaySSN(userInfo.ssn!),
    },
  ];

  const produceInfoCard = useMemo(() => {
    return (
      <div className="product-info-card">
        <div className="product-logo">
          <img src={PrudentialLogo} alt="logo" />
        </div>

        <p className="plan-type">Premium Coverage</p>
        {/* Product Info List ul */}
        <ul className="product-detail-list">
          <li className="product-detail">
            <p>
              Transparent accident coverage <br></br>
              <span className="modal-link" onClick={() => setShowDisclaimer(true)}>
                (view details)
              </span>
            </p>
          </li>
          <li className="product-detail">Claims processed quickly</li>
          <li className="product-detail">No exclusions (except criminal activity)</li>
          <li className="product-detail">Use benefit payments however you like</li>
        </ul>
        {/* Coverage Info */}
        <p className="payment-info">
          Coverage begins {nextMonth} 1st. You will be charged monthly for $
          {calculateMonthlyPayments(selectedCoveragePlan.monthlyCost, 3)} starting on {nextMonth}{' '}
          {lastDayOfNextMonth}. Cancel Anytime.
        </p>
      </div>
    );
  }, []);
  const handleIframeModal = () => {
    setShowIframe(true);
    return;
  };

  const formContent = {
    header: 'Your Accident Insurance Recommendation',
    buttonTxt: 'Billing and Payment',
    fields: [
      <SummaryContentContainer
        leftContent={<SummaryCard cardInfo={cardSectionDetails} />}
        rightContent={produceInfoCard}
      />,
    ],
  };

  return (
    <div className="voluntary-review-page">
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        fields={formContent.fields}
        onSubmit={null}
        hasApiCall={true}
        isLoading={isLoading}
        disabledButton={isLoading}
        onAPIClick={handleIframeModal}
      />
      {errorMessage && (
        <TimedErrorBanner closeFunction={setErrorMessage} delay={8000} message={errorMessage} />
      )}
      <PolicyDisclaimer disclaimerComponent={<PrudentialPolicyDetails />} />
      <ModalCardContainer
        title="Payment and Billing"
        showModal={showIframe}
        content={
          <>
            {!isIframeLoaded && (
              <p className="loading-text">
                Hang tight. We are packaging your policy and preparing the payment form.
              </p>
            )}
            <CustomIframe url={iframeURL} setIsLoaded={setIsIframeLoaded} />
          </>
        }
        handleToggle={() => setShowIframe(false)}
      />
    </div>
  );
};

export default VoluntaryReviewPage;
