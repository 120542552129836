const AfiQuotesError = () => {
  return (
    <div className="afi-quotes-error-container">
      <p className="afi-quotes-error title">No carriers found.</p>
      <p className="afi-quotes-error">
        Unfortunately, we were unable to find any carriers to offer you quotes. We are hard at work on
        expanding our selection of carriers. Next time you visit, we hope to provide you a policy that fits
        your needs.
      </p>
    </div>
  );
};

export default AfiQuotesError;
