// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-content-container {
  width: 100%;
  display: flex;
  margin-bottom: 24px;
}
.summary-content-container .left-content,
.summary-content-container .right-content {
  width: 50%;
}
.summary-content-container .left-content {
  margin-right: 16px;
}
@media (max-width: 840px) {
  .summary-content-container {
    flex-direction: column;
    margin-bottom: 16px;
  }
  .summary-content-container .left-content,
  .summary-content-container .right-content {
    width: 100%;
  }
  .summary-content-container .left-content {
    margin-right: initial;
    margin-bottom: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/steps/voluntary-add-insurance/summary-content/summary-content-container.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;;EAEE,UAAA;AACJ;AACE;EACE,kBAAA;AACJ;AAEE;EAbF;IAcI,sBAAA;IACA,mBAAA;EACF;EACE;;IAEE,WAAA;EACJ;EAEE;IACE,qBAAA;IACA,mBAAA;EAAJ;AACF","sourcesContent":[".summary-content-container {\n  width: 100%;\n  display: flex;\n  margin-bottom: 24px;\n\n  .left-content,\n  .right-content {\n    width: 50%;\n  }\n  .left-content {\n    margin-right: 16px;\n  }\n\n  @media (max-width: 840px) {\n    flex-direction: column;\n    margin-bottom: 16px;\n\n    .left-content,\n    .right-content {\n      width: 100%;\n    }\n\n    .left-content {\n      margin-right: initial;\n      margin-bottom: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
