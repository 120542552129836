import DetailCardList from '../components/common/detail-card/DetailCardList';
import ToggleDropdown from '../components/common/forms/toggle-dropdown/ToggleDropdown';

export const lifeIntroImages = (partner: string, device: string) => {
  const LifeIntroImg =
    require(`../assets/images/partners/${partner}/life/${device}/intro.svg`).default;

  return <LifeIntroImg />;
};

export const insurancePolicyImages = (partner: string, device: string) => {
  const InsurancePolicyImg =
    require(`../assets/images/partners/${partner}/life/${device}/policy.svg`).default;

  return <InsurancePolicyImg />;
};

export const policyImages = (partner: string, device: string) => {
  const PolicyImg =
    require(`../assets/images/partners/${partner}/life/${device}/insurance-policy.svg`).default;

  return <PolicyImg />;
};


export const incomeImages = (partner: string, device: string) => {
  const IncomeImg =
    require(`../assets/images/partners/${partner}/life/${device}/income.svg`).default;

  return <IncomeImg />;
};

export const savingsImages = (partner: string, device: string) => {
  const SavingsImg =
    require(`../assets/images/partners/${partner}/life/${device}/savings.svg`).default;

  return <SavingsImg />;
};

export const debtImages = (partner: string, device: string) => {
  const DebtImg =
    require(`../assets/images/partners/${partner}/life/${device}/debt.svg`).default;

  return <DebtImg />;
};

export const collegeImages = (partner: string, device: string) => {
  const CollegeImg =
    require(`../assets/images/partners/${partner}/life/${device}/college.svg`).default;

  return <CollegeImg />;
};

export const dependentsImages = (partner: string, device: string) => {
  const DependentsImg =
    require(`../assets/images/partners/${partner}/life/${device}/dependents.svg`).default;

  return <DependentsImg />;
};

export const lifeIntroTitles: { [key: string]: string } = {
  smallMobile: "<span>Protect</span> what's <br> most important <br> to you",
  mobile: "<span>Protect</span> what's <br> most important <br> to you",
  tablet: "<span>Protect</span> what's most important <br> to you",
  desktop: "<span>Protect</span> what's most important to you",
};

export const defaultLifeResponses = {
  income: null,
  dependents: null,
  debt: null,
  savings: null,
  education: null,
  termLength: 20,
};

export const defaultPolicyAmounts = {
  termLength: 0,
  policyAmount: 0,
  monthlyCost: 0,
};

export const defaultYearOptions = [
  { id: '1', value: '5', displayName: '5 years', isDisabled: false },
  { id: '2', value: '10', displayName: '10 years', isDisabled: false },
  { id: '3', value: '15', displayName: '15 years', isDisabled: false },
  { id: '4', value: '20', displayName: '20 years', isDisabled: false },
  { id: '5', value: '25', displayName: '25 years', isDisabled: false },
  { id: '6', value: '30', displayName: '30 years', isDisabled: false },
];

export const defaultAmountOptions = [
  { id: '1', value: '25000', displayName: '$25,000', isDisabled: false },
  { id: '2', value: '50000', displayName: '$50,000', isDisabled: false },
  { id: '3', value: '100000', displayName: '$100,000', isDisabled: false },
  { id: '4', value: '250000', displayName: '$250,000', isDisabled: false },
  { id: '5', value: '500000', displayName: '$500,000', isDisabled: false },
  { id: '6', value: '750000', displayName: '$750,000', isDisabled: false },
  { id: '7', value: '1000000', displayName: '$1,000,000', isDisabled: false },
  { id: '8', value: '1250000', displayName: '$1,250,000', isDisabled: false },
  { id: '9', value: '1500000', displayName: '$1,500,000', isDisabled: false },
  { id: '10', value: '1750000', displayName: '$1,750,000', isDisabled: false },
  { id: '11', value: '2000000', displayName: '$2,000,000', isDisabled: false },
  { id: '12', value: '2250000', displayName: '$2,250,000', isDisabled: false },
  { id: '13', value: '2500000', displayName: '$2,500,000', isDisabled: false },
  { id: '14', value: '2750000', displayName: '$2,750,000', isDisabled: false },
  { id: '15', value: '3000000', displayName: '$3,000,000', isDisabled: false },
];

export const lifeStepNames = [
  '',
  'existing-policy',
  'source',
  'income',
  'savings',
  'debt',
  'dependents',
  'college',
];

export const getLifeProgressStep = (stepId: string) => {
  return lifeStepNames.indexOf(stepId);
};
const termLifeList = [
  'Are a current U.S resident',
  'Are between 18-64 years old',
  'Are not active in the military or in the process of enlisting',
  'Do not intend to use the policy for business purposes',
  'Are replacing an existing policy and have already surrendered it',
];
export const TermLifeComponent = () => {
  return (
    <>
      <p>
        Term life insurance is a crucial component of any family’s financial
        resilience. Coverage can be used to replace lost income, settle your
        debts, pay for your kids' college tuition, or any other expense should
        the worst happen to you.
      </p>
      <div>
        <p>
          You are eligible for our term life insurance offering through Haven if
          you:
        </p>
        <DetailCardList items={termLifeList} />
      </div>
    </>
  );
};

export const EmployerInsuranceComponent = () => {
  return (
    <>
      <p>
        While many employers offer free or inexpensive group life insurance,
        there’s a good chance that it doesn’t provide enough coverage and that
        it’s only a partial stopgap for financial issues if something happens to
        you. Also, keep in mind that if you change jobs in the future, you will
        lose your employer-based coverage and need to re-apply. Having your own
        policy for full coverage is the superior path to financial resilience.
      </p>
    </>
  );
};

export const SelfInsuranceComponent = () => {
  return (
    <>
      <p>
        Congrats on holding a life insurance policy! Having a policy is an
        important step to protect you and your family.
      </p>

      <p>
        At Second Bank, we’ve partnered up with Haven Life Insurance who has
        some of the most competitive quotes in the market. Check our rates
        compared to what you’re paying, we may be able to save you money!
      </p>
    </>
  );
};

export const nonHolderDetails = [
  {
    title: 'Do I need life insurance?',
    description: `Start with this question: Does anyone financially depend on you? This could include children, a spouse, and even your parents.  If you have others who rely on you, debt that someone would inherit, or your funeral would be a financial burden to others, life insurance can work to ensure they have the monetary resources needed when you’re gone.<br><br>If you have no financial dependents, we do not recommend life insurance at this time.  If you see a partner or kids as part of your future, you may need it in the future.`,
  },
  {
    title: 'How much does it cost?',
    description: `In short, the cost of a life insurance policy varies widely. Policies can cost a few dollars a month or hundreds of dollars a month depending on your situation and needs. Your approval and final rate will be determined based on your age, personal health, and risk factors. You can get a price estimate using our quote tool.`,
  },
  {
    title: 'How long will the application take, and what should I expect?',
    description: `The application process takes about an hour or less. Haven Life’s application is a fully digital experience which you can complete in your web browser. You will be asked a series of questions about your health and life situation that may seem overly personal at times, but the questions asked are used for underwriting and are standard in life insurance. 
    <br>
    <br>
    After you complete the questionnaire, your selections may indicate the need for a medical exam to complete the underwriting process. Although this seems inconvenient, Haven Life makes it easy to schedule, and you could be eligible for a policy at a lower cost.
    <br>
    <br>
    You will receive a decision based on your full submission. Denial of coverage and policy premiums are based on different factors that include: health conditions like diabetes or obesity, nicotine usage, age, and criminal history or DUIs. If approved, you can always choose to accept the offer or look for another insurance provider. If you decide to purchase the policy, you will be directed through a simple digital payment and signature process to finalize your policy details and kick off your coverage!
    `,
  },
  {
    title: 'Who is Haven Life, and why do you recommend them?',
    description: ` Haven Life is a platform bringing life insurance out of antiquity and into the digital era.
    <br>
    <br>
    Among the many reasons we recommend Haven, their affordability, digital experience, and high degree of trust are on top of the list.
    <br>
    <br>
    In terms of affordability, a $250,000 20-year Haven Term policy starts at just $14.99/mo (quote as of 6/18/19, based on a 30-year old female in excellent health). That is much cheaper than other providers in the marketplace.
    <br>
    <br>
    Our members appreciate easy, digital experiences. With Haven’s platform, you can apply online without having to speak to anyone. In many cases, you can find out immediately if you’re approved for coverage. And if you need help during the application process, they provide phone support and live chat options.
    <br>
    <br>
    Lastly, Haven Life provides coverage our members can trust. Haven’s term life insurance coverage is issued by MassMutual or C.M. Life, a subsidiary of MassMutual. Both companies have an A++ A.M. Best rating for financial strength and claims-paying ability. Also, MassMutual was named 2021 World's Most Ethical Companies® by Ethisphere and received a 100 score on the Human Rights Campaign Corporate Equality Index (2021).`,
  },
  {
    title: 'How do claims work?',
    description: `
    In the event that you pass away during your coverage period, a beneficiary on the policy will need to file a claim with the life insurance company. When they contact the policy provider, they need to provide proof of death, as well as their status as a beneficiary. To process the claim, the insurance company will need: the policyholder’s date of birth, a government-issued death certificate, and their Social Security number.  Any additional details of the policy, like the policy number, are also helpful.
    <br>
    <br>
    As the policyholder, you can make the claims process easier on your loved ones by letting the beneficiary/ies know the policy details, including policy number, carrier information, your date of birth, and Social Security number. Having this information on-hand for later reference makes the claims process smoother when your loved ones are already dealing with loss.
    <br>
    <br>
    When the claim has been approved, most companies send payouts directly to a beneficiary’s bank account. Claims are often paid out within a few days, sometimes within 24 hours! It’s important to know that banks will sometimes hold the money 1-2 days before fully depositing the amount due to the unusual size of the lump sum payment.
    `,
  },
];

export const holderDetails = [
  {
    title: 'How long will the application take, and what should I expect?',
    description: `Haven Life’s application is a fully digital experience which you can complete in your web browser.  You will be asked a series of questions about your health and life situation that should take about an hour or less. These questions may seem overly personal at times, but the questions asked are used for underwriting and are standard in life insurance.
    <br>
    <br>
    After you complete the questionnaire, you might need to schedule a medical exam based on your selections to complete the underwriting process. Although this seems inconvenient, Haven Life makes it easy to schedule, and the insurer may be able to offer you a policy at a lower cost.
    <br>
    <br>
    You will be given a decision based on your full submission. Risk factors for denial or higher rates vary based on different factors and include: health conditions like diabetes or obesity, smoking cigarettes, older age, and criminal history or DUIs. If you are approved, you can then decide if you want to accept the offer and can always back out. You will be directed through a simple digital payment and signature process to finalize your policy details and kick off your coverage!
    `,
  },
  {
    title: 'Do I have to cancel my existing policy?',
    description:
      'It depends. If you want to supplement an existing policy, there are no laws against holding multiple policies, and chances are you won’t have to cancel it.  If your existing policy is privately held, please check with your carrier first.  On the other hand, if you want to replace an existing policy, you will have to surrender the old policy with the insurance carrier first before you can replace it with new coverage.',
  },
  {
    title: 'How much does it cost?',
    description: `To put it briefly, the cost of a life insurance policy varies widely.  Policies can cost a few dollars a month or hundreds of dollars a month depending on your situation.  Your final approved rate will be determined based on your age, personal health, and other relevant information about your life situation.  This is why we recommend Haven Life for some of the best pricing in the industry! You can also get a price estimate using our quote tool.`,
  },
  {
    title: 'Who is Haven Life, and why do you recommend them?',
    description: `
    Haven Life is a platform bringing life insurance out of antiquity and into the digital era.
    <br>
    <br>
    There are several reasons we partner with Haven, the first of which is affordability. A $250,000 20-year Haven Term policy starts at just $14.99/mo (quote as of 6/18/19, based on a 30-year old female in excellent health). That is much cheaper than other providers in the marketplace.
    <br>
    <br>
    Second, we appreciate easy, digital experiences. With Haven’s platform, you can apply online without having to speak to anyone over the phone. In some cases, you can even find out immediately if you’re approved for coverage. In case you do need help during your application, they also have a phone support line and live chat options. 
    <br>
    <br>
    Lastly, Haven Life provides coverage you can trust. Haven’s term life insurance coverage is issued by MassMutual or C.M. Life, a subsidiary of MassMutual. Both companies have an A++ A.M. Best rating for financial strength and claims-paying ability. Also, MassMutual was named 2021 World's Most Ethical Companies® by Ethisphere and received a 100 score on the Human Rights Campaign Corporate Equality Index (2021).
 `,
  },
  {
    title: 'How do claims work?',
    description: `In the event that you (if you are the policyholder) pass away, a beneficiary on the policy will need to file the claim with the life insurance company.  They will have to reach out to the claims team or other method of contact provided by the insurance company and prove the death, as well as their status as a beneficiary.  They will need a few things: the policyholder’s date of birth, a government-issue death certificate, and their Social Security number.  Any additional details of the policy, like the policy number, are also helpful.
    <br>
    <br>
    If you are a policyholder, then you will want to make it easy for your loved ones to file a claim in your absence.  It is important to let the beneficiary/ies of the policy know the policy details, including policy number and carrier information.  You should also make sure they know your date of birth and Social Security number. Having this information on-hand for later reference will make the claims process as painless as it can be -- the last thing you want is for it to be a battle with the insurance company while your loved ones are already dealing with loss.
    <br>
    <br>
    Once the claim has been approved, most companies send payouts directly to a beneficiary’s bank account. If the claim is in good order, the claim can be paid out within a few days, sometimes within 24 hours!  The lump sum payouts are often very large, so the bank will sometimes hold the money 1-2 days before fully depositing the lump sum amount.`,
  },
];

type QuestionComponentType = {
  questions: { title: string; description: string }[];
};
export const QuestionComponent = ({ questions }: QuestionComponentType) => {
  return (
    <>
      <p>
        We’ve provided some answers to common questions below. For even more
        information, click Continue above and our partner Haven Life will have
        more information
      </p>
      <div>
        <ToggleDropdown details={questions} />
      </div>
    </>
  );
};

export const LifeDisclaimer = () => {
  return (
    <>
      <p className="life-disclaimer-text">
        With every policy purchased from Haven Life Insurance Agency, we will be
        paid a commission.
      </p>
      <p className="life-disclaimer-text">
        Haven Term is a Term Life Insurance Policy (ICC21 Haven Term in certain
        states, including NC) issued by C.M. Life Insurance Company (C.M. Life),
        Enfield, CT 06082. In New York (DTC-NY), California (DTC-CA), and other
        states it is issued by Massachusetts Mutual Life Insurance Company
        (MassMutual), Springfield, MA 01111-0001.
      </p>
      <p className="life-disclaimer-text">
        Haven Simple is a Simplified Issue Term Life Insurance Policy (ICC20
        HAVEN SIMPLE in certain states, including NC) issued by C.M. Life
        Insurance Company, Enfield, CT 06082. Policy and rider form numbers and
        features may vary by state and may not be available in all states. Our
        Agency license number in California is OK71922 and in Arkansas
        100139527.
      </p>
    </>
  );
};

export const dependentChildrenOptions = [
  { id: '0', value: '0' },
  { id: '1', value: '1' },
  { id: '2', value: '2' },
  { id: '3', value: '3' },
  { id: '4', value: '4' },
  { id: '5', value: '5' },
  { id: '6', value: '6' },
  { id: '7', value: '7' },
  { id: '8', value: '8' },
  { id: '9', value: '9' },
  { id: '10', value: '10' },
  { id: '11', value: '11' },
  { id: '12', value: '12' },
  { id: '13', value: '13' },
  { id: '14', value: '14' },
  { id: '15', value: '15' },
];

//TO DO: replace with actual values when available
export const coverageOptions = [
  { id: '1', value: '1' },
  { id: '2', value: '2' },
  { id: '3', value: '3' },
  { id: '4', value: '4' },
  { id: '5', value: '5' },
  { id: '6', value: '6' },
  { id: '7', value: '7' },
];

export const defaultHavenUrl = `https://havenlife.com/amorphic/init/haven.js?utm_source=HarmonicIQ`;

export const excemptionStates = ['CA', 'DE', 'SD', 'ND', 'NY'];
export const allYearOptions = new Set(['5', '10', '15', '20', '25', '30']);
export const californiaOver50YearOptions = new Set(['10', '15', '20']);
export const simpleOver50YearOptions = new Set(['20', '25', '30']);
export const havenTermYearOptions = new Set(['10', '15', '20', '25', '30']);
export const exceptionYearOptions = new Set(['10', '15', '20', '30']);
export const floridaYearOptions = new Set(['5', '10', '15', '20', '30']);
export const allLifeValues = defaultAmountOptions.map((option) => {
  return option.value;
});
export const havenAmountValues = defaultAmountOptions.map((option) => {
  return parseInt(option.value) >= 250000 && option.value;
});
export const allAmountOptions = new Set(allLifeValues);
export const havenAmountOptions = new Set(havenAmountValues);

export const LifeIntroTitles: { [key: string]: string } = {
  smallMobile: "<span>Protect</span> what's <br> most important <br> to you",
  mobile: "<span>Protect</span> what's <br> most important <br> to you",
  tablet: "<span>Protect</span> what's most important<br> to you",
  desktop: "<span>Protect</span> what's most <br> important to you",
};

export const headerTitle = 'Life Insurance';

export const lifeIntroText = "Based on what you told us, a life insurance policy is a good fit. Now, find out how much coverage you should have."

export const getLifeIntroContent = (partner: string) => {
  if (partner === 'carshield') {
    return 'Based on what you told us, a life insurance policy is a good fit. Now, find out how much coverage you should have.';
  }
  return `Please answer these additional questions. Then, we’ll recommend
  the right life insurance package for you and your family. Apply
  for immediate approval.`;
};

export const getLifeIntroDescription = (partner: string) => {
  if (partner === 'carshield') {
    return null;
  }
  return `Congratulations on activating your $10,000 AD&D policy. It’s a great start to protect your loved ones.
  `;
};
