import { useMemo } from 'react';
import { LifeResponseInterface } from '../../components/interfaces/LifeInterfaces';
import { SelectOptionInterface } from '../../components/interfaces/InputInterfaces';
import DetailCard from '../../components/common/detail-card/DetailCard';

import {
  TermLifeComponent,
  EmployerInsuranceComponent,
  SelfInsuranceComponent,
  QuestionComponent,
  nonHolderDetails,
  holderDetails,
  excemptionStates,
  allYearOptions,
  californiaOver50YearOptions,
  simpleOver50YearOptions,
  havenTermYearOptions,
  exceptionYearOptions,
  floridaYearOptions,
  allAmountOptions,
  havenAmountOptions,
} from '../../constants/LifeConstants';

export const getRecommendedContent = (insuranceProvider: string) => {
  if (insuranceProvider === 'non-holder') {
    return {
      content: (
        <>
          <DetailCard
            title="What is Term Life Insurance?"
            content={<TermLifeComponent />}
          />
          <DetailCard
            title="Still Have Questions?"
            content={<QuestionComponent questions={nonHolderDetails} />}
          />
        </>
      ),
    };
  }
  if (insuranceProvider === 'employer') {
    return {
      content: (
        <>
          <DetailCard
            title="Is your employer-provided life insurance coverage enough?"
            content={<EmployerInsuranceComponent />}
          />
          <DetailCard
            title="What is Term Life Insurance?"
            content={<TermLifeComponent />}
          />
          <DetailCard
            title="Still Have Questions?"
            content={<QuestionComponent questions={holderDetails} />}
          />
        </>
      ),
    };
  }
  if (insuranceProvider === 'self-bought') {
    return {
      content: (
        <>
          <DetailCard
            title="Already a life insurance policy holder?"
            content={<SelfInsuranceComponent />}
          />
          <DetailCard
            title="What is Term Life Insurance?"
            content={<TermLifeComponent />}
          />
          <DetailCard
            title="Still Have Questions?"
            content={<QuestionComponent questions={holderDetails} />}
          />
        </>
      ),
    };
  }
  return {
    content: (
      <>
        <DetailCard
          title="What is Term Life Insurance?"
          content={<TermLifeComponent />}
        />
        <DetailCard
          title="Still Have Questions?"
          content={<QuestionComponent questions={nonHolderDetails} />}
        />
      </>
    ),
  };
};

const toggleOptions = (
  currentOptions: SelectOptionInterface[],
  newOptions: any
) => {
  return currentOptions.map((option) => {
    //   if current option isnt in the new options, disable it
    if (!newOptions.has(option.value)) {
      option.isDisabled = true;
      return option;
    }
    option.isDisabled = false;
    return option;
  });
};

export const useCalculateLifeOptions = (
  yearOptions: SelectOptionInterface[],
  amountOptions: SelectOptionInterface[],
  state: string,
  age: number,
  coverageAmount?: number
) => {
  const isHavenState = excemptionStates.some(
    (excemptionState) => excemptionState === state
  );
  let computedYearOptions = useMemo(() => {
    const yearOptionsCopy = [...yearOptions];

    //   HAVEN STATES
    if (isHavenState) {
      if (state === 'CA') {
        // handle california and over 50
        if (age >= 50) {
          return toggleOptions(yearOptionsCopy, californiaOver50YearOptions);
        }
        return toggleOptions(yearOptionsCopy, havenTermYearOptions);
      }
      return toggleOptions(yearOptionsCopy, exceptionYearOptions);
    }

    //  Handle Florida :((
    if (state === 'FL' && coverageAmount) {
      if (coverageAmount >= 250000 && coverageAmount <= 500000) {
        return toggleOptions(yearOptionsCopy, floridaYearOptions);
      }
      if (coverageAmount > 500000) {
        return toggleOptions(yearOptionsCopy, exceptionYearOptions);
      }
      return toggleOptions(yearOptionsCopy, allYearOptions);
    }

    // Handle Simple States amounts
    if (coverageAmount) {
      if (coverageAmount >= 250000 && coverageAmount <= 500000) {
        return toggleOptions(yearOptionsCopy, allYearOptions);
      }
      if (coverageAmount > 500000) {
        return toggleOptions(yearOptionsCopy, havenTermYearOptions);
      }
    }

    // handle Simple States Age over 50
    if (age > 50) {
      return toggleOptions(yearOptionsCopy, simpleOver50YearOptions);
    }

    return yearOptionsCopy;
  }, [state, age, coverageAmount]);

  let computedAmountOptions = useMemo(() => {
    const amountOptionsCopy = [...amountOptions];

    if (isHavenState) {
      return toggleOptions(amountOptionsCopy, havenAmountOptions);
    }
    return toggleOptions(amountOptionsCopy, allAmountOptions);
  }, [state, age, coverageAmount]);

  return [computedYearOptions, computedAmountOptions];
};

export const calculateLifeAmount = (values: LifeResponseInterface) => {
  const { income, dependents, debt, savings, education } = values;
  return income * 11 - savings + debt + education * dependents;
};
