import { Dispatch, SetStateAction } from 'react';

const CustomIframe = ({
  url,
  setIsLoaded,
}: {
  url: string;
  setIsLoaded: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <iframe
      height={955}
      onLoad={() => setIsLoaded(true)}
      width={'100%'}
      src={url}
      className="custom-iframe"
      style={{
        lineHeight: '0px',
        width: '100%',
        height: '955px',
        margin: '0',
        padding: '0',
        overflow: 'auto',
      }}
    ></iframe>
  );
};

export default CustomIframe;
