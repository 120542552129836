import { VehicleInterface } from '../../components/interfaces/VehicleInterfaces';
import { vehicleMakers } from '../../constants';
import { yupMessages } from '../yupValidations';
import { getAddressInfo } from './CommonHelpers';

type driverObject = {
  [key: string]: number | string;
};

export const generateArrayOfYears = (min: number) => {
  let max = new Date().getFullYear();
  let count = 1;
  let years = [];

  for (var i = max; i >= min; i--) {
    years.push({ id: count.toString(), value: i.toString() });
    count++;
  }
  return years;
};

//   generate select optoins from array of values
export const generateSelectOptions = (values: string[]) => {
  return values.map((value, idx) => {
    return {
      id: idx.toString(),
      value: value,
    };
  });
};

export const generateModels = (make: string) => {
  let models = vehicleMakers[make];
  if (!models) {
    return [];
  }
  return models.map((model: string, idx: number) => {
    return {
      id: idx.toString(),
      value: model,
    };
  });
};

export const updateVehicle = (info: VehicleInterface, setVehicles: Function, index: number) => {
  setVehicles((prev: Array<VehicleInterface>) => {
    const tmp = [...prev];
    tmp[index] = { ...tmp[index], ...info };
    return tmp;
  });
};

export const updateDriver = (info: driverObject, setDriverInfo: Function, index: number) => {
  setDriverInfo((prev: Array<driverObject>) => {
    const tmp = [...prev];
    tmp[index] = { ...tmp[index], ...info };
    return tmp;
  });
};

export const getOwnerValue = (type: string) => {
  if (type === 'Owned') {
    return 1;
  }
  if (type === 'Financed') {
    return 2;
  }
  if (type === 'Leased') {
    return 3;
  }
  return 1;
};

export const quoteAmount = (length: number) => {
  switch (length) {
    case 1:
      return { first: 'one', second: 'the' };
    case 2:
      return { first: 'two', second: 'both' };
    case 3:
      return { first: 'three', second: 'the' };
    case 4:
      return { first: 'four', second: 'the' };
    case 5:
      return { first: 'five', second: 'the' };
    case 6:
      return { first: 'six', second: 'the' };
    case 7:
      return { first: 'seven', second: 'the' };
    case 8:
      return { first: 'eight', second: 'the' };
    case 9:
      return { first: 'nine', second: 'the' };
    default:
      return { first: length.toString(), second: 'the' };
  }
};

export const getAgeValue = (age: string) => {
  switch (age) {
    case '25-34':
      return 3;
    case '35-49':
      return 4;
    case '50-64':
      return 5;
    case '65+':
      return 6;
    default:
      return 2;
  }
};

export const getAutoDisclaimerText = (partnerName: string) => {
  const partners: { [key: string]: string } = {
    carshield: 'Carshield',
  };
  const partner = partners[partnerName] || 'Safety Net Alliance';

  return `By clicking "Get My Quotes" I give my express consent to receive marketing communications via calls, texts, and emails from 
   ${partner}, its marketing and re-marketing network, and up to 5 marketing partners or their affiliates at the above phone number, of which I am the regular user, via auto dialers, prerecorded messages, artificial voice or other electronic means. I am providing this consent even if my number is wireless or VOIP and/or registered on a Do-Not-Call Registry. Message & data rates may apply. I understand that my consent is not a condition of purchase and that I may revoke my consent at any time by visiting our Contact Us page, selecting "Do not call" under the "Opt-Out" section and submitting your information. Also, by providing my information and clicking "Get My Quotes" I am directing cheapest-auto-insurance.com to share my information with their network of partners to help me obtain insurance quotes.`;
};

export const setAutoAddress = async (
  zip: string,
  setErrors: Function,
  setShortState: Function,
  setCity: Function,
  setZip: Function,
  setStepAnimationType: Function,
) => {
  try {
    let addressResp = await getAddressInfo(`${zip} , US`);

    if (addressResp) {
      let { administrative_area_level_1, administrative_area_level_2, locality, postal_code, country } =
        addressResp;
      if (country && country?.short_name !== 'US') {
        setErrors({ zip: yupMessages.zipCodeInvalid });
        return;
      }
      const stateResp = administrative_area_level_1?.short_name;
      const cityResp = locality?.long_name || administrative_area_level_2?.long_name;
      const zipCodeResp = postal_code?.long_name;

      // set address info
      setShortState(stateResp);
      setCity(cityResp);
      setZip(zipCodeResp);
      setStepAnimationType('slide-in');
      return true;
    } else {
      return false;
    }
  } catch {
    return false;
  }
};
