import { string } from 'yup';
import { object, date } from 'yup';
import { isAgeInRange } from '../../services/helpers/CommonHelpers';

export const userFormSchema = object({
  firstName: string().min(2, 'First name is required').required('First name is required'),
  lastName: string().min(2, 'Last name is required').required('Last name is required'),
  gender: string().required('Required Field'),
  dob: string()
    .required('Required Field')
    .typeError('Invalid Date')
    .test('dob', 'Age must be between 18 and 70', (value) => {
      return isAgeInRange(value, 18, 70);
    }),
  phone: string().min(10, 'Phone Number is required').required('Phone Number is required'),
  ssn: string().min(9, 'SSN is required').required('SSN is required'),
  email: string().email('Email must be valid').required('Email is required'),
});

let today = new Date();
const age70 = today.setFullYear(today.getFullYear() - 70);

export const memberFormSchema = object({
  firstName: string().min(2, 'First name is required').required('First name is required'),
  lastName: string().min(2, 'Last name is required').required('Last name is required'),
  dob: date()
    .min(new Date(age70), 'Invalid Date')
    .max(new Date(), 'Invalid Date')
    .required('Required Field')
    .typeError('Invalid Date'),
  gender: string().required('Required Field'),
  kinship: string().required('Required Field'),
});

export const userAddressSchema = object({
  address: string().min(2, 'Address is required').required('Address is required'),
  city: string().min(2, 'City is required'),
  state: string().min(2, 'State is required'),
  zip: string().min(5, 'Please enter a valid zipcode').required('Zipcode is required'),
});