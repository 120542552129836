import { useState, useContext } from 'react';
import StepComponent from '../../step-component/StepComponent';
import RadioButtonContainer from '../../radio-button-container/RadioButtonContainer';
import { LifeInsuranceContext } from '../../../../context/LifeInsuranceContext';
import { useNavigate } from 'react-router-dom';
import { insurancePolicyImages } from '../../../../constants/LifeConstants';
import { UserContext } from '../../../../context/UserContext';


const InsuranceProvider = () => {
  const { insuranceProvider, setInsuranceProvider } =
    useContext(LifeInsuranceContext);
    const { partnerName, device } = useContext(UserContext);

  const [policyOrigin, setPolicyOrigin] = useState(
    insuranceProvider === 'employer' ? 'My Employer' : 'I purchased it myself'
  );
  const navigate = useNavigate();

  const formContent = {
    header: 'Where did you get it from? ',
    img: insurancePolicyImages(partnerName, device),
    buttonTxt: 'Continue',
    onSubmit: () => {
      setInsuranceProvider(
        policyOrigin === 'My Employer' ? 'employer' : 'self-bought'
      );
      navigate('/life/income');
    },
    fields: [
      <RadioButtonContainer
        options={['My Employer', 'I purchased it myself']}
        size="large"
        value={policyOrigin}
        handleChange={(val) => setPolicyOrigin(val)}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.img}
      />
    </>
  );
};

export default InsuranceProvider;
