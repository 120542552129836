import React from 'react';
import './summary-card.scss';

const PolicySummaryCard = (props: {
  cardInfo: { title: string; value: React.ReactNode }[];
}) => {
  const { cardInfo } = props;

  return (
    <div className="summary-card-container">
      {cardInfo.map((section, idx) => {
        return (
          <div className="section-container" key={idx}>
            <p className="section-title">{section.title}</p>
            {Array.isArray(section.value) ? (
              section.value.map((value, idx) => {
                return <p key={idx} className="section-value">{value}</p>;
              })
            ) : (
              <p className="section-value">{section.value}</p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default PolicySummaryCard;
