import { UpsellRecInterface } from '../../components/interfaces/AddTypeInterfaces';
import { POLICY_IDENTIFIERS } from '../../constants/CommonConstants';
export const introContent = {
  carshieldDisclaimer: `CarShield is a strategic partner of the Safety Net Alliance. <br/> <br/>This policy is paid for by the Safety Net Alliance, which you need to join to receive this member benefit. Member benefits will renew quarterly. This offer is extended at the sole discretion of Safety Net Alliance and may end at any time.`,
  disclaimer:
    'This policy is paid for by the Safety Net Alliance, which you need to join to receive this member benefit. Member benefits will renew quarterly. This offer is extended at the sole discretion of Safety Net Alliance and may end at any time.',
};

export const noThanksContent = {
  description:
    'Not a problem. Be sure to check your email for the details on your AD&D policy, and enjoy the piece of mind that comes from having the $10,000 member benefit.',
};

export const noRecommendationsContent = {
  description:
    "Given your answers, we don't think a life insurance policy makes sense for you at this time. Enjoy your AD&D policy and enjoy the piece of mind that comes from having the $10,000 member benefit.",
};

export const termsDisclaimer =
  ' Safety Net Alliance members receive a $10,000 member benefit Accidental Death & Dismemberment policy issued by The Prudential Insurance Company of America except those living in: AK, GA, KS, LA, ME, MN, MS, MT, NM, OR, UT, WA, and WV. Members in those states receive a $10,000 member benefit Accidental Death & Dismemberment policy issued by Amalgamated Life Insurance Company.';
export const safetyNetInfo = [
  {
    title: 'Who is the Safety Net Alliance?',
    description:
      'The Safety Net Alliance is made up of thousands of people like you working to reduce their financial stress, protect their families, and prepare for the unexpected.',
  },
];

export const prudentialPolicyInfo = [
  {
    title: 'Eligible Members',
    description: 'All association members in good standing',
  },
  {
    title: 'Premium Contribution',
    description: 'Non-contributory',
  },
  {
    title: 'Coverage Amount',
    description: 'Flat $10,000',
  },
  {
    title: 'Age Reductions',
    description: 'Do not apply',
  },
  {
    title: '100% of benefit amount is paid for loss of',
    description:
      'Life, both hands or both feet, sight of both eyes, speech and hearing in both ears, quadriplegia, and any two or more: one foot, one hand, and sight of one eye',
  },
  {
    title: '75% of benefit amount is paid for loss of',
    description: 'Paraplegia, triplegia, one arm or one leg',
  },
  {
    title: '50% of benefit amount is paid for loss of',
    description: 'One hand or one foot or sight of one eye or speech or hearing in both ears or hemiplegia',
  },
  {
    title: '25% of benefit amount is paid for loss of',
    description:
      'Uniplegia, four fingers of the same hand, thumb & index finger of same hand or hearing in one ear',
  },
  {
    title: '13% of Benefit Amount is paid for loss of',
    description: 'All toes on one foot',
  },
  {
    title: '5% of Benefit Amount is paid for loss of',
    description: 'Big Toe',
  },
  {
    title: 'Coma Benefit',
    description:
      'The lesser of 2% per month and $1,000, up to 100 months; after 100 months a lump sum equal to 100% of the amount of insurance minus the amount already paid for coma',
  },
  {
    title: 'Seatbelt Benefit',
    description: 'Lesser of 10% of the coverage amount or $25,000',
  },
  {
    title: 'Airbag Benefit',
    description: 'Lesser of 10% of the coverage amount or $10,000',
  },
  {
    title: 'Repatriation of Remains Benefit',
    description: 'Lesser of the returns of remains expenses or $10,000',
  },
  {
    title: 'Conversion',
    description: 'Conversion option not available',
  },
  {
    title: 'Provider',
    description: 'Coverage issued by The Prudential Insurance Company of America.',
  },
];

export const amalgamatedPolicyInfo = [
  {
    title: 'Eligible members',
    description: 'All association members in good standing',
  },
  {
    title: 'Premium Contribution',
    description: 'Non-contributory',
  },
  {
    title: 'Coverage Amount',
    description: 'Flat $10,000',
  },
  {
    title: 'Age Reductions',
    description: '50% benefit reduction at age 70',
  },
  {
    title: 'The entire benefit amount is paid for loss of',
    description:
      'Life, both hands or both feet, sight of both eyes, speech and hearing in both ears, quadriplegia, and any two or more: one foot, one hand, sight of one eye, speech, hearing in both ears',
  },
  {
    title: '75% of benefit amount is paid for loss of',
    description: 'Paraplegia, triplegia, one arm or one leg',
  },
  {
    title: '50% of benefit amount is paid for loss of',
    description: 'One hand or one foot or sight of one eye or speech or hearing in both ears or hemiplegia',
  },
  {
    title: '25% of benefit amount is paid for loss of',
    description:
      'Uniplegia, four fingers of the same hand, thumb & index finger of same hand or hearing in one ear',
  },
  {
    title: '13% of benefit amount is paid for loss of',
    description: 'All toes on one foot',
  },
  {
    title: '5% of benefit amount is paid for loss of',
    description: 'Big toe',
  },
  {
    title: 'Coma Benefit',
    description:
      'The lesser of 2% per month and $1,000, up to 100 months; after 100 months a lump sum equal to 100% of the amount of insurance minus the amount already paid for coma',
  },
  {
    title: 'Seatbelt Benefit',
    description: 'Lesser of 10% of the coverage amount or $25,000',
  },
  {
    title: 'Airbag Benefit',
    description: 'Lesser of 10% of the coverage amount or $10,000',
  },
  {
    title: 'Repatriation of Remains Benefit',
    description: 'Lesser of the returns of remains expenses or $10,000',
  },
  {
    title: 'Conversion',
    description: 'Conversion option not available',
  },
  {
    title: 'Provider',
    description: 'Amalgamated Life Insurance Company, 333 Westchester Avenue, White Plains, NY 10604',
  },
];

export const getSafetyNetPolicyInfo = (isAmalgamated: boolean) => {
  return isAmalgamated ? amalgamatedPolicyInfo : prudentialPolicyInfo;
};

export const defaultBeneficiaries = [{ name: '', dob: '', kinship: '', percentage: '' }];

const stepNames = [
  '',
  'name',
  'address',
  'birthday',
  'gender',
  'beneficiary',
  'terms',
  'congrats',
  'already-covered',
];

export const upsellStepNames = ['', 'dependents', 'debt', 'health', 'smoke'];

export const questionnaireStepIds = [
  'name',
  'address',
  'birthday',
  'gender',
  'beneficiary',
  'beneficiary-2',
  'beneficiary-3',
  'beneficary-4',
  'amounts',
];

export const upsellStepIds = ['dependents', 'debt', 'health', 'smoke'];

export const getProgressStep = (stepId: string) => {
  return stepNames.indexOf(stepId);
};

export const getUpsellProgressStep = (stepId: string) => {
  return upsellStepNames.indexOf(stepId);
};

export const getProgressStepByIndex = (index: number) => {
  return stepNames[index];
};

export const relationshipStatusOptions = [
  { id: '1', value: 'Spouse/ Domestic Partner' },
  { id: '2', value: 'Parent' },
  { id: '3', value: 'Child' },
  { id: '4', value: 'Sibling' },
  { id: '5', value: 'Grandparent' },
  { id: '6', value: 'Grandchild' },
  { id: '7', value: 'Other family' },
  { id: '8', value: 'Other (non-family)' },
];

export const beneficiaryStep = ['beneficiary', 'amounts'];

export const policyIdentifier = POLICY_IDENTIFIERS.ACCIDENTAL_DEATH;

export const isADDFlow = (stepID: string) => {
  return questionnaireStepIds.some((id) => id === stepID);
};

export const upsellRecommendations: { [key: string]: UpsellRecInterface } = {
  haven_life: {
    url: 'life/start',
    title: 'We Recommend Life Insurance',
    description:
      'Based on your answers, we think you should have a life insurance policy. These policies offer well rounded coverage that will protect your family and loved ones in the case of an accident.',
  },
  haven_add: {
    url: 'accidental-death-voluntary/loading',
    title: 'We Recommend Additional AD&D',
    description:
      'Based on your answers, we think an AD&D policy is right for you. These policies are cheap and protect you from accidents. <br/> <br/> Did you know: Accidents are the most common cause of death for those under 44?',
  },
};

export const getUpsellRecommendationLink = (identifier: string) => {
  const upsellOptions: { [name: string]: string } = {
    haven_life: 'accidental-death/life-recommendation',
    voluntary_accidental_death: 'accidental-death/voluntary-recommendation',
  };

  return upsellOptions[identifier];
};

export const defaultBeneficiary = {
  firstName: '',
  lastName: '',
  dob: '',
  kinship: '',
  percentage: '100%',
};
