import React from 'react';
import './policy-disclaimer.scss';

const PolicyDisclaimer = (props: { disclaimerComponent: React.ReactNode }) => {
  return (
    <div className="policy-disclaimer-container">
      <div className="policy-disclaimer">{props.disclaimerComponent}</div>
    </div>
  );
};

export default PolicyDisclaimer;
