import './tool-tip.scss';
import TooltipIconMobile from '../../../assets/common/tooltip-mobile.svg';
import TooltipIconDesktop from '../../../assets/common/tooltip-desktop.svg';
import { UserContext } from '../../../context/UserContext';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useContext } from 'react';

export default function Tooltip({ content, ...rest }: { content: string[] }) {
  const { device } = useContext(UserContext);
  return (
    <div className="tooltip-container">
      <OverlayTrigger
        trigger={device === 'desktop' ? ['hover', 'focus'] : ['click']}
        placement={device === 'desktop' ? 'right-start' : 'bottom'}
        rootClose
        overlay={
          <Popover id="popover-basic">
            {content.map((item: any, index: number) => (
              <p className="tooltip-text" key={index}>
                {item}
              </p>
            ))}
          </Popover>
        }
        {...rest}
      >
        <button className="tooltip-button">
          {device === 'desktop' ? <TooltipIconDesktop /> : <TooltipIconMobile />}
        </button>
      </OverlayTrigger>
    </div>
  );
}
