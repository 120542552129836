import { useContext, useEffect } from 'react';
import './life.scss';
import { lifeIntroImages, LifeIntroTitles } from '../../constants/LifeConstants';
import VerticalLayout from '../../components/layouts/vertical-layout/VerticalLayout';
import PrimaryButton from '../../components/common/buttons/PrimaryButton';
import { LifeInsuranceContext } from '../../context/LifeInsuranceContext';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import mixpanelWrapper from '../../utils/tools/mixpanel';

const LifeIntro = () => {
  const navigate = useNavigate();
  const { setProgressStep } = useContext(LifeInsuranceContext);
  const { device, isMobile, authToken, partnerName, headerContent, isUpsellCompleted } =
    useContext(UserContext);

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
    if (!authToken) {
      navigate('/accidental-death/start');
    }

    if (!isUpsellCompleted) {
      navigate('/accidental-death/start');
      return;
    }
  }, []);

  const handleClick = () => {
    setProgressStep(1);
    navigate('/life/existing-policy');
  };

  return (
    <>
      <div className="life-intro-page">
        <VerticalLayout
          headerContent={headerContent}
          showInfoIcon={false}
          title={LifeIntroTitles[device]}
          img={lifeIntroImages(partnerName, device)}
          description="Congratulations on activating your $10,000 AD&D policy. It’s a great start to protect your loved ones. <br/><br/> Please answer these additional questions. Then, we’ll recommend the right life insurance package for you and your family.  Apply for immediate approval. "
          content={
            <div className="life-button-container">
              <PrimaryButton text={isMobile ? 'Continue' : 'Get Started'} onClick={handleClick} />
            </div>
          }
        />
      </div>
    </>
  );
};

export default LifeIntro;
