import QuoteCard from '../../cards/quote-card/QuoteCard';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StepComponent from '../../step-component/StepComponent';
import { VoluntaryAddContext } from '../../../../context/VoluntaryContext';
import {
  coverageImages,
  defaultCoveragePlan,
  displayVoluntaryAddQuotes,
  voluntaryAddQuotes,
} from '../../../../constants/VoluntaryConstants';
import SelectQuoteCard from '../../cards/select-quote-card/SelectQuoteCard';
import { UserContext } from '../../../../context/UserContext';

const VoluntaryCoverage = () => {
  const { coveredMembers, selectedCoveragePlan, setSelectedCoveragePlan, updateLastStep } =
    useContext(VoluntaryAddContext);
  const { device, partnerName } = useContext(UserContext);
  const [selectedCard, setSelectedCard] = useState(selectedCoveragePlan?.id ? -2 : -1);
  const [selectedPlan, setSelectedPlan] = useState(
    selectedCoveragePlan.monthlyCost !== 0 ? selectedCoveragePlan : defaultCoveragePlan,
  );
  const [selectedRatesCard, setSelectedRatesCard] = useState(selectedCoveragePlan?.id || -1);

  const navigate = useNavigate();

  const handleSubmit = () => {
    updateLastStep('coverage');
    setSelectedCoveragePlan(selectedPlan);
    localStorage.setItem('voluntaryCoverageAmount', selectedPlan.coverage.toString());
    navigate('/accidental-death-voluntary/beneficiaries');
  };

  useEffect(() => {
    if (selectedCoveragePlan.monthlyCost) {
      let ind;
      if (!selectedCoveragePlan.id) {
        ind = displayVoluntaryAddQuotes.findIndex((elem) =>
          Object.values(elem).includes(selectedCoveragePlan.monthlyCost),
        );
        ind !== -1 && setSelectedCard(ind);
        return;
      }
    }
  }, []);

  useEffect(() => {
    selectedCard !== -2 && setSelectedRatesCard(-1);
  }, [selectedCard]);

  const handleQuoteCards = () => {
    return [
      displayVoluntaryAddQuotes.map(({ coverage, user, family }, index) => {
        return (
          <QuoteCard
            key={user}
            coverage={coverage}
            interval={3}
            monthlyCost={coveredMembers.length > 0 ? family : user}
            index={index}
            isInput={true}
            isSelected={selectedCard === index}
            setSelectedCard={setSelectedCard}
            setSelectedPlan={setSelectedPlan}
          />
        );
      }),
      <SelectQuoteCard
        key={'select'}
        selectedPlan={selectedPlan}
        setSelectedCard={setSelectedCard}
        setSelectedPlan={setSelectedPlan}
        selectedRatesCard={selectedRatesCard}
        setSelectedRatesCard={setSelectedRatesCard}
        isSelected={selectedCard === -2}
        ratesOptions={(function () {
          let rates = voluntaryAddQuotes.map((elem, index) => ({
            id: index + 1,
            value: `${coveredMembers.length > 0 ? 'Whole family' : 'Just me'} at $${
              coveredMembers.length > 0 ? elem.family.toFixed(2) : elem.user.toFixed(2)
            } / mo`,
            monthlyCost: coveredMembers.length > 0 ? elem.family : elem.user,
            coverage: elem.coverage,
            interval: 3,
          }));
          rates.unshift({
            id: 0,
            value: 'Rates',
            monthlyCost: 0,
            interval: 0,
            coverage: 0,
          });

          return rates;
        })()}
      />,
    ];
  };

  const formContent = {
    header: 'Select Coverage Level:',
    Img: coverageImages(partnerName, device),
    buttonTxt: 'Continue',
    disableButton: (selectedCard === -2 && selectedRatesCard === -1) || selectedPlan?.monthlyCost === 0,
    onSubmit: handleSubmit,
    fields: [<>{handleQuoteCards()}</>],
  };
  return (
    <>
      <StepComponent
        cardImage={formContent.Img}
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        disabledButton={formContent.disableButton}
        className="voluntary-coverage"
      />
    </>
  );
};

export default VoluntaryCoverage;
