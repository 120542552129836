import { useContext } from 'react';
import { VoluntaryAddContext } from '../../../../context/VoluntaryContext';
import { UserAddress } from '../common-steps/UserAddress';

const VoluntaryAddress = () => {
  const { updateLastStep } = useContext(VoluntaryAddContext);
  return (
    <UserAddress
      nextStepUrl="/accidental-death-voluntary/family"
      updateStep={() => updateLastStep('family')}
    />
  );
};

export default VoluntaryAddress;
