import { AddInsurnaceProvider } from '../context/AddInsuranceContext';
import { Routes, Route } from 'react-router-dom';
import AddIntro from '../pages/add-insurance/AddIntro';
import AddQuestionPage from '../pages/add-insurance/AddQuestionPage';
import AddSuccess from '../pages/add-insurance/AddSuccess';
import TermsPage from '../pages/add-insurance/TermsPage';
import AddAlreadyHave from '../pages/add-insurance/AddAlreadyHave';
import AddUpsellRecommendations from '../pages/add-insurance/AddUpsellRecommendations';
import NoThanks from '../pages/add-insurance/NoThanks';
import { UserContext } from '../context/UserContext'
import { useContext } from 'react';
import { BLOCKED_UPSELL_PARTNERS } from '../constants/UpsellConstants';
import { POLICY_IDENTIFIERS } from '../constants/CommonConstants';

const AddInsurance = () => {
  const { partnerName } = useContext(UserContext)
  return (
    <AddInsurnaceProvider>
      <Routes>
        <Route path="/accidental-death/start" element={<AddIntro />} />
        <Route path="/accidental-death/:stepId" element={<AddQuestionPage />} />
        <Route path="/accidental-death/terms" element={<TermsPage />} />
        <Route path="/accidental-death/congrats" element={<AddSuccess />} />
        <Route
          path="/accidental-death/life-recommendation"
          element={<AddUpsellRecommendations type={POLICY_IDENTIFIERS.HAVEN_LIFE} />}
        />
        <Route
          path="/accidental-death/voluntary-recommendation"
          element={<AddUpsellRecommendations type="haven_add" />}
        />
        <Route
          path="/accidental-death/upsell-already-have"
          element={<AddAlreadyHave isUpsellAvailable={BLOCKED_UPSELL_PARTNERS.indexOf(partnerName) === -1}/>}
        />
        <Route
          path="/accidental-death/no-thanks"
          element={<NoThanks type="no-thanks" />}
        />
        <Route
          path="/accidental-death/no-recommendations"
          element={<NoThanks type="no-recommendation" />}
        />
      </Routes>
    </AddInsurnaceProvider>
  );
};

export default AddInsurance;
