import './beneficiary.scss';
import DeleteIcon from '../../../../../assets/common/delete-icon.svg';
import { AddBeneficiary } from '../../../../interfaces/AddTypeInterfaces';

type BeneficiaryCardProps = {
  beneficiary: AddBeneficiary;
  onDelete: (
    index: number,
    name: string,
    beneficiaryId?: string | undefined
  ) => Promise<void>;
  onSelect: React.MouseEventHandler<HTMLParagraphElement>;
};

export const BeneficiaryCard = ({
  beneficiary,
  onDelete,
  onSelect,
}: BeneficiaryCardProps) => {
  return (
    <div
      className={`beneficiary-card  voluntary ${
        beneficiary.isSelected ? 'selected' : 'deselected'
      }`}
    >
      <p className="name" onClick={onSelect}>
        {beneficiary.firstName} {beneficiary.lastName}
      </p>
      {beneficiary.canBeDeleted && <DeleteIcon onClick={onDelete} />}
    </div>
  );
};
