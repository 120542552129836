import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import StepComponent from '../../../step-component/StepComponent';
import { AutoInsuranceContext } from '../../../../../context/AutoInsuranceContext';
import RadioButtonContainer from '../../../radio-button-container/RadioButtonContainer';
import { updateDriver } from '../../../../../services/helpers/AutoHelpers';
import { UserContext } from '../../../../../context/UserContext';
import { ageImages } from '../../../../../constants/AutoConstants';

export const DriverAge = ({ driverIndex }: { driverIndex: number }) => {
  const {
    driverInfo,
    setDriverInfo,
    updateAutoInsuranceStep,
  } = useContext(AutoInsuranceContext);
  const [driverAge, setDriverAge] = useState(
    driverInfo[driverIndex]?.driverAge || '18-24'
  );
  const { partnerName, device } = useContext(UserContext);

  const navigate = useNavigate();

  const formContent = {
    header: `${driverIndex === 0 ? 'Your' : 'Their'} age`,
    buttonTxt: driverIndex === 4 ? 'Get My Quotes' : 'Next',
    Img: ageImages(partnerName, device),
    onSubmit: () => {
      updateDriver({ driverAge: driverAge }, setDriverInfo, driverIndex);

      if (driverIndex === 0) {
        updateAutoInsuranceStep('contact');
        navigate('/auto/contact');
        return;
      }

      navigate(`/auto/contact-${driverIndex + 1}`);
    },
    fields: [
      <RadioButtonContainer
        options={['18-24', '25-34', '35-49', '50-64', '65+']}
        size="large"
        value={driverAge}
        handleChange={(val) => setDriverAge(val)}
      />
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        cardImage={formContent.Img}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
      />
    </>
  );
};
