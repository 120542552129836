import { useContext } from 'react';
import StepComponent from '../../../step-component/StepComponent';
import { AutoInsuranceContext } from '../../../../../context/AutoInsuranceContext';
import { UserContext } from '../../../../../context/UserContext';
import RadioButtonContainer from '../../../radio-button-container/RadioButtonContainer';

// Images
import { homeOwnerImages } from '../../../../../constants/AutoConstants';
import { useNavigate } from 'react-router';

export const HomeOwnerStep = () => {
  const { isHomeowner, setIsHomeOwner, updateAutoInsuranceStep } =
    useContext(AutoInsuranceContext);
  const { partnerName, device } = useContext(UserContext);
  const navigate = useNavigate();
  
  const formContent = {
    header: 'Are you a homeowner?',
    cardImg: homeOwnerImages(partnerName, device),
    buttonTxt: 'Next',
    onSubmit: () => {
      updateAutoInsuranceStep('credit-score');
      navigate('/auto/credit-score');
    },
    errors: {},
    fields: [
      <RadioButtonContainer
        options={['Yes', 'No']}
        size="large"
        value={isHomeowner}
        handleChange={(val) => setIsHomeOwner(val)}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.cardImg}
      />
    </>
  );
};
