import { defaultYearOptions } from '../../constants/LifeConstants';
import loadsh from 'lodash';
import { UpsellInfoInterface } from '../../components/interfaces/AddTypeInterfaces';

const termOnlyStates = ['DE', 'SD', 'ND', 'NY'];

export const getValidIntervals = (
  state: string,
  age: number,
  coverageAmount: number,
  upsellInfo: UpsellInfoInterface
) => {
  let availableIntervals = loadsh.cloneDeep(defaultYearOptions);
  if (age > 50) {
    availableIntervals = availableIntervals.map((elem) => {
      elem.isDisabled = elem.value === '30' || elem.value !== '25';
      return elem;
    });
  }
  if (
    termOnlyStates.includes(state) ||
    coverageAmount > 500000 ||
    upsellInfo.smoker ||
    upsellInfo.health === 'Average'
  ) {
    availableIntervals[0].isDisabled = true;
  }
  if (state === 'FL') {
    availableIntervals[4].isDisabled = true;
  }

  return availableIntervals;
};

export const getClosestIntervals = (
  state: string,
  age: number,
  coverageAmount: number,
  currentInterval: number,
  upsellInfo: UpsellInfoInterface
) => {
  const validIntervals = getValidIntervals(
    state,
    age,
    coverageAmount,
    upsellInfo
  );
  if (
    validIntervals
      .filter((elem) => !elem.isDisabled)
      .map((elem) => parseInt(elem.value))
      .includes(currentInterval)
  ) {
    return currentInterval;
  }
  let closestInterval = 10;
  let currentDiff = Infinity;
  validIntervals
    .filter((elem) => !elem.isDisabled)
    .forEach((elem) => {
      const newDiff = Math.abs(parseInt(elem.value) - currentInterval);
      if (newDiff < currentDiff) {
        closestInterval = parseInt(elem.value);
        currentDiff = newDiff;
      }
    });
  return closestInterval;
};
