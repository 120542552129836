import { useContext } from 'react';
import StepComponent from '../../../step-component/StepComponent';
import { AutoInsuranceContext } from '../../../../../context/AutoInsuranceContext';
import { UserContext } from '../../../../../context/UserContext';
import { useNavigate } from 'react-router';
import SelectField from '../../../select-field/SelectField';
import { insuranceOptions } from '../../../../../constants';

import { currentProviderImages } from '../../../../../constants/AutoConstants';
export const CurrentProvider = () => {
  const {
    insuranceProviderName,
    setInsuranceProviderName,
    currentInsuranceProvider,
    setCurrentInsuranceProvider,
    updateAutoInsuranceStep,
  } = useContext(AutoInsuranceContext);
  const { partnerName, device } = useContext(UserContext);
  const navigate = useNavigate();

  const formContent = {
    header: 'Select current insurer',
    cardImg: currentProviderImages(partnerName, device),
    buttonTxt: 'Next',
    onSubmit: () => {
      updateAutoInsuranceStep('derogatories');
      navigate('/auto/derogatories');
    },
    fields: [
      <SelectField
        options={insuranceOptions}
        handleChange={(e) => {
          setInsuranceProviderName(e.target.value);
          setCurrentInsuranceProvider(+e.target.selectedOptions[0].attributes[1].value);
        }}
        name="provider"
        placeholder="Select Insurer"
        value={insuranceProviderName}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        disabledButton={currentInsuranceProvider === 0 ? true : false}
        cardImage={formContent.cardImg}
      />
    </>
  );
};
