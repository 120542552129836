import { useContext, useState } from 'react';
import StepComponent from '../../../step-component/StepComponent';
import SelectField from '../../../select-field/SelectField';
import { AutoInsuranceContext } from '../../../../../context/AutoInsuranceContext';
import { UserContext } from '../../../../../context/UserContext';
import { vehicleMakers } from '../../../../../constants';
import { carImages } from '../../../../../constants/AutoConstants';

import { useNavigate } from 'react-router';
import {
  generateArrayOfYears,
  generateSelectOptions,
  generateModels,
  updateVehicle,
} from '../../../../../services/helpers/AutoHelpers';

type VehicleMileageProps = {
  vehicleNumber: number;
};

export const VehicleInfoStep = ({ vehicleNumber }: VehicleMileageProps) => {
  const navigate = useNavigate();
  const { updateAutoInsuranceStep, vehicles, setVehicles } =
    useContext(AutoInsuranceContext);
  const { partnerName, device } = useContext(UserContext);

  const [localYear, setLocalYear] = useState(
    vehicles[vehicleNumber]?.year || ''
  );
  const [localMake, setLocalMake] = useState(
    vehicles[vehicleNumber]?.make || ''
  );
  const [localModel, setLocalModel] = useState(
    vehicles[vehicleNumber]?.model || ''
  );

  const formContent = {
    header: 'Tell us About Your Car',
    cardImg: carImages( partnerName, device),
    buttonTxt: 'Next',
    onSubmit: () => {
      updateVehicle(
        { year: localYear, make: localMake, model: localModel },
        setVehicles,
        vehicleNumber
      );
      if (vehicleNumber === 1) {
        updateAutoInsuranceStep('current-insurance');
        navigate('/auto/current-insurance');
        return;
      }
      updateAutoInsuranceStep('second-vehicle');
      navigate('/auto/second-vehicle');
    },
    disabledButton: localYear === '' || localMake === '' || localModel === '',
    fields: [
      <SelectField
        options={generateArrayOfYears(1990)}
        handleChange={(e) => setLocalYear(e.target.value)}
        handleBlur={() => {}}
        name="year"
        placeholder="Year"
        value={localYear}
      />,
      <SelectField
        options={generateSelectOptions(Object.keys(vehicleMakers))}
        handleChange={(e) => {
          setLocalMake(e.target.value);
        }}
        handleBlur={() => {}}
        name="make"
        placeholder="Make"
        value={localMake}
      />,
      <SelectField
        options={generateModels(localMake)}
        handleChange={(e) => setLocalModel(e.target.value)}
        handleBlur={() => {}}
        name="model"
        placeholder="Model"
        value={localModel}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        disabledButton={formContent.disabledButton}
        cardImage={formContent.cardImg}
      />
    </>
  );
};
