import LoadingMessage from '../../../../components/common/loading-screen/LoadingMessage/LoadingMessage';
import LoadingImg from '../../../../assets/common/loading.svg';
import './canopy-loading.scss';
import { useEffect } from 'react';

const CanopyLoading = ({ startNewTimeout }: { startNewTimeout: Function }) => {
  useEffect(() => {
    startNewTimeout();
  }, []);
  return (
    <div className="canopy-loading-container">
      <h1 className="canopy-loading-title"> Attempting to connect</h1>
      <LoadingMessage text="Hold on just a few seconds while we securely connect to your account" />
      <div className="canopy-loading-img">
        <LoadingImg />
      </div>
    </div>
  );
};

export default CanopyLoading;
