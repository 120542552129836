import SmallModal from '../../small-modal/SmallModal';
import { capitalizeName } from '../../../../../services/helpers/CommonHelpers';
import { handleSplitPercentageAmount } from '../../../../../services/helpers/AddHelpers';
import { AddBeneficiary, SelectedBeneficiary } from '../../../../interfaces/AddTypeInterfaces';
import { deleteBeneficiary } from '../../../../../api/apiFunctions';
import { UserContext } from '../../../../../context/UserContext';
import { useContext } from 'react';

type BeneficiaryModalProps = {
  setShowBeneficiaryModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBeneficiary: SelectedBeneficiary | null;
  setSelectedBeneficiary: React.Dispatch<React.SetStateAction<SelectedBeneficiary | null>>;
  beneficiariesInfo: AddBeneficiary[];
  setBeneficiariesInfo: React.Dispatch<React.SetStateAction<AddBeneficiary[]>>;
  isAmount?: boolean;
};

const BeneficiaryModal = ({
  setShowBeneficiaryModal,
  selectedBeneficiary,
  setSelectedBeneficiary,
  beneficiariesInfo,
  setBeneficiariesInfo,
  isAmount,
}: BeneficiaryModalProps) => {
  const { authToken, setShowErrorBanner } = useContext(UserContext);

  const handleRemoveBeneficiary = async () => {
    let beneficiaries = [...beneficiariesInfo];

    try {
      const res = await deleteBeneficiary(selectedBeneficiary?.id || '', authToken);
      const removedBeneficiaryIndex = beneficiaries.findIndex((ben) => ben.id === selectedBeneficiary?.id);

      selectedBeneficiary && beneficiaries.splice(removedBeneficiaryIndex, 1);
      beneficiaries = handleSplitPercentageAmount(beneficiaries);
      setBeneficiariesInfo(beneficiaries);
      setShowBeneficiaryModal(false);
      setSelectedBeneficiary(null);
    } catch {
      setShowBeneficiaryModal(false);
      setSelectedBeneficiary(null);
      setShowErrorBanner(true);
    }
  };

  const handleToggleBeneficiary = () => {
    let beneficiaries = [...beneficiariesInfo];
    const beneficiaryIndex = beneficiaries.findIndex((elem) => elem.id === selectedBeneficiary?.id);
    if (beneficiaryIndex > -1) {
      beneficiaries[beneficiaryIndex].isSelected = false;
      beneficiaries = handleSplitPercentageAmount(beneficiaries);
      setBeneficiariesInfo(beneficiaries);
    }
    setShowBeneficiaryModal(false);
    setSelectedBeneficiary(null);
  };

  return (
    <SmallModal
      setShowModal={setShowBeneficiaryModal}
      onClick={isAmount ? handleToggleBeneficiary : handleRemoveBeneficiary}
      title="Are you sure?"
      promptText={`Are you sure you want to delete ${
        selectedBeneficiary && capitalizeName(selectedBeneficiary.name)
      } as a beneficiary?`}
    />
  );
};

export default BeneficiaryModal;
