import {
  useState,
  createContext,
  FC,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';
import {
  defaultAmountOptions,
  defaultLifeResponses,
  defaultYearOptions,
  defaultPolicyAmounts,
} from '../constants/LifeConstants';
import {
  LifeResponseInterface,
  RecommendedValuesInterface,
  HavenLinkResponseInterface,
} from '../components/interfaces/LifeInterfaces';

import { SelectOptionInterface } from '../components/interfaces/InputInterfaces';
import { useContext } from 'react';
import { UserContext } from './UserContext';
import { calculateLifeAmount } from '../services/helpers/LifeHelpers';
import { getHavenLink, getLifeQuote } from '../api/apiFunctions';

type LifeInsuranceContextType = {
  lifeResponses: LifeResponseInterface;
  setLifeResponses: Dispatch<SetStateAction<LifeResponseInterface>>;
  policyAmounts: RecommendedValuesInterface;
  setPolicyAmounts: Dispatch<SetStateAction<RecommendedValuesInterface>>;
  hasLifeInsurance: boolean;
  setHasLifeInsurance: Dispatch<SetStateAction<boolean>>;
  insuranceProvider: string;
  setInsuranceProvider: Dispatch<SetStateAction<string>>;
  yearOptions: SelectOptionInterface[];
  setYearOptions: Function;
  amountOptions: SelectOptionInterface[];
  setAmountOptions: Function;
  progressStep: number;
  setProgressStep: Dispatch<SetStateAction<number>>;
  havenRedirectInfo: HavenLinkResponseInterface | null;
  setHavenRedirectInfo: Dispatch<
    SetStateAction<HavenLinkResponseInterface | null>
  >;
  totalSteps: number;
  lifeProductType: string;
  setLifeProductType: Dispatch<SetStateAction<string>>;
  handleLifeCalculations: Function;
};

const initialContextValues = {
  lifeResponses: defaultLifeResponses,
  setLifeResponses: (data: SetStateAction<LifeResponseInterface>) => {},
  policyAmounts: defaultPolicyAmounts,
  setPolicyAmounts: (data: SetStateAction<RecommendedValuesInterface>) => {},
  hasLifeInsurance: true,
  setHasLifeInsurance: (data: SetStateAction<boolean>) => {},
  insuranceProvider: 'employer',
  setInsuranceProvider: (data: SetStateAction<string>) => {},
  yearOptions: defaultYearOptions,
  setYearOptions: Function,
  amountOptions: defaultAmountOptions,
  setAmountOptions: Function,
  progressStep: 0,
  setProgressStep: (data: SetStateAction<number>) => {},
  havenRedirectInfo: null,
  setHavenRedirectInfo: () => {},
  totalSteps: 8,
  lifeProductType: '',
  setLifeProductType: (data: SetStateAction<string>) => {},
  handleLifeCalculations: Function,
};

const LifeInsuranceContext =
  createContext<LifeInsuranceContextType>(initialContextValues);
interface Props {
  children: ReactNode;
}

const LifeInsuranceProvider: FC<Props> = ({ children }) => {
  const { upsellInfo, setShowErrorBanner } = useContext(UserContext);
  const [lifeResponses, setLifeResponses] =
    useState<LifeResponseInterface>(defaultLifeResponses);
  const [policyAmounts, setPolicyAmounts] = useState(defaultPolicyAmounts);
  const [hasLifeInsurance, setHasLifeInsurance] = useState(true);
  const [insuranceProvider, setInsuranceProvider] = useState('employer');
  const [yearOptions, setYearOptions] = useState(defaultYearOptions);
  const [amountOptions, setAmountOptions] = useState(defaultAmountOptions);
  const [havenRedirectInfo, setHavenRedirectInfo] =
    useState<HavenLinkResponseInterface | null>(null);
  const [lifeProductType, setLifeProductType] = useState('');
  const [progressStep, setProgressStep] = useState(0);
  let totalSteps = !upsellInfo.hasDependents ? 5 : 7;

  const handleLifeCalculations = async (
    updatedAmounts: LifeResponseInterface,
    authToken: string,
    handleSubmit: Function,
    handleLoading: Dispatch<SetStateAction<boolean>>,
    path: string
  ) => {
    handleLoading(true);

    setLifeResponses(updatedAmounts);

    let recommendedAmount = calculateLifeAmount(updatedAmounts);

    const payload = {
      coverageAmount: recommendedAmount,
      health: upsellInfo.health,
      smoker: upsellInfo.smoker,
      termLength: 10,
    };

    try {
      const res = await getLifeQuote(payload, authToken);
      const info = res?.data?.quotes[0];
      setPolicyAmounts({
        termLength: info?.termLength,
        policyAmount: info?.coverageAmount,
        monthlyCost: info?.monthlyRate,
      });
      const havenLinkPayload = {
        coverageAmount: info.coverageAmount,
        health: upsellInfo.health,
        smoker: upsellInfo.smoker,
        termLength: info.termLength,
        policyPrice: info.monthlyRate,
      };
      let resp = await getHavenLink(havenLinkPayload, authToken);
      setHavenRedirectInfo(resp.data);

      setLifeProductType(info?.productIdentifier);
      handleSubmit(path);
    } catch {
      setShowErrorBanner(true);
    } finally {
      handleLoading(false);
    }
  };

  return (
    <LifeInsuranceContext.Provider
      value={{
        lifeResponses,
        setLifeResponses,
        policyAmounts,
        setPolicyAmounts,
        hasLifeInsurance,
        setHasLifeInsurance,
        insuranceProvider,
        setInsuranceProvider,
        yearOptions,
        setYearOptions,
        amountOptions,
        setAmountOptions,
        progressStep,
        setProgressStep,
        havenRedirectInfo,
        setHavenRedirectInfo,
        totalSteps,
        lifeProductType,
        setLifeProductType,
        handleLifeCalculations,
      }}
    >
      {children}
    </LifeInsuranceContext.Provider>
  );
};

export { LifeInsuranceContext, LifeInsuranceProvider };
