// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/common/check-mark-small.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../assets/common/check-mark.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-card {
  max-width: 500px;
  padding: 36px 28px;
  background-color: var(--secondary-background-color);
  border-radius: 20px;
  width: 100%;
  font-family: var(--primary-font);
  color: var(--secondary-text-color);
  box-shadow: 0px -5px 20px rgba(43, 43, 43, 0.1);
}
.detail-card .detail-card-title {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 24px;
}
.detail-card .detail-card-content {
  display: flex;
  flex-direction: column;
  gap: 36px;
  line-height: 30px;
}
.detail-card .detail-card-content .detail-card-list {
  margin-left: 16px;
}
.detail-card .detail-card-content .detail-card-list li {
  font-weight: 500;
  margin-left: 3px;
}
.detail-card li {
  list-style-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@media (min-width: 840px) {
  .detail-card {
    max-width: 1102px;
    padding: 64px;
  }
  .detail-card .detail-card-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
  }
  .detail-card p {
    font-size: 20px;
    line-height: 35px;
  }
  .detail-card li {
    font-size: 20px;
    line-height: 50px;
    list-style-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/detail-card/detail-card.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;EACA,mDAAA;EACA,mBAAA;EACA,WAAA;EACA,gCAAA;EACA,kCAAA;EACA,+CAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;AAAJ;AAEI;EACE,iBAAA;AAAN;AACM;EACE,gBAAA;EACA,gBAAA;AACR;AAIE;EACE,yDAAA;AAFJ;AAME;EAtCF;IAuCI,iBAAA;IACA,aAAA;EAHF;EAKE;IACE,gBAAA;IACA,eAAA;IACA,iBAAA;EAHJ;EAME;IACE,eAAA;IACA,iBAAA;EAJJ;EAOE;IACE,eAAA;IACA,iBAAA;IACA,yDAAA;EALJ;AACF","sourcesContent":[".detail-card {\n  max-width: 500px;\n  padding: 36px 28px;\n  background-color: var(--secondary-background-color);\n  border-radius: 20px;\n  width: 100%;\n  font-family: var(--primary-font);\n  color: var(--secondary-text-color);\n  box-shadow: 0px -5px 20px rgba(43, 43, 43, 0.1);\n\n  .detail-card-title {\n    font-size: 20px;\n    line-height: 30px;\n    text-align: center;\n    font-weight: 600;\n    margin-bottom: 24px;\n  }\n\n  .detail-card-content {\n    display: flex;\n    flex-direction: column;\n    gap: 36px;\n    line-height: 30px;\n\n    .detail-card-list {\n      margin-left: 16px;\n      li {\n        font-weight: 500;\n        margin-left: 3px;\n      }\n    }\n  }\n\n  li {\n    list-style-image: url('../../../assets/common/check-mark-small.png');\n  }\n\n  //desktop\n  @media (min-width: 840px) {\n    max-width: 1102px;\n    padding: 64px;\n\n    .detail-card-title {\n      font-weight: 600;\n      font-size: 28px;\n      line-height: 35px;\n    }\n\n    p {\n      font-size: 20px;\n      line-height: 35px;\n    }\n\n    li {\n      font-size: 20px;\n      line-height: 50px;\n      list-style-image: url('../../../assets/common/check-mark.png');\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
