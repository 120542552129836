import { BooleanObject } from '../../components/interfaces/InputInterfaces';
import { MONTHS } from '../../constants/CommonConstants';
import { voluntaryAddPlans } from '../../constants/VoluntaryConstants';
import { FamilyMemberInterface, UserInterface } from '../../components/interfaces/CommonInterfaces';
import { AddBeneficiary } from '../../components/interfaces/AddTypeInterfaces';

export const getMonthlyPayment = (memberAmount: number) => {
  return memberAmount === 0 ? '$3.00' : '$4.15';
};

export const allUsersValid = (errorsObj: BooleanObject) => {
  return Object.values(errorsObj).every((value) => value === false);
};

export const isMemberValid = (relation: string, age: number) => {
  // // check if spouse age is between 18 and 70
  if (relation === 'spouse' && (age < 18 || age > 70)) {
    return { isValid: false, errorMessage: 'Spouse must be between 18 and 70' };
  }
  // // check if child is above 26
  if (relation === 'child' && age > 26) {
    return { isValid: false, errorMessage: 'Children cannot be older than 26' };
  }
  return { isValid: true, errorMessage: '' };
};

export const getPaymentMonths = (date: Date) => {
  const currentMonth = date.getMonth();
  const nextMonth = MONTHS[(currentMonth + 1) % MONTHS.length];
  const lastDayOfNextMonth = new Date(
    date.getFullYear(),
    date.getMonth() + 2, 0
  ).getDate()
  const secondPaymentMonth = MONTHS[(currentMonth + 4) % MONTHS.length];
  const year =
    nextMonth === 'January' ? date.getFullYear() + 1 : date.getFullYear();
  return {
    nextMonth,
    secondPaymentMonth,
    lastDayOfNextMonth,
    year,
  };
};

export const calculateMonthlyPayments = (amount: number, interval: number) => {
  return (amount * interval).toFixed(2);
};

export const getQuoteCardText = (people: (FamilyMemberInterface | UserInterface)[], cost: number) => {
  return {
    text: `${
      people.length > 0 ? voluntaryAddPlans.family : voluntaryAddPlans.user
    } at`,
    price: `$${cost.toFixed(2)} / mo`,
  };
};

export const getSelectedBensText = (bens: AddBeneficiary[]) => {
  return [...bens]
    .filter((ben) => ben.isSelected)
    .map((ben) => `${ben.firstName} ${ben.lastName} (${ben.kinship})`);
};