// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slide {
  0% {
    opacity: 0;
    top: -40px;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}
.error-banner-container {
  background-color: var(--error-color);
  color: var(--primary-text-color);
  padding: 16px;
  font-size: 14px;
  line-height: 26px;
  font-family: var(--primary-font);
  position: fixed;
  width: 100%;
  max-width: 865px;
  z-index: 60000;
  animation: slide 0.35s linear;
  animation-fill-mode: forwards;
}

@media (min-width: 840px) {
  .error-banner-container {
    left: 50%;
    transform: translateX(-50%);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/error-banner/error-banner.scss"],"names":[],"mappings":"AAAA;EACE;IACE,UAAA;IACA,UAAA;EACF;EACA;IACE,UAAA;IACA,QAAA;EACF;AACF;AAEA;EACE,oCAAA;EACA,gCAAA;EACA,aAAA;EACA,eAAA;EACA,iBAAA;EACA,gCAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,cAAA;EACA,6BAAA;EACA,6BAAA;AAAF;;AAIA;EACE;IACE,SAAA;IACA,2BAAA;EADF;AACF","sourcesContent":["@keyframes slide {\n  0% {\n    opacity: 0;\n    top: -40px;\n  }\n  100% {\n    opacity: 1;\n    top: 0px;\n  }\n}\n\n.error-banner-container {\n  background-color: var(--error-color);\n  color: var(--primary-text-color);\n  padding: 16px;\n  font-size: 14px;\n  line-height: 26px;\n  font-family: var(--primary-font);\n  position: fixed;\n  width: 100%;\n  max-width: 865px;\n  z-index: 60000;\n  animation: slide 0.35s linear;\n  animation-fill-mode: forwards;\n}\n\n//desktop\n@media (min-width: 840px) {\n  .error-banner-container {\n    left: 50%;\n    transform: translateX(-50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
