import DeleteIcon from '../../../../assets/common/afi/trash-can.svg';
import EditIcon from '../../../../assets/common/edit-pen.svg';
type SummaryCardProps = {
  label: string;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  cardTitle: string;
  cardIndex: number;
  fields: { detailLabel: string; detail: string }[];
  showError: boolean;
  isComplete: boolean | undefined;
};
const SummaryCard = ({
  label,
  cardTitle,
  fields,
  onEdit,
  onDelete,
  cardIndex,
  showError,
  isComplete,
}: SummaryCardProps) => {
  return (
    <div className="afi-summary-card-container">
      <div className="card-header">
        {label && <p className="afi-summary-card-label">{label}</p>}
        <p className="afi-summary-card-title">{cardTitle}</p>
      </div>
      <div className="afi-summary-details-container">
        {fields?.map((item, index) => (
          <div className="afi-summary-details" key={index}>
            <p className="afi-summary-detail-label">{item.detailLabel}</p>
            <p className="afi-summary-detail">{item.detail}</p>
          </div>
        ))}
      </div>
      <div className="afi-summary-card-footer">
        {isComplete !== undefined && (
          <p className="afi-profile-completion-text">Profile {isComplete ? 'Completed' : 'Incomplete'}</p>
        )}
        <div className="afi-summary-buttons-container">
          <EditIcon onClick={() => onEdit(cardIndex)} />
          {showError && <DeleteIcon onClick={() => onDelete(cardIndex)} />}
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
