// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.checkbox-option-container {
  min-height: 46px;
  border: 1px solid var(--stroke-color);
  border-radius: 8px;
  padding: 8px 16px;
  background-color: transparent;
  width: 100%;
  text-align: left;
  padding-left: 52px;
  position: relative;
  cursor: pointer;
  color: var(--secondary-text-color);
}
button.checkbox-option-container:focus-visible {
  outline: solid var(--secondary-text-color);
}
button.checkbox-option-container:active {
  background-color: transparent;
  color: var(--secondary-text-color);
}
button.checkbox-option-container div.checkbox {
  border: 1px solid var(--stroke-color);
  border-radius: 8px;
  width: 24px;
  min-height: 24px;
  position: absolute;
  left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 580px) {
  button.checkbox-option-container {
    height: 52px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/forms/CheckboxOption/checkbox.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,qCAAA;EACA,kBAAA;EACA,iBAAA;EACA,6BAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kCAAA;AACF;AACE;EACE,0CAAA;AACJ;AACE;EACE,6BAAA;EACA,kCAAA;AACJ;AAEE;EACE,qCAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,UAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;AAIE;EAlCF;IAmCI,YAAA;EADF;AACF","sourcesContent":["button.checkbox-option-container {\n  min-height: 46px;\n  border: 1px solid var(--stroke-color);\n  border-radius: 8px;\n  padding: 8px 16px;\n  background-color: transparent;\n  width: 100%;\n  text-align: left;\n  padding-left: 52px;\n  position: relative;\n  cursor: pointer;\n  color: var(--secondary-text-color);\n\n  &:focus-visible {\n    outline: solid var(--secondary-text-color);\n  }\n  &:active {\n    background-color: transparent;\n    color: var(--secondary-text-color);\n  }\n\n  div.checkbox {\n    border: 1px solid var(--stroke-color);\n    border-radius: 8px;\n    width: 24px;\n    min-height: 24px;\n    position: absolute;\n    left: 16px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  //tablet\n  @media (min-width: 580px) {\n    height: 52px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
