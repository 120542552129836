// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-quote-container {
  color: var(--secondary-text-color);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--stroke-color);
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 16px;
  cursor: pointer;
  max-width: 311px;
  width: 100%;
}
.select-quote-container .select-quote-checkbox {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 1px solid var(--card-border-color);
  margin-right: 12px;
  display: grid;
  place-content: center;
}
.select-quote-container .select-quote-checkbox .checked {
  background-color: var(--radio-button-color);
  border-radius: 50%;
  width: 12px;
  height: 12px;
}
.select-quote-container h4.select-quote-description {
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: var(--secondary-text-color);
}
.select-quote-container .select-quote-content {
  width: 100%;
  display: flex;
  align-items: center;
}
.select-quote-container .select-quote-content .quote-card-container:last-child {
  margin-bottom: 0px;
}
.select-quote-container .select-rate-dropdown-container {
  margin-top: 12px;
}
@media (min-width: 840px) {
  .select-quote-container {
    max-width: 500px;
    width: 100%;
  }
  .select-quote-container h4.select-quote-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: var(--secondary-text-color);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/cards/select-quote-card/select-quote-card.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,aAAA;EACA,sBAAA;EACA,qCAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,0CAAA;EACA,kBAAA;EACA,aAAA;EACA,qBAAA;AACJ;AACI;EACE,2CAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACN;AAGE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kCAAA;AADJ;AAGE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AADJ;AAGI;EACE,kBAAA;AADN;AAKE;EACE,gBAAA;AAHJ;AAOE;EAlDF;IAmDI,gBAAA;IACA,WAAA;EAJF;EAME;IACE,gBAAA;IACA,eAAA;IACA,iBAAA;IACA,kCAAA;EAJJ;AACF","sourcesContent":[".select-quote-container {\n  color: var(--secondary-text-color);\n  display: flex;\n  flex-direction: column;\n  border: 1px solid var(--stroke-color);\n  border-radius: 8px;\n  padding: 12px 16px;\n  margin-bottom: 16px;\n  cursor: pointer;\n  max-width: 311px;\n  width: 100%;\n\n  .select-quote-checkbox {\n    width: 23px;\n    height: 23px;\n    border-radius: 50%;\n    border: 1px solid var(--card-border-color);\n    margin-right: 12px;\n    display: grid;\n    place-content: center;\n\n    .checked {\n      background-color: var(--radio-button-color);\n      border-radius: 50%;\n      width: 12px;\n      height: 12px;\n    }\n  }\n\n  h4.select-quote-description {\n    font-weight: 400;\n    font-size: 17px;\n    line-height: 26px;\n    color: var(--secondary-text-color);\n  }\n  .select-quote-content {\n    width: 100%;\n    display: flex;\n    align-items: center;\n\n    .quote-card-container:last-child {\n      margin-bottom: 0px;\n    }\n  }\n\n  .select-rate-dropdown-container {\n    margin-top: 12px;\n  }\n\n  //dektop\n  @media (min-width: 840px) {\n    max-width: 500px;\n    width: 100%;\n\n    h4.select-quote-description {\n      font-weight: 400;\n      font-size: 18px;\n      line-height: 26px;\n      color: var(--secondary-text-color);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
