import './buttons.scss';
import { Spinner } from 'react-bootstrap';

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  text: string;
  link?: string;
  icon: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  form?: string;
  type?: "button" | "submit" | "reset" | undefined
};

const PrimaryButtonWithIcon = ({
  text,
  onClick,
    icon,
  loading = false,
  disabled = false,
  form,
  type,
}: ButtonProps) => {

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    !disabled && onClick && onClick(e);
  };

  return (
    <>
      <button
        className={`primary-button with-icon ${disabled ? 'disabled' : ''}`}
        onClick={handleClick}
        form={form}
        type={type}
      >
        {loading ? (
          <Spinner
            animation="border"
            role="status"
            aria-hidden="true"
            variant="primary"
          />
        ) : <div> {icon}
          {text}
        </div>}
      </button>
    </>
  );
};

export default PrimaryButtonWithIcon;
