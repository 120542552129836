import { useContext, useState } from 'react';
import StepComponent from '../../step-component/StepComponent';
// Images
import { useNavigate } from 'react-router';
import RadioButtonContainer from '../../radio-button-container/RadioButtonContainer';
import { createUser } from '../../../../api/apiFunctions';
import { UserContext } from '../../../../context/UserContext';
import { CreateUserInterface } from '../../../interfaces/AddTypeInterfaces';
import { genderImages } from '../../../../constants/AddConstants';
import { POLICY_IDENTIFIERS } from '../../../../constants/CommonConstants';

export const UserGender = () => {
  const { partnerName, trackingId } = useContext(UserContext);
  const { setAuthToken, setShowErrorBanner, userInfo, setUserInfo, addressInfo, device } =
    useContext(UserContext);
  const [gender, setGender] = useState(userInfo.gender || 'Male');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formContent = {
    header: 'What is your gender?',
    ImagePaddingTop: '30px',
    ImagePaddingBottom: '42px',
    buttonTxt: 'Continue',
    Img: genderImages(partnerName, device),
    onAPIClick: (onSubmit: Function) => {
      setUserInfo({ ...userInfo, gender: gender });
      setIsLoading(true);
      //   run check for user
      let createUserInfo: CreateUserInterface = {
        user: {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: userInfo.email,
          dob: userInfo.dob.replaceAll('/', '-'),
          gender: gender.toLowerCase(),
        },
        address: {
          address: addressInfo.address,
          city: addressInfo.city,
          state: addressInfo.state,
          zip: addressInfo.zip,
        },
        policyName: POLICY_IDENTIFIERS.ACCIDENTAL_DEATH,
      };
      if (partnerName) {
        createUserInfo.clientName = partnerName;
      }
      if (trackingId) {
        createUserInfo.clientIdentifier = trackingId;
      }
      if (addressInfo.address2) {
        createUserInfo.address['address2'] = addressInfo.address2;
      }

      createUser(createUserInfo)
        .then((res) => {
          setUserInfo({
            ...userInfo,
            gender: gender.toLowerCase(),
            id: res?.data?.user?.id,
          });
          setAuthToken(res?.data?.token);
          if (res?.data?.hasInsurance) {
            onSubmit(true);
            return false;
          }
          onSubmit(false);
        })
        .catch((err) => {
          setShowErrorBanner(true);
        })
        .finally(() => setIsLoading(false));
    },
    onSubmit: (flag: boolean) => {
      flag ? navigate('/accidental-death/upsell-already-have') : navigate('/accidental-death/beneficiary');
    },
    fields: [
      <RadioButtonContainer
        options={['Male', 'Female']}
        size="large"
        value={gender}
        handleChange={(val) => setGender(val)}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        buttonTxt={formContent.buttonTxt}
        hasApiCall={true}
        onAPIClick={formContent.onAPIClick}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.Img}
        isLoading={isLoading}
        disabledButton={isLoading}
      />
    </>
  );
};
