import { useState, useEffect } from "react";
import "./error-banner.scss";

type TimedErrorBannerType = {
  message: string;
  delay: number;
  closeFunction: Function;
};
const TimedErrorBanner = ({
  message,
  delay,
  closeFunction,
}: TimedErrorBannerType) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      closeFunction(false);
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  return visible ? (
    <div className="error-banner-container">{message}</div>
  ) : null;
};

export default TimedErrorBanner;
