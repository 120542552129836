import { useContext, useState } from 'react';
import StepComponent from '../../../step-component/StepComponent';
import { useNavigate } from 'react-router';
import RadioButtonContainer from '../../../radio-button-container/RadioButtonContainer';
import { AddInsuranceContext } from '../../../../../context/AddInsuranceContext';
import { UserContext } from '../../../../../context/UserContext';
import { debtImages } from '../../../../../constants/UpsellConstants';

export const Debt = () => {
  const { setLastCompletedUpsellStep } = useContext(AddInsuranceContext);
  const { upsellInfo, setUpsellInfo, device, partnerName } = useContext(UserContext);
  const [hasDebtResp, setHasDebtResp] = useState(
    upsellInfo.debt === true ? 'Yes' : 'No'
  );
  const navigate = useNavigate();

  const formContent = {
    header: 'Do you have more than $10,000 of debt?',
    subHeader: 'Examples include credit cards, student loans, and mortgages',
    buttonTxt: 'Continue',
    Img: debtImages(partnerName, device),
    onSubmit: () => {
      let updatedInfo = {
        ...upsellInfo,
        debt: hasDebtResp === 'Yes',
      };
      setUpsellInfo(updatedInfo);
      setLastCompletedUpsellStep('debt');
      navigate('/accidental-death/health');
    },
    fields: [
      <RadioButtonContainer
        options={['Yes', 'No']}
        size="large"
        value={hasDebtResp}
        handleChange={(val: string) => setHasDebtResp(val)}
      />,
    ],
  };
  return (
    <>
      <StepComponent
        header={formContent.header}
        subHeader={formContent.subHeader}
        buttonTxt={formContent.buttonTxt}
        onSubmit={formContent.onSubmit}
        fields={formContent.fields}
        cardImage={formContent.Img}
      />
    </>
  );
};
