// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recommendation-layout-container {
  display: flex;
  height: 512px;
}
.recommendation-layout-container .side-content {
  max-width: 240px;
  width: 100%;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  position: relative;
  top: -6px;
}
.recommendation-layout-container .side-content .arrow-container {
  display: flex;
  justify-content: center;
}
.recommendation-layout-container .side-content .content-container {
  max-height: 453px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  margin-top: 16px;
  margin-bottom: 16px;
}
.recommendation-layout-container .main-content {
  flex: 1;
  max-width: 726px;
}
@media (min-width: 840px) {
  .recommendation-layout-container .side-content {
    margin-right: 28px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/desktop-recommendations-layout/desktop-recommendations-layout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;AACF;AACE;EACE,gBAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;AACJ;AACI;EACE,aAAA;EACA,uBAAA;AACN;AAEI;EACE,iBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAN;AAGE;EACE,OAAA;EACA,gBAAA;AADJ;AAKE;EACE;IACE,kBAAA;EAHJ;AACF","sourcesContent":[".recommendation-layout-container {\n  display: flex;\n  height: 512px;\n\n  .side-content {\n    max-width: 240px;\n    width: 100%;\n    flex-shrink: 0;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    overflow-y: scroll;\n    position: relative;\n    top: -6px;\n\n    .arrow-container {\n      display: flex;\n      justify-content: center;\n    }\n\n    .content-container {\n      max-height: 453px;\n      height: 100%;\n      width: 100%;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      overflow-y: scroll;\n      margin-top: 16px;\n      margin-bottom: 16px;\n    }\n  }\n  .main-content {\n    flex: 1;\n    max-width: 726px;\n  }\n\n  //desktop\n  @media (min-width: 840px) {\n    .side-content {\n      margin-right: 28px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
