// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bullet-point {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--accent-color);
  color: var(--primary-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--primary-font);
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/common/bullet-list/bullet-point/bullet-point.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,qCAAA;EACA,gCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gCAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AACF","sourcesContent":[".bullet-point {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  background-color: var(--accent-color);\n  color: var(--primary-text-color);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family: var(--primary-font);\n  font-size: 20px;\n  line-height: 20px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
