import { AddBeneficiary } from '../../components/interfaces/AddTypeInterfaces';

export const getNumberInputs = (input: string) => {
  return input === '' || /^[0-9\b]+$/.test(input);
};

export const formatNumber = (value: string) => {
  if (value) {
    const numberToString = value.toString().replace(/[()-\s]/g, '');
    return `${numberToString.slice(0, 3)}-${numberToString.slice(
      3,
      6
    )}-${numberToString.slice(6, numberToString.length)}`;
  }
  return '--';
};

export const formatPhoneNumber = (value?: string) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export const formatDateToString = (dateValue: any) => {
  if (!dateValue) {
    return '';
  }
  return `${
    dateValue.getMonth() + 1 < 10
      ? '0' + (dateValue.getMonth() + 1)
      : dateValue.getMonth() + 1
  }/${dateValue.getDate()}/${dateValue.getFullYear()} `;
};

export const formatDate = (value: string) => {
  let tmp = '';
  value
    .split('/')
    .join('')
    .split('')
    .forEach((elem, index) => {
      if (index === 1 || index === 3) {
        tmp += `${elem}/`;
      } else {
        tmp += elem;
      }
    });
  return tmp;
};

export const handlePercentageChange = (
  e: any,
  id: string,
  beneficiariesCopy: AddBeneficiary[],
  setBeneficiariesCopy: Function
) => {
  let beneficiaries = [...beneficiariesCopy];
  const beneficiaryIndex = beneficiaries.findIndex(elem => elem.id === id)
  beneficiaries[beneficiaryIndex].percentage = e.target.value;
  setBeneficiariesCopy(beneficiaries);
};

export const handlePercentageBlur = (
  e: any,
  id: string,
  beneficiariesCopy: AddBeneficiary[],
  setBeneficiariesCopy: Function
) => {
  let beneficiaries = [...beneficiariesCopy];
  const beneficiaryIndex = beneficiaries.findIndex(elem => elem.id === id)
  beneficiaries[beneficiaryIndex].percentage = `${e.target.value}%`

  setBeneficiariesCopy(beneficiaries);
};

export const handlePercentageFocus = (e: any) => {
  let value = e.target.value;
  // remove percentage symbol on focus
  if (value.charAt(value.length - 1) === '%') {
    e.target.value = e.target.value.slice(0, -1);
  }
};

export const isValidEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isValidAmount = (value: string) => {
  const formatValue = parseFloat(value.slice(1).replace(/,/g, '')).toString();

  if (getNumberInputs(formatValue)) {
    return parseInt(formatValue) >= 0;
  }
  return false;
};

// Value in state
export const storeDollarValue = (value: string) => {
  const reg = /^\d+$/;
  if (reg.test(value) || value.includes('$')) {
    // if last value doesnt pass, remove it
    if (!reg.test(value[value.length - 1])) {
      value = value.slice(0, -1);
    }
    if (value.length === 1) {
      return value;
    }

    return value.substring(1).split(',').join('');
  }
  return value;
};

export const formatDollarValue = (value: number) => {
  return `$${new Intl.NumberFormat('en-EN').format(value)}`;
};
