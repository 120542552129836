import { object } from 'yup';
import { yupValidations } from '../../services/yupValidations';

export const zipCodeSchema = object({
  zip: yupValidations.zipCode,
});

export const shortDateSchema = object({
  month: yupValidations.monthRequired,
  year: yupValidations.yearValidation,
});
