import { useState } from 'react';
import Chevron from '../../../../assets/common/chevron-down.svg';
import './toggle-dropdown.scss';

type ToggleDropdownType = {
  details: { title: string; description: string }[];
};

const ToggleDropdown = ({ details }: ToggleDropdownType) => {
  const [checkedItems, setCheckedItems] = useState(
    new Array(details.length).fill(false)
  );

  const toggleOption = (fieldIndex: number) => {
    setCheckedItems((prev) => {
      return prev.map((item, index) => {
        return !((index === fieldIndex && item === true) || index !== fieldIndex)
      });
    });
  };

  return (
    <div className="toggle-dropdown-container">
      {details.map((detail, index) => {
        return (
          <div key={index} className="dropdown-item">
            <div className="dropdown-title" onClick={() => toggleOption(index)}>
              <p>{detail.title}</p>
              <Chevron />
            </div>
            <div
              className={`dropdown-detail ${
                checkedItems[index] ? 'show' : 'hide'
              }`}
            >
              <p dangerouslySetInnerHTML={{ __html: detail.description }}></p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ToggleDropdown;
