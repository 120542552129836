// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multi-field-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.multi-field-container > div {
  margin-right: 16px;
}
.multi-field-container > div:is(:last-child) {
  margin-right: 0px;
}
@media (min-width: 580px) {
  .multi-field-container {
    flex-direction: row;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/field-container/multi-field-container.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;AACF;AACE;EACE,kBAAA;AACJ;AAAI;EACE,iBAAA;AAEN;AAGE;EAdF;IAeI,mBAAA;EAAF;AACF","sourcesContent":[".multi-field-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n\n  & > div {\n    margin-right: 16px;\n    &:is(:last-child) {\n      margin-right: 0px;\n    }\n  }\n\n  //tablet\n  @media (min-width: 580px) {\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
