import { useContext, useEffect, useState } from 'react';
import { VoluntaryAddContext } from '../../../../context/VoluntaryContext';
import './quote-card.scss';
import { getQuoteCardText } from '../../../../services/helpers/VoluntaryHelpers';

type QuoteCardProps = {
  coverage?: number;
  monthlyCost: number;
  interval?: number;
  isInput: boolean;
  index?: number;
  isSelected: boolean;
  setSelectedCard?: Function;
  setSelectedPlan?: Function;
  small?: boolean;
};

const QuoteCard = ({
  coverage,
  monthlyCost,
  interval,
  isInput,
  index,
  isSelected,
  setSelectedCard,
  setSelectedPlan,
  small = false,
}: QuoteCardProps) => {
  const { coveredMembers } = useContext(VoluntaryAddContext);
  const [quoteCardText, setQuoteCardText] = useState({ text: '', price: '' });

  useEffect(() => {
    setQuoteCardText(getQuoteCardText(coveredMembers, monthlyCost));
  }, [coveredMembers, monthlyCost]);

  return (
    <div
      className="quote-card-container"
      onClick={() => {
        setSelectedCard && setSelectedCard(index);
        setSelectedPlan &&
          setSelectedPlan({
            monthlyCost: monthlyCost,
            coverage: coverage,
            interval: interval,
          });
      }}
    >
      {isInput && (
        <div className="quote-card-checkbox">
          <div className={isSelected ? 'checked' : ''}></div>
        </div>
      )}
      <div className="quote-card-content">
        <h3 className="quote-card-title">
          {quoteCardText.text}{' '}
          <span className="quote-card-price">{quoteCardText.price}</span>
        </h3>
        {!small && (
          <>
            <h4 className="quote-card-description">{`$${coverage?.toLocaleString()} of coverage`}</h4>
            <h4 className="quote-card-description">{`Billed every ${interval} months`}</h4>
          </>
        )}
      </div>
    </div>
  );
};

export default QuoteCard;
