import { number } from 'yup';
import { date, string } from 'yup';
import { createTimeframe, dobForRequiredAge } from './helpers/CommonHelpers';
import { getNumberInputs } from './helpers/inputFieldHelpers';

export const yupMessages = {
  required: 'Required Field',
  stringLengthRestriction: (number: number, type: 'min' | 'max') =>
    `Value must be equal or ${type === 'min' ? 'longer' : 'shorter'} than ${number} characters`,
  stringFixedLength: (number: number, type: 'char' | 'num') =>
    `Value must must contain ${number} ${type === 'num' && 'numerical '}characters `,
  ageRestriction: (number: number, type: 'min' | 'max') =>
    `Age must be ${number} or ${type === 'min' ? 'older' : 'younger'}`,
  phone: 'Phone number must be valid',
  numberInvalid: 'Number must be valid',
  email: 'Email Address must be valid',
  dateInvalid: 'Invalid Date',
  dateIncorrect: 'Date must be in the past',
  futureDateRequired: 'Date must be in the future',
  zipCodeInvalid: 'Please enter a valid zip code',
  policyStartDateMin: 'Your policy start date must be in the future',
  policyStartDateMax: (numberOfDays: number) =>
    `Policy Start Date must be within ${numberOfDays} days of today`,
  monthInvalid: 'Invalid month',
  yearInvalid: 'Invalid year',
  minPurchaseYear: 'Purchase year must be after 1982',
  maxPurchaseDate: 'Must be less than 7 days in the future',
  annualMileageType: 'Annual mileage must be a number',
  annualMileageValue: 'Annual mileage must be higher than 2500',
  monthRequired: 'Month is required',
  yearRequired: 'Year is required',
  policyGaps: 'Start date must be the same day or earlier than your existing policy end date',
  nameCharacters: 'Only alpha characters are allowed',
};

export const yupValidations = {
  optionRequired: string().required(yupMessages.required),
  numberRequired: number().required(yupMessages.required).typeError(yupMessages.numberInvalid),
  ageNumberRequired: (minValue: number) =>
    number()
      .typeError('Invalid number')
      .min(minValue, yupMessages.ageRestriction(minValue, 'min'))
      .required(yupMessages.required),
  futureDateRequired: date()
    .min(new Date(), yupMessages.futureDateRequired)
    .required(yupMessages.required)
    .typeError(yupMessages.dateInvalid),
  dateRequired: date()
    .max(new Date(), yupMessages.dateIncorrect)
    .required(yupMessages.required)
    .typeError(yupMessages.dateInvalid),
  stringNotRequired: (min: number, max: number) => {
    const validation = string()
      .test('length', yupMessages.stringLengthRestriction(min, 'min'), (val) => {
        if (val == undefined) {
          return true;
        }
        return val.length === 0 || val.length >= min;
      })
      .test('length', yupMessages.stringLengthRestriction(max, 'max'), (val) => {
        if (val == undefined) {
          return true;
        }
        return val.length <= max;
      });
    return validation;
  },
  stringMinMaxLength: (min: number | null, max: number | null, nameValidation?: 'name validation') => {
    let validation = string();
    if (nameValidation) {
      validation = string().matches(/^[A-Za-z\s-]+$/, yupMessages.nameCharacters);
    }

    if (min) validation = validation.min(min, yupMessages.stringLengthRestriction(min, 'min'));
    if (max) validation = validation.max(max, yupMessages.stringLengthRestriction(max, 'max'));
    return validation.required(yupMessages.required);
  },
  ageMinMax: (minValue: number, maxValue: number) =>
    date()
      .max(new Date(dobForRequiredAge(minValue)), yupMessages.ageRestriction(minValue, 'min'))
      .min(new Date(dobForRequiredAge(maxValue)), yupMessages.ageRestriction(maxValue, 'max'))
      .required(yupMessages.required)
      .typeError(yupMessages.dateInvalid),
  phone: string().length(10, yupMessages.phone).required(yupMessages.required),
  email: string().email(yupMessages.email).required(yupMessages.required),
  zipCode: string()
    .matches(/^[0-9]{5}$/, yupMessages.stringFixedLength(5, 'num'))
    .required(yupMessages.required),
  policyDate: date()
    .test('date', yupMessages.policyStartDateMin, (val) => {
      if (val !== undefined) {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        return val > yesterday;
      }
      return false;
    })
    .max(new Date(createTimeframe(60)), yupMessages.policyStartDateMax(60))
    .required(yupMessages.required)
    .typeError(yupMessages.dateInvalid),
  monthRequired: string()
    .required(yupMessages.monthRequired)
    .test(
      'month',
      yupMessages.monthInvalid,
      (val) =>
        val !== undefined &&
        ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].includes(val.toString()),
    ),
  yearValidation: string()
    .required(yupMessages.yearRequired)
    .test('year', yupMessages.yearInvalid, (val) => {
      if (val !== undefined) {
        return val.length >= 4;
      }
      return false;
    }),
  annualMileage: string()
    .test('mileage', yupMessages.annualMileageType, (val) => {
      if (val !== undefined) {
        return getNumberInputs(val.replace(/,/g, ''));
      }
      return false;
    })
    .test('mileage', yupMessages.annualMileageValue, (val) => {
      if (val !== undefined) {
        return parseFloat(val.replace(/,/g, '')) >= 2500;
      }
      return false;
    })
    .required(yupMessages.required),
  maxYearDifference: (max: number) =>
    string()
      .required(yupMessages.required)
      .test('year', yupMessages.yearInvalid, (val) => {
        const currentDate = new Date();
        if (val !== undefined) {
          const [month, year] = val.split('/');
          const inputDate = new Date(`${month}/01/${year}`);
          const differenceInMonths =
            (currentDate.getFullYear() - inputDate.getFullYear()) * 12 +
            (currentDate.getMonth() - inputDate.getMonth());
          const maxDifferenceInMonths = max * 12;
          return differenceInMonths < maxDifferenceInMonths && inputDate <= currentDate;
        }
        return false;
      }),
  minDateRequired: (yearsAgo: number, errorMessage: string) =>
    date()
      .typeError(yupMessages.dateInvalid)
      .required(yupMessages.required)
      .min(new Date(new Date().setFullYear(new Date().getFullYear() - yearsAgo)), errorMessage),
  minYearRequired: (min: number) =>
    string()
      .required(yupMessages.required)
      .test('year', yupMessages.minPurchaseYear, (val) => {
        const minDate = new Date(`${min}/12/31`);

        if (val !== undefined) {
          const [month, year] = val.split('/');
          const inputDate = new Date(`${month}/01/${year}`);

          return inputDate > minDate;
        }
        return false;
      })
      .test('year', yupMessages.maxPurchaseDate, (val) => {
        const currentDate = new Date();
        if (val !== undefined) {
          const [month, year] = val.split('/');
          const inputDate = new Date(`${month}/01/${year}`);

          if (inputDate > currentDate) {
            const diffInMS = Math.abs(inputDate.getTime() - currentDate.getTime());
            const diffInDays = Math.floor(diffInMS / (1000 * 60 * 60 * 24));
            return diffInDays <= 7;
          }
          return true;
        }
        return false;
      }),
};
