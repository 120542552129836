import PrimaryButton from '../components/common/buttons/PrimaryButton';
import { POLICY_IDENTIFIERS } from './CommonConstants';

export const introTitles: { [key: string]: string } = {
  smallMobile: '$10,000 Policy at no <br/> cost to you',
  mobile: '$10,000 Policy at no <br/> cost to you',
  tablet: '$10,000 Policy at no cost <br/> to you',
  desktop: '$10,000 Policy <span class="shrink">at no cost to you</span>',
};

export const termsTitles: { [key: string]: string } = {
  smallMobile: 'One final step to<br> activate your <span>$10,000 policy</span>',
  mobile: 'One final step to<br> activate your <span>$10,000</span> <br>policy',
  tablet: 'One final step to activate<br> your <span>$10,000 policy</span>',
  desktop: 'One final step to activate<br> your <span>$10,000 policy</span>',
};

export const upsellRecommendationImages = (partner: string, device: string, type: string) => {
  if (type === POLICY_IDENTIFIERS.HAVEN_LIFE) {
    const LifeImage =
      require(`../assets/images/partners/${partner}/ad&d/${device}/life-recommendation.svg`).default;
    return <LifeImage />;
  }
  const AddImages =
    require(`../assets/images/partners/${partner}/ad&d/${device}/add-recommendation.svg`).default;
  return <AddImages />;
};

export const noThanksImages = (partner: string, device: string, type: string) => {
  if (type === 'no-thanks') {
    const NoThanksImages =
      require(`../assets/images/partners/${partner}/ad&d/${device}/no-thanks.svg`).default;

    return <NoThanksImages />;
  }
  const NoRecommendationImages =
    require(`../assets/images/partners/${partner}/ad&d/${device}/no-recommendations.svg`).default;
  return <NoRecommendationImages />;
};

export const noThanksTitles: { [key: string]: { [key: string]: string } } = {
  'no-thanks': {
    smallMobile: 'Enjoy your <span>AD&D policy</span>',
    mobile: 'Enjoy your <span>AD&D policy</span>',
    tablet: 'Enjoy your <span>AD&D policy</span>',
    desktop: 'Enjoy y<span>our AD&D policy</span>',
  },
  'no-recommendation': {
    smallMobile: '<span>No recommendation</span> <br> at this time.',
    mobile: '<span>No recommendation</span> at <br> this time.',
    tablet: '<span>No recommendation</span> at <br> this time.',
    desktop: '<span>No recommendation</span> at <br> this time.',
  },
};

export const headerTitle = 'AD&D Insurance at no cost to you';

export const introImages = (partner: string, device: string) => {
  const AddIntroImages = require(`../assets/images/partners/${partner}/ad&d/${device}/intro.svg`).default;

  return <AddIntroImages />;
};

export const userInfoImages = (partner: string, device: string) => {
  const UserInfoImages = require(`../assets/images/partners/${partner}/ad&d/${device}/user-info.svg`).default;

  return <UserInfoImages />;
};

export const addressImages = (partner: string, device: string) => {
  const AddressImages = require(`../assets/images/partners/${partner}/ad&d/${device}/address.svg`).default;

  return <AddressImages />;
};

export const userDobImages = (partner: string, device: string) => {
  const UserDobImages = require(`../assets/images/partners/${partner}/ad&d/${device}/user-dob.svg`).default;

  return <UserDobImages />;
};

export const genderImages = (partner: string, device: string) => {
  const GenderImages = require(`../assets/images/partners/${partner}/ad&d/${device}/gender.svg`).default;

  return <GenderImages />;
};

export const beneficiaryImages = (partner: string, device: string) => {
  if (device !== 'smallMobile' && device !== 'mobile') {
    const BeneficiaryImages =
      require(`../assets/images/partners/${partner}/ad&d/${device}/beneficiary.svg`).default;

    return <BeneficiaryImages />;
  }
  return null;
};

export const amountsImages = (partner: string, device: string) => {
  const AmountsImages = require(`../assets/images/partners/${partner}/ad&d/${device}/amounts.svg`).default;

  return <AmountsImages />;
};

export const termsImages = (partner: string, device: string) => {
  const TermsImages = require(`../assets/images/partners/${partner}/ad&d/${device}/terms-check.svg`).default;

  return <TermsImages />;
};

export const successImages = (partner: string, device: string) => {
  const SuccessImages = require(`../assets/images/partners/${partner}/ad&d/${device}/intro.svg`).default;

  return <SuccessImages />;
};

export const IntroPageModal = (props: { toggleFunction?: () => void; enrollFunction?: () => void }) => {
  const { toggleFunction, enrollFunction } = props;

  return (
    <>
      <div className="disclaimer-item">
        <p className="disclaimer-item-title">
          What's AD&D<sup>1</sup> insurance?{' '}
        </p>
        <p className="disclaimer-item-description">
          AD&D<sup>1</sup> stands for Accidental Death & Dismemberment. The insurance policy provides the
          funds necessary to cover your family's expenses, from housing and utility bills to food and
          clothing, in the event that you’re accidentally injured or killed.{' '}
        </p>
      </div>
      <div className="disclaimer-item">
        <p className="disclaimer-item-title">What does the policy cover?</p>
        <p className="disclaimer-item-description">
          If you suffer a loss as a result of a covered accident (i.e., death; coma; paralysis; and/or a loss
          of eyesight; limb; speech; or hearing), you or your beneficiary will receive benefits in addition to
          any proceeds payable under a life insurance policy. For more specifics{' '}
          <span className="modal-link" onClick={toggleFunction}>
            click here
          </span>
        </p>
      </div>
      <div className="disclaimer-item">
        <p className="disclaimer-item-title">What is the cost?</p>
        <p className="disclaimer-item-description">
          At CarShield, we want to help our loyal members make good financial decisions across all aspects of
          their lives, from car ownership to protecting what matters most, their families.
        </p>
        <p className="disclaimer-item-description">
          We have partnered with Safety Net Alliance to offer you this no cost AD&D1 policy as a no-cost
          benefit. When you join and become a member of the Safety Net Alliance– at no cost to you- Safety Net
          Alliance will provide you with group AD&D1 insurance coverage, as well as pay for that coverage on
          your behalf.
        </p>
        <p className="disclaimer-item-description">Consider it a thank you because CarShield cares.</p>
      </div>
      <p className="disclaimer-table-text small">
        <sup>1</sup>Safety Net Alliance members receive a $10,000 member benefit Accidental Death &
        Dismemberment policy issued by The Prudential Insurance Company of America except those living in: AK,
        GA, KS, LA, ME, MN, MS, MT, NM, OR, UT, WA, and WV. Members in those states receive a $10,000 member
        benefit Accidental Death & Dismemberment policy issued by Amalgamated Life Insurance Company.
      </p>
      <div className="enroll-button-container">
        <PrimaryButton text="Enroll Now" onClick={enrollFunction} />
      </div>
    </>
  );
};
