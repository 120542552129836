import { useContext, useEffect } from 'react';
import './add.scss';
import VerticalLayout from '../../components/layouts/vertical-layout/VerticalLayout';
import { AddInsuranceContext } from '../../context/AddInsuranceContext';
import { UserContext } from '../../context/UserContext';
import { noThanksContent, noRecommendationsContent } from './constants';
import { useNavigate } from 'react-router-dom';
import { headerTitle, noThanksImages, noThanksTitles } from '../../constants/AddConstants';
import { getHeaderContent } from '../../services/helpers/CommonHelpers';
import mixpanelWrapper from '../../utils/tools/mixpanel';

const NoThanks = (props: { type: string }) => {
  const { progressStep } = useContext(AddInsuranceContext);
  const { device, partnerName, partnerHeader, headerContent } = useContext(UserContext);
  const headerLogo = getHeaderContent(partnerName, device);
  const { type } = props;
  const content =
    type === 'no-thanks'
      ? {
          ...noThanksContent,
          headerInfo: {
            buttonType: '',
            withDisclaimer: !!headerLogo,
            title: partnerHeader || headerTitle,
            subtitle: partnerHeader ? 'brings you AD&D Insurance at no cost to you' : '',
          },
        }
      : {
          ...noRecommendationsContent,
          headerInfo: {
            buttonType: '',
            withDisclaimer: !!headerLogo,
            title: partnerHeader || headerTitle,
            subtitle: partnerHeader ? 'brings you AD&D Insurance at no cost to you' : '',
          },
        };
  const navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
    //route blocking
    if (progressStep === 0) {
      navigate('/accidental-death/start');
      return;
    }
  }, []);

  return (
    <>
      <div className="add-intro-page no-thanks">
        <VerticalLayout
          {...content}
          headerContent={headerContent}
          showInfoIcon={true}
          title={noThanksTitles[type][device]}
          img={noThanksImages(partnerName, device, type)}
          content={<></>}
        />
      </div>
    </>
  );
};

export default NoThanks;
