import React, { useEffect, useRef, useState } from 'react';
import { AfiQuoteType } from '../../../../interfaces/AFIInterfaces';
import ChevronLeft from '../../../../../assets/common/chevron-left.svg';
import ChevronRight from '../../../../../assets/common/chevron-right.svg';
import './canopy-cards.scss';

const CanopyCardsCarousel = ({
  quotes,
  selectedCarrier,
  setCarrier,
}: {
  quotes: AfiQuoteType[];
  selectedCarrier: string;
  setCarrier: Function;
}) => {
  const carouselRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const cardsRef = useRef([]);
  const [cardWidths, setCardWidths] = useState<number[]>([]);
  const [scrollIndex, setScrollIndex] = useState<number>(0);
  const [enableScroll, setEnableScroll] = useState(true);

  useEffect(() => {
    cardsRef.current = cardsRef.current.slice(0, quotes.length);
  }, [quotes]);

  useEffect(() => {
    const widthLengths: number[] = [];
    cardsRef.current.length > 0 &&
      cardsRef.current.forEach((item: { scrollWidth: number }) => widthLengths.push(item.scrollWidth));
    setCardWidths(widthLengths);
  }, [cardsRef]);

  useEffect(() => {
    scrollIndex === 0 &&
      cardsRef.current[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    scrollIndex === cardWidths.length - 1 &&
      cardsRef.current[scrollIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
  }, [scrollIndex]);

  useEffect(() => {
    if (cardWidths.length > 0) {
      const isLastCardVisible =
        cardsRef.current[cardWidths.length - 1].offsetLeft +
          cardsRef.current[cardWidths.length - 1].clientWidth >
        carouselRef.current.clientWidth;
      setEnableScroll(isLastCardVisible);
    }
  }, [window.innerWidth, cardWidths, cardsRef, carouselRef]);

  const handleScroll = (direction: 'left' | 'right' | number) => {
    if (typeof direction !== 'number') {
      const scrollValue = cardWidths[direction === 'right' ? scrollIndex : scrollIndex - 1];
      const directionValue = direction === 'right' ? 1 : -1;
      carouselRef.current.scrollBy({
        left: (scrollValue + 10) * directionValue,
        behavior: 'smooth',
      });
      setScrollIndex((prev) => prev + directionValue);
      return;
    }
    cardsRef.current[direction].scrollIntoView({
      left: 0,
      behavior: 'smooth',
      block: 'nearest',
    });
    setScrollIndex(direction);
  };

  return (
    <div
      className={`canopy-coverages-carousel-container ${
        scrollIndex === cardWidths.length - 1 ? 'scroll-ended' : ''
      }`}
    >
      {enableScroll && scrollIndex !== 0 && (
        <div
          className={`chevron-container left`}
          onClick={() => {
            handleScroll('left');
          }}
        >
          <ChevronLeft className={`chevron-left`} />
        </div>
      )}
      <div className="carousel-items-container" ref={carouselRef}>
        {quotes.map((quote, index) => (
          <div key={index} className="carousel-item-content" ref={(el) => (cardsRef.current[index] = el)}>
            <div
              key={quote.insurer.name}
              className={`canopy-coverages-carousel-item ${
                quote.insurer.name === selectedCarrier ? 'active' : ''
              }`}
              onClick={() => {
                setCarrier(quote);
                handleScroll(index);
              }}
            >
              {quote.insurer.name}
            </div>
          </div>
        ))}
      </div>

      {enableScroll && scrollIndex !== cardWidths.length - 1 && (
        <div
          className={`chevron-container right`}
          onClick={() => {
            handleScroll('right');
          }}
        >
          <ChevronRight className={`chevron-right`} />
        </div>
      )}
    </div>
  );
};

export default CanopyCardsCarousel;
