import React, { useRef } from 'react';
import './desktop-recommendations-layout.scss';
import ArrowUp from '../../../assets/common/arrow-up.svg';
import ArrowDown from '../../../assets/common/arrow-down.svg';
import { scrollHandler } from '../../../services/helpers/CommonHelpers';

const DesktopRecommendationLayout = (props: {
  sideContent: React.ReactNode;
  mainContent: React.ReactNode;
  isScrollable?: boolean;
}) => {
  const { sideContent, mainContent, isScrollable } = props;
  let sideContentRef = useRef<HTMLDivElement>(null);
  return (
    <div className="recommendation-layout-container">
      <div className="side-content">
        {isScrollable && (
          <div className="arrow-container">
            <ArrowUp className="arrow" onClick={() => scrollHandler('up', sideContentRef)} />
          </div>
        )}
        <div className="content-container" ref={sideContentRef}>
          {sideContent}
        </div>
        {isScrollable && (
          <div className="arrow-container">
            <ArrowDown className="arrow" onClick={() => scrollHandler('down', sideContentRef)} />
          </div>
        )}
      </div>
      <div className="main-content">{mainContent}</div>
    </div>
  );
};

export default DesktopRecommendationLayout;
