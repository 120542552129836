import './canopy-tos.scss';
import * as DOMPurify from 'dompurify';
import CloseIcon from '../../../../assets/common/close-icon.svg';

const CanopyTOSModal = ({ canopyTOS, closeModal }: { canopyTOS: any; closeModal: Function }) => {
  return (
    <div className="canopy-tos-modal-container">
      <header className="canopy-tos-modal-header">
        <CloseIcon onClick={closeModal} />
      </header>
      <div
        className="canopy-tos-content"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(canopyTOS) }}
      ></div>
    </div>
  );
};

export default CanopyTOSModal;
