import { useState, useEffect } from 'react';

const getDeviceByDimensions = (width: number, height: number) => {
  if (width >= 841) {
    return 'desktop';
  }
  if (width <= 840 && width >= 581) {
    return 'tablet';
  }
  if (width <= 580) {
    if (height < 650) {
      return 'smallMobile';
    }
    return 'mobile';
  }
  return '';
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  const device: 'desktop' | 'tablet' | 'mobile' | 'smallMobile' | '' =
    getDeviceByDimensions(width, height);
  const isMobile = device === 'smallMobile' || device === 'mobile';

  return {
    width,
    height,
    device,
    isMobile,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
