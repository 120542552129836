import { stringsObject, BooleanObject } from '../components/interfaces/InputInterfaces';
import { formatSSN, getAgeFromBirthday, isAgeInRange } from './helpers/CommonHelpers';
import {
  CoveredMember,
  CoveredUser,
  FamilyMemberInterface,
  UserInterface,
} from '../components/interfaces/CommonInterfaces';
import { FormikErrors } from 'formik';
import { VoluntaryUser } from '../components/interfaces/VoluntaryInterfaces';

export const errorMessages = {
  required: 'Required Field',
  size: 'Invalid length',
  date: 'Invalid date',
  email: 'Invalid email',
  age: 'This program is available to those between 18-78 years old.',
};

export const allFieldsValid = (
  values: stringsObject | object,
  errors: stringsObject | FormikErrors<VoluntaryUser>,
  optionalField?: string,
) => {
  // determine if all fields are valid
  const tempValues = { ...values };
  if (optionalField) {
    delete tempValues[optionalField];
  }
  const allFieldsFilled = !Object.values(tempValues).some((value) => value === '');
  if (!allFieldsFilled || Object.keys(errors).length) {
    return true;
  }

  return false;
};

export const isMemberValid = (member: FamilyMemberInterface) => {
  let age = getAgeFromBirthday(member.dob);
  let hasValidChildAge = age < 26;
  let hasValidSpouseAge = age >= 18 && age <= 70;

  if (member.firstName.length < 2) {
    return false;
  }
  if (member.lastName.length < 2) {
    return false;
  }
  if (member.gender.length < 1) {
    return false;
  }
  if (member.kinship.length < 1) {
    return false;
  }

  if (member.kinship === 'child' && !hasValidChildAge) {
    return false;
  }

  if (member.kinship === 'spouse' && !hasValidSpouseAge) {
    return false;
  }

  return true;
};

export const isUserValid = (user: UserInterface) => {
  if (user.firstName.length < 2) {
    return false;
  }
  if (user.lastName.length < 2) {
    return false;
  }
  if (user.gender.length < 1) {
    return false;
  }
  if (!isAgeInRange(user.dob, 18, 70)) {
    return false;
  }

  if (user.phone!.length !== 10) {
    return false;
  }

  if (user.ssn!.length !== 9) {
    return false;
  }

  return true;
};

export const isSSNValid = (value: string) => {
  let formattedValue = formatSSN(value);
  const regex = /^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/;
  //check for at least 3 different digits
  const uniqueDigitsRegex = /^(?=.*(.)(?!$|.*\1))(?=.*(?!\1)(.)(?!$|.*\2))[0-9]+$/;

  //check if first 3 digits are not 999
  const not999firstDigitsRegex = /^(?!999).+/;
  const isValid =
    not999firstDigitsRegex.test(formattedValue) &&
    regex.test(formattedValue) &&
    uniqueDigitsRegex.test(formattedValue.replaceAll('-', ''));

  return isValid;
};

export const createErrorObject = (members: Array<CoveredMember | CoveredUser>): BooleanObject => {
  let defaultObject: BooleanObject = {};
  return members.reduce((a, v) => {
    return {
      ...a,
      [v.id]: true,
    };
  }, defaultObject);
};
