import './select-field.scss';
import Chevron from '../../../assets/common/chevron-down.svg';
import { SelectOptionInterface } from '../../interfaces/InputInterfaces';

type SelectFieldProps = {
  handleBlur?: () => void;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  name: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  options: SelectOptionInterface[];
  error?: any;
  displayError?: string | false;
};

const SelectField = ({
  handleBlur,
  handleChange,
  value,
  disabled,
  options,
  placeholder,
  name,
  error = null,
  displayError = '',
}: SelectFieldProps) => {
  return (
    <div className="select-container">
      <select
        name={name}
        id={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        className={`select-field ${
          value === '' || value === undefined || value === null ? 'placeholder' : ''
        } ${error ? 'error' : ''}`}
        disabled={disabled}
      >
        {/* value for default = "" for safari */}
        <option value="" hidden>
          {placeholder}
        </option>
        {options.map((option) => (
          <option
            key={option.id + option.value}
            value={option.value}
            id={option.id}
            label={option.displayName || option.value}
            disabled={option.isDisabled}
          >
            {option.displayName || option.value}
          </option>
        ))}
      </select>
      <Chevron />
      {displayError && error && <p className="error-message">{displayError}</p>}
    </div>
  );
};

export default SelectField;
