import { AddUserInterface } from "../../components/interfaces/AddTypeInterfaces";
import { amalgamatedStates } from "../../constants";
import { AddBeneficiary } from "../../components/interfaces/AddTypeInterfaces";
import { introContent } from "../../pages/add-insurance/constants";
export const isUser = (obj: any): obj is AddUserInterface => {
  return (
    "firstName" in obj &&
    "lastName" in obj &&
    "dob" in obj &&
    "gender" in obj &&
    "email" in obj
  );
};

export const formatSplit = (value: string) => {
  return parseInt(value.slice(0, -1));
};

export const isAmalgamatedState = (state: string) => {
  let isAmalgamated = amalgamatedStates.some((item) => item === state);
  return isAmalgamated;
};

export const handleSplitPercentageAmount = (array: any) => {
  const splitAmount = 100 / array.filter((elem: any) => elem.isSelected).length;
  let selectedCounter = 0;
  return array.map((element: AddBeneficiary, index: number) => {
    if (element.isSelected) {
      element.percentage =
        selectedCounter === 0
          ? Math.ceil(splitAmount).toString() + "%"
          : Math.floor(splitAmount).toString() + "%";
      selectedCounter++;
    } else {
      element.percentage = "0%";
    }
    return element;
  });
};

export const getADDisclaimer = (partnerName: string) => {
  switch (partnerName) {
    case "carshield":
      return introContent.carshieldDisclaimer;
    default:
      return introContent.disclaimer;
  }
};
