import _ from 'lodash';
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { getAfiUserInfo, getUserByIntegration, submitAfiUserInfo } from '../api/apiFunctions';
import {
  AfiNavigationType,
  AfiUserType,
  AfiIndexes,
  AfiQuoteType,
  CanopyInsurerType,
  PriorPolicyCoverageType,
} from '../components/interfaces/AFIInterfaces';

import { AfiAnswersTemplate, AfiDefaultIndexes, defaultPriorPolicyCoverage } from '../constants/AFIConstants';
import { AfiNavigation as defaultNavigation } from '../constants/AFIConstants';
import {
  addCarFieldsToCoverage,
  toggleAfiStepsForCanopy,
  toggleDiscountsByAge,
  toggleDriverSteps,
  updateCarsSteps,
  updateCoverageSteps,
} from '../services/helpers/AFICustomNavigation';
import { deserializeAfiData, serializeAfiData } from '../services/helpers/AFIHelpers';
import { UserContext } from './UserContext';
import { useSearchParams } from 'react-router-dom';

export type AnswerFinancialContextType = {
  AFIAnswersObject: { [key: string]: any };
  setAFIAnswersObject: Dispatch<SetStateAction<{ [key: string]: any }>>;
  getAfiAnswerVariables: (path: (string | number)[]) => any;
  setAfiAnswerVariables: (newValue: any, path: (string | number)[], apiCall?: boolean) => void;
  AfiNavigation: AfiNavigationType;
  setAfiNavigation: Dispatch<SetStateAction<AfiNavigationType>>;
  submitUserData: (data: any) => any;
  indexes: AfiIndexes;
  setIndexes: Dispatch<SetStateAction<AfiIndexes>>;
  quotes: AfiQuoteType[];
  setQuotes: Dispatch<SetStateAction<AfiQuoteType[]>>;
  carriersError: boolean;
  setCarriersError: React.Dispatch<React.SetStateAction<boolean>>;
  vehicleMake: string | null;
  setVehicleMake: React.Dispatch<React.SetStateAction<string | null>>;
  lastStepCompleted: string;
  setLastStepCompleted: Dispatch<SetStateAction<string>>;
  isCanopy: boolean;
  setIsCanopy: React.Dispatch<React.SetStateAction<boolean>>;
  currentCanopyInsurer: CanopyInsurerType;
  setCurrentCanopyInsurer: React.Dispatch<React.SetStateAction<CanopyInsurerType>>;
  priorPolicyCoverage: PriorPolicyCoverageType;
  setPriorPolicyCoverage: React.Dispatch<React.SetStateAction<PriorPolicyCoverageType>>;
  canopyPullId: string;
  setCanopyPullId: React.Dispatch<React.SetStateAction<string>>;
  shouldSkipCanopy: boolean;
  AfiUserInfo: AfiUserType;
  AfiUserState: 'loading' | 'error' | 'success';
  requestId: string;
  setRequestId: React.Dispatch<React.SetStateAction<string>>;
};

export const AnswerFinancialContext = createContext<AnswerFinancialContextType>({
  AFIAnswersObject: {},
  setAFIAnswersObject: () => {},
  getAfiAnswerVariables: () => null,
  setAfiAnswerVariables: () => null,
  AfiNavigation: {},
  setAfiNavigation: () => null,
  submitUserData: () => {},
  indexes: { ...AfiDefaultIndexes },
  setIndexes: () => null,
  quotes: [],
  setQuotes: () => null,
  carriersError: false,
  setCarriersError: (data: React.SetStateAction<boolean>) => {},
  vehicleMake: null,
  setVehicleMake: (data: React.SetStateAction<string | null>) => {},
  lastStepCompleted: 'start',
  setLastStepCompleted: (data: React.SetStateAction<string>) => {},
  isCanopy: true,
  setIsCanopy: (data: React.SetStateAction<boolean>) => {},
  currentCanopyInsurer: { name: '', carrier_id: '', homepageUrl: '' },
  setCurrentCanopyInsurer: (data: React.SetStateAction<CanopyInsurerType>) => {},
  priorPolicyCoverage: { ...defaultPriorPolicyCoverage },
  setPriorPolicyCoverage: (data: React.SetStateAction<PriorPolicyCoverageType>) => {},
  canopyPullId: '',
  setCanopyPullId: (data: React.SetStateAction<string>) => {},
  shouldSkipCanopy: false,
  AfiUserInfo: { token: '', user: {} },
  AfiUserState: 'loading',
  requestId: '',
  setRequestId: (data: React.SetStateAction<string>) => {},
});

const getDefaultAfiData = () => {
  try {
    // Attempt to gather data from session storage
    const storageObject = JSON.parse(sessionStorage.getItem('afi_answers') as string);
    // Match session storage data to the template object, this will prevent users from manually adding or removing fields
    const matchedObject = _.defaultsDeep(storageObject, AfiAnswersTemplate);
    // Update the session storage to the correct structure
    sessionStorage.setItem('afi_answers', JSON.stringify(matchedObject));
    return matchedObject;
  } catch (error) {
    // This will trigger if the object was not found or its syntax is wrong
    sessionStorage.setItem('afi_answers', JSON.stringify(AfiAnswersTemplate));
    return AfiAnswersTemplate;
  }
};

const getDefaultAfiNavigation = (dataObject: any) => {
  const newObj = _.cloneDeep(defaultNavigation);
  _.assign(newObj, updateCoverageSteps(newObj, dataObject.basics.address).newNavigation);
  _.assign(newObj, updateCarsSteps(newObj, dataObject));
  _.assign(newObj, addCarFieldsToCoverage(newObj, dataObject).newNavigation);
  _.assign(newObj, toggleDriverSteps(newObj, dataObject.drivers[0].history));
  _.assign(newObj, toggleDiscountsByAge(newObj, dataObject.drivers[0].details));

  return newObj;
};

const getDefaultCanopyNavigation = (dataObject: any) => {
  const newObj = _.cloneDeep(defaultNavigation);
  delete newObj.coverage;
  _.assign(newObj, updateCarsSteps(newObj, dataObject));
  _.assign(newObj, toggleDriverSteps(newObj, dataObject.drivers[0].history));
  _.assign(newObj, toggleDiscountsByAge(newObj, dataObject.drivers[0].details));
  return newObj;
};

const AnswerFinancialContextProvider = ({ children }: { children: ReactNode }) => {
  const [AFIAnswersObject, setAFIAnswersObject] = useState(getDefaultAfiData());
  const [AfiNavigation, setAfiNavigation] = useState(getDefaultCanopyNavigation(getDefaultAfiData()));

  const [quotes, setQuotes] = useState<AfiQuoteType[]>([]);

  const [indexes, setIndexes] = useState({ ...AfiDefaultIndexes });

  const [AfiUserInfo, setAfiUserInfo] = useState<AfiUserType>({});
  const [AfiUserState, setAfiUserState] = useState<'loading' | 'error' | 'success'>('loading');
  const { setAuthToken, authToken, setShowErrorBanner } = useContext(UserContext);
  const [carriersError, setCarriersError] = useState(false);
  const [vehicleMake, setVehicleMake] = useState<string | null>(null);
  const [lastStepCompleted, setLastStepCompleted] = useState(
    sessionStorage.getItem('lastStepCompleted') || '',
  );
  const [isCanopy, setIsCanopy] = useState<boolean>(JSON.parse(sessionStorage.getItem('isCanopy') || 'true'));
  const [shouldSkipCanopy, setShouldSkipCanopy] = useState(
    JSON.parse(sessionStorage.getItem('should_skip_canopy') || 'false'),
  );
  const [canopyPullId, setCanopyPullId] = useState('');
  const [currentCanopyInsurer, setCurrentCanopyInsurer] = useState<CanopyInsurerType>(
    JSON.parse(sessionStorage.getItem('afi_current_insurer') as string) || '',
  );
  const [priorPolicyCoverage, setPriorPolicyCoverage] = useState(
    JSON.parse(sessionStorage.getItem('canopy_coverages') as string) || {},
  );
  const [requestId, setRequestId] = useState<string>(sessionStorage.getItem('requestId') || '');
  const getAfiAnswerVariables = (path: (string | number)[]) => {
    return _.get(AFIAnswersObject, path);
  };

  const [searchParams] = useSearchParams();

  const getUserData = async (id: string, encryptedEmail: string, partner: string) => {
    try {
      setAfiUserState('loading');
      const data = await getUserByIntegration(id, encryptedEmail, partner);
      if (data.data) {
        setAuthToken(data.data.token);
        setAfiUserInfo(data.data);
        const existingAfiData = await getAfiUserInfo(data.data.token);
        if (existingAfiData.data && existingAfiData.data.userInfo) {
          const newData = deserializeAfiData(existingAfiData.data.userInfo);
          _.assign(newData.basics.name, { emailAddress: data.data.user.email });
          setAFIAnswersObject(newData);
          sessionStorage.setItem('afi_answers', JSON.stringify(newData));
          setShouldSkipCanopy(true);
          sessionStorage.setItem('should_skip_canopy', 'true');
          setIsCanopy(existingAfiData.data.isCanopy);
          if (existingAfiData.data.isCanopy) {
            const parsedPriorData = {
              pastProvider: existingAfiData.data.userInfo.policy?.personal?.other?.pastProvider || '',
              amount: existingAfiData.data.userInfo.policy?.personal?.other?.amount || '',
              priorExpirationDate:
                existingAfiData.data.userInfo.policy?.personal?.other?.priorExpirationDate || '',
              vehicles:
                existingAfiData.data.userInfo.policy?.personalAutoLineBusiness?.vehicles?.map(
                  (elem: any) => elem.coverages,
                ) || [],
            };
            setCurrentCanopyInsurer({
              name: parsedPriorData.pastProvider,
              homepageUrl: '',
              carrier_id: parsedPriorData.pastProvider,
            });
            setPriorPolicyCoverage(parsedPriorData);
          }
        } else {
          const newData = AFIAnswersObject;
          _.assign(newData.basics.name, { emailAddress: data.data.user.email });
          setAFIAnswersObject(newData);
          sessionStorage.setItem('afi_answers', JSON.stringify(newData));
          setShouldSkipCanopy(false);
          sessionStorage.setItem('should_skip_canopy', 'false');
        }
      }
      setAfiUserState('success');
      return data.data;
    } catch (error) {
      setAfiUserState('error');
      setShowErrorBanner(true);
    }
  };
  // Get and Set the indexes sessionStorage
  useEffect(() => {
    const storedIndexes = sessionStorage.getItem('indexes');
    if (storedIndexes) {
      setIndexes(JSON.parse(storedIndexes));
    }
  }, []);

  // After getting the partner name
  useEffect(() => {
    const userId = (searchParams.get('id') as string) || sessionStorage.getItem('trackingId') || '';
    const userEmail = (searchParams.get('email') as string) || sessionStorage.getItem('afi_user_email') || '';
    const partner = (searchParams.get('utm_source') as string) || sessionStorage.getItem('partner') || '';
    sessionStorage.setItem('afi_user_email', userEmail);
    getUserData(userId, userEmail, partner);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('lastStepCompleted', lastStepCompleted);
  }, [lastStepCompleted]);

  useEffect(() => {
    sessionStorage.setItem('indexes', JSON.stringify(indexes));
  }, [indexes]);

  useEffect(() => {
    sessionStorage.setItem('isCanopy', JSON.stringify(isCanopy));
    updateCanopyNavigation(isCanopy);
  }, [isCanopy]);

  // Will modify an entire step
  const setAfiAnswerVariables = (newValues: any, path: (string | number)[], apiCall: boolean = true) => {
    setAFIAnswersObject((prev: any) => {
      let newObj = _.cloneDeep(prev);
      _.set(newObj, path, newValues);
      sessionStorage.setItem('afi_answers', JSON.stringify(newObj));
      if (apiCall) {
        submitUserData(serializeAfiData(_.defaultsDeep(newObj, AfiAnswersTemplate)));
      }
      return newObj;
    });
  };

  const submitUserData = async (data: any) => {
    if (authToken) {
      const submitData = await submitAfiUserInfo(authToken as string, data).then((data) => {
        return data;
      });
      return submitData;
    }
  };

  const updateCanopyNavigation = (newCanopyValue: boolean) => {
    const defaultAfiNavigation = getDefaultAfiNavigation(getDefaultAfiData());
    //if isCanopy is true, remove the coverages section, else reset the navigation to the original one
    setAfiNavigation(toggleAfiStepsForCanopy(newCanopyValue, defaultAfiNavigation));
  };

  return (
    <AnswerFinancialContext.Provider
      value={{
        AFIAnswersObject,
        setAFIAnswersObject,
        getAfiAnswerVariables,
        setAfiAnswerVariables,
        AfiNavigation,
        setAfiNavigation,
        submitUserData,
        indexes,
        setIndexes,
        quotes,
        setQuotes,
        carriersError,
        setCarriersError,
        vehicleMake,
        setVehicleMake,
        lastStepCompleted,
        setLastStepCompleted,
        isCanopy,
        setIsCanopy,
        canopyPullId,
        setCanopyPullId,
        currentCanopyInsurer,
        setCurrentCanopyInsurer,
        priorPolicyCoverage,
        setPriorPolicyCoverage,
        shouldSkipCanopy,
        AfiUserInfo,
        AfiUserState,
        setRequestId,
        requestId,
      }}
    >
      {children}
    </AnswerFinancialContext.Provider>
  );
};

export default AnswerFinancialContextProvider;

export const useAnswerFinancial = () => {
  return useContext(AnswerFinancialContext);
};
