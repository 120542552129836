import { useMemo } from 'react';
import './radio-button-container.scss';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

type RadioButtonContainerProps = {
  options: string[];
  color?: string;
  size: string;
  title?: string;
  value: string | number | boolean;
  text?: string;
  handleChange: (value: string) => void;
  labels?: string[];
  disabled?: boolean;
};
const RadioButtonContainer = ({
  options,
  size,
  color,
  title,
  value,
  handleChange,
  labels,
  disabled = false,
}: RadioButtonContainerProps) => {
  const renderButtons = useMemo(() => {
    if (size === 'large') {
      return options.map((option, idx) => {
        return (
          <div
            className={`radio-container radio-button-wrapper-${size}`}
            key={idx}
            onClick={(e) => !disabled && handleChange(option)}
          >
            <ToggleButton
              value={option}
              type="radio"
              disabled={disabled}
              onChange={(e) => handleChange(e.target.value)}
              checked={option === value}
              id={`radio-${idx}`}
              style={{ accentColor: color }}
            ></ToggleButton>
            <label className="radio-button-label">{labels ? labels[idx] : option}</label>
          </div>
        );
      });
    }
    return (
      <>
        {title && <h1 className="radio-button-title">{title}</h1>}
        <div className={`radio-button-wrapper-${size}`}>
          {options.map((option, idx) => {
            return (
              <div className="radio-container" key={idx} onClick={(e) => !disabled && handleChange(option)}>
                <ToggleButton
                  value={option}
                  type="radio"
                  disabled={disabled}
                  onChange={(e) => handleChange(e.target.value)}
                  checked={option === value}
                  id={`radio-${idx}`}
                  style={{ accentColor: color }}
                ></ToggleButton>
                <label className="radio-button-label">{labels ? labels[idx] : option}</label>
              </div>
            );
          })}
        </div>
      </>
    );
  }, [value, disabled]);

  return (
    <ToggleButtonGroup className={`radio-button-container-${size}`} type="radio" name="radio">
      {renderButtons}
    </ToggleButtonGroup>
  );
};

export default RadioButtonContainer;
