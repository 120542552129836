import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import TimedErrorBanner from '../../components/common/error-banner/ErrorBanner';
import Header from '../../components/common/Header/Header';
import AfiProgressBar from '../../components/common/progress-bar/AfiProgressBar/AfiProgressBar';
import { AfiNavigationType } from '../../components/interfaces/AFIInterfaces';
import { AFI_ERROR_BANNER_DURATION, stepsFlow } from '../../constants/AFIConstants';
import { AnswerFinancialContext } from '../../context/AnswerFinancialContext';
import { UserContext } from '../../context/UserContext';
import { findCurrentStep, findNextStep, updateLastCompletedAFIStep } from '../../services/helpers/AFIHelpers';
import './afi.scss';
import AfiStepFactory from './AfiStepFactory';
import SummaryPage from './SummaryPage';
import mixpanelWrapper from '../../utils/tools/mixpanel';

const AfiStepNavigation = () => {
  const { headerContent, isMobile, showErrorBanner, setShowErrorBanner } = useContext(UserContext);
  const {
    AfiNavigation,
    setAfiNavigation,
    indexes,
    setAfiAnswerVariables,
    getAfiAnswerVariables,
    lastStepCompleted,
    setLastStepCompleted,
    isCanopy,
    priorPolicyCoverage,
    setIsCanopy,
    shouldSkipCanopy,
  } = useContext(AnswerFinancialContext);
  const navigate = useNavigate();

  // Navigation
  const { sectionId, stepId } = useParams();
  const [currentSection, setCurrentSection] = useState(AfiNavigation[sectionId as string] || undefined);
  const [currentStep, setCurrentStep] = useState(
    findCurrentStep(stepId as string, AfiNavigation[sectionId as string]) || undefined,
  );
  const sections = Object.keys(AfiNavigation);
  // This works as a safe guard for mistaken URLs coming from manual user input and will never trigger during normal navigation
  if (currentStep === undefined || currentSection === undefined) {
    return <Navigate to="/full-quote-auto/start" replace />;
  }

  useEffect(() => {
    // @ts-ignore
    mixpanelWrapper.track_pageview();
  }, [stepId, sectionId]);

  useEffect(() => {
    const currentStepProgress = `${currentSection.name}/${stepId}`;
    const indexCurrentStepProgress = stepsFlow.indexOf(currentStepProgress);
    const indexLastStep = stepsFlow.indexOf(lastStepCompleted);
    if (indexLastStep === -1 || indexCurrentStepProgress > indexLastStep + 1) {
      navigate(`/full-quote-auto/start`);
    }
    if (
      stepId === 'name' &&
      sectionId === 'basics' &&
      isCanopy &&
      !Object.keys(priorPolicyCoverage).length &&
      !shouldSkipCanopy
    ) {
      setIsCanopy(false);
    }
  }, [stepId]);

  useEffect(() => {
    const newSection = AfiNavigation[sectionId as string];
    setCurrentSection(newSection);
    const newStep = findCurrentStep(stepId as string, newSection);
    setCurrentStep(newStep);
  }, [sectionId, stepId, AfiNavigation]);

  const navigateNextStep = (navigation: AfiNavigationType) => {
    const nextStep = findNextStep(
      stepId as string,
      sectionId as string,
      findCurrentStep(stepId as string, navigation[sectionId as string]),
      navigation[sectionId as string],
      navigation,
    );
    navigate(`/full-quote-auto/${nextStep.section}/${nextStep.step}`);
  };

  const onSubmit = (newValues: any) => {
    updateLastCompletedAFIStep(
      `${currentSection.name}/${stepId}`,
      lastStepCompleted,
      setLastStepCompleted,
      newValues,
    );

    if (currentStep.onSubmitModifyOptions) {
      const newObj = currentStep.onSubmitModifyOptions(AfiNavigation, newValues);

      //Don't overwrite the prior policy details received by canopy
      if (!isCanopy) {
        setAfiAnswerVariables(newObj.newValues, ['coverage', 'new-coverage']);
      }
      setAfiNavigation(newObj.newNavigation);
      navigateNextStep(newObj.newNavigation);
    } else if (currentStep.customStepSubmit) {
      const newNavigation = currentStep.customStepSubmit(AfiNavigation, newValues);
      setAfiNavigation(newNavigation);
      navigateNextStep(newNavigation);
    } else {
      navigateNextStep(AfiNavigation);
    }
  };

  const onSummarySubmit = (newValues: any) => {
    updateLastCompletedAFIStep(
      `${currentSection.name}/${stepId}`,
      lastStepCompleted,
      setLastStepCompleted,
      newValues,
    );

    if (currentSection.customSubmitModifyValues && stepId === currentSection.sectionSummary) {
      const newObject = currentSection.customSubmitModifyValues(isCanopy, AfiNavigation, newValues);
      const allCoverageAnswers = {
        ...getAfiAnswerVariables(['coverage', 'new-coverage']),
        ...newObject?.newValues,
      };
      setAfiAnswerVariables(allCoverageAnswers, ['coverage', 'new-coverage']);
      setAfiNavigation(newObject?.newNavigation);
      navigateNextStep(newObject?.newNavigation);
    } else if (currentSection.customSectionSubmit && stepId === currentSection.sectionSummary) {
      const newNavigation = currentSection.customSectionSubmit(AfiNavigation, newValues);
      setAfiNavigation(newNavigation);
      navigateNextStep(newNavigation);
    } else {
      navigateNextStep(AfiNavigation);
    }
  };

  return (
    <div className="afi-step-factory-container">
      <Header headerContent={headerContent} showIcon={false} onIconClick={() => {}} />
      {showErrorBanner && (
        <TimedErrorBanner
          closeFunction={setShowErrorBanner}
          delay={AFI_ERROR_BANNER_DURATION}
          message="Uh oh! Something unexpected occurred. We were not able to process your data."
        />
      )}
      {isMobile && (
        <AfiProgressBar
          sections={sections}
          sectionId={sectionId as string}
          currentSection={currentSection}
          currentStep={currentStep}
          stepId={stepId as string}
          indexes={indexes}
        />
      )}
      {currentStep?.stepSummary === stepId || currentSection?.sectionSummary === stepId ? (
        <SummaryPage stepId={stepId as string} sectionId={sectionId as string} onSubmit={onSummarySubmit} />
      ) : (
        <AfiStepFactory sectionId={currentSection.name} stepId={currentStep.name} onSubmit={onSubmit} />
      )}
    </div>
  );
};

export default AfiStepNavigation;
