// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.88.2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-field-container {
  margin-bottom: 20px;
  font-family: var(--primary-font);
}
.question-field-container div.radio-button-container-small .radio-button-wrapper-small .radio-container {
  margin-bottom: 0px;
}
.question-field-container > div.afi-amount-field {
  position: relative;
}
.question-field-container > div.afi-amount-field::after {
  content: "\$";
  display: block;
  width: 1rem;
  height: 1.5rem;
  margin-right: 5px;
  font-size: 16px;
  color: var(--secondary-text-color);
  position: absolute;
  top: 49%;
  left: 16px;
  transform: translateY(-50%);
}
@media (min-width: 580px) {
  .question-field-container > div.afi-amount-field::after {
    top: 47%;
    font-size: 18px;
  }
}
.question-field-container > div.afi-amount-field.placeholder::after {
  opacity: 0.5;
  color: var(--primary-font-color);
}
.question-field-container > div.afi-amount-field input {
  padding-left: 26px;
}
@media (min-width: 580px) {
  .question-field-container {
    margin-bottom: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/afi/QuestionField/question-field.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,gCAAA;AACF;AAEI;EACE,kBAAA;AAAN;AAIE;EACE,kBAAA;AAFJ;AAGI;EACE,YAAA;EACA,cAAA;EACA,WAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;EACA,kCAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,2BAAA;AADN;AAGM;EAbF;IAcI,QAAA;IACA,eAAA;EAAN;AACF;AAIM;EACE,YAAA;EACA,gCAAA;AAFR;AAMI;EACE,kBAAA;AAJN;AASE;EA5CF;IA6CI,mBAAA;EANF;AACF","sourcesContent":[".question-field-container {\n  margin-bottom: 20px;\n  font-family: var(--primary-font);\n\n  div.radio-button-container-small {\n    .radio-button-wrapper-small .radio-container {\n      margin-bottom: 0px;\n    }\n  }\n\n  > div.afi-amount-field {\n    position: relative;\n    &::after {\n      content: '$';\n      display: block;\n      width: 1rem;\n      height: 1.5rem;\n      margin-right: 5px;\n      font-size: 16px;\n      color: var(--secondary-text-color);\n      position: absolute;\n      top: 49%;\n      left: 16px;\n      transform: translateY(-50%);\n      //tablet\n      @media (min-width: 580px) {\n        top: 47%;\n        font-size: 18px;\n      }\n    }\n\n    &.placeholder {\n      &::after {\n        opacity: 0.5;\n        color: var(--primary-font-color);\n      }\n    }\n\n    input {\n      padding-left: 26px;\n    }\n  }\n\n  //tablet\n  @media (min-width: 580px) {\n    margin-bottom: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
