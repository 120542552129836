import React, { Fragment, useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import {
  nextPageAnimationProps,
  stepAnimation,
} from '../../../pages/auto-insurance/constants';
import PrimaryButton from '../buttons/PrimaryButton';
import './step-component.scss';
import BackButton from '../buttons/BackButton';

type StepComponentProps = {
  header: string;
  subHeader?: string;
  cardImage?: React.ReactNode;
  className?: string;
  fields: React.ReactNode[];
  buttonTxt: string;
  disabledButton?: boolean;
  hideButton?: boolean;
  onSubmit: any;
  onClick?: () => boolean;
  hasApiCall?: boolean;
  onAPIClick?: (callback: Function) => void;
  error?: string;
  isLoading?: boolean;
};

const StepComponent = ({
  header,
  subHeader,
  cardImage,
  className,
  fields,
  buttonTxt,
  hideButton,
  onSubmit,
  onClick,
  onAPIClick,
  error,
  disabledButton,
  isLoading = false,
  hasApiCall = false,
}: StepComponentProps) => {
  const { stepAnimationType, setStepAnimationType } = useContext(UserContext);

  const handleComponentAnimation = () => {
    switch (stepAnimationType) {
      case 'slide-out':
        return 'animation_slide-out';
      case 'slide-in_navback':
        return 'animation_slide-in_navback';
      case 'slide-out_navback':
        return 'animation_slide-out_navback';
      default:
        return 'animation_slide-in';
    }
  };
  return (
    <div className="form-container">
      <div
        className={`form-factory-container ${
          !cardImage ? 'single-column' : ''
        } ${handleComponentAnimation()} ${className ? className : ''}`}
      >
        <div className="form-factory-title-container">
          <h1 className="form-factory-title">{header}</h1>
          {subHeader && <p className="form-factory-subheader">{subHeader}</p>}
        </div>

        {cardImage && (
          <div className="form-factory-image-container">
            <div>{cardImage}</div>
          </div>
        )}

        {/*display fields*/}
        <div
          className={`content-container ${!cardImage ? 'full-width' : ''}`}
          style={{ width: '100%' }}
        >
          {fields.map((field, idx) => {
            return <Fragment key={idx}>{field}</Fragment>;
          })}
          {error && <h4>{error}</h4>}
          {!hideButton && (
            <PrimaryButton
              text={buttonTxt}
              loading={isLoading}
              onClick={() => {
                if (hasApiCall) {
                  const submitWithAnimation = (
                    flag: boolean,
                    recommendationLink: string
                  ) => {
                    stepAnimation(
                      setStepAnimationType,
                      nextPageAnimationProps,
                      () => onSubmit(flag, recommendationLink)
                    );
                  };
                  onAPIClick && onAPIClick(submitWithAnimation);
                  return;
                }

                if (!onClick) {
                  stepAnimation(
                    setStepAnimationType,
                    nextPageAnimationProps,
                    onSubmit
                  );
                } else {
                  const hasErrors: boolean = onClick();
                  !hasErrors &&
                    stepAnimation(
                      setStepAnimationType,
                      nextPageAnimationProps,
                      onSubmit
                    );
                }
              }}
              disabled={disabledButton}
            />
          )}
          <BackButton/>
        </div>
      </div>
    </div>
  );
};

export default StepComponent;
